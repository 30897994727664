import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormLabel, FormGroup, FormControl, FilledInput, TextField, Button, Icon, IconButton, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useReactToPrint } from 'react-to-print';

import Loader from '../../common/loader';
import WeekPicker from '../../common/week-picker';
import ChecksToPrint from '../components/checks-to-print';

import AuthContext from '../../context';
import helpers from '../../utils/helpers';
import { get } from '../../utils/api-services';

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 20
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: 20
    },
    search: {
        marginLeft: 20,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    print: {
        backgroundColor: '#6E6E6E',
        borderColor: '#6E6E6E',
        '&:hover': {
            backgroundColor: '#6E6E6E',
            borderColor: '#6E6E6E',
            boxShadow: 'none'
        }
    },
    tableContainer: {
        width: '100%'
    },
    checksPrint: {
        display: 'none'
    }
}));

const ChecksList = ({ permissions }) => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const [showPicker, setShowPicker] = useState(false);
    const [selectedChecks, setSelectedChecks] = useState([]);
    const [state, setState] = useState({
        loading: true,
        suppliers: [],
        filter: {
            code: '',
            week: '',
            supplier: { id: 0, name: 'All suppliers' }
        },
        checks: [],
        checksPrint: [],
        permissions: {}
    });

    const printRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printRef.current
    });

    useEffect(() => {
        setState(prev => ({ ...prev, permissions }));
    }, [permissions]);

    useEffect(() => {
        window.document.title = 'Checks';

        makeRequest();
    }, []);

    useEffect(() => {
        if (state.checksPrint.length > 0) {
            handlePrint();
        }
    }, [state.checksPrint]);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/checks');
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                suppliers: response?.suppliers ?? []
            }));
        } else {
            console.log('[ChecksList] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const handleChange = ({ target: { name, value } }) => setState(prev => ({ ...prev, filter: { ...prev.filter, [name]: value } }));

    const handleChangeAutocomplete = (key, value) => setState(prev => ({ ...prev, filter: { ...prev.filter, [key]: value } }));

    const setWeek = ({ week }) => {
        setShowPicker(false);
        setState(prev => ({ ...prev, filter: { ...prev.filter, week } }));
    }

    const search = async () => {
        const { code, week } = state.filter;
        if (code.trim() || week) {
            showLoader(true);
            const response = await get('/checks/search', state.filter);
            if (response.status === 200) {
                setState(prev => ({ ...prev, checks: response?.data ?? [] }));

                if (!response?.data[0]) showToast('There are no checks with these filters', 'warning');
            } else {
                console.log('[ChecksList] search error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        } else {
            showToast('You must select a week', 'error');
        }
    }

    const getCheckToPrint = async (idcheck = null) => {
        let form;
        if (idcheck === null) {
            const ids = selectedChecks;

            if (ids.length < 1) {
                showToast('Select some check', 'error');
                return null;
            }

            form = { idcheck: ids.join('-') };
        } else {
            form = { idcheck };
        }

        showLoader(true);
        const response = await get('/checks/print', form);
        if (response.status === 200) {
            setState(prev => ({ ...prev, checksPrint: response?.data ?? [] }));

            if (response.data?.length === 0) showToast('No checks to print', 'warning');
        } else {
            console.log('[ChecksList] getCheckToPrint error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const valueGetter = params => params.value.name;

    const renderTotal = params => `$ ${params.value}`;

    const renderCell = ({ row }) => {
        return (
            <div>
                <Link to={`/ticket/checks/${row.idcheck}`} target='_blank'>
                    <Tooltip title='Show detail' placement='top'>
                        <IconButton>
                            <Icon>{'visibility'}</Icon>
                        </IconButton>
                    </Tooltip>
                </Link>
                <Tooltip title='Print' placement='top'>
                    <IconButton onClick={() => getCheckToPrint(row.idcheck)}>
                        <Icon>{'print'}</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

    const columns = [
        { field: 'week', headerName: 'Week', flex: 0.5 },
        { field: 'code', headerName: 'Check No.', flex: 1 },
        { field: 'supplier', headerName: 'Supplier', flex: 1, valueGetter },
        { field: 'state', headerName: 'Status', flex: 0.5 },
        { field: 'total', headerName: 'Total', flex: 1, valueGetter: renderTotal },
        { field: 'detail', headerName: 'Actions', flex: 1, renderCell }
    ]

    if (state.loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Check No.'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Check No.'
                                name='code'
                                onChange={handleChange}
                                value={state.filter.code}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={4}>
                    <FormGroup onClick={() => setShowPicker(true)}>
                        <FormLabel>{'Week'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                readOnly
                                type='text'
                                placeholder='Select a week'
                                value={state.filter.week}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Supplier'}</FormLabel>
                        <Autocomplete
                            disabled={Boolean(state.filter.code.trim())}
                            options={state.suppliers}
                            value={state.filter.supplier}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleChangeAutocomplete('supplier', value)}
                            renderInput={params => <TextField {...params} placeholder='Supplier' />}
                        />
                    </FormGroup>
                </Grid>
            </Grid>

            <div className={classes.buttonsContainer}>
                <Button
                    variant='contained'
                    className={classes.print}
                    onClick={() => getCheckToPrint()}
                >
                    {'Print selected'}
                </Button>
                <Button
                    variant='contained'
                    className={classes.search}
                    onClick={search}
                >
                    {'Search'}
                </Button>
            </div>

            <div className={classes.checksPrint}>
                <div ref={printRef}>
                    <ChecksToPrint data={state.checksPrint} />
                </div>
            </div>

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.checks}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idcheck}
                    checkboxSelection
                    disableSelectionOnClick
                    onSelectionModelChange={checks => setSelectedChecks(checks)}
                />
            </div>

            <WeekPicker
                open={showPicker}
                onClose={() => setShowPicker(false)}
                setWeek={setWeek}
            />
        </div>
    );
}

export default ChecksList;