import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, TextField, Tooltip, IconButton, Icon } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLocation, Link } from 'react-router-dom';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import ViewTitle from '../../common/view-title';
import Trash from '../../common/trash';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%'
    }
}));

const Lands = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [state, setState] = useState({
        loading: true,
        owners: [],
        lands: [],
        land: {
            idland: 0,
            code: '',
            name: '',
            latitude: '',
            longitude: '',
            idland_owner: 0,
            owner: {}
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Lands';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/lands');
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                lands: response?.data ?? [],
                owners: response?.owners ?? [],
                land: {
                    ...prev.land,
                    owner: response?.owners[0] ?? {},
                }
            }));
        } else {
            console.log('[Lands] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, land: { ...prev.land, [target.name]: target.value } }));

    const validateForm = (type) => {
        const { idland, code, name, owner } = state.land;

        switch (type) {
            case 'update':
                const data = state.lands.filter(value => value.idland === idland);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.land);
                    if (before !== after) {
                        if (code.trim() && name.trim() && owner.id) {
                            return true;
                        } else {
                            showToast('All fields is required', 'error');
                            return false;
                        }
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select some land', 'error');
                    return false;
                }

            case 'store':

                if (code.trim() && name.trim() && owner.id) {
                    return true;
                } else {
                    showToast('All fields is required', 'error');
                    return false;
                }

            case 'delete':
                if (idland === 0) {
                    showToast('Select some land', 'error');
                    return false;
                }
                const cond = window.confirm(`Are you sure you want to delete this land: ${code}?`);

                return Boolean(idland && cond);
            default:
                break;
        }
    }

    const clear = () => setState(prev => ({
        ...prev,
        land: {
            ...prev.land,
            idland: 0,
            code: '',
            name: '',
            latitude: '',
            longitude: '',
            owner: prev.owners[0],
        }
    }));


    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/land/store', state.land);
            if (response.status === 200) {
                setState(prev => ({ ...prev, lands: response?.data ?? prev.lands }));
                showToast(response.message);
            } else {
                console.log('[Lands] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false;
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { idland } = state.land;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/land/${idland}/update`, state.land);
            if (response.status === 200) {
                setState(prev => ({ ...prev, lands: response?.data ?? prev.lands }));
                showToast(response.message);
            } else {
                console.log('[Lands] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idland } = state.land;
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/land/${idland}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, lands: response?.data ?? prev.lands }));
                showToast(response.message);
            } else {
                console.log('[Lands] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const valueGetter = params => params.value.name;

    const renderCell = ({ row }) => {
        return (
            <Link to={`/history/lands/${row.idland}`} target='_blank'>
                <Tooltip title='Show history' placement='top'>
                    <IconButton>
                        <Icon>{'history'}</Icon>
                    </IconButton>
                </Tooltip>
            </Link>
        );
    }

    const columns = [
        { field: 'code', headerName: 'Code', flex: 1, minWidth: 150 },
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 180 },
        { field: 'owner', headerName: 'Owner', flex: 1, minWidth: 180, valueGetter },
        { field: 'latitude', headerName: 'Latitude', flex: 1, minWidth: 150 },
        { field: 'longitude', headerName: 'Longitude', flex: 1, minWidth: 150 },
        { field: 'history', headerName: 'Actions', flex: 0.5, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }

    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle sedeFlag title='Lands' />
            <Grid container>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Code'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Code'
                                name='code'
                                value={state.land.code}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Name'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Name'
                                name='name'
                                value={state.land.name}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Land owner'}</FormLabel>

                        <Autocomplete
                            options={state.owners}
                            value={state.land.owner}
                            getOptionLabel={owner => owner?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, owner) => {
                                setState(prev => ({ ...prev, land: { ...prev.land, owner } }));
                            }}
                            renderInput={(params) => <TextField {...params} placeholder='Land owner' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Latitude'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Latitude'
                                name='latitude'
                                value={state.land.latitude}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Longitude'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Longitude'
                                name='longitude'
                                value={state.land.longitude}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            {
                state.permissions.delete_attr &&
                <Trash table='lands' id='idland' onRefresh={makeRequest} />
            }

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.lands}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idland}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, land: row }))}
                />
            </div>
        </div>
    );
}

export default Lands;