import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Barcode from 'react-barcode';

import '../../assets/css/print.css';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        pageBreakAfter: 'auto'
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        pageBreakInside: 'avoid',
    },
    name: {
        color: '#283851',
        paddingLeft: 20
    }
}));

const StationsToPrint = ({ data, other: { sections, positions } }) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div>
                {
                    data?.map(station =>
                        sections?.split(',').map(section =>
                            Array.from(Array(Number(positions)).keys()).map(position => {
                                const result = `${station.description}${section?.toUpperCase()}${position + 1}`;

                                return (
                                    <div key={`${station.idstation}-${section}-${position + 1}`} className={classes.container}>
                                        <Barcode value={result} />
                                        <h3 className={classes.name}>{result}</h3>
                                    </div>
                                );
                            })
                        )
                    )
                }
            </div>
        </div >
    );
}

export default StationsToPrint;