import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Button, TextField, Tooltip, IconButton, Icon } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import Loader from '../../common/loader';
import NotFound from '../../common/not-found';
import DateRange from '../../common/date-range';

import AuthContext from '../../context';
import { get } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        width: '100%',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    divider: {
        marginBottom: 24
    },
    tableContainer: {
        width: '100%'
    }
}));

const ProcessIndex = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const [state, setState] = useState({
        loading: true,
        filters: {
            code: '',
            startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
            dateRange: `${dayjs().subtract(1, 'day').format('MM/DD/YYYY')} - ${dayjs().format('MM/DD/YYYY')}`,
            employee: { id: 0, name: 'All employees' },
        },
        employees: [],
        processes: [],
        rangeModal: {
            open: false,
            range: [
                {
                    startDate: new Date(dayjs().subtract(1, 'day').toDate()),
                    endDate: new Date(),
                    key: 'main'
                }
            ]
        },
        permissions: {}
    });

    useEffect(() => {
        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(location.pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/processes', state.filters);
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                processes: response?.data ?? [],
                employees: response?.employees ?? []
            }));
        } else {
            console.log('[ProcessIndex] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }


    const handleChange = ({ target }) => setState(prev => ({ ...prev, filters: { ...prev.filters, [target.name]: target.value } }));

    const handleAutocomplete = (key, value) => setState(prev => ({ ...prev, filters: { ...prev.filters, [key]: value } }));

    const handleDateChange = item => {
        const { startDate, endDate } = item.main;
        const dateRange = `${dayjs(startDate.toISOString()).format('MM/DD/YYYY')} - ${dayjs(endDate.toISOString()).format('MM/DD/YYYY')}`;
        setState(prev => ({
            ...prev,
            filters: {
                ...prev.filters,
                dateRange,
                startDate: dayjs(startDate.toISOString()).format('YYYY-MM-DD'),
                endDate: dayjs(endDate.toISOString()).format('YYYY-MM-DD')
            },
            rangeModal: { ...prev.rangeModal, range: [item.main] }
        }));
    }

    const handleModal = () => setState(prev => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));

    const search = async () => {
        showLoader(true);
        const response = await get('/processes/search', state.filters);
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                processes: response?.data ?? []
            }));
        } else {
            console.log('[ProcessIndex] search error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    const valueGetter = params => params.value?.name;
    const getCode = ({ row }) => row?.detail?.ticket?.code;
    const renderCell = ({ row }) => {
        return (
            <Tooltip title='Show detail' placement='right'>
                <IconButton onClick={() => history.push('/process/processes/detail', { code: row?.detail?.ticket?.code })}>
                    <Icon>{'visibility'}</Icon>
                </IconButton>
            </Tooltip>
        );
    }

    const columns = [
        { field: 'date', headerName: 'Date', flex: 1, minWidth: 180 },
        { field: 'employee', headerName: 'Employee', flex: 1, minWidth: 180, valueGetter },
        { field: 'code', headerName: 'Ticket No.', flex: 1, minWidth: 18, valueGetter: getCode },
        { field: 'total_qty', headerName: 'Processed', flex: 1, minWidth: 180 },
        { field: 'pending_qty', headerName: 'Pending', flex: 1, minWidth: 180 },
        { field: 'detail', headerName: 'Detail', flex: 0.5, renderCell }
    ];

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={6} md={3}>
                    <FormGroup>
                        <FormLabel>{'Ticket No.'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Ticket No.'
                                name='code'
                                value={state.filters.code}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <FormGroup onClick={handleModal}>
                        <FormLabel>{'Date range'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                readOnly
                                type='text'
                                placeholder='Date range'
                                value={state.filters.dateRange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={4}>
                    <FormGroup>
                        <FormLabel>{'Employee'}</FormLabel>
                        <Autocomplete
                            options={state.employees}
                            value={state.filters.employee}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleAutocomplete('employee', value)}
                            renderInput={params => <TextField {...params} placeholder='Employee' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={2} className={classes.buttonContainer}>
                    <Button
                        variant='contained'
                        className={classes.button}
                        onClick={search}
                    >
                        {'Search'}
                    </Button>
                </Grid>

            </Grid>

            <div className={classes.tableContainer}>
                <DataGrid
                    autoHeight
                    pageSize={45}
                    density='compact'
                    columns={columns}
                    rows={state.processes}
                    getRowId={row => row.idticket_process}
                />
            </div>

            <DateRange
                open={state.rangeModal.open}
                range={state.rangeModal.range}
                onClose={handleModal}
                onChange={handleDateChange}
            />
        </div>
    );
}

export default ProcessIndex;