import React, { useState, useEffect, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Select, MenuItem, Tooltip, IconButton, Icon } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import Loader from '../../common/loader';
import Trash from '../../common/trash';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    tableContainer: {
        width: '100%',
    }
}));

const Users = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [state, setState] = useState({
        loading: true,
        roles: [],
        users: [],
        user: {
            iduser: 0,
            username: '',
            names: '',
            surnames: '',
            email: '',
            status: 'active',
            role: {},
            special_permission: false
        },
        permissions: {},
    });

    useEffect(() => {
        window.document.title = 'Users';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions?.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/users');
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                users: response?.data ?? [],
                roles: response?.roles ?? [],
                user: {
                    ...prev.user,
                    role: response?.roles[0] ?? {}
                }
            }));
        } else {
            console.log('[Users] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, user: { ...prev.user, [target.name]: target.value } }));

    const validateForm = (type) => {
        const { iduser, username, names, surnames, status, role } = state.user;

        switch (type) {
            case 'update':
                const data = state.users.filter(value => value.iduser === iduser);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.user);
                    if (before !== after) {
                        if (username.trim() && names.trim() && surnames.trim() && status.trim() && role.id) {
                            return true;
                        } else {
                            showToast('All fields is required', 'error');
                            return false;
                        }
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select a data', 'error');
                    return false;
                }

            case 'store':

                if (username.trim() && names.trim() && surnames.trim() && status.trim() && role.id) {
                    return true;
                } else {
                    showToast('All fields is required', 'error');
                    return false;
                }

            case 'delete':
                if (iduser === 0) {
                    showToast('Select a data', 'error');
                    return false;
                }
                const cond = window.confirm('Are you sure you want to delete this user?');

                return Boolean(iduser && cond);
            default:
                break;
        }
    }

    const clear = () => {
        setState(prev => ({
            ...prev,
            user: {
                ...prev.user,
                iduser: 0,
                username: '',
                names: '',
                surnames: '',
                email: '',
                status: 'active',
                role: prev.roles[0],
                special_permission: false
            }
        }));
    }

    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/user/store', { ...state.user });
            if (response.status === 200) {
                setState(prev => ({ ...prev, users: response?.data ?? prev.users }));
                showToast(response.message);
            } else {
                console.log('[Users] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false;
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { iduser } = state.user;

        const cond = validateForm('update');
        if (cond) {
            showLoader(true);
            const response = await put(`/user/${iduser}/update`, { ...state.user });
            if (response.status === 200) {
                setState(prev => ({ ...prev, users: response?.data ?? prev.users }));
                showToast(response.message);
            } else {
                console.log('[Users] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { iduser } = state.user;

        const cond = validateForm('delete');
        if (cond) {
            showLoader(true);

            const response = await del(`/user/${iduser}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, users: response?.data ?? prev.users }));
                showToast(response.message);
            } else {
                console.log('[Users] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const valueGetter = params => params.value.name;

    const valueGetterBool = ({ value }) => value ? 'Yes' : 'No';

    const renderCell = ({ row }) => {
        return (
            <Link to={`/history/users/${row.iduser}`} target='_blank'>
                <Tooltip title='Show history' placement='top'>
                    <IconButton>
                        <Icon>{'history'}</Icon>
                    </IconButton>
                </Tooltip>
            </Link>
        );
    }

    const columns = [
        { field: 'username', headerName: 'Username', flex: 1, minWidth: 180 },
        { field: 'names', headerName: 'Names', flex: 1, minWidth: 180 },
        { field: 'surnames', headerName: 'Surnames', flex: 1, minWidth: 180 },
        { field: 'email', headerName: 'Email', flex: 1, minWidth: 180 },
        { field: 'role', headerName: 'Role', flex: 1, minWidth: 120, valueGetter },
        { field: 'special_permission', headerName: 'Special permission', flex: 1, minWidth: 120, valueGetter: valueGetterBool },
        { field: 'actions', headerName: 'Actions', flex: 0.5, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }

    if (!state.permissions?.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <h1>{'Users'}</h1>
            <div>
                <Grid container>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Username'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Username'
                                    name='username'
                                    value={state.user.username}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Names'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Names'
                                    name='names'
                                    value={state.user.names}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Surnames'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Surnames'
                                    name='surnames'
                                    value={state.user.surnames}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Email'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Email'
                                    name='email'
                                    value={state.user.email}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Role'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <Select
                                    value={state.user.role.id}
                                    onChange={({ target }) => setState(prev => ({ ...prev, user: { ...prev.user, role: { id: target.value } } }))}
                                >
                                    {
                                        state.roles.map(value => (
                                            <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Special permission'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <Select
                                    name='special_permission'
                                    value={state.user.special_permission}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={true}>{'Yes'}</MenuItem>
                                    <MenuItem value={false}>{'No'}</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
            </div>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            {
                state.permissions.delete_attr &&
                <Trash table='users' id='iduser' onRefresh={makeRequest} />
            }

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.users}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.iduser}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, user: row }))}
                />
            </div>
        </div>
    );
}

export default Users;