import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal, FilledInput, Typography, Button, FormGroup, FormLabel, FormControl, Box, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import themeColors from '../../assets/colors';
import { post } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import AuthContext from '../../context';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.background.default,
        padding: 20,
        width: '80%',
        borderRadius: 10
    },
    store: {
        marginLeft: 15,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    bottom: {
        display: 'flex',
        alignSelf: 'flex-end',
    }
}));

const CreateTicket = ({ open, onClose, arrays }) => {

    const sede = JSON.parse(localStorage.getItem('@sede'));
    const classes = useStyles();
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const history = useHistory();
    const [state, setState] = useState({
        suppliers: arrays?.suppliers ?? [],
        lands: arrays?.lands ?? [],
        locations: arrays?.locations ?? [],
        ticket: {
            code: '',
            date: dayjs().format('YYYY-MM-DD'),
            supplier: arrays?.suppliers[0],
            land: arrays?.lands[0],
            location: arrays?.locations[0]
        }
    });

    const handleAutocomplete = (key, value) => setState(prev => ({ ...prev, ticket: { ...prev.ticket, [key]: value } }));

    const handleChange = ({ target }) => setState(prev => ({ ...prev, ticket: { ...prev.ticket, [target.name]: target.value } }));

    const validateForm = () => {
        const { code, date, supplier, land, location } = state.ticket;

        if (code.trim() && date && supplier.id && land.id && location.id) {
            const cond = window.confirm('Confirm if all data is correct');
            if (cond) {
                store();
            }
        } else {
            showToast('All fields is required', 'error');
        }
    }

    const store = async () => {
        showLoader(true);
        const response = await post('/ticket/store', state.ticket);
        if (response.status === 200) {
            history.push(`/ticket/tickets/${response.id}`);
            showToast(response.message);
        } else {
            console.log('[CreateTickets] store error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.root}
        >
            <div className={classes.container}>
                <Typography variant='h2'>{'Create ticket'}</Typography>
                <Grid container>
                    <Grid item xs={12} lg={4}>
                        <FormGroup>
                            <FormLabel>{'No. ticket'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Code'
                                    name='code'
                                    value={state.ticket.code}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Date'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='date'
                                    placeholder='Date'
                                    name='date'
                                    value={state.ticket.date}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Supplier'}</FormLabel>
                            <Autocomplete
                                options={state.suppliers}
                                value={state.ticket.supplier}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(e, value) => handleAutocomplete('supplier', value)}
                                renderInput={params => <TextField {...params} placeholder='Supplier' />}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Land'}</FormLabel>
                            <Autocomplete
                                options={state.lands}
                                value={state.ticket.land}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(e, value) => handleAutocomplete('land', value)}
                                renderInput={params => <TextField {...params} placeholder='Land' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Location'}</FormLabel>
                            <Autocomplete
                                options={state.locations}
                                value={state.ticket.location}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(e, value) => handleAutocomplete('location', value)}
                                renderInput={params => <TextField {...params} placeholder='Location' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Sede'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    type='text'
                                    value={sede.name}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
                <div className={classes.bottom}>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={onClose}
                    >
                        {'Cancel'}
                    </Button>
                    <Button
                        variant='contained'
                        className={classes.store}
                        onClick={validateForm}
                    >
                        {'Store'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default CreateTicket;