import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormLabel, FormGroup, FormControl, FilledInput, TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactExport from 'react-data-export';
import dayjs from 'dayjs';

import Loader from '../../common/loader';
import DateRange from '../../common/date-range';

import AuthContext from '../../context';
import helpers from '../../utils/helpers';
import { get } from '../../utils/api-services';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    buttonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        marginBottom: 20
    },
    search: {
        backgroundColor: '#198754',
        borderColor: '#198754',
        '&:hover': {
            backgroundColor: '#198754',
            borderColor: '#198754',
            boxShadow: 'none',
        }
    },
    download: {
        marginRight: 20,
        backgroundColor: '#6E6E6E',
        borderColor: '#6E6E6E',
        '&:hover': {
            backgroundColor: '#6E6E6E',
            borderColor: '#6E6E6E',
            boxShadow: 'none'
        }
    }
}));

const defSede = { id: 0, name: 'All sedes' };

const TicketSedeTransferReport = ({ permissions }) => {
    
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const [state, setState] = useState({
        loading: true,
        sedes: [],
        filter: {
            sede: defSede,
            startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
            dateRange: `${dayjs().subtract(1, 'day').format('MM/DD/YYYY')} - ${dayjs().format('MM/DD/YYYY')}`
        },
        data: [],
        rangeModal: {
            open: false,
            range: [
                {
                    startDate: new Date(dayjs().subtract(1, 'day').toDate()),
                    endDate: new Date(),
                    key: 'main'
                }
            ]
        },
        permissions: {}
    });

    useEffect(() => {
        setState(prev => ({ ...prev, permissions }));
    }, [permissions]);

    useEffect(() => {
        window.document.title = 'TicketSedeTransferReport';

        makeRequest();
    }, []);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/reports/indexTicketTransfer');
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                sedes: response?.sedes ?? []
            }));
        } else {
            console.log('[TicketSedeTransferReport] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const handleDateChange = item => {
        const { startDate, endDate } = item.main;
        const dateRange = `${dayjs(startDate.toISOString()).format('MM/DD/YYYY')} - ${dayjs(endDate.toISOString()).format('MM/DD/YYYY')}`;
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                dateRange,
                startDate: dayjs(startDate.toISOString()).format('YYYY-MM-DD'),
                endDate: dayjs(endDate.toISOString()).format('YYYY-MM-DD')
            },
            rangeModal: { ...prev.rangeModal, range: [item.main] }
        }));
    }

    const handleModal = () => {
        setState(prev => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));
    }

    const handleChangeAutocomplete = (key, value) => setState(prev => ({ ...prev, filter: { ...prev.filter, [key]: value } }));

    const search = async () => {
        showLoader(true);
        const response = await get('/reports/searchTicketTransfer', state.filter);
        if (response.status === 200) {
            setState(prev => ({ ...prev, data: response?.data ?? [] }));

            if (!response?.data[0]) showToast('There are no data with these filters', 'warning');
        } else {
            console.log('[TicketSedeTransferReport] search error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    if (state.loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={6} lg={3}>
                    <FormGroup onClick={handleModal}>
                        <FormLabel>{'Date range'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                readOnly
                                autoComplete='off'
                                type='text'
                                placeholder='Date range'
                                name='range'
                                value={state.filter.dateRange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Origin'}</FormLabel>
                        <Autocomplete
                            options={state.sedes}
                            value={state.filter.sede}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleChangeAutocomplete('sede', value)}
                            renderInput={params => <TextField {...params} placeholder='Origin' />}
                        />
                    </FormGroup>
                </Grid>
            </Grid>

            <div className={classes.buttonsContainer}>
                {
                    state.data.length > 0 &&
                    <ExcelFile
                        filename={`${dayjs().format('YYYY-MM-DD')}-ticket-sede-transfer-report`}
                        element={<Button variant='contained' className={classes.download}>{'Download data'}</Button>}
                    >
                        <ExcelSheet data={state.data} name='Sede transfer report'>
                            <ExcelColumn label='Transfer ID' value='idinv_ticket_sede_transfer' />
                            <ExcelColumn label='Transfer date' value='date' />
                            <ExcelColumn label='Transfer notes' value='notes' />
                            <ExcelColumn label='Transfer status' value='status' />
                            <ExcelColumn label='Transfer origin code' value='origin.code' />
                            <ExcelColumn label='Transfer origin name' value='origin.name' />
                            <ExcelColumn label='Transfer destination code' value='destination.code' />
                            <ExcelColumn label='Transfer destination name' value='destination.name' />
                            <ExcelColumn label='Ticket code' value='tickets.code' />
                            <ExcelColumn label='Ticket date' value='tickets.date' />
                            <ExcelColumn label='Ticket product code' value='tickets.product.code' />
                            <ExcelColumn label='Ticket product name' value='tickets.product.name' />
                            <ExcelColumn label='Ticket product qty' value='product_qty' />
                        </ExcelSheet>
                    </ExcelFile>
                }

                <Button
                    variant='contained'
                    className={classes.search}
                    onClick={search}
                >
                    {'Search'}
                </Button>
            </div>

            <DateRange
                open={state.rangeModal.open}
                range={state.rangeModal.range}
                onClose={handleModal}
                onChange={handleDateChange}
                minDate={dayjs('2022-01-01').toDate()}
            />
        </div>
    );
}

export default TicketSedeTransferReport;