import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal, Typography, FilledInput, Button, FormGroup, FormLabel } from '@material-ui/core';

import themeColors from '../../assets/colors';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.background.default,
        padding: 20,
        width: '30%',
        borderRadius: 10,
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    inputContainer: {
        width: '90%',
        marginBottom: 15,
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    button: {
        width: '30%',
    }
}));

const Create = ({ open, onClose, form, onChange, store }) => {

    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div className={classes.container}>
                <Typography variant='h2'>{'Create module'}</Typography>
                <form noValidate autoComplete='off' className={classes.formContainer} >
                    <FormGroup className={classes.inputContainer}>
                        <FormLabel>{'Name'}</FormLabel>
                        <FilledInput
                            placeholder='Name'
                            name='name'
                            value={form.name}
                            onChange={onChange}
                        />
                    </FormGroup>
                    <FormGroup className={classes.inputContainer}>
                        <FormLabel>{'Icon'}</FormLabel>
                        <FilledInput
                            placeholder='Icon'
                            name='icon'
                            value={form.icon}
                            onChange={onChange}
                        />
                    </FormGroup>
                    <FormGroup className={classes.inputContainer}>
                        <FormLabel>{'Path'}</FormLabel>
                        <FilledInput
                            placeholder='Path'
                            name='path'

                            value={form.path}
                            onChange={onChange}
                        />
                    </FormGroup>
                </form>
                <div className={classes.buttonsContainer}>
                    <Button onClick={onClose} variant='contained' color='secondary' className={classes.button}>
                        {'Cancel'}
                    </Button>
                    <Button onClick={store} variant='contained' color='primary' className={classes.button}>
                        {'Store'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default Create;