import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import ViewTitle from '../../common/view-title';
import ProductionsMobile from './productions-mobile';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    rootDesktop: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20,
        width: '40%',
        alignSelf: 'center'
    }
}));

const Productions2 = () => {

    const classes = useStyles();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

    useEffect(() => {
        window.document.title = 'Productions';

        window.addEventListener('resize', handleWindowSize);

        return () => window.removeEventListener('resize', handleWindowSize);
    }, []);

    const handleWindowSize = () => setIsMobile(window.innerWidth < 500);

    return (
        <div className={isMobile ? classes.root : classes.rootDesktop}>
            <ViewTitle title='Productions by couple (parejas)' sedeFlag />

            <ProductionsMobile alone={false} />
        </div>
    );
}

export default Productions2;