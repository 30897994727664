import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import Loader from '../../common/loader';

const Logo = require('../../assets/images/logo-full.png').default;

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20
    },
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    logo: {
        '@media (min-width: 480px)': {
            width: '90%'
        },
        '@media (min-width: 720px)': {
            width: '60%'
        },
        '@media (min-width: 1000px)': {
            width: '40%'
        }
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        '@media (min-width: 480px)': {
            width: '90%'
        },
        '@media (min-width: 720px)': {
            width: '60%'
        },
        '@media (min-width: 1000px)': {
            width: '40%'
        }
    },
    button: {
        display: 'inherit',
        width: '35%',
        backgroundColor: '#198754',
        borderColor: '#198754',
        '&:hover': {
            backgroundColor: '#198754',
            borderColor: '#198754',
            boxShadow: 'none',
        },
    },
}));

const TicketSedeTransferMenu = ({ permissions }) => {

    const classes = useStyles();
    const [state, setState] = useState({
        loading: true,
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Ticket sede transfer';

        setState(prev => ({ ...prev, permissions, loading: false }));
    }, [permissions]);

    if (state.loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <img className={classes.logo} src={Logo} alt='logo cfg' />
                <div className={classes.buttonsContainer}>
                    {
                        state.permissions?.create_attr &&
                        <Button
                            variant='contained'
                            className={classes.button}
                            component={Link}
                            to={'/inventory/invTicketSedeTransfer/create'}
                        >
                            {'Start transfer'}
                        </Button>
                    }

                    <Button
                        variant='contained'
                        className={classes.button}
                        component={Link}
                        to={'/inventory/invTicketSedeTransfer/index'}
                    >
                        {'Search'}
                    </Button>
                </div>
                <div className={classes.buttonsContainer}>
                    <Button
                        variant='contained'
                        className={classes.button}
                        component={Link}
                        to={'/inventory/invTicketSedeTransfer/report'}
                    >
                        {'Report'}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default TicketSedeTransferMenu;