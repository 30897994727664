import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom';

import Loader from '../../common/loader';
import NotFound from '../../common/not-found';
import RecipeRaws from '../components/recipe-raws';
import RecipeMaterials from '../components/recipe-materials';
import RecipeLabors from '../components/recipe-labors';
import RecipeSubassemblies from '../components/recipe-subassemblies';

import AuthContext from '../../context';
import { get, post } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import useQuery from '../../utils/useQuery';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20
    },
    divider: {
        marginTop: 24,
        marginBottom: 24
    },
    table: {
        marginBottom: 0
    },
    tableContainer: {
        width: '100%',
        '& .total': {
            backgroundColor: '#E0F8E0',
            fontWeight: 'bold'
        }
    },
    buttonContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center'
    },
    secondary: {
        width: '100%',
        backgroundColor: '#A4A4A4',
        borderColor: '#A4A4A4',
        '&:hover': {
            backgroundColor: '#A4A4A4',
            borderColor: '#A4A4A4',
            boxShadow: 'none',
        }
    },
    status: {
        width: '100%',
        backgroundColor: '#B45F04',
        borderColor: '#B45F04',
        '&:hover': {
            backgroundColor: '#B45F04',
            borderColor: '#B45F04',
            boxShadow: 'none',
        }
    },
    supplier_shed: {
        width: '100%',
        backgroundColor: '#088A85',
        borderColor: '#088A85',
        '&:hover': {
            backgroundColor: '#088A85',
            borderColor: '#088A85',
            boxShadow: 'none',
        }
    }
}));

const RecipeDetail = ({ permissions }) => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const query = useQuery();
    const [state, setState] = useState({
        loading: true,
        id: query.get('id'),
        recipeField: { id: 0, name: 'Choose a product sale' },
        recipe: {},
        totals: {},
        recipeList: [],
        subassemblies: [],
        rawList: [],
        materialList: [],
        laborList: [],
        raws: [],
        materials: [],
        labors: [],
        permissions: {}
    });

    useEffect(() => {
        setState(prev => ({ ...prev, permissions }));
    }, [permissions]);

    useEffect(() => {
        window.document.title = 'Recipe';

        makeRequest();
    }, []);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get(`/recipe/detail`, { id: state?.id ?? 0 });
        if (response.status === 200) {
            const recipeField = Boolean(response.data) ? { id: response.data.idproduct_sale, name: `${response.data.code} - ${response.data.description}` } : { id: 0, name: 'Choose a product sale' };
            setState(prev => ({
                ...prev,
                loading: false,
                recipeField,
                recipe: response?.data ?? {},
                recipeList: response?.recipes ?? [],
                totals: response?.totals ?? {},
                subassemblies: response?.subassemblies ?? [],
                rawList: response?.rawList ?? [],
                materialList: response?.materialList ?? [],
                laborList: response?.laborList ?? [],
                raws: response?.raws ?? [],
                materials: response?.materials ?? [],
                labors: response?.labors ?? []
            }));
        } else {
            console.log('[RecipeDetail] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const syncTotals = async () => {
        const response = await get(`/recipe/${state.recipeField.id}/getTotals`);
        if (response.status === 200) {
            setState(prev => ({ ...prev, totals: response.data }));
        } else {
            console.log('[RecipeDetail] syncTotals error', response);
            helpers.failRequest(response, showToast, signOut)
        }
    }

    const searchRecipe = async (id) => {
        showLoader(true);
        if (id > 0) {
            const response = await get(`/recipe/search`, { id });
            if (response.status === 200) {
                setState(prev => ({
                    ...prev,
                    recipe: response.data,
                    totals: response.totals,
                    subassemblies: response.subassemblies,
                    raws: response.raws,
                    materials: response.materials,
                    labors: response.labors
                }));
                showToast(response.message);
            } else {
                console.log('[RecipeDetail] searchRecipe error', response);
                helpers.failRequest(response, showToast, signOut)
            }
        }
        showLoader(false);
    }

    const toggleRecipeStatus = async () => {
        const { active } = state.totals;

        showLoader(true);
        const response = await post(`/recipe/${state.recipeField.id}/toggleStatus`, { status: active });
        if (response.status === 200) {
            showToast(response.message);
            setState(prev => ({ ...prev, totals: { ...prev.totals, active: !active } }));
            syncTotals();
        } else {
            console.log('[RecipeDetail] toggleRecipeStatus error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const togglePreprocessedStatus = async () => {
        const { supplier_shed } = state.recipe;

        showLoader(true);
        const response = await post(`/recipe/${state.recipeField.id}/togglePreprocessed`, { supplier_shed: !supplier_shed });
        if (response.status === 200) {
            showToast(response.message);
            setState(prev => ({ ...prev, recipe: { ...prev.recipe, supplier_shed: !supplier_shed } }));
            syncTotals();
        } else {
            console.log('[RecipeDetail] togglePreprocessedStatus error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const updateRecipe = async (subassemblies, raws, materials, labors) => {
        setState(prev => ({ ...prev, subassemblies, raws, materials, labors }));

        syncTotals();
    }

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <div>
                <Grid container style={{ backgroundColor: (state.recipe?.supplier_shed === true) ? '#EFF2FB' : (state.totals?.active === false) ? '#FBEFEF' : 'transparent' }}>
                    <Grid item xs={12} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Product sale'}</FormLabel>
                            <Autocomplete
                                options={state.recipeList}
                                value={state.recipeField}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, recipeField) => {
                                    setState(prev => ({ ...prev, recipeField }));
                                    searchRecipe(recipeField.id);
                                }}
                                renderInput={(params) => <TextField {...params} placeholder='Product sale' />}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>{'Description'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    type='text'
                                    placeholder='Description'
                                    value={state.recipe?.description ?? ''}
                                />
                            </FormControl>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>{'Department'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    type='text'
                                    placeholder='Department'
                                    value={state.recipe?.department?.name ?? ''}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>

                    <Grid item xs={8} lg={2}>
                        {
                            state.recipe?.image ?
                                <img src={`https://cfg-images-bucket.s3.amazonaws.com/recipes/${state.recipe?.image}`} width='100%' alt='product' />
                                :
                                <span>{'Image N/A'}</span>
                        }
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <TableContainer component={Paper} className='MuiDataGrid-root'>
                                <Table size='small' className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{''}</TableCell>
                                            <TableCell>{'Total cost per product'}</TableCell>
                                            <TableCell>{'Total cost per pack'}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>{'Raws'}</TableCell>
                                            <TableCell>{`$ ${state.totals?.total_raw_unit ?? 0}`}</TableCell>
                                            <TableCell>{`$ ${state.totals?.total_raw_pack ?? 0}`}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{'Materials'}</TableCell>
                                            <TableCell>{`$ ${state.totals?.total_material_unit ?? 0}`}</TableCell>
                                            <TableCell>{`$ ${state.totals?.total_material_pack ?? 0}`}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{'Labors'}</TableCell>
                                            <TableCell>{`$ ${state.totals?.total_labor_unit ?? 0}`}</TableCell>
                                            <TableCell>{`$ ${state.totals?.total_labor_pack ?? 0}`}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><b>{'TOTAL:'}</b></TableCell>
                                            <TableCell><b>{`$ ${state.totals?.final_cost_product ?? 0}`}</b></TableCell>
                                            <TableCell><b>{`$ ${state.totals?.final_cost_pack ?? 0}`}</b></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </FormGroup>
                        {
                            Boolean(state.recipeField.id) &&
                            <div className={classes.buttonContainer}>
                                {
                                    (state.permissions.update_attr) &&
                                    <Grid item xs={12} md={6} lg={5} xl={3}>
                                        <FormGroup>
                                            <FormControl
                                                variant='filled'
                                                component={Box}
                                                width='100%'
                                            >
                                                <Button
                                                    variant='contained'
                                                    className={classes.status}
                                                    onClick={toggleRecipeStatus}
                                                >
                                                    {`${state.totals.active ? 'Deactivate' : 'Activate'} recipe`}
                                                </Button>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                }
                                {
                                    (state.permissions.update_attr) &&
                                    <Grid item xs={12} md={6} lg={5} xl={3}>
                                        <FormGroup>
                                            <FormControl
                                                variant='filled'
                                                component={Box}
                                                width='100%'
                                            >
                                                <Button
                                                    variant='contained'
                                                    className={classes.supplier_shed}
                                                    onClick={togglePreprocessedStatus}
                                                >
                                                    {`${state.recipe.supplier_shed ? 'Unmark' : 'Mark'} as Supplier (Shed)`}
                                                </Button>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                }
                                <Grid item xs={12} md={6} lg={5} xl={3}>
                                    <FormGroup>
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                        >
                                            <Link to={`/bom/recipe/history/${state.recipe?.idproduct_sale}`} target='_blank'>
                                                <Button
                                                    variant='contained'
                                                    className={classes.secondary}
                                                >
                                                    {'History'}
                                                </Button>
                                            </Link>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </div>
                        }
                    </Grid>
                </Grid>

                <Divider className={classes.divider} />
                <RecipeSubassemblies recipe={state.recipe} recipes={state.recipeList} data={state.subassemblies} permissions={state.permissions} syncTotals={syncTotals} updateRecipe={updateRecipe} />

                <Divider className={classes.divider} />
                <RecipeRaws recipe={state.recipe} raws={state.rawList} data={state.raws} permissions={state.permissions} subassemblies={state.subassemblies} syncTotals={syncTotals} />

                <Divider className={classes.divider} />
                <RecipeMaterials recipe={state.recipe} materials={state.materialList} data={state.materials} permissions={state.permissions} subassemblies={state.subassemblies} syncTotals={syncTotals} />

                <Divider className={classes.divider} />
                <RecipeLabors recipe={state.recipe} labors={state.laborList} data={state.labors} permissions={state.permissions} subassemblies={state.subassemblies} syncTotals={syncTotals} />

            </div>
        </div >
    );
}

export default RecipeDetail;