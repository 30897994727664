import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Barcode from 'react-barcode';

import '../../assets/css/print.css';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0,
        pageBreakAfter: 'auto'
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        pageBreakInside: 'avoid',
    },
    name: {
        color: '#283851',
        paddingLeft: 20
    }
}));

const EmployeeToPrint = ({ data }) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div>
                {
                    data?.map(employee => {
                        return (
                            <div key={employee.idemployee} className={classes.container}>
                                <Barcode value={employee.code} />
                                <h3 className={classes.name}>{employee.name}</h3>
                            </div>
                        );
                    })
                }
            </div>
        </div >
    );
}

export default EmployeeToPrint;