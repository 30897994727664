import React, { useState, useEffect, useContext } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Toolbar, List, ListItem, ListItemIcon, Icon, ListItemText, CircularProgress, Hidden, Collapse, Typography, Avatar } from '@material-ui/core';

import NavBar from './nav-bar';

import { get } from '../utils/api-services';
import themeColors from '../assets/colors';
import helpers from '../utils/helpers';
import AuthContext from '../context';

const drawerWidth = 210;

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth,
        paddingTop: 0
    },
    drawerContainer: {
        overflow: 'auto'
    },
    list: {
        alignItems: 'center'
    },
    item: {
        display: 'flex',
        flexDirection: 'row'
    },
    avatar: {
        width: '2.2rem',
        height: '2.2rem',
        marginRight: 10
    },
    user: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '8px 0'
    }
}));

const Parent = ({ children, module, updateModule }) => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { path } = useRouteMatch();
    const [showDrawer, setShowDrawer] = useState(false);
    const [showSedes, setShowSedes] = useState(false);
    const [state, setState] = useState({
        loading: true,
        user: {},
        module: {},
        options: [],
    });

    useEffect(() => {
        const user = helpers.getAuthUser();
        setState(prev => ({ ...prev, user }));

        makeRequest();
    }, []);

    useEffect(() => {
        if (module !== null && JSON.stringify(state.module) !== JSON.stringify(module)) {

            setState(prev => ({ ...prev, module, options: module?.options ?? prev.options }));
        }
    }, [module]);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get(`/module${path}`);
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                module: response?.data ?? {},
                options: response.data?.options ?? [],
            }));
            updateModule(response.data);
        } else {
            console.log('[Parent] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const selectSede = value => {
        localStorage.setItem('@sede', JSON.stringify(value));
        window.location.reload();
    }

    const toggleDrawer = () => setShowDrawer(!showDrawer);

    const isAdmin = helpers.isAdmin();

    const drawerContent = (
        <div className={classes.drawerContainer}>
            <List className={classes.list}>
                {
                    !state.loading ?
                        state.options.length ?
                            state.options.map((value) => (
                                <ListItem
                                    button
                                    key={value.idoption_module}
                                    className={classes.item}
                                    style={{ backgroundColor: isAdmin ? themeColors.visible[value.visible] : '#fff' }}
                                    component={Link}
                                    to={path + value.path}
                                >
                                    <ListItemIcon><Icon>{value.icon}</Icon></ListItemIcon>
                                    <ListItemText primary={value.name} />
                                </ListItem>
                            ))
                            :
                            <span>{`You don't have access to this module`}</span>
                        :
                        <CircularProgress />
                }
                {
                    isAdmin &&
                    <ListItem
                        button
                        className={classes.item}
                        component={Link}
                        to={path + '/settings'}
                    >
                        <ListItemIcon><Icon>{'settings'}</Icon></ListItemIcon>
                        <ListItemText primary={'Settings'} />
                    </ListItem>
                }
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            <NavBar fixed toggleDrawer={toggleDrawer} />
            <Hidden xsDown>
                <Drawer
                    className={classes.drawer}
                    classes={{ paper: classes.drawerPaper }}
                    variant='permanent'
                >
                    <Toolbar />
                    {drawerContent}
                </Drawer>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    className={classes.drawer}
                    classes={{ paper: classes.drawerPaper }}
                    variant='temporary'
                    open={showDrawer}
                    onClose={toggleDrawer}
                    ModalProps={{ keepMounted: true }}
                >
                    <ListItem
                        button
                        component={Link}
                        className={classes.user}
                        to='/profile'
                    >
                        <ListItemIcon>
                            <Avatar className={classes.avatar} >{state.user.initials}</Avatar>
                        </ListItemIcon>
                        <Typography>{`${state.user.names} ${state.user.surnames}`}</Typography>
                    </ListItem>
                    <ListItem
                        button
                        className={classes.item}
                        onClick={() => setShowSedes(!showSedes)}
                    >
                        <ListItemText primary={'Current sede'} />
                        <Icon>{showSedes ? 'expand_less' : 'expand_more'}</Icon>
                    </ListItem>
                    {
                        state.user.sedes?.map(sede => {
                            return (
                                <Collapse key={sede.id} in={showSedes}>
                                    <ListItem
                                        button
                                        className={classes.item}
                                        selected={state.user.sede.id === sede.id}
                                        onClick={() => selectSede(sede)}
                                    >
                                        <ListItemIcon><Icon>{'apartment'}</Icon></ListItemIcon>
                                        <ListItemText primary={sede.name} />
                                    </ListItem>
                                </Collapse>
                            );
                        })

                    }
                    {drawerContent}
                    <ListItem
                        button
                        className={classes.item}
                        onClick={signOut}
                    >
                        <ListItemIcon>
                            <Icon>{'exit_to_app'}</Icon>
                        </ListItemIcon>
                        <ListItemText primary={'Sign out'} />
                    </ListItem>
                </Drawer>
            </Hidden>
            {children}
        </div>
    );
}

export default Parent;