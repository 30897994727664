import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormLabel, FormGroup, FormControl, FilledInput, Button, Divider } from '@material-ui/core';
import ReactExport from 'react-data-export';
import dayjs from 'dayjs';

import Loader from '../../common/loader';
import DateRange from '../../common/date-range';

import AuthContext from '../../context';
import helpers from '../../utils/helpers';
import { get } from '../../utils/api-services';
import ViewTitle from '../../common/view-title';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    buttonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        marginBottom: 20
    },
    search: {
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    download: {
        marginRight: 20,
        backgroundColor: '#6E6E6E',
        borderColor: '#6E6E6E',
        '&:hover': {
            backgroundColor: '#6E6E6E',
            borderColor: '#6E6E6E',
            boxShadow: 'none'
        }
    }
}));

const Reports = ({ permissions }) => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const [state, setState] = useState({
        loading: true,
        filter: {
            startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
            dateRange: `${dayjs().subtract(1, 'day').format('MM/DD/YYYY')} - ${dayjs().format('MM/DD/YYYY')}`
        },
        detailGeneral: [],
        detailProcessed: [],
        inventoryGeneral: [],
        inventoryProcessed: [],
        rangeModal: {
            open: false,
            range: [
                {
                    startDate: new Date(dayjs().subtract(1, 'day').toDate()),
                    endDate: new Date(),
                    key: 'main'
                }
            ]
        },
        permissions: {}
    });

    useEffect(() => {
        setState(prev => ({ ...prev, permissions }));
    }, [permissions]);

    useEffect(() => {
        window.document.title = 'Reports';

        setState(prev => ({ ...prev, loading: false }));
    }, []);

    const handleDateChange = item => {
        const { startDate, endDate } = item.main;
        const dateRange = `${dayjs(startDate.toISOString()).format('MM/DD/YYYY')} - ${dayjs(endDate.toISOString()).format('MM/DD/YYYY')}`;
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                dateRange,
                startDate: dayjs(startDate.toISOString()).format('YYYY-MM-DD'),
                endDate: dayjs(endDate.toISOString()).format('YYYY-MM-DD')
            },
            rangeModal: { ...prev.rangeModal, range: [item.main] }
        }));
    }

    const handleModal = () => setState(prev => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));

    const searchDetail = async type => {
        const response = await get('/reports/searchProcessDetail', { ...state.filter, type });
        if (response.status === 200) {
            setState(prev => ({ ...prev, [`detail${type}`]: response?.data ?? [] }));

            if (!response?.data[0]) showToast('There are no data with these filters', 'warning');
        } else {
            console.log('[Reports] searchDetail error', response);
            helpers.failRequest(response, showToast, signOut);
        }
    }

    const searchInventory = async type => {

        const response = await get('/reports/searchProcessInventory', { ...state.filter, type });
        if (response.status === 200) {
            setState(prev => ({ ...prev, [`inventory${type}`]: response?.data ?? [] }));

            if (!response?.data[0]) showToast('There are no data with these filters', 'warning');
        } else {
            console.log('[Reports] searchInventory error', response);
            helpers.failRequest(response, showToast, signOut);
        }
    }

    const search = async type => {
        showLoader(true);

        if (type === 'General' || type === 'Processed') {
            await Promise.any([searchDetail(type), searchInventory(type)]);
        } else {
            showToast('Type error', 'error');
        }

        showLoader(false);
    }

    if (state.loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle sedeFlag title='Reports' />
            <Grid container>
                <Grid item xs={6} lg={4}>
                    <FormGroup onClick={handleModal}>
                        <FormLabel>{'Date range'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                readOnly
                                type='text'
                                placeholder='Date range'
                                value={state.filter.dateRange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <ViewTitle title='General reports' />

            <div className={classes.buttonsContainer}>
                {
                    /* Detail Report */

                    state.detailGeneral.length > 0 &&
                    <ExcelFile
                        filename={`${dayjs().format('YYYY-MM-DD')}-process-detail-report-general`}
                        element={<Button variant='contained' className={classes.download}>{'Download Detail Report'}</Button>}
                    >
                        <ExcelSheet data={state.detailGeneral} name='Ticket process detail report'>
                            <ExcelColumn label='Ticket No.' value='code' />
                            <ExcelColumn label='Ticket date' value='date' />
                            <ExcelColumn label='ID' value='detail.iddetail_ticket' />
                            <ExcelColumn label='Sede code' value='sede.code' />
                            <ExcelColumn label='Sede name' value='sede.name' />
                            <ExcelColumn label='Detail item name' value='detail.item.name' />
                            <ExcelColumn label='Ticket qty' value='detail.qty' />
                            <ExcelColumn label='Pack' value='detail.qty_product' />
                            <ExcelColumn label='Total ticket units' value='detail.total_qty' />
                            <ExcelColumn label='Product code' value='detail.product.code' />
                            <ExcelColumn label='Product name' value='detail.product.name' />
                            <ExcelColumn label='Product category code' value='detail.product.category.code' />
                            <ExcelColumn label='Product category name' value='detail.product.category.name' />
                            <ExcelColumn label='Product type name' value='detail.product.product_type.name' />
                            <ExcelColumn label='Product types name' value='detail.type.name' />
                            <ExcelColumn label='Process date' value='detail.processes.date' />
                            <ExcelColumn label='Process employee code' value='detail.processes.employee.code' />
                            <ExcelColumn label='Process employee name' value='detail.processes.employee.name' />
                            <ExcelColumn label='Process item name' value='detail.processes.item.name' />
                            <ExcelColumn label='Process total qty' value='detail.processes.total_qty' />
                            <ExcelColumn label='Process pending qty' value='detail.processes.pending_qty' />
                            <ExcelColumn label='Process status name' value='detail.process.status.name' />
                        </ExcelSheet>
                    </ExcelFile>
                }

                {
                    /* Inventory Report */

                    state.inventoryGeneral.length > 0 &&
                    <ExcelFile
                        filename={`${dayjs().format('YYYY-MM-DD')}-process-inventory-report-general`}
                        element={<Button variant='contained' className={classes.download}>{'Download Inventory Report'}</Button>}
                    >
                        <ExcelSheet data={state.inventoryGeneral} name='Ticket process inventory report'>
                            <ExcelColumn label='Ticket No.' value='code' />
                            <ExcelColumn label='Ticket date' value='date' />
                            <ExcelColumn label='Processing start date' value='process_start_date' />
                            <ExcelColumn label='Sede code' value='sede.code' />
                            <ExcelColumn label='Sede name' value='sede.name' />
                            <ExcelColumn label='Location code' value='location.code' />
                            <ExcelColumn label='Location name' value='location.name' />
                            <ExcelColumn label='Pack' value='pack' />
                            <ExcelColumn label='Total ticket units' value='total_qty' />
                            <ExcelColumn label='Product code' value='product_code' />
                            <ExcelColumn label='Product name' value='product_name' />
                            <ExcelColumn label='Product category code' value='product_category_code' />
                            <ExcelColumn label='Product category name' value='product_category_name' />
                            <ExcelColumn label='Product type name' value='product_type' />
                            <ExcelColumn label='Product types name' value='product_types' />
                            <ExcelColumn label='Processed total qty' value='process_qty' />
                            <ExcelColumn label='Process pending qty' value='pending_qty' />
                            <ExcelColumn label='Process status name' value='process_name' />
                            <ExcelColumn label='Checkpoint date' value='checkpoint_date' />
                            <ExcelColumn label='Time without being processed' value='days_without_being_processed' />
                        </ExcelSheet>
                    </ExcelFile>
                }

                <Button
                    variant='contained'
                    className={classes.search}
                    onClick={() => search('General')}
                >
                    {'Search (general)'}
                </Button>
            </div>

            <Divider />
            <ViewTitle title='Processed reports' />

            <div className={classes.buttonsContainer}>
                {
                    /* Detail Report */

                    state.detailProcessed.length > 0 &&
                    <ExcelFile
                        filename={`${dayjs().format('YYYY-MM-DD')}-process-detail-report-processed`}
                        element={<Button variant='contained' className={classes.download}>{'Download Detail Report'}</Button>}
                    >
                        <ExcelSheet data={state.detailProcessed} name='Ticket process detail report'>
                            <ExcelColumn label='Ticket No.' value='code' />
                            <ExcelColumn label='Ticket date' value='date' />
                            <ExcelColumn label='Processing start date' value='process_start_date' />
                            <ExcelColumn label='ID' value='detail.iddetail_ticket' />
                            <ExcelColumn label='Sede code' value='sede.code' />
                            <ExcelColumn label='Sede name' value='sede.name' />
                            <ExcelColumn label='Detail item name' value='detail.item.name' />
                            <ExcelColumn label='Ticket qty' value='detail.qty' />
                            <ExcelColumn label='Pack' value='detail.qty_product' />
                            <ExcelColumn label='Total ticket units' value='detail.total_qty' />
                            <ExcelColumn label='Product code' value='detail.product.code' />
                            <ExcelColumn label='Product name' value='detail.product.name' />
                            <ExcelColumn label='Product category code' value='detail.product.category.code' />
                            <ExcelColumn label='Product category name' value='detail.product.category.name' />
                            <ExcelColumn label='Product type name' value='detail.product.product_type.name' />
                            <ExcelColumn label='Product types name' value='detail.type.name' />
                            <ExcelColumn label='Process date' value='detail.processes.date' />
                            <ExcelColumn label='Process employee code' value='detail.processes.employee.code' />
                            <ExcelColumn label='Process employee name' value='detail.processes.employee.name' />
                            <ExcelColumn label='Process item name' value='detail.processes.item.name' />
                            <ExcelColumn label='Process total qty' value='detail.processes.total_qty' />
                            <ExcelColumn label='Process pending qty' value='detail.processes.pending_qty' />
                            <ExcelColumn label='Process status name' value='detail.process.status.name' />
                        </ExcelSheet>
                    </ExcelFile>
                }

                {
                    /* Inventory Report */

                    state.inventoryProcessed.length > 0 &&
                    <ExcelFile
                        filename={`${dayjs().format('YYYY-MM-DD')}-process-inventory-report-processed`}
                        element={<Button variant='contained' className={classes.download}>{'Download Inventory Report'}</Button>}
                    >
                        <ExcelSheet data={state.inventoryProcessed} name='Ticket process inventory report'>
                            <ExcelColumn label='Ticket No.' value='code' />
                            <ExcelColumn label='Ticket date' value='date' />
                            <ExcelColumn label='Processing start date' value='process_start_date' />
                            <ExcelColumn label='Sede code' value='sede.code' />
                            <ExcelColumn label='Sede name' value='sede.name' />
                            <ExcelColumn label='Location code' value='location.code' />
                            <ExcelColumn label='Location name' value='location.name' />
                            <ExcelColumn label='Pack' value='pack' />
                            <ExcelColumn label='Total ticket units' value='total_qty' />
                            <ExcelColumn label='Product code' value='product_code' />
                            <ExcelColumn label='Product name' value='product_name' />
                            <ExcelColumn label='Product category code' value='product_category_code' />
                            <ExcelColumn label='Product category name' value='product_category_name' />
                            <ExcelColumn label='Product type name' value='product_type' />
                            <ExcelColumn label='Product types name' value='product_types' />
                            <ExcelColumn label='Processed total qty' value='process_qty' />
                            <ExcelColumn label='Process pending qty' value='pending_qty' />
                            <ExcelColumn label='Process status name' value='process_name' />
                            <ExcelColumn label='Checkpoint date' value='checkpoint_date' />
                            <ExcelColumn label='Time without being processed' value='days_without_being_processed' />
                        </ExcelSheet>
                    </ExcelFile>
                }

                <Button
                    variant='contained'
                    className={classes.search}
                    onClick={() => search('Processed')}
                >
                    {'Search (processed)'}
                </Button>
            </div>


            <DateRange
                open={state.rangeModal.open}
                range={state.rangeModal.range}
                onClose={handleModal}
                onChange={handleDateChange}
                minDate={dayjs('2022-01-01').toDate()}
            />
        </div>
    );
}

export default Reports;