import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Typography, Icon, Avatar, CircularProgress, Menu, MenuItem, Hidden, IconButton } from '@material-ui/core';

import AuthContext from '../context';
import themeColors from '../assets/colors';
import helpers from '../utils/helpers';

const env = process.env.REACT_APP_ENV;

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    toolBar: {
        backgroundColor: (env === 'test') ? themeColors.app.mainTest : themeColors.app.main,
        justifyContent: 'space-between'
    },
    left: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    right: {
        display: 'flex',
        flexDirection: 'row',
    },
    link: {
        fontSize: 18,
        color: '#fff'
    },
    user: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    avatar: {
        width: '2.2rem',
        height: '2.2rem',
        marginRight: 10,
    },
    button: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 25
    }
}));

const NavBar = ({ fixed, toggleDrawer }) => {

    const { signOut } = useContext(AuthContext);
    const classes = useStyles();
    const [user, setUser] = useState(null);
    const [sedeEl, setSedeEl] = useState(null);
    const [profileEl, setProfileEl] = useState(null);

    useEffect(() => {
        const user = helpers.getAuthUser();
        setUser(user);
    }, []);

    const handleClickProfileEl = e => setProfileEl(e.currentTarget);

    const handleCloseProfileEl = () => setProfileEl(null);

    const handleClickSedeEl = e => setSedeEl(e.currentTarget);

    const handleCloseSedeEl = () => setSedeEl(null);

    const selectSede = value => {
        localStorage.setItem('@sede', JSON.stringify(value));
        window.location.reload();
    }

    return (
        <AppBar position={fixed ? 'fixed' : 'static'} className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
                <div className={classes.left}>
                    {
                        toggleDrawer &&
                        <Hidden smUp>
                            <IconButton edge='start' onClick={toggleDrawer} color='inherit'>
                                <Icon>{'menu'}</Icon>
                            </IconButton>
                        </Hidden>
                    }
                    <Link to='/home'>
                        <Typography className={classes.link}>{'Continental Floral Software'}</Typography>
                    </Link>
                </div>

                <Hidden xsDown className={classes.right}>
                    {
                        user ?
                            <div className={classes.user}>
                                <div className={classes.button} onClick={handleClickProfileEl}>
                                    <Avatar className={classes.avatar} >{user.initials}</Avatar>
                                    <Typography>{`${user.names} ${user.surnames}`}</Typography>
                                </div>
                                <Menu
                                    anchorEl={profileEl}
                                    open={Boolean(profileEl)}
                                    onClose={handleCloseProfileEl}
                                >
                                    <MenuItem component={Link} to='/profile'>{'My account'}</MenuItem>
                                    <MenuItem onClick={signOut}>{'Sign out'}</MenuItem>
                                </Menu>
                                <div className={classes.button} onClick={handleClickSedeEl}>
                                    <Typography>{user.sede.name}</Typography>
                                    <Icon>{'arrow_drop_down'}</Icon>
                                </div>
                                <Menu
                                    anchorEl={sedeEl}
                                    open={Boolean(sedeEl)}
                                    onClose={handleCloseSedeEl}
                                >
                                    {
                                        user.sedes?.map(value => {
                                            return <MenuItem key={value.id} onClick={() => selectSede(value)}>{value.name}</MenuItem>
                                        })
                                    }
                                </Menu>
                            </div>
                            :
                            <CircularProgress />
                    }
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

export default NavBar;