import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import ViewTitle from '../../common/view-title';
import ProductionsMobile from './productions-mobile';
import ProductionsDesktop from './productions-desktop';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    }
}));

const Productions = () => {

    const classes = useStyles();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

    useEffect(() => {
        window.document.title = 'Productions';

        window.addEventListener('resize', handleWindowSize);

        return () => window.removeEventListener('resize', handleWindowSize);
    }, []);

    const handleWindowSize = () => setIsMobile(window.innerWidth < 500);

    return (
        <div className={classes.root}>
            <ViewTitle title='Productions' sedeFlag />

            {isMobile ? <ProductionsMobile alone={true} /> : <ProductionsDesktop />}
        </div>
    );
}

export default Productions;