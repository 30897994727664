import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal, FilledInput, Typography, Button, FormGroup, FormLabel, FormControl, Box, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import dayjs from 'dayjs';

import themeColors from '../../assets/colors';
import DateRangeModal from '../../common/date-range';
import Type from './type';

import { get, post } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import AuthContext from '../../context';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.background.default,
        padding: 20,
        width: '80%',
        borderRadius: 10
    },
    store: {
        marginLeft: 15,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    bottom: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    types: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

const CreatePrice = ({ open, onClose, arrays }) => {

    const classes = useStyles();
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const [state, setState] = useState({
        locations: arrays?.locations ?? [],
        products: arrays?.products ?? [],
        price: {
            startDate: dayjs().format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
            dateRange: `${dayjs().format('MM/DD/YYYY')} - ${dayjs().format('MM/DD/YYYY')}`,
            location: arrays?.locations[0],
            product: arrays?.products[0],
            types: []
        },
        rangeModal: {
            open: false,
            range: [
                {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'main'
                }
            ]
        }
    });

    const handleModal = () => setState(prev => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));

    const handleAutocomplete = (key, value) => setState(prev => ({ ...prev, price: { ...prev.price, [key]: value } }));

    const handleDateChange = item => {
        const { startDate, endDate } = item.main;
        const dateRange = `${dayjs(startDate.toISOString()).format('MM/DD/YYYY')} - ${dayjs(endDate.toISOString()).format('MM/DD/YYYY')}`;
        setState(prev => ({
            ...prev,
            price: {
                ...prev.price,
                dateRange,
                startDate: dayjs(startDate.toISOString()).format('YYYY-MM-DD'),
                endDate: dayjs(endDate.toISOString()).format('YYYY-MM-DD')
            },
            rangeModal: { ...prev.rangeModal, range: [item.main] }
        }));
    }

    const getTypes = async product => {
        showLoader(true);
        const response = await get(`/product/${product.id}`);
        if (response.status === 200) {
            const { data } = response;
            setState(prev => ({ ...prev, price: { ...prev.price, types: data.types } }));
        } else {
            setState(prev => ({ ...prev, price: { ...prev.price, types: [] } }));
            console.log('[CreatePrices] getTypes error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const handleChangeSelect = (e) => {
        const { target } = e;
        const array = state.price.types.slice();

        array.map(item => {
            if (item.id == target.name) {
                item.selected = target.checked;
                if (!target.checked) {
                    item.price = 0;
                }
            }
        });
        setState(prev => ({ ...prev, price: { ...prev.price, types: array } }));
    }

    const handleChangePrice = e => {
        const { target } = e;
        const array = state.price.types.slice();

        array.map(item => {
            if (item.id == target.name) {
                item.price = target.value;
            }
        });
        setState(prev => ({ ...prev, price: { ...prev.price, types: array } }));
    }

    const validateForm = () => {
        const { location, product } = state.price;

        if (location.id && product.id) {
            const cond = window.confirm('Confirm if all data is correct');
            if (cond) {
                store();
            }
        } else {
            showToast('Select Location and Product', 'error');
        }
    }

    const store = async () => {
        showLoader(true);
        const response = await post('/price/store', state.price);
        if (response.status === 200) {
            onClose(true);
            showToast(response.message);
        } else {
            console.log('[CreatePrices] store error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div className={classes.container}>
                <Typography variant='h2'>{'Create price'}</Typography>
                <Grid container>
                    <Grid item xs={12} lg={4}>
                        <FormGroup onClick={handleModal}>
                            <FormLabel>{'Date range'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    readOnly
                                    type='text'
                                    placeholder='Date range'
                                    value={state.price.dateRange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Location'}</FormLabel>
                            <Autocomplete
                                options={state.locations}
                                value={state.price.location}
                                getOptionLabel={location => location?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, value) => handleAutocomplete('location', value)}
                                renderInput={params => <TextField {...params} placeholder='Type' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Product'}</FormLabel>
                            <Autocomplete
                                options={state.products}
                                value={state.price.product}
                                getOptionLabel={product => product?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, value) => {
                                    handleAutocomplete('product', value);
                                    (value.id != 0)
                                        ? getTypes(value)
                                        : setState(prev => ({ ...prev, price: { ...prev.price, types: [] } }));
                                }}
                                renderInput={params => <TextField {...params} placeholder='Product' />}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <div className={classes.bottom}>
                    <div className={classes.types}>
                        {
                            state.price.types.length ?
                                state.price.types.map((item, index) => {
                                    return (
                                        <Type
                                            key={item.id}
                                            item={item}
                                            onChangeSelect={handleChangeSelect}
                                            onChangePrice={handleChangePrice}
                                        />
                                    )
                                }) :
                                <Typography variant='inherit'>{'The product has no types'}</Typography>
                        }
                    </div>
                    <div>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={onClose}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            variant='contained'
                            className={classes.store}
                            onClick={validateForm}
                        >
                            {'Store'}
                        </Button>
                    </div>
                </div>

                <DateRangeModal
                    open={state.rangeModal.open}
                    onClose={handleModal}
                    range={state.rangeModal.range}
                    onChange={handleDateChange}
                />
            </div>
        </Modal>
    );
}

export default CreatePrice;