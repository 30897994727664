import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Select, MenuItem, TextField, Tooltip, IconButton, Icon, Button } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLocation, Link } from 'react-router-dom';
import ReactExport from 'react-data-export';
import dayjs from 'dayjs';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import ViewTitle from '../../common/view-title';
import Trash from '../../common/trash';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%'
    },
    buttonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        marginBottom: 20
    },
    download: {
        backgroundColor: '#6E6E6E',
        borderColor: '#6E6E6E',
        '&:hover': {
            backgroundColor: '#6E6E6E',
            borderColor: '#6E6E6E',
            boxShadow: 'none'
        }
    }
}));

const Stumpage = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [state, setState] = useState({
        loading: true,
        lands: [],
        suppliers: [],
        products: [],
        stumpages: [],
        excelData: [],
        stumpage: {
            idstumpage: 0,
            percent: false,
            value: 0,
            idland: 0,
            land: {},
            idsupplier: 0,
            supplier: {},
            idproduct: 0,
            product: {}
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Stumpage';

        validatePermissions();
    }, []);

    useEffect(() => {
        const excelData = [];

        state.stumpages.forEach(item => {
            const land = item?.land?.name ?? 'All';
            const supplier = item?.supplier?.name ?? 'All';
            const product = item?.product?.name ?? 'All';
            const sede = item.sede.name;
            const percent = item.percent ? 'Yes' : 'No';

            excelData.push({ ...item, land, supplier, product, sede, percent });
        });

        setState(prev => ({ ...prev, excelData }));
    }, [state.stumpages]);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/stumpage');
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                stumpages: response?.data ?? [],
                lands: response?.lands ?? [],
                suppliers: response?.suppliers ?? [],
                products: response?.products ?? [],
                stumpage: {
                    ...prev.stumpage,
                    land: response?.lands[0] ?? {},
                    supplier: response?.suppliers[0] ?? {},
                    product: response?.products[0] ?? {},
                }
            }));
        } else {
            console.log('[Stumpage] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const validateForm = (type) => {
        const { idstumpage, value, land, supplier, product } = state.stumpage;

        switch (type) {
            case 'update':
                const data = state.stumpages.filter(value => value.idstumpage === idstumpage);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.stumpage);
                    if (before !== after) {
                        if (value >= 0) {
                            if (!land?.id && !supplier?.id && !product?.id) {
                                showToast('Combination not allowed', 'error');
                                return false;
                            }

                            return true;
                        } else {
                            showToast('Value is required', 'error');
                            return false;
                        }
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select some stumpage', 'error');
                    return false;
                }

            case 'store':

                if (value >= 0) {
                    if (!land?.id && !supplier?.id && !product?.id) {
                        showToast('Combination not allowed', 'error');
                        return false;
                    }

                    return true;
                } else {
                    showToast('Value is required', 'error');
                    return false;
                }

            case 'delete':
                if (idstumpage === 0) {
                    showToast('Select some stumpage', 'error');
                    return false;
                }
                const cond = window.confirm(`Are you sure you want to delete this stumpage?`);

                return Boolean(idstumpage && cond);
            default:
                break;
        }
    }

    const clear = () => setState(prev => ({
        ...prev,
        stumpage: {
            ...prev.stumpage,
            idstumpage: 0,
            percent: false,
            value: 0,
            land: prev.lands[0],
            supplier: prev.suppliers[0],
            product: prev.products[0],
        }
    }));


    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/stumpage/store', state.stumpage);
            if (response.status === 200) {
                setState(prev => ({ ...prev, stumpages: response?.data ?? prev.stumpages }));
                showToast(response.message);
            } else {
                console.log('[Stumpage] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false;
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { idstumpage } = state.stumpage;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/stumpage/${idstumpage}/update`, state.stumpage);
            if (response.status === 200) {
                setState(prev => ({ ...prev, stumpages: response?.data ?? prev.stumpages }));
                showToast(response.message);
            } else {
                console.log('[Stumpage] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idstumpage } = state.stumpage;
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/stumpage/${idstumpage}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, stumpages: response?.data ?? prev.stumpages }));
                showToast(response.message);
            } else {
                console.log('[Stumpage] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }


    const handleChange = ({ target }) => setState(prev => ({ ...prev, stumpage: { ...prev.stumpage, [target.name]: target.value } }));

    const handleAutocomplete = (key, value) => setState(prev => ({ ...prev, stumpage: { ...prev.stumpage, [key]: value } }));

    const valueGetter = params => params?.value?.name ?? 'All';

    const getPercentField = params => params.value ? 'Yes' : 'No';

    const getValueField = params => `${params.row.percent ? '%' : '$'} ${params.value}`;

    const renderCell = ({ row }) => {
        return (
            <Link to={`/history/stumpage/${row.idstumpage}`} target='_blank'>
                <Tooltip title='Show history' placement='top'>
                    <IconButton>
                        <Icon>{'history'}</Icon>
                    </IconButton>
                </Tooltip>
            </Link>
        );
    }

    const columns = [
        { field: 'land', headerName: 'Land', flex: 1, minWidth: 180, valueGetter },
        { field: 'supplier', headerName: 'Supplier', flex: 1, minWidth: 180, valueGetter },
        { field: 'product', headerName: 'Product', flex: 1, minWidth: 180, valueGetter },
        { field: 'percent', headerName: 'Is percentage', flex: 1, minWidth: 150, valueGetter: getPercentField },
        { field: 'value', headerName: 'Value', flex: 1, minWidth: 100, valueGetter: getValueField },
        { field: 'actions', headerName: 'Actions', flex: 0.5, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle sedeFlag title='Stumpage' />
            <Grid container>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Land'}</FormLabel>
                        <Autocomplete
                            options={state.lands}
                            value={state.stumpage.land}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleAutocomplete('land', value)}
                            renderInput={params => <TextField {...params} placeholder='Land' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Supplier'}</FormLabel>
                        <Autocomplete
                            options={state.suppliers}
                            value={state.stumpage.supplier}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleAutocomplete('supplier', value)}
                            renderInput={params => <TextField {...params} placeholder='Supplier' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Product'}</FormLabel>
                        <Autocomplete
                            options={state.products}
                            value={state.stumpage.product}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleAutocomplete('product', value)}
                            renderInput={params => <TextField {...params} placeholder='Product' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Is percent'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <Select
                                value={state.stumpage.percent}
                                onChange={({ target }) => setState(prev => ({ ...prev, stumpage: { ...prev.stumpage, percent: target.value } }))}
                            >
                                <MenuItem value={false}>{'No'}</MenuItem>
                                <MenuItem value={true}>{'Yes'}</MenuItem>
                            </Select>
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Value'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='number'
                                placeholder='Value'
                                name='value'
                                value={state.stumpage.value}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            {
                state.permissions.delete_attr &&
                <Trash table='stumpage' id='idstumpage' onRefresh={makeRequest} />
            }

            <div className={classes.buttonsContainer}>
                {
                    state.excelData.length > 0 &&
                    <ExcelFile
                        filename={`${dayjs().format('YYYY-MM-DD')}-stumpage-report`}
                        element={<Button variant='contained' className={classes.download}>{'Download data'}</Button>}
                    >
                        <ExcelSheet data={state.excelData} name='Stumpage report'>
                            <ExcelColumn label='Land' value='land' />
                            <ExcelColumn label='Supplier' value='supplier' />
                            <ExcelColumn label='Product' value='product' />
                            <ExcelColumn label='Sede' value='sede' />
                            <ExcelColumn label='Is percent' value='percent' />
                            <ExcelColumn label='Value' value='value' />
                        </ExcelSheet>
                    </ExcelFile>
                }
            </div>

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.stumpages}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idstumpage}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, stumpage: row }))}
                />
            </div>
        </div>
    );
}

export default Stumpage;