import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { useParams, useHistory, Link } from "react-router-dom";
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, InputAdornment, TextField, Button, Icon, IconButton, Divider, Collapse } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DataGrid } from "@material-ui/data-grid";
import dayjs from "dayjs";

import Loader from "../../common/loader";
import NotFound from "../../common/not-found";

import AuthContext from "../../context";
import { get, post, put, del } from "../../utils/api-services";
import helpers from "../../utils/helpers";
import themeColors from "../../assets/colors";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		marginBottom: 20,
	},
	tableContainer: {
		width: "100%",
		"& .total": {
			backgroundColor: "#E0F8E0",
		},
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	bottom: {
		display: "flex",
		alignSelf: "flex-end",
	},
	store: {
		marginLeft: 20,
		backgroundColor: "#0d6efd",
		borderColor: "#0d6efd",
		"&:hover": {
			backgroundColor: "#0d6efd",
			borderColor: "#0d6efd",
			boxShadow: "none",
		},
	},
	trash: {
		backgroundColor: "#f94f4f",
		borderColor: "#f94f4f",
		padding: "0.5rem",
		borderRadius: "0.5rem",
		color: "#fff",
		margin: 0,
		marginTop: "1.5rem",
		"&:hover": {
			backgroundColor: "#f94f4f",
			borderColor: "#f94f4f",
			boxShadow: "none",
		},
		"&:disabled": {
			backgroundColor: "rgba(0, 0, 0, 0.5)",
			color: "#fff",
		},
	},
	update: {
		marginLeft: 20,
		backgroundColor: "#ffc107",
		borderColor: "#ffc107",
		"&:hover": {
			backgroundColor: "#ffc107",
			borderColor: "#ffc107",
			boxShadow: "none",
		},
	},
	delete: {
		width: "100%",
		backgroundColor: "#dc3545",
		borderColor: "#dc3545",
		"&:hover": {
			backgroundColor: "#dc3545",
			borderColor: "#dc3545",
			boxShadow: "none",
		},
	},
	clear: {
		backgroundColor: "#A4A4A4",
		borderColor: "#A4A4A4",
		"&:hover": {
			backgroundColor: "#A4A4A4",
			borderColor: "#A4A4A4",
			boxShadow: "none",
		},
	},
	calculate: {
		marginLeft: 20,
		backgroundColor: "#6E6E6E",
		borderColor: "#6E6E6E",
		"&:hover": {
			backgroundColor: "#6E6E6E",
			borderColor: "#6E6E6E",
			boxShadow: "none",
		},
	},
	generate: {
		marginLeft: 20,
		backgroundColor: "#198754",
		borderColor: "#198754",
		"&:hover": {
			backgroundColor: "#198754",
			borderColor: "#198754",
			boxShadow: "none",
		},
	},
	row: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	rowText: {
		display: "inherit",
		flexDirection: "inherit",
		alignItems: "inherit",
	},
	state: {
		fontSize: "1rem",
		marginLeft: 5,
		marginRight: 10,
		color: themeColors.gray[600],
	},
	divider: {
		marginBottom: 24,
	},
	highlight: {
		color: "#0d6efd",
	},
	controls: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "center",
	},
	wrapText: {
		"&>.MuiAlert-message": {
			whiteSpace: "pre-wrap",
		},
	},
	requiredValue: {
		fontWeight: "600",
		color: "rgba(255,0,0,1)",
	},
}));

export default function DeliveryTicketsCreate({ permissions }) {
	const { showToast, signOut, showLoader } = useContext(AuthContext);
	const { id } = useParams();
	const history = useHistory();
	const classes = useStyles();
	const [formInitilized, setFormInitilized] = useState(false);
	const [showPayment, setShowPayment] = useState(true);

	const productSetDefaultState = { id: 0, product: { id: 0, name: "Choose a product" }, weight: "", rate: "", total: 0, userInput: false };
	const supplierSetDefaultState = {
		id: 0,
		supplier: { id: 0, name: "Choose a supplier" },
		land: { id: 0, name: "Choose a land" },
		product: { id: 0, name: "Choose a product" },
		weight: "",
		rate: "",
		total: 0,
		userInput: false,
	};
	const cutterSetDefaultState = { id: 0, cutter: { id: 0, name: "Choose a supplier" }, weight: "", rate: "", total: 0, userInput: false, product: {id: 0, name: "Choose a product"} };

	const [state, setState] = useState({
		loading: true,
		loaders: [],
		truckers: [],
		customers: [],
		products: [],
		suppliers: [],
		landOwners: [],
		lands: [],
		ticket: {
			dateCreated: "",
			deliveryNumber: "",
			purchaseOrder: "",
			loader: { id: 0, name: "Choose a loader" },
			trucker: { id: 0, name: "Choose a trucking company" },
			truckNumber: "",
			truckPlate: "",
			trailerNumber: "",
			fullWeight: "",
			emptyWeight: "ß",
			netWeight: 0,
			customer: { id: 0, name: "Choose a customer" },
			dateReceived: "",
			invoiceTotal: 0,
			datePayment: "",
			invoiceNumber: "",
			status: "Not received",
			invoiceStatus: "Not invoiced",
			note: "",
			suppliers: [{ ...supplierSetDefaultState }],
			cutters: [{ ...cutterSetDefaultState }],
			products: [{ ...productSetDefaultState }],
		},

		permissions: {},
	});

	useEffect(() => {
		setState((prev) => ({ ...prev, permissions }));
	}, [permissions]);

	useEffect(() => {
		fetchRecordData();
	}, [formInitilized]);

	useEffect(() => {
		window.document.title = `${typeof id !== "undefined" ? "Update" : "Create"} delivery ticket`;
		makeRequest();
	}, []);

	const convertTicketState = (ticket) => {
		ticket['status'] = ticket['status'] === "Not paid" ? "Not received" : new dayjs(ticket['datePayment']).format("MM/DD/YYYY");
		ticket['invoiceStatus'] = ticket['invoiceStatus'] === "Not invoiced" ? "Not invoiced" : ticket['invoiceNumber'];
		return ticket;
	}

	const makeRequest = async () => {
		showLoader(true);
		const response = await get(`/farmForest/delivery/options`);

		if (response.status !== 200) {
			console.log("[DeliveryTicketsDetail] makeRequest error", response);
			helpers.failRequest(response, showToast, signOut);
			setState((prev) => ({ ...prev, loading: false }));
			showLoader(false);
			return;
		}

		setState((prev) => ({
			...prev,
			customers: response?.customers ?? {},
			loaders: response?.loaders ?? [],
			truckers: response?.trucking ?? [],
			products: response?.products ?? [],
			suppliers: response?.suppliers ?? [],
			landOwners: response?.landOwners ?? [],
			lands: response?.lands ?? [],
			ticket: {
				...prev.ticket,
				loader: response?.loaders[0] ?? {},
				trucker: response?.trucking[0] ?? {},
				customer: response?.customers[0] ?? {},
				suppliers: [{ ...supplierSetDefaultState }],
				cutters: [{ ...cutterSetDefaultState }],
				products: [{ ...productSetDefaultState }],
			},
			loading: typeof id !== "undefined" ? true : false,
		}));

		setFormInitilized(true);

		showLoader(false);
	};

	const isNumeric = (str) => {
		if (typeof str == "number") return true; // if the value is alredy a number return true
		if (typeof str != "string") return false; // only validate strings
		// use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		// ...and ensure strings of whitespace fail
		return !isNaN(str) && !isNaN(parseFloat(str));
	};

	const checkIfUserInputTheInitialSetStates = (temp, subKey, arrayIndex) => {
		const row = { ...temp[subKey][arrayIndex] };

		switch (subKey) {
			case "suppliers":
				if (row.supplier.id === 0 && row.land.id === 0 && row.product.id === 0 && row.weight === "" && row.rate === "" && row.total === 0) {
					temp[subKey][arrayIndex]["userInput"] = false;
				}
				break;
			case "cutters":
				if (row.cutter.id === 0 && row.weight === "" && row.rate === "" && row.total === 0) {
					temp[subKey][arrayIndex]["userInput"] = false;
				}
				break;
			default:
				if (row.product.id === 0 && row.weight === "" && row.rate === "" && row.total === 0) {
					temp[subKey][arrayIndex]["userInput"] = false;
				}
		}

		return temp;
	};

	const handleRightSideCeros = (str) => {
		if (typeof str != "string") str = `${str}`;

		//limit to only 1 cero
		while (str.charAt(0) === "0") {
			if (str.length === 1) {
				break;
			}
			if (str.charAt(1) === ".") {
				break;
			}
			str = str.substr(1, str.length - 1);
		}

		// if there is a ".", limit it to 2 digits
		if (str.indexOf(".") !== -1) {
			const newValue = str.split(".");
			if (newValue[1].length > 2) str = `${newValue[0]}.${newValue[1].substr(0, 2)}`;
		}

		return str;
	};

	const fetchRecordData = async () => {
		if (typeof id === "undefined" || state.loaders.length === 0) return;

		const data = await get(`/farmForest/delivery/${id}`);
		if (data.status !== 200) {
			console.log("[DeliveryTicketsDetail] makeRequest error", data);
			helpers.failRequest(data, showToast, signOut);
			setState((prev) => ({ ...prev, loading: false }));
			showLoader(false);
			return;
		}

		let statusTicket = convertTicketState(data.data)

		setState((prev) => ({ ...prev, ticket: { ...prev.ticket, ...statusTicket }, loading: false }));
	};

	const handleAutocomplete = (key, value) => {
		// support for the array kyes like "product[0].product"
		if (key.indexOf("[") !== -1) {
			const subKey = key.split("[")[0]; // array key "product"
			const arrayIndex = key.split("[")[1].split("]")[0]; // array index "0"
			const category = key.split(".")[1]; // subkey "product"
			let temp = { ...state.ticket };
			temp[subKey][arrayIndex][category] = value;
			temp[subKey][arrayIndex]["userInput"] = true;
			temp = UpdateArrayTotal(temp, subKey, arrayIndex, category);
			temp = checkIfUserInputTheInitialSetStates(temp, subKey, arrayIndex);
			temp = UpdateGlobalTotal(temp, subKey);
			return setState((prev) => ({ ...prev, ticket: temp }));
		}

		return setState((prev) => ({ ...prev, ticket: { ...prev.ticket, [key]: value } }));
	};

	const handleChange = (event) => {
		const key = event.target.name;
		const value = event.target.value;
		// support for the array kyes like "product[0].product"
		if (key.indexOf("[") !== -1) {
			const subKey = key.split("[")[0]; // array key "product"
			const arrayIndex = key.split("[")[1].split("]")[0]; // array index "0"
			const category = key.split(".")[1]; // subkey "product"
			let temp = { ...state.ticket };
			temp[subKey][arrayIndex][category] = value;
			temp[subKey][arrayIndex]["userInput"] = true;
			temp = UpdateArrayTotal(temp, subKey, arrayIndex, category, event);
			temp = checkIfUserInputTheInitialSetStates(temp, subKey, arrayIndex);
			temp = UpdateGlobalTotal(temp, subKey);
			setState((prev) => ({ ...prev, ticket: temp }));
			return;
		}

		let temp = { ...state.ticket };

		temp[key] = value;

		if (key === "datePayment") {
			if (temp[key].length > 0 && temp[key] !== "") {
				temp.status = new dayjs(value).format("MM/DD/YYYY");
			} else {
				temp.status = "Not received";
			}
		}

		if (key === "invoiceNumber") {
			if (temp[key].length > 0 && temp[key] !== "") {
				temp.invoiceStatus = value;
			} else {
				temp.invoiceStatus = "Not invoiced";
			}
		}

		if (key === "fullWeight" || key === "emptyWeight") temp = handleWeightValidation(key, temp, event);

		return setState((prev) => ({ ...prev, ticket: temp }));
	};

	const handleWeightValidation = (key, temp, event) => {
		temp[key] = handleRightSideCeros(temp[key]);

		const fullWeight = !isNumeric(temp["fullWeight"]) ? 0 : parseFloat(temp["fullWeight"]);
		const emptyWeight = !isNumeric(temp["emptyWeight"]) ? 0 : parseFloat(temp["emptyWeight"]);

		if (emptyWeight === 0 && fullWeight === 0) {
			temp["netWeight"] = 0;
			temp["products"][0]["weight"] = 0;
			temp["suppliers"][0]["weight"] = 0;
			temp["cutters"][0]["weight"] = 0;
			return temp;
		}

		// Only accept posity numbers if any goes bellow 0 reset the netweight
		if (fullWeight < 0 || emptyWeight < 0) {
			temp[key] = "";
			temp["netWeight"] = 0;
			temp["products"][0]["weight"] = "";
			temp["suppliers"][0]["weight"] = "";
			temp["cutters"][0]["weight"] = "";
			return temp;
		}

		// if fullweight is less than empty weight set net weight to full weight and empty weight to 0
		if (emptyWeight >= fullWeight) {
			temp["netWeight"] = fullWeight;
			temp["emptyWeight"] = "";
			temp["products"][0]["weight"] = fullWeight;
			temp["suppliers"][0]["weight"] = fullWeight;
			temp["cutters"][0]["weight"] = fullWeight;
			return temp;
		}

		// set net weight
		temp["netWeight"] = handleRightSideCeros(fullWeight - emptyWeight);

		//asign the netweight to the first set of product
		if ((!temp["products"][0]["weight"] || temp["products"][0]["weight"] >= 0) && (!temp["products"][0]["rate"] || temp["products"][0]["rate"] === "")) {
			temp["products"][0]["weight"] = temp["netWeight"];
		}

		//asign the netweight to the first set of suppliers
		if ((!temp["suppliers"][0]["weight"] || temp["suppliers"][0]["weight"] >= 0) && (!temp["suppliers"][0]["rate"] || temp["suppliers"][0]["rate"] === "")) {
			temp["suppliers"][0]["weight"] = temp["netWeight"];
		}

		//asign the netweight to the first set of cutters
		if ((!temp["cutters"][0]["weight"] || temp["cutters"][0]["weight"] >= 0) && (!temp["cutters"][0]["rate"] || temp["cutters"][0]["rate"] === "")) {
			temp["cutters"][0]["weight"] = temp["netWeight"];
		}

		return temp;
	};

	const UpdateArrayTotal = (tempState, subKey, arrayIndex, category, event) => {
		if (category === "weight" || category === "rate") {
			tempState[subKey][arrayIndex][category] = handleRightSideCeros(tempState[subKey][arrayIndex][category]);

			const rate = !isNumeric(tempState[subKey][arrayIndex]["rate"]) ? 0 : parseFloat(tempState[subKey][arrayIndex]["rate"]);
			const weight = !isNumeric(tempState[subKey][arrayIndex]["weight"]) ? 0 : parseFloat(tempState[subKey][arrayIndex]["weight"]);

			//check if the value is 0, if so reset the total to 0
			if (rate === 0 || weight === 0) {
				tempState[subKey][arrayIndex]["total"] = 0;
				return { ...tempState };
			}

			// only positive values
			if (rate < 0 || weight < 0) {
				tempState[subKey][arrayIndex][category] = "";
				tempState[subKey][arrayIndex]["total"] = 0;
				return { ...tempState };
			}

			tempState[subKey][arrayIndex]["total"] = handleRightSideCeros(weight * rate);
		}

		return { ...tempState };
	};

	const UpdateGlobalTotal = (tempState, subKey) => {
		if (subKey === "products") {
			const list = [...tempState[subKey]];

			let total = list.reduce((acc, obj) => {
				let value = parseFloat(obj.total);
				if (obj.product.id === 0) value = 0;
				return acc + value;
			}, 0);

			if (`${total}`.indexOf(".") !== -1) {
				const temp = `${total}`.split(".");
				if (temp[1].length > 3) total = `${temp[0]}.${temp[1].substr(0, 3)}`;
			}

			tempState["invoiceTotal"] = parseFloat(total).toFixed(2);
		}
		return { ...tempState };
	};

	const handleAddNewItemToList = (category) => {
		const currentList = state.ticket[category];

		switch (category) {
			case "suppliers":
				currentList.push({ ...supplierSetDefaultState });
				break;
			case "cutters":
				currentList.push({ ...cutterSetDefaultState });
				break;
			default:
				currentList.push({ ...productSetDefaultState });
		}

		setState((prev) => ({ ...prev, ticket: { ...prev.ticket, [category]: currentList } }));
	};

	const handleDeleteItemFromList = (index, category) => {
		let temp = { ...state.ticket };

		const currentList = [...temp[category]];

		currentList.splice(index, 1);

		temp[category] = currentList;

		temp = UpdateGlobalTotal(temp, category);

		setState((prev) => ({ ...prev, ticket: { ...prev.ticket, ...temp } }));
	};

	const handleClearItemFromList = (index, category) => {
		let temp = { ...state.ticket };
		const currentList = [...temp[category]];

		switch (category) {
			case "suppliers":
				currentList[index] = { ...supplierSetDefaultState };
				break;
			case "cutters":
				currentList[index] = { ...cutterSetDefaultState };
				break;
			default:
				currentList[index] = { ...productSetDefaultState };
		}

		temp[category] = currentList;
		temp = UpdateGlobalTotal(temp, category);

		setState((prev) => ({ ...prev, ticket: { ...prev.ticket, ...temp } }));
	};

	const handleFormClear = (isClearButton = true) => {
		if(isClearButton){
			const confirmation = window.confirm("Are you sure you want to clear the form?");
			if (!confirmation) return;
		}
		const initialValues = {
			dateCreated: "",
			deliveryNumber: "",
			purchaseOrder: "",
			loader: { id: 0, name: "Choose a loader" },
			trucker: { id: 0, name: "Choose a trucking company" },
			truckNumber: "",
			truckPlate: "",
			trailerNumber: "",
			fullWeight: "",
			emptyWeight: "",
			netWeight: "",
			customer: { id: 0, name: "Choose a customer" },
			dateReceived: "",
			invoiceTotal: "",
			totalStumpage: "",
			totalCutter: "",
			datePayment: "",
			invoiceNumber: "",
			status: "Not received",
			invoiceStatus: "Not invoiced",
			note: "",
			suppliers: [{ ...supplierSetDefaultState }],
			cutters: [{ ...cutterSetDefaultState }],
			products: [{ ...productSetDefaultState }],
		};

		setState((prev) => ({ ...prev, ticket: { ...prev.ticket, ...initialValues } }));
	};

	const createDelay = (delay) => {
		return new Promise((res) => setTimeout(res, delay));
	};

	const validateFiledsRequiredFromForm = () => {
		const currentState = { ...state.ticket };
		let issues = 0;
		let msg = "";

		if (currentState.deliveryNumber.length === 0 || currentState.deliveryNumber === "") {
			issues += 1;
			msg += "Delivery ticket can't be empty\n";
		}

		if (currentState.dateCreated === "") {
			issues += 1;
			msg += "Date can't be empty\n";
		}

		if (!isNumeric(currentState.fullWeight)) {
			issues += 1;
			msg += "Full weight doesn't have a valid value\n";
		}

		if (!isNumeric(currentState.emptyWeight)) {
			issues += 1;
			msg += "Empty weight doesn't have a valid value\n";
		}

		if (isNumeric(currentState.fullWeight) && parseFloat(currentState.fullWeight) === 0) {
			issues += 1;
			msg += "Full weight can't be 0\n";
		}

		if (currentState.netWeight === 0) {
			issues += 1;
			msg += "Net weight can't be 0\n";
		}

		if (!currentState.customer["id"] || currentState.customer["id"] === 0) {
			issues += 1;
			msg += "Customer can't be empty\n";
		}

		if (!currentState.products[0]["product"]["id"] || currentState.products[0]["product"]["id"] === 0 || currentState.products[0]["total"] === 0) {
			let temp = "[Products]";

			if (!currentState.products[0]["product"]["id"] || currentState.products[0]["product"]["id"] === 0) {
				issues += 1;
				temp += " At least 1 product must be selected ";
			}

			if (currentState.products[0]["total"] === 0) {
				issues += 1;
				temp += "| product subtotal can't be 0 ";
			}

			temp += "\n";

			msg += temp;
		}

		if (issues > 0) showToast(msg, "error", 4000, classes.wrapText);

		return issues > 0 ? false : true;
	};

	const checkForPartialValues = async () => {
		const currentState = { ...state.ticket };
		let issues = 0;
		const rows = [];
		let globalMsg = "";

		currentState.products.forEach((item, index) => {
			if (index === 0) return;
			if (!item.userInput) return;

			let msg = `[${index + 1}] : `;

			if (item.product.id === 0) {
				issues += 1;
				msg += " product name | ";
			}

			if (!isNumeric(item.rate)) {
				issues += 1;
				msg += " rate | ";
			} else if (isNumeric(item.rate) && parseFloat(item.rate) === 0) {
				issues += 1;
				msg += " rate | ";
			}

			if (!isNumeric(item.weight)) {
				issues += 1;
				msg += " weight | ";
			} else if (isNumeric(item.weight) && parseFloat(item.weight) === 0) {
				issues += 1;
				msg += " weight | ";
			}

			if (msg === `[${index + 1}] : `) return;

			rows.push({ type: "Products", message: msg });
			msg = "";
		});

		currentState.suppliers.forEach((item, index) => {
			if (!item.userInput) return;

			let msg = `[${index + 1}] : `;

			if (item.supplier.id === 0) {
				issues += 1;
				msg += " supplier | ";
			}

			if (item.land.id === 0) {
				issues += 1;
				msg += " land | ";
			}

			if (item.product.id === 0) {
				issues += 1;
				msg += " product | ";
			}

			if (!isNumeric(item.rate)) {
				issues += 1;
				msg += " stumpage rate | ";
			} else if (isNumeric(item.rate) && parseFloat(item.rate) === 0) {
				issues += 1;
				msg += " stumpage rate | ";
			}

			if (!isNumeric(item.weight)) {
				issues += 1;
				msg += " stumpage weight | ";
			} else if (isNumeric(item.weight) && parseFloat(item.weight) === 0) {
				issues += 1;
				msg += " stumpage weight | ";
			}

			if (msg === `[${index + 1}] : `) return;
			rows.push({ type: "Suppliers", message: msg });
			msg = "";
		});

		currentState.cutters.forEach((item, index) => {
			if (!item.userInput) return;

			let msg = `[${index + 1}] : `;

			if (item.cutter.id === 0) {
				issues += 1;
				msg += " cutter name | ";
			}

			if (item.product.id === 0) {
				issues += 1;
				msg += " cutter product | ";
			}

			if (!isNumeric(item.rate)) {
				issues += 1;
				msg += " cutter rate | ";
			} else if (isNumeric(item.rate) && parseFloat(item.rate) === 0) {
				issues += 1;
				msg += " cutter rate | ";
			}

			if (!isNumeric(item.weight)) {
				issues += 1;
				msg += " cutter weight | ";
			} else if (isNumeric(item.weight) && parseFloat(item.weight) === 0) {
				issues += 1;
				msg += " cutter weight | ";
			}

			if (msg === `[${index + 1}] : `) return;
			rows.push({ type: "Cutters", message: msg });
			msg = "";
		});

		if (issues === 0) return true;

		const ProductsArray = rows.filter((item) => item.type === "Products");

		if (ProductsArray.length !== 0) {
			globalMsg += "Products:\n";
			ProductsArray.forEach((item) => {
				globalMsg += `${item.message}\n`;
			});
		}

		const SuppliersArray = rows.filter((item) => item.type === "Suppliers");

		if (SuppliersArray.length !== 0) {
			globalMsg += `${globalMsg.length > 0 ? "\n" : ""}Suppliers:\n`;
			SuppliersArray.forEach((item) => {
				globalMsg += `${item.message}\n`;
			});
		}

		const CuttersArray = rows.filter((item) => item.type === "Cutters");

		if (CuttersArray.length !== 0) {
			globalMsg += `${globalMsg.length > 0 ? "\n" : ""}Cutters:\n`;
			CuttersArray.forEach((item) => {
				globalMsg += `${item.message}\n`;
			});
		}

		showToast(globalMsg, "warning", 4000, classes.wrapText);

		await createDelay(500);

		const confirmation = window.confirm("There are some fields that are partially filed and the record won't be saved, do you want to continue with the save process?");

		return confirmation;
	};

	const store = async () => {
		showLoader(true);

		if (!validateFiledsRequiredFromForm()) {
			showLoader(false);
			return;
		}

		if (!(await checkForPartialValues())) {
			showLoader(false);
			return;
		}

		// change to enum values
		let ticketTemp = state.ticket;
		ticketTemp['status'] = ticketTemp['status'] === "Not received" ? "Not paid" : "Paid";
		ticketTemp['invoiceStatus'] = ticketTemp['invoiceStatus'] === "Not invoiced" ? "Not invoiced" : "Invoiced";

		// if there is not an ID it will create a new ticket
		if (typeof id === "undefined") {
			const response = await post("/farmForest/delivery/store", ticketTemp);
			if (response.status === 200) {
				let tempCTicket = convertTicketState(response.data)
				// update the current state with the back-ends data for the ids of the list.
				setState((prev) => ({ ...prev, ticket: { ...prev.ticket, ...tempCTicket }, loading: false }));
				handleFormClear(false);
				window.scrollTo(0, 0)
				showToast(response.message);
			} else {
				console.log("[DeliveryTicketsCreate] store error", response);
				helpers.failRequest(response, showToast, signOut);
			}

			showLoader(false);
			return;
		}

		// if there is an id it will update the information
		const updateResponse = await put(`/farmForest/delivery/${id}`, state.ticket);
		if (updateResponse.status === 200) {
			// merge the new data into the current state
			let tempCTicket = convertTicketState(updateResponse.data)
			setState((prev) => ({ ...prev, ticket: { ...prev.ticket, ...tempCTicket } }));
			showToast(updateResponse.message);
		} else {
			console.log("[DeliveryTicketsStore] store error", updateResponse);
			helpers.failRequest(updateResponse, showToast, signOut);
		}

		showLoader(false);
	};

	if (state.loading) return <Loader fullScreen />;

	if (!state.permissions.read_attr) return <NotFound />;

	return (
		<div className={classes.root}>
			<Divider />
			<div className={classes.row}>
				<div className={classes.rowText}>
					<h3>{"Payment Status:"}</h3>
					<label className={classes.state}>{state.ticket.status}</label>
				</div>
				<div className={classes.rowText}>
					<h3>{"Invoiced:"}</h3>
					<label className={classes.state}>{state.ticket.invoiceStatus}</label>
				</div>
			</div>

			<Divider className={classes.divider} />

			<Grid container>
				<Grid item xs={6} lg={3}>
					<FormGroup>
						<FormLabel>{"ID"}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput disabled autoComplete="off" type="text" placeholder="ID" name="id" value={typeof id !== "undefined" ? id : "New"} onChange={handleChange} />
						</FormControl>
					</FormGroup>
				</Grid>
				<Grid item xs={6} lg={3}>
					<FormGroup>
						<FormLabel>
							{"Delivery ticket #"} <span className={classes.requiredValue}>*</span>{" "}
						</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput
								autoComplete="off"
								type="text"
								disabled={typeof id !== "undefined" ? true : false}
								placeholder="No. ticket"
								name="deliveryNumber"
								value={state.ticket.deliveryNumber}
								onChange={handleChange}
							/>
						</FormControl>
					</FormGroup>
				</Grid>
				<Grid item xs={6} lg={3}>
					<FormGroup>
						<FormLabel>
							{"Date"} <span className={classes.requiredValue}>*</span>{" "}
						</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput
								autoComplete="off"
								type="date"
								placeholder="Date"
								name="dateCreated"
								value={state.ticket.dateCreated}
								onChange={handleChange}
							/>
						</FormControl>
					</FormGroup>
				</Grid>
				<Grid item xs={6} lg={3}>
					<FormGroup>
						<FormLabel>{"Purchase order #"}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput autoComplete="off" type="text" placeholder="Purchase order" name="purchaseOrder" value={state.ticket.purchaseOrder} onChange={handleChange} />
						</FormControl>
					</FormGroup>
				</Grid>
			</Grid>

			<Grid container>
				<Grid item xs={6} lg={4}>
					<FormGroup>
						<FormLabel>{"Loader name"}</FormLabel>
						<Autocomplete
							options={state.loaders}
							value={state.ticket.loader}
							getOptionLabel={(option) => option?.name ?? ""}
							getOptionSelected={(option, value) => option.id === value.id}
							onChange={(e, value) => handleAutocomplete("loader", value)}
							renderInput={(params) => <TextField {...params} placeholder="Loaders" />}
							disableClearable={true}
						/>
					</FormGroup>
				</Grid>
				<Grid item xs={6} lg={4}>
					<FormGroup>
						<FormLabel>{"Trucking company"}</FormLabel>
						<Autocomplete
							options={state.truckers}
							value={state.ticket.trucker}
							getOptionLabel={(option) => option?.name ?? ""}
							getOptionSelected={(option, value) => option.id === value.id}
							onChange={(e, value) => handleAutocomplete("trucker", value)}
							renderInput={(params) => <TextField {...params} placeholder="Trucking company" />}
							disableClearable={true}
						/>
					</FormGroup>
				</Grid>
				<Grid item xs={12} lg={4}>
					<FormGroup>
						<FormLabel>{"Trucking number"}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput autoComplete="off" type="text" placeholder="Trucking number" name="truckNumber" value={state.ticket.truckNumber} onChange={handleChange} />
						</FormControl>
					</FormGroup>
				</Grid>
				<Grid item xs={12} lg={4}>
					<FormGroup>
						<FormLabel>{"Trucking license plate"}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput autoComplete="off" type="text" placeholder="Trucking license plate" name="truckPlate" value={state.ticket.truckPlate} onChange={handleChange} />
						</FormControl>
					</FormGroup>
				</Grid>
				<Grid item xs={12} lg={4}>
					<FormGroup>
						<FormLabel>{"Trailer number"}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput autoComplete="off" type="text" placeholder="Trailer number" name="trailerNumber" value={state.ticket.trailerNumber} onChange={handleChange} />
						</FormControl>
					</FormGroup>
				</Grid>
			</Grid>

			<Grid container>
				<Grid item xs={12} lg={4}>
					<FormGroup>
						<FormLabel>
							{"CFF full weight"} <span className={classes.requiredValue}>*</span>{" "}
						</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput autoComplete="off" type="number" placeholder="CFF full weight" name="fullWeight" min={0} value={state.ticket.fullWeight} onChange={handleChange} />
						</FormControl>
					</FormGroup>
				</Grid>
				<Grid item xs={12} lg={4}>
					<FormGroup>
						<FormLabel>
							{"CFF empty weight"} <span className={classes.requiredValue}>*</span>{" "}
						</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput autoComplete="off" type="number" placeholder="CFF empty weight" name="emptyWeight" min={0} value={state.ticket.emptyWeight} onChange={handleChange} />
						</FormControl>
					</FormGroup>
				</Grid>
				<Grid item xs={12} lg={4}>
					<FormGroup>
						<FormLabel>{"CFF net weight"}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput autoComplete="off" disabled type="number" placeholder="CFF net weight" value={state.ticket.netWeight} />
						</FormControl>
					</FormGroup>
				</Grid>
			</Grid>

			<Divider className={classes.divider} />

			<Grid container>
				<Grid item xs={12} lg={4}>
					<FormGroup>
						<FormLabel>
							{"Customer name"} <span className={classes.requiredValue}>*</span>{" "}
						</FormLabel>
						<Autocomplete
							options={state.customers}
							value={state.ticket.customer}
							getOptionLabel={(option) => option?.name ?? ""}
							getOptionSelected={(option, value) => option.id === value.id}
							onChange={(e, value) => handleAutocomplete("customer", value)}
							renderInput={(params) => <TextField {...params} placeholder="Customer" />}
							disableClearable={true}
						/>
					</FormGroup>
				</Grid>
				<Grid item xs={12} lg={4}>
					<FormGroup>
						<FormLabel>{"Date Received"}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput autoComplete="off" type="date" placeholder="Date Received" name="dateReceived" value={state.ticket.dateReceived} onChange={handleChange} />
						</FormControl>
					</FormGroup>
				</Grid>
			</Grid>

			<Divider />
			<div>
				<div className={classes.row}>
					<h2>{"Payment"}</h2>
					<IconButton onClick={() => setShowPayment(!showPayment)}>
						<Icon>{showPayment ? "expand_less" : "expand_more"}</Icon>
					</IconButton>
				</div>
				<Collapse in={showPayment}>
					<Grid container>
						<Grid item xs={12} lg={4}>
							<FormGroup>
								<FormLabel>{"Payment Received"}</FormLabel>
								<FormControl variant="filled" component={Box} width="100%">
									<FilledInput autoComplete="off" type="date" placeholder="Payment Received" name="datePayment" value={state.ticket.datePayment} onChange={handleChange} />
								</FormControl>
							</FormGroup>
						</Grid>
						<Grid item xs={12} lg={4}>
							<FormGroup>
								<FormLabel>{"Invoice #"}</FormLabel>
								<FormControl variant="filled" component={Box} width="100%">
									<FilledInput autoComplete="off" type="text" placeholder="Invoice number" name="invoiceNumber" value={state.ticket.invoiceNumber} onChange={handleChange} />
								</FormControl>
							</FormGroup>
						</Grid>
					</Grid>
				</Collapse>
			</div>

			<Divider className={classes.divider} />

			<Grid container style={{ justifyContent: "flex-end" }}>
				<Grid item xs={12} lg={2}>
					<FormGroup>
						<Button
							variant="contained"
							className={classes.store}
							onClick={() => {
								handleAddNewItemToList("products");
							}}
						>
							{"Add Product"}
						</Button>
					</FormGroup>
				</Grid>
			</Grid>

			{state.ticket.products.map((item, index) => {
				return (
					<Grid container key={`ffProductsRow${index}`} style={{ columnGap: "0.5rem" }}>
						<Grid item xs={1} lg={1}>
							<FormGroup>
								<IconButton
									onClick={() => {
										if (state.ticket.products.length === 1) {
											handleClearItemFromList(index, "products");
										} else {
											handleDeleteItemFromList(index, "products");
										}
									}}
									className={classes.trash}
								>
									<Icon>{"delete"}</Icon>
								</IconButton>
							</FormGroup>
						</Grid>

						<Grid container xs={11} lg={11} styl>
							<Grid item xs={12} lg={6}>
								<FormGroup>
									<FormLabel>
										{`Product ${index + 1}`}
										{index === 0 ? <span className={classes.requiredValue}>*</span> : ""}
									</FormLabel>
									<Autocomplete
										options={state.products}
										value={state.ticket.products[index].product}
										getOptionLabel={(option) => option?.name ?? ""}
										getOptionSelected={(option, value) => option.id === value.id}
										onChange={(e, value) => handleAutocomplete(`products[${index}].product`, value)}
										renderInput={(params) => <TextField {...params} placeholder="Product" />}
										disableClearable={true}
									/>
								</FormGroup>
							</Grid>
							<Grid item xs={12} lg={2}>
								<FormGroup>
									<FormLabel>
										{`Weight ${index + 1}`}
										{index === 0 ? <span className={classes.requiredValue}>*</span> : ""}
									</FormLabel>
									<FormControl variant="filled" component={Box} width="100%">
										<FilledInput
											autoComplete="off"
											type="number"
											placeholder="Weight"
											name={`products[${index}].weight`}
											value={state.ticket.products[index].weight}
											min={0}
											onChange={handleChange}
										/>
									</FormControl>
								</FormGroup>
							</Grid>
							<Grid item xs={12} lg={2}>
								<FormGroup>
									<FormLabel>
										{`Rate ${index + 1}`}
										{index === 0 ? <span className={classes.requiredValue}>*</span> : ""}
									</FormLabel>
									<FormControl variant="filled" component={Box} width="100%">
										<FilledInput
											autoComplete="off"
											type="number"
											placeholder="Rate"
											name={`products[${index}].rate`}
											value={state.ticket.products[index].rate}
											min={0}
											onChange={handleChange}
										/>
									</FormControl>
								</FormGroup>
							</Grid>
							<Grid item xs={12} lg={2}>
								<FormGroup>
									<FormLabel>{`Subtotal ${index + 1}`}</FormLabel>
									<FormControl variant="filled" component={Box} width="100%">
										<FilledInput
											disabled
											autoComplete="off"
											type="number"
											startAdornment={<InputAdornment position="start">{"$"}</InputAdornment>}
											placeholder="Subtotal"
											value={state.ticket.products[index].total}
										/>
									</FormControl>
								</FormGroup>
							</Grid>
						</Grid>
					</Grid>
				);
			})}

			<Grid container style={{ justifyContent: "flex-end" }}>
				<Grid item xs={12} lg={2}>
					<FormGroup>
						<FormLabel>{"Invoice total"}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput
								disabled
								autoComplete="off"
								type="text"
								startAdornment={<InputAdornment position="start">{"$"}</InputAdornment>}
								placeholder="Invoice total"
								name="invoiceTotal"
								value={state.ticket.invoiceTotal}
							/>
						</FormControl>
					</FormGroup>
				</Grid>
			</Grid>

			<Divider className={classes.divider} />

			<Grid container style={{ justifyContent: "flex-end" }}>
				<Grid item xs={12} lg={2}>
					<FormGroup item xs={12} lg={1}>
						<Button
							variant="contained"
							className={classes.store}
							onClick={() => {
								handleAddNewItemToList("suppliers");
							}}
						>
							{"Add Supplier"}
						</Button>
					</FormGroup>
				</Grid>
			</Grid>

			{state.ticket.suppliers.map((item, index) => {
				return (
					<>
						<Grid container key={`ffSuppliersRow${index}`} style={{ columnGap: "0.5rem" }}>
							<Grid item xs={1} lg={1}>
								<FormGroup style={{ margin: 0 }}>
									<IconButton
										onClick={() => {
											if (state.ticket.suppliers.length === 1) {
												handleClearItemFromList(index, "suppliers");
											} else {
												handleDeleteItemFromList(index, "suppliers");
											}
										}}
										className={classes.trash}
									>
										<Icon>{"delete"}</Icon>
									</IconButton>
								</FormGroup>
							</Grid>
							<Grid container xs={11} lg={11} styl>
								<Grid item xs={12} lg={4}>
									<FormGroup>
										<FormLabel>{`Land Owner ${index + 1}`}</FormLabel>
										<Autocomplete
											options={state.suppliers}
											value={state.ticket.suppliers[index].supplier}
											getOptionLabel={(option) => option?.name ?? ""}
											getOptionSelected={(option, value) => option.id === value.id}
											onChange={(e, value) => handleAutocomplete(`suppliers[${index}].supplier`, value)}
											renderInput={(params) => <TextField {...params} placeholder="Supplier" />}
											disableClearable={true}
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={12} lg={4}>
									<FormGroup>
										<FormLabel>{`Land area ${index + 1}`}</FormLabel>
										<Autocomplete
											options={state.lands}
											value={state.ticket.suppliers[index].land}
											getOptionLabel={(option) => option?.name ?? ""}
											getOptionSelected={(option, value) => option.id === value.id}
											onChange={(e, value) => handleAutocomplete(`suppliers[${index}].land`, value)}
											renderInput={(params) => <TextField {...params} placeholder="Land area" />}
											disableClearable={true}
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={12} lg={4}>
									<FormGroup>
										<FormLabel>{`Product ${index + 1}`}</FormLabel>
										<Autocomplete
											options={state.products}
											value={state.ticket.suppliers[index].product}
											getOptionLabel={(option) => option?.name ?? ""}
											getOptionSelected={(option, value) => option.id === value.id}
											onChange={(e, value) => handleAutocomplete(`suppliers[${index}].product`, value)}
											renderInput={(params) => <TextField {...params} placeholder="Product" />}
											disableClearable={true}
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={12} lg={4}>
									<FormGroup>
										<FormLabel>{`Stumpage weight ${index + 1}`}</FormLabel>
										<FormControl variant="filled" component={Box} width="100%">
											<FilledInput
												autoComplete="off"
												type="number"
												placeholder="Weight"
												name={`suppliers[${index}].weight`}
												value={state.ticket.suppliers[index].weight}
												min={0}
												onChange={handleChange}
											/>
										</FormControl>
									</FormGroup>
								</Grid>
								<Grid item xs={12} lg={4}>
									<FormGroup>
										<FormLabel>{`Stumpage rate ${index + 1}`}</FormLabel>
										<FormControl variant="filled" component={Box} width="100%">
											<FilledInput
												autoComplete="off"
												type="number"
												placeholder="Rate"
												name={`suppliers[${index}].rate`}
												value={state.ticket.suppliers[index].rate}
												min={0}
												onChange={handleChange}
											/>
										</FormControl>
									</FormGroup>
								</Grid>
								<Grid item xs={12} lg={4}>
									<FormGroup>
										<FormLabel>{`Stumpage total ${index + 1}`}</FormLabel>
										<FormControl variant="filled" component={Box} width="100%">
											<FilledInput
												disabled
												autoComplete="off"
												type="number"
												startAdornment={<InputAdornment position="start">{"$"}</InputAdornment>}
												placeholder="Total supplier"
												value={state.ticket.suppliers[index].total}
											/>
										</FormControl>
									</FormGroup>
								</Grid>
							</Grid>
						</Grid>
					</>
				);
			})}

			<Divider className={classes.divider} />

			<Grid container style={{ justifyContent: "flex-end" }}>
				<Grid item xs={12} lg={2}>
					<FormGroup item xs={12} lg={1}>
						<Button
							variant="contained"
							className={classes.store}
							onClick={() => {
								handleAddNewItemToList("cutters");
							}}
						>
							{"Add Cutter"}
						</Button>
					</FormGroup>
				</Grid>
			</Grid>

			{state.ticket.cutters.map((item, index) => {
				return (
					<>
						<Grid container key={`ffCuttersRow${index}`} style={{ columnGap: "0.5rem" }}>
							<Grid item xs={1} lg={1}>
								<FormGroup>
									<IconButton
										onClick={() => {
											if (state.ticket.cutters.length === 1) {
												handleClearItemFromList(index, "cutters");
											} else {
												handleDeleteItemFromList(index, "cutters");
											}
										}}
										className={classes.trash}
									>
										<Icon>{"delete"}</Icon>
									</IconButton>
								</FormGroup>
							</Grid>
							<Grid container xs={11} lg={11} styl>
								<Grid item xs={12} lg={3}>
									<FormGroup>
										<FormLabel>{`Cutter/Topper ${index + 1}`}</FormLabel>
										<Autocomplete
											options={state.suppliers}
											value={state.ticket.cutters[index].cutter}
											getOptionLabel={(option) => option?.name ?? ""}
											getOptionSelected={(option, value) => option.id === value.id}
											onChange={(e, value) => handleAutocomplete(`cutters[${index}].cutter`, value)}
											renderInput={(params) => <TextField {...params} placeholder="Cutter/Topper" />}
											disableClearable={true}
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={12} lg={3}>
									<FormGroup>
										<FormLabel>{`Product ${index + 1}`}</FormLabel>
										<Autocomplete
											options={state.products}
											value={state.ticket.cutters[index].product}
											getOptionLabel={(option) => option?.name ?? ""}
											getOptionSelected={(option, value) => option.id === value.id}
											onChange={(e, value) => handleAutocomplete(`cutters[${index}].product`, value)}
											renderInput={(params) => <TextField {...params} placeholder="Product" />}
											disableClearable={true}
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={12} lg={2}>
									<FormGroup>
										<FormLabel>{`Cutter weight ${index + 1}`}</FormLabel>
										<FormControl variant="filled" component={Box} width="100%">
											<FilledInput
												autoComplete="off"
												type="number"
												placeholder="Weight"
												name={`cutters[${index}].weight`}
												value={state.ticket.cutters[index].weight}
												min={0}
												onChange={handleChange}
											/>
										</FormControl>
									</FormGroup>
								</Grid>
								<Grid item xs={12} lg={2}>
									<FormGroup>
										<FormLabel>{`Cutter rate ${index + 1}`}</FormLabel>
										<FormControl variant="filled" component={Box} width="100%">
											<FilledInput
												autoComplete="off"
												type="number"
												placeholder="Rate"
												name={`cutters[${index}].rate`}
												value={state.ticket.cutters[index].rate}
												min={0}
												onChange={handleChange}
											/>
										</FormControl>
									</FormGroup>
								</Grid>
								<Grid item xs={12} lg={2}>
									<FormGroup>
										<FormLabel>{`Total cutter/topper ${index + 1}`}</FormLabel>
										<FormControl variant="filled" component={Box} width="100%">
											<FilledInput
												disabled
												autoComplete="off"
												type="number"
												startAdornment={<InputAdornment position="start">{"$"}</InputAdornment>}
												placeholder="Total cutter/topper"
												value={state.ticket.cutters[index].total}
											/>
										</FormControl>
									</FormGroup>
								</Grid>
							</Grid>
						</Grid>
					</>
				);
			})}

			<Divider className={classes.divider} />

			<Grid container>
				<Grid item xs={12} lg={12}>
					<FormGroup>
						<FormLabel>{"Notes"}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput autoComplete="off" type="text" placeholder="Notes" name="note" value={state.ticket.note} onChange={handleChange} />
						</FormControl>
					</FormGroup>
				</Grid>
			</Grid>

			<Divider className={classes.divider} />

			<Grid item xs={12} lg={6} className={classes.bottom}>
				{typeof id !== "undefined" ? (
					""
				) : (
					<>
						<div className={classes.buttonContainer}>
							<Button variant="contained" onClick={handleFormClear} className={classes.clear}>
								{"Clear"}
							</Button>
						</div>
					</>
				)}

				{state.permissions?.update_attr && (
					<div className={classes.buttonContainer}>
						<Button variant="contained" onClick={store} className={classes.store}>
							{typeof id !== "undefined" ? "Update" : "Store"}
						</Button>
					</div>
				)}
			</Grid>
		</div>
	);
}
