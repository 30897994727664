import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal, Typography, Button, Grid, FormGroup, FormLabel, FormControl, FilledInput, Box } from '@material-ui/core';
import { Calendar } from 'react-date-range';
import isoWeek from 'dayjs/plugin/isoWeek';
import dayjs from 'dayjs';

import themeColors from '../assets/colors';
import AuthContext from '../context';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

dayjs.extend(isoWeek);

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.background.default,
        padding: 20,
        borderRadius: 10,
        '@media (min-width: 480px)': {
            width: '80%'
        },
        '@media (min-width: 720px)': {
            width: '50%'
        },
        '@media (min-width: 1000px)': {
            width: '40%'
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 15
    },
    button: {
        marginLeft: 15,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
}));

const WeekPickerModal = ({ open, onClose, setWeek, dayAvailable, biweekly }) => {

    const { showToast } = useContext(AuthContext);
    const classes = useStyles();
    const [state, setState] = useState({
        date: dayjs().toDate(),
        week: biweekly ? `${dayjs().isoWeekYear()}-${dayjs().isoWeek() - 1}-${dayjs().isoWeek()}` : `${dayjs().isoWeekYear()}-${dayjs().isoWeek()}`,
        range: '',
        start: '',
        end: ''
    });

    useEffect(() => {
        const dayOfTheWeek = dayjs(state.date).format('d');
        const sunday = dayjs(state.date).subtract(dayOfTheWeek, 'days');
        let startDay = dayjs(sunday);
        let endDay = dayjs(sunday);

        if (dayAvailable === '1' && biweekly) {
            startDay = startDay.subtract(1, 'week');
            endDay = endDay.add(6, 'days');
        } else if (dayAvailable === '1') {
            /* Next Sunday */
            endDay = endDay.add(6, 'days');
        } else {
            /* Previus Thursday */
            startDay = startDay.subtract(3, 'days');
            /* Current Wednesday */
            endDay = endDay.add(3, 'days');
        }

        const start = startDay.format('YYYY-MM-DD');
        const end = endDay.format('YYYY-MM-DD');

        setState(prev => ({ ...prev, start, end, range: `${startDay.format('MM/DD/YY')} - ${endDay.format('MM/DD/YY')}` }));
    }, [state.date]);

    const handleChange = value => {
        const newValue = dayjs(value.toISOString());
        const date = newValue.toDate();
        const week = biweekly ? `${newValue.isoWeekYear()}-${newValue.isoWeek() - 1}-${newValue.isoWeek()}` : `${newValue.isoWeekYear()}-${newValue.isoWeek()}`;
        setState(prev => ({ ...prev, date, week }));
    }

    const sendWeek = () => {
        setWeek
            ? setWeek(state)
            : showToast('This function is not programmed', 'error');
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div className={classes.container}>
                <Typography variant='h2'>{'Select the week'}</Typography>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>{'Week'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Week'
                                    name='week'
                                    value={state.week}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>{'Date range'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Date range'
                                    name='range'
                                    value={state.range}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>

                <Calendar
                    date={state.date}
                    onChange={handleChange}
                    disabledDay={d => dayjs(d.toISOString()).format('d') !== (dayAvailable ? dayAvailable : '4')}
                />

                <div className={classes.buttonContainer}>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={onClose}
                    >
                        {'Close'}
                    </Button>
                    <Button
                        variant='contained'
                        className={classes.button}
                        onClick={sendWeek}
                    >
                        {'Set week'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default WeekPickerModal;