import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Button, Divider, TextField, Icon, IconButton, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import Loader from '../../common/loader';
import NotFound from '../../common/not-found';

import AuthContext from '../../context';
import { get, post } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    searchButton: {
        width: '100%',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    tableContainer: {
        width: '100%',
        marginBottom: 24
    },
    controls: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    cancel: {
        backgroundColor: '#A4A4A4',
        borderColor: '#A4A4A4',
        '&:hover': {
            backgroundColor: '#A4A4A4',
            borderColor: '#A4A4A4',
            boxShadow: 'none'
        }
    },
    store: {
        marginLeft: 20,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none'
        }
    },
}));

const defSede = { id: 0, name: 'Choose a sede' };

const TicketSedeTransferCreate = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const sede = JSON.parse(localStorage.getItem('@sede'));
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [state, setState] = useState({
        loading: true,
        sedes: [],
        tt: {
            sede_origin: { id: sede.id, name: sede.name },
            sede_destination: defSede,
            date: dayjs().format('YYYY-MM-DD'),
            notes: ''
        },
        tickets: [],
        code: '',
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Ticket sede transfer';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(location.pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        const response = await get('/invTicketSedeTransfer/create');
        if (response.status === 200) {
            const sedes = response?.sedes ?? [defSede];
            setState(prev => ({ ...prev, loading: false, sedes }));
        } else {
            console.log('[TicketSedeTransferCreate] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
    }

    const handleAutocomplete = (key, value) => setState(prev => ({ ...prev, tt: { ...prev.tt, [key]: value } }));

    const handleChange = ({ target }) => setState(prev => ({ ...prev, tt: { ...prev.tt, [target.name]: target.value } }));

    const validateForm = type => {
        const code = state.code;
        const tickets = state.tickets;
        const { sede_destination, date } = state.tt;

        switch (type) {
            case 'getTicket':
                if (code.trim()) {
                    return true;
                } else {
                    showToast('Complete the field', 'error');
                }
                return false;

            case 'store':
                if (sede_destination?.id) {
                    if (dayjs(date).isValid()) {
                        if (tickets.length > 0) {
                            const cond = window.confirm('Confirm if all data is correct');

                            return cond;
                        } else {
                            showToast('Use at least 1 ticket', 'error');
                        }
                    } else {
                        showToast('Use a valid date', 'error');
                    }
                } else {
                    showToast('You must choose a destination', 'error');
                }
                return false;

            default:
                break;
        }

    }

    const getTicket = async () => {
        const cond = validateForm('getTicket');
        if (cond) {
            showLoader(true);
            const response = await get('/invTicketSedeTransfer/getTicket', { code: state.code });
            if (response.status === 200) {
                const tickets = state.tickets.slice();
                const ticket = response.ticket;

                if (tickets.filter(a => a.idticket === ticket.idticket).length === 0) {
                    tickets.push(ticket);
                    setState(prev => ({ ...prev, tickets, code: '' }));
                } else {
                    showToast('This ticket is already added', 'error');
                }
            } else {
                console.log('[TicketSedeTransferCreate] getTicket error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const removeTicket = row => {
        const tickets = state.tickets.slice();
        const index = tickets.indexOf(row);

        tickets.splice(index, 1);

        setState(prev => ({ ...prev, tickets }));
    }

    const cancel = () => {
        const cond = window.confirm('If you cancel, you will discard all changes made.');
        if (cond) {
            history.replace('/inventory/invTicketSedeTransfer');
        }
    }

    const store = async () => {
        const cond = validateForm('store');
        if (cond) {
            showLoader(true);
            const response = await post('/invTicketSedeTransfer/store', { ...state.tt, tickets: state.tickets });
            if (response.status === 200) {
                showToast(response.message);
                history.push(`/inventory/invTicketSedeTransfer/${response.id}`);
            } else {
                console.log('[TicketSedeTransferCreate] store error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const getDate = ({ value }) => dayjs(value).format('MM/DD/YYYY');

    const getName = ({ value }) => value?.name ?? '?';

    const renderCell = ({ row }) => {
        return (
            <Tooltip title='Remove ticket' placement='top'>
                <IconButton onClick={() => removeTicket(row)}>
                    <Icon>{'delete'}</Icon>
                </IconButton>
            </Tooltip>
        );
    }

    const columns = [
        { field: 'code', headerName: 'Code', flex: 1, minWidth: 150 },
        { field: 'date', headerName: 'Date', flex: 1, minWidth: 150, valueGetter: getDate },
        { field: 'location_origin', headerName: 'Origin location', flex: 1, minWidth: 150, valueGetter: getName },
        { field: 'product', headerName: 'Product', flex: 1, minWidth: 150, valueGetter: getName },
        { field: 'product_qty', headerName: 'Product qty', flex: 1, minWidth: 150 },
        { field: 'state', headerName: 'Status', flex: 1, minWidth: 150 },
        { field: 'actions', headerName: 'Actions', flex: 0.7, minWidth: 100, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={6} sm={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Origin'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='text'
                                value={state.tt.sede_origin?.name}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Destination'}</FormLabel>
                        <Autocomplete
                            options={state.sedes}
                            value={state.tt.sede_destination}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleAutocomplete('sede_destination', value)}
                            renderInput={params => <TextField {...params} placeholder='Sede' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Date'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='date'
                                placeholder='Date'
                                name='date'
                                value={state.tt.date}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Notes'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Notes'
                                name='notes'
                                value={state.tt.notes}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Divider />

            <h2>{`Tickets (${state.tickets.length})`}</h2>
            <Grid container>
                <Grid item xs={6} sm={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Ticket'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='No. Ticket'
                                name='code'
                                value={state.code}
                                onChange={({ target }) => setState(prev => ({ ...prev, [target.name]: target.value }))}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} lg={1} className={classes.buttonContainer}>
                    <Button
                        variant='contained'
                        className={classes.searchButton}
                        onClick={getTicket}
                    >
                        <Icon fontSize='small'>{'search'}</Icon>
                    </Button>
                </Grid>
            </Grid>

            <div className={classes.tableContainer}>
                <DataGrid
                    autoHeight
                    pageSize={45}
                    density='compact'
                    columns={columns}
                    rows={state.tickets}
                    getRowId={row => row.idticket}
                />
            </div>

            <div className={classes.controls}>
                <Button
                    variant='contained'
                    className={classes.cancel}
                    onClick={cancel}
                >
                    {'Cancel'}
                </Button>
                {
                    state.permissions?.create_attr &&
                    <Button
                        variant='contained'
                        className={classes.store}
                        onClick={store}
                    >
                        {'Store'}
                    </Button>
                }
            </div>
        </div>
    );
}

export default TicketSedeTransferCreate;