import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, InputAdornment, TextField, Button, Icon, IconButton, Divider, Collapse, Tooltip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DataGrid } from '@material-ui/data-grid';
import dayjs from 'dayjs';

import Loader from '../../common/loader';
import NotFound from '../../common/not-found';

import AuthContext from '../../context';
import { get, post, put, del } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import themeColors from '../../assets/colors';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20
    },
    tableContainer: {
        width: '100%',
        '& .total': {
            backgroundColor: '#E0F8E0'
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    store: {
        marginLeft: 20,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none'
        }
    },
    update: {
        marginLeft: 20,
        backgroundColor: '#ffc107',
        borderColor: '#ffc107',
        '&:hover': {
            backgroundColor: '#ffc107',
            borderColor: '#ffc107',
            boxShadow: 'none'
        }
    },
    delete: {
        width: '100%',
        backgroundColor: '#dc3545',
        borderColor: '#dc3545',
        '&:hover': {
            backgroundColor: '#dc3545',
            borderColor: '#dc3545',
            boxShadow: 'none'
        }
    },
    clear: {
        backgroundColor: '#A4A4A4',
        borderColor: '#A4A4A4',
        '&:hover': {
            backgroundColor: '#A4A4A4',
            borderColor: '#A4A4A4',
            boxShadow: 'none'
        }
    },
    calculate: {
        marginLeft: 20,
        backgroundColor: '#6E6E6E',
        borderColor: '#6E6E6E',
        '&:hover': {
            backgroundColor: '#6E6E6E',
            borderColor: '#6E6E6E',
            boxShadow: 'none'
        }
    },
    generate: {
        marginLeft: 20,
        backgroundColor: '#198754',
        borderColor: '#198754',
        '&:hover': {
            backgroundColor: '#198754',
            borderColor: '#198754',
            boxShadow: 'none'
        }
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    rowText: {
        display: 'inherit',
        flexDirection: 'inherit',
        alignItems: 'inherit'
    },
    state: {
        fontSize: '1rem',
        marginLeft: 5,
        marginRight: 10,
        color: themeColors.gray[600]
    },
    divider: {
        marginBottom: 24
    },
    highlight: {
        color: '#0d6efd'
    },
    controls: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
    }
}));

const defTypes = [{ id: 0, name: 'Choose a type' }];

const TicketsDetail = ({ permissions }) => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const sede = JSON.parse(localStorage.getItem('@sede'));
    const { id } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const [showDetail, setShowDetail] = useState(false);
    const [state, setState] = useState({
        loading: true,
        items: [],
        products: [],
        types: defTypes,
        ticket: {},
        dtickets: [],
        oldDticket: {},
        dticket: {
            iddetail_ticket: 0,
            item: {},
            product: {},
            type: {},
            qty_product: 0,
            qty: 0,
            total_qty: 0,
            price: 0,
            discount: 0,
            final_price: 0,
            subtotal: 0,
            admin_fee: 0,
            stumpage: 0,
            extras: 0,
            total: 0,
            note: ''
        },
        extras: {
            af: {},
            s: {},
            e: {}
        },
        permissions: {}
    });

    useEffect(() => {
        setState(prev => ({ ...prev, permissions }));
    }, [permissions]);

    useEffect(() => {
        window.document.title = 'Ticket detail';

        makeRequest();
    }, []);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get(`/ticket/${id}`);
        if (response.status === 200) {
            if (response.data.status !== 'active' || response.data.idsede_origin !== sede.id) {
                showToast('This ticket is not available', 'error');
                history.replace('/ticket/tickets');
            } else {
                setState(prev => ({
                    ...prev,
                    ticket: response?.data ?? {},
                    items: response?.items ?? [],
                    products: response?.products ?? [],
                    types: defTypes,
                    dticket: {
                        ...prev.dticket,
                        item: response?.items[0] ?? {},
                        product: response?.products[0] ?? {},
                        type: defTypes[0]
                    }
                }));
                setDetail(response.detail);
            }
        } else {
            console.log('[TicketsDetail] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const getTypes = async product => {
        showLoader(true);
        const response = await get(`/product/${product.id}`);
        if (response.status === 200) {
            const { data } = response;
            setState(prev => ({
                ...prev,
                types: [...defTypes, ...data.types],
                dticket: {
                    ...prev.dticket,
                    type: defTypes[0]
                }
            }));
        } else {
            setState(prev => ({ ...prev, types: defTypes, dticket: { ...prev.dticket, type: defTypes[0] } }));
            console.log('[TicketsDetail] getTypes error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const operation = (a, b, op) => {
        const n1 = Number(a) * 100;
        const n2 = Number(b) * 100;
        switch (op) {
            case '+':
                return ((n1 + n2) / 100).toFixed(2);

            case '-':
                return ((n1 - n2) / 100).toFixed(2);

            default:
                break;
        }
    }

    const setDetail = array => {
        array.map(byProduct => {
            const totales = {
                iddetail_ticket: 0, type: { name: '' }, item: { name: 'TOTALES' }, qty_product: 0,
                qty: 0, final_price: 0, subtotal: 0, admin_fee: 0, stumpage: 0, extras: 0, total: 0
            }

            byProduct.map(item => {
                totales.qty_product = item.qty_product;
                totales.final_price = item.final_price;
                if (item?.item?.action === '+') {
                    totales.qty = operation(totales.qty, item.qty, '+');
                    totales.subtotal = operation(totales.subtotal, item.subtotal, '+');
                    totales.total = operation(totales.total, item.total, '+');
                } else if (item?.item?.action === '-') {
                    totales.qty = operation(totales.qty, item.qty, '-');
                    totales.subtotal = operation(totales.subtotal, item.subtotal, '-');
                    totales.total = operation(totales.total, item.total, '-');
                }
                totales.admin_fee = operation(totales.admin_fee, item.admin_fee, '+');
                totales.stumpage = operation(totales.stumpage, item.stumpage, '+');
                totales.extras = operation(totales.extras, item.extras, '+');
            });
            byProduct.push(totales);
        });

        setState(prev => ({ ...prev, loading: false, dtickets: array }));
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, dticket: { ...prev.dticket, [target.name]: target.value } }));

    const handleAutocomplete = (key, value) => setState(prev => ({ ...prev, dticket: { ...prev.dticket, [key]: value } }));

    const validateForm = option => {
        const { iddetail_ticket, item, product, type, qty, price, final_price } = state.dticket;

        if (sede?.id === 5) {
            if (option === 'store' || option === 'update') {
                if (item.id && product.id && type.id) {
                    return true;
                } else {
                    showToast('Item, Product & Type is required', 'error');
                }
                return false;
            }
        }


        switch (option) {
            case 'store':
                /* Avoid empty values */
                if (item.id && product.id && type.id && Number(qty) > 0) {
                    /* Avoid no calculating */
                    if (JSON.stringify(state.dticket) === JSON.stringify(state.oldDticket)) {
                        /* Avoid priceless storage */
                        if (Number(price) && Number(final_price)) {
                            if (Number(final_price) > 0) {
                                return true;
                            } else {
                                showToast('The final price cannot be negative', 'error', 5000);
                            }
                        } else {
                            showToast('You must have a price in the detail', 'error', 5000);
                        }
                    } else {
                        showToast('You must calculate before storing', 'error');
                    }
                } else {
                    showToast('Item, Product, Type & Qty is required', 'error');
                }
                return false;

            case 'update':
                if (Boolean(iddetail_ticket)) {
                    if (item.id && product.id && type.id && Number(qty) > 0) {
                        /* Avoid no calculating */
                        if (JSON.stringify(state.dticket) === JSON.stringify(state.oldDticket)) {
                            /* Avoid priceless storage */
                            if (Number(price) && Number(final_price)) {
                                return true;
                            } else {
                                showToast('You must have a price in the detail', 'error', 6000);
                            }
                        } else {
                            showToast('You must calculate before storing', 'error');
                        }
                    } else {
                        showToast('Item, Product, Type & Qty is required', 'error');
                    }
                }
                return false;

            case 'delete':
                if (state.ticket.idticket == 0) {
                    showToast('Please try later', 'error');
                    return false;
                }
                const dCond = window.confirm('Are you sure you want to delete this ticket?');

                return Boolean(state.ticket.idticket && dCond);

            case 'generateCheck':
                if (state.ticket.idticket == 0) {
                    showToast('Please try later', 'error');
                    return false;
                }
                const gCond = window.confirm('Are you sure you want to generate a check with this ticket?');

                return Boolean(state.ticket.idticket && gCond);
            default:
                break;
        }
    }

    const calculate = async () => {
        try {
            const { ticket: { date, supplier, land, location }, dticket: { product, type, qty, discount, item } } = state;

            if (date && supplier?.id && land?.id && location?.id && product?.id && type?.id && qty) {
                showLoader(true);
                const form = { date, supplier, land, location, product, type, item };
                const response = await get('/detailTicket/calculate', form);
                if (response.status === 200) {
                    const { price, fee, stumpage, extra } = response;
                    const { qty_product } = state.dticket;

                    /* Total qty */
                    const total_qty = qty * qty_product;

                    /* Price */
                    let partialPrice = 0;
                    let finalPrice = 0;
                    if (price !== null) {
                        partialPrice = price.price;
                        finalPrice = (price.price - discount).toFixed(3);
                    } else {
                        showToast('There is no price registered with this data', 'warning', 5000);
                    }

                    /* Subtotal */
                    const subtotal = (Number(total_qty) * Number(finalPrice)).toFixed(2);

                    /* AdminFee && Stumpage */
                    let totalAdminFee = '0.00';
                    let totalStumpage = '0.00';
                    if (fee !== null && !fee.percent) {
                        totalAdminFee = (Number(total_qty) * Number(fee.value)).toFixed(2);
                    } else if (fee !== null && fee.percent) {
                        totalAdminFee = (Number(subtotal) * (Number(fee.value) / 100)).toFixed(2);
                    }
                    if (stumpage !== null && !stumpage.percent) {
                        totalStumpage = (Number(total_qty) * Number(stumpage.value)).toFixed(2);
                    } else if (stumpage !== null && stumpage.percent) {
                        totalStumpage = (Number(subtotal) * (Number(stumpage.value) / 100)).toFixed(2);
                    }

                    /*  Extra */
                    let totalExtra = '0.00';
                    if (extra !== null) {
                        totalExtra = (Number(total_qty) * Number(extra.value)).toFixed(2);
                    }

                    /* Total */
                    const total = (Number(subtotal) - Number(totalAdminFee) - Number(totalStumpage) + Number(totalExtra)).toFixed(2);

                    /* Set data */
                    setState(prev => ({
                        ...prev,
                        dticket: {
                            ...prev.dticket,
                            total_qty,
                            price: partialPrice,
                            final_price: finalPrice,
                            admin_fee: totalAdminFee,
                            stumpage: totalStumpage,
                            extras: totalExtra,
                            subtotal,
                            total
                        },
                        extras: {
                            ...prev.extras,
                            af: fee,
                            s: stumpage
                        },
                        oldDticket: {
                            ...prev.dticket,
                            total_qty,
                            price: partialPrice,
                            final_price: finalPrice,
                            admin_fee: totalAdminFee,
                            stumpage: totalStumpage,
                            extras: totalExtra,
                            subtotal,
                            total
                        }
                    }));
                } else {
                    console.log('[TicketDetail] calculate error', response);
                    helpers.failRequest(response, showToast, signOut);
                }
                showLoader(false);
            } else {
                showToast('Item, Product, Type & Qty is required', 'error');
            }
        } catch (e) {
            console.log('[TicketDetail] calculate catch', e);
            showLoader(false);
            showToast('Something went wrong', 'error');
        }
    }

    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/detailTicket/store', { ...state.dticket, idticket: state.ticket.idticket });
            if (response.status === 200) {
                setDetail(response?.data);
                showToast(response.message);
                clear();
            } else {
                console.log('[TicketsDetail] store error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const update = async () => {
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/detailTicket/${state.dticket.iddetail_ticket}/update`, state.dticket);
            if (response.status === 200) {
                setDetail(response?.data);
                showToast(response.message);
                clear();
            } else {
                console.log('[TicketsDetail] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/ticket/${state.ticket.idticket}`);
            if (response.status === 200) {
                history.replace('/ticket/tickets');
                showToast(response.message);
            } else {
                console.log('[TicketsDetail] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const generateCheck = async () => {
        const cond = validateForm('generateCheck');

        if (cond) {
            showLoader(true);
            const response = await post('/ticket/generateCheck', { id: state.ticket.idticket });
            if (response.status === 200) {
                history.push(`/ticket/checks/${response?.id ?? 'list'}`);
                showToast(response.message);
            } else {
                console.log('[TicketsDetail] generateCheck error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const clear = () => {
        setState(prev => ({
            ...prev,
            dticket: {
                ...prev.dticket,
                iddetail_ticket: 0,
                item: prev.items[0] ?? {},
                product: prev.products[0] ?? {},
                type: defTypes[0],
                qty_product: 0,
                qty: 0,
                total_qty: 0,
                price: 0,
                discount: 0,
                final_price: 0,
                subtotal: 0,
                admin_fee: 0,
                stumpage: 0,
                extras: 0,
                total: 0,
                note: ''
            },
            extras: {
                af: {},
                s: {},
                e: {}
            }
        }));
    }

    const getField = params => params.value.name;

    const getQtyField = params => {
        const total = Number(params.row.qty) * Number(params.row.qty_product);

        return total;
    }

    const valueGetter = params => {
        let prefix = '';
        if (params.field === 'admin_fee' || params.field === 'stumpage') {
            prefix = '-';
        } else if (params.field === 'extras') {
            prefix = '+';
        }
        return `${prefix} $ ${params.value}`;
    }

    const columns = [
        { field: 'item', headerName: 'Item', flex: 1, valueGetter: getField },
        { field: 'qty', headerName: 'Total qty', flex: 1, valueGetter: getQtyField },
        { field: 'final_price', headerName: 'Price', flex: 1, valueGetter },
        { field: 'subtotal', headerName: 'Subtotal', flex: 1, valueGetter },
        { field: 'admin_fee', headerName: 'Admin fee', flex: 1, valueGetter },
        { field: 'stumpage', headerName: 'Stumpage', flex: 1, valueGetter },
        { field: 'extras', headerName: 'Extras', flex: 1, valueGetter },
        { field: 'total', headerName: 'Total', flex: 1, valueGetter }
    ]

    if (state.loading) {
        return <Loader fullScreen />;
    }
    if (!state.permissions.read_attr || !state.ticket.idticket) {
        return <NotFound />
    }

    return (
        <div className={classes.root}>
            <Divider />
            <div className={classes.row}>
                <div className={classes.rowText}>
                    <h3>{'Status:'}</h3>
                    <label className={classes.state}>{state.ticket.state}</label>
                </div>
                {
                    (state.ticket?.idsede !== state.ticket?.idsede_origin) &&
                    <Tooltip title={`This ticket was transferred. Origin: ${state.ticket?.sede_origin?.name ?? '?'} | destination: ${state.ticket?.sede?.name ?? '?'}`} placement='top'>
                        <div className={classes.transferredContainer}>
                            <Icon className={classes.transferredIcon} fontSize='small'>{'local_shipping'}</Icon>
                            {state.ticket?.sede_origin?.name ?? '?'}
                        </div>
                    </Tooltip>
                }
                {
                    (state.ticket.checks?.length > 0) &&
                    <div className={classes.rowText} >
                        <h3>{'No. check:'}</h3>
                        {
                            state.ticket.checks?.map(check => {
                                return (
                                    <Link key={check.id} to={`/ticket/checks/${check.id}`} target='_blank'>
                                        <span className={classes.state}>{check?.code ?? 'unassigned code'}</span>
                                    </Link>
                                );
                            })
                        }
                    </div>
                }
            </div>
            <Divider className={classes.divider} />
            <Grid container>
                <Grid item xs={6} lg={2}>
                    <FormGroup>
                        <FormLabel>{'ID'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                autoComplete='off'
                                type='text'
                                placeholder='ID'
                                name='id'
                                value={state.ticket.idticket}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'No. ticket'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                autoComplete='off'
                                type='text'
                                placeholder='No. ticket'
                                name='code'
                                value={state.ticket.code}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Date'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                autoComplete='off'
                                type='date'
                                placeholder='Date'
                                name='date'
                                value={dayjs(state.ticket.date).format('YYYY-MM-DD')}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Sede'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                autoComplete='off'
                                type='text'
                                placeholder='Sede'
                                name='sede'
                                value={state.ticket.sede.name}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                {
                    state.permissions?.delete_attr &&
                    <Grid item xs={6} lg={2} className={classes.buttonContainer}>
                        <Button
                            variant='contained'
                            className={classes.delete}
                            onClick={destroy}
                        >
                            {'Delete'}
                        </Button>
                    </Grid>
                }
            </Grid>
            <Grid container>
                <Grid item xs={12} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Supplier'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                autoComplete='off'
                                type='text'
                                placeholder='Supplier'
                                name='supplier'
                                value={state.ticket?.supplier?.name}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Land'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                autoComplete='off'
                                type='text'
                                placeholder='Land'
                                name='land'
                                value={state.ticket?.land?.name}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Location'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                autoComplete='off'
                                type='text'
                                placeholder='Location'
                                name='location'
                                value={state.ticket?.location?.name}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Divider />

            <div className={classes.row}>
                <h2>{'Detail'}</h2>
                <IconButton onClick={() => setShowDetail(!showDetail)}>
                    <Icon>{showDetail ? 'expand_less' : 'expand_more'}</Icon>
                </IconButton>
            </div>
            <Collapse in={showDetail}>
                <Grid container>
                    <Grid item xs={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Item'}</FormLabel>
                            <Autocomplete
                                options={state.items}
                                value={state.dticket.item}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, value) => handleAutocomplete('item', value)}
                                renderInput={params => <TextField {...params} placeholder='Item' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Product'}</FormLabel>
                            <Autocomplete
                                options={state.products}
                                value={state.dticket.product}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, value) => {
                                    handleAutocomplete('product', value);
                                    (Boolean(value?.id))
                                        ? getTypes(value)
                                        : setState(prev => ({ ...prev, types: defTypes, dticket: { ...prev.dticket, type: defTypes[0], qty_product: 0 } }));
                                }}
                                renderInput={params => <TextField {...params} placeholder='Product' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Type'}</FormLabel>
                            <Autocomplete
                                options={state.types}
                                value={state.dticket.type}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, value) => {
                                    handleAutocomplete('type', value);
                                    setState(prev => ({ ...prev, dticket: { ...prev.dticket, qty_product: value?.product_types?.qty ?? 0 } }))
                                }}
                                renderInput={params => <TextField {...params} placeholder='Type' />}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={6} lg={2}>
                        <FormGroup>
                            <FormLabel>{'Qty of product'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    type='number'
                                    placeholder='Qty prod.'
                                    name='qty_product'
                                    value={state.dticket.qty_product}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <FormGroup>
                            <FormLabel>{'Qty'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    inputProps={{ min: 0 }}
                                    placeholder='Qty'
                                    name='qty'
                                    value={state.dticket.qty}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <FormGroup>
                            <FormLabel>{'Total qty'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    inputProps={{ className: classes.highlight }}
                                    type='number'
                                    placeholder='Total qty'
                                    name='total_qty'
                                    value={state.dticket.total_qty}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <FormGroup>
                            <FormLabel>{'Price'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    startAdornment={<InputAdornment position='start'>{'$'}</InputAdornment>}
                                    type='number'
                                    placeholder='Price'
                                    name='price'
                                    value={state.dticket.price}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <FormGroup>
                            <FormLabel>{'Price discount'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    inputProps={{ min: 0 }}
                                    placeholder='Discount'
                                    name='discount'
                                    value={state.dticket.discount}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} lg={2}>
                        <FormGroup>
                            <FormLabel>{'Final price'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    inputProps={{ className: classes.highlight }}
                                    startAdornment={<InputAdornment position='start'>{'$'}</InputAdornment>}
                                    type='number'
                                    placeholder='Final price'
                                    name='final_price'
                                    value={state.dticket.final_price}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <FormGroup>
                            <FormLabel>{'Subtotal'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    inputProps={{ className: classes.highlight }}
                                    startAdornment={<InputAdornment position='start'>{'$'}</InputAdornment>}
                                    type='number'
                                    placeholder='Subtotal'
                                    name='subtotal'
                                    value={state.dticket.subtotal}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <FormGroup>
                            <FormLabel>{'Extras (+)'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    startAdornment={<InputAdornment position='start'>{'$'}</InputAdornment>}
                                    type='number'
                                    placeholder='Extras'
                                    name='extras'
                                    value={state.dticket.extras}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <FormGroup>
                            <FormLabel>
                                {`Admin fee (-) `}
                                {(state.extras.af && Object.entries(state.extras.af).length > 0) ? `${state.extras.af?.percent ? `| % ` : '| $'} ${state.extras.af?.value}` : ''}
                            </FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    startAdornment={<InputAdornment position='start'>{'$'}</InputAdornment>}
                                    type='number'
                                    placeholder='Admin fee'
                                    name='admin_fee'
                                    value={state.dticket.admin_fee}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <FormGroup>
                            <FormLabel>
                                {`Stumpage (-) `}
                                {(state.extras.s && Object.entries(state.extras.s).length > 0) ? `${state.extras.s?.percent ? `| % ` : '| $'} ${state.extras.s?.value}` : ''}
                            </FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    startAdornment={<InputAdornment position='start'>{'$'}</InputAdornment>}
                                    type='number'
                                    placeholder='Stumpage'
                                    name='stumpage'
                                    value={state.dticket.stumpage}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>

                    <Grid item xs={6} lg={2}>
                        <FormGroup>
                            <FormLabel>{'Total'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    inputProps={{ className: classes.highlight }}
                                    startAdornment={<InputAdornment position='start'>{'$'}</InputAdornment>}
                                    type='number'
                                    placeholder='Total'
                                    name='total'
                                    value={state.dticket.total}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>{'Notes'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Note'
                                    name='note'
                                    value={state.dticket.note}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6} className={classes.controls}>
                        <div className={classes.buttonContainer}>
                            <Button
                                variant='contained'
                                className={classes.clear}
                                onClick={clear}
                            >
                                {'Clear'}
                            </Button>
                        </div>
                        <div className={classes.buttonContainer}>
                            <Button
                                variant='contained'
                                className={classes.calculate}
                                onClick={calculate}
                            >
                                {'Calculate'}
                            </Button>
                        </div>
                        {
                            (state.permissions?.update_attr) &&
                            <div className={classes.buttonContainer}>
                                <Button
                                    variant='contained'
                                    className={(state.dticket.iddetail_ticket === 0) ? classes.store : classes.update}
                                    onClick={(state.dticket.iddetail_ticket === 0) ? store : update}
                                >
                                    {(state.dticket.iddetail_ticket === 0) ? 'Store' : 'Update'}
                                </Button>
                            </div>
                        }
                    </Grid>
                </Grid>

            </Collapse>
            {
                (state.permissions?.create_attr) &&
                <div className={classes.controls}>
                    <Button
                        variant='contained'
                        className={classes.generate}
                        onClick={generateCheck}
                    >
                        {'Generate check'}
                    </Button>
                </div>
            }
            {
                state.dtickets.map((item, index) => {
                    return (
                        <div key={index.toString()} className={classes.tableContainer}>
                            <h3>{`${item[0].product.name} | ${item[0].type.name}`}</h3>
                            <DataGrid
                                rows={item}
                                columns={columns}
                                pageSize={25}
                                autoHeight
                                rowHeight={40}
                                headerHeight={40}
                                getRowId={row => row.iddetail_ticket}
                                hideFooter
                                getRowClassName={params => (params.id === 0) ? 'total' : ''}
                                onRowClick={({ row }) => setState(prev => ({ ...prev, dticket: { ...prev.dticket, ...row } }))}
                            />
                        </div>
                    );
                })
            }
        </div>
    );
}

export default TicketsDetail;