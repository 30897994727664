import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tooltip, IconButton, Icon, Grid, FormGroup, FormLabel, Divider, Collapse, FormControl, Box, FilledInput, Button } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import Loader from '../../common/loader';
import NotFound from '../../common/not-found';
import DateRangeModal from '../../common/date-range';

import AuthContext from '../../context';
import { get } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import themeColors from '../../assets/colors';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    label: {
        padding: 15,
        borderRadius: 8,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#E0E0E0'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    search: {
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    tableContainer: {
        width: '100%',
        '& .priority_1': {
            backgroundColor: themeColors.process_status._1
        },
        '& .priority_2': {
            backgroundColor: themeColors.process_status._2
        },
        '& .priority_3': {
            backgroundColor: themeColors.process_status._3
        },
        '& .priority_4': {
            backgroundColor: themeColors.process_status._4
        },
        '& .priority_5': {
            backgroundColor: themeColors.process_status._5
        },
        '& .priority_6': {
            backgroundColor: themeColors.process_status._6
        },
        '& .priority_7': {
            backgroundColor: themeColors.process_status._7
        }
    }
}));

const ProcessPending = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const [showFilters, setShowFilters] = useState(false);
    const [state, setState] = useState({
        loading: true,
        processes: [],
        filter: {
            startDate: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
            dateRange: `${dayjs().subtract(1, 'month').startOf('month').format('MM/DD/YYYY')} - ${dayjs().format('MM/DD/YYYY')}`
        },
        rangeModal: {
            open: false,
            range: [
                {
                    startDate: new Date(dayjs().subtract(1, 'month').startOf('month').toDate()),
                    endDate: new Date(),
                    key: 'main'
                }
            ]
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Pending tickets';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(location.pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/processes/pending', state.filter);
        if (response.status === 200) {
            setState(prev => ({ ...prev, loading: false, processes: response?.data ?? [] }));
        } else {
            console.log('[ProcessPending] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const handleModal = () => setState(prev => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));

    const handleDateChange = item => {
        const { startDate, endDate } = item.main;
        const dateRange = `${dayjs(startDate.toISOString()).format('MM/DD/YYYY')} - ${dayjs(endDate.toISOString()).format('MM/DD/YYYY')}`;

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                dateRange,
                startDate: dayjs(startDate.toISOString()).format('YYYY-MM-DD'),
                endDate: dayjs(endDate.toISOString()).format('YYYY-MM-DD')
            },
            rangeModal: { ...prev.rangeModal, range: [item.main] }
        }));
    }

    const search = async () => {
        showLoader(true);
        const response = await get('/processes/searchPending', state.filter);
        if (response.status === 200) {
            setState(prev => ({ ...prev, loading: false, processes: response?.data ?? [] }));
        } else {
            console.log('[ProcessPending] search error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const getRowClassName = params => `priority_${params.row.process}`;

    const getCode = ({ row }) => row.ticket?.code;
    const getDate = ({ row }) => dayjs(row.ticket?.date).format('MM/DD/YYYY');
    const getProduct = ({ row }) => row?.product.name;
    const getProductCategory = ({ row }) => row?.product?.category.name;
    const getItem = ({ row }) => row?.item.name;
    const getQty = params => Number(params.value);
    const getPending = ({ row }) => row.processes[0]?.pending_qty ?? Number(row.total_qty);
    const renderCell = ({ row }) => {
        return (
            <Tooltip title='Show detail' placement='right'>
                <IconButton onClick={() => history.push('/process/processes/detail', { code: row.ticket?.code })}>
                    <Icon>{'visibility'}</Icon>
                </IconButton>
            </Tooltip>
        );
    }

    const columns = [
        { field: 'code', headerName: 'Ticket No.', flex: 1, minWidth: 135, valueGetter: getCode },
        { field: 'date', headerName: 'Ticket date', flex: 1, minWidth: 135, valueGetter: getDate },
        { field: 'product', headerName: 'Product', flex: 1.25, minWidth: 180, valueGetter: getProduct },
        { field: 'category', headerName: 'Category', flex: 1, minWidth: 180, valueGetter: getProductCategory },
        { field: 'total_qty', headerName: 'Ticket qty', flex: 1, minWidth: 120, valueGetter: getQty },
        { field: 'pending', headerName: 'Pending', flex: 1, minWidth: 100, valueGetter: getPending },
        { field: 'item', headerName: 'Item', flex: 0.75, minWidth: 100, valueGetter: getItem },
        { field: 'detail', headerName: 'Detail', flex: 0.5, minWidth: 80, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={6} md={3}>
                    <FormGroup>
                        <FormLabel className={classes.label} style={{ backgroundColor: themeColors.process_status._1 }}>{'1. Pending'}</FormLabel>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormGroup>
                        <FormLabel className={classes.label} style={{ backgroundColor: themeColors.process_status._2 }}>{'2. To complete'}</FormLabel>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormGroup>
                        <FormLabel className={classes.label} style={{ backgroundColor: themeColors.process_status._3 }}>{'3. Completed'}</FormLabel>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormGroup>
                        <FormLabel className={classes.label} style={{ backgroundColor: themeColors.process_status._4 }}>{'4. Discarted'}</FormLabel>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormGroup>
                        <FormLabel className={classes.label} style={{ backgroundColor: themeColors.process_status._5 }}>{'5. In storage'}</FormLabel>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormGroup>
                        <FormLabel className={classes.label} style={{ backgroundColor: themeColors.process_status._6 }}>{'6. No process required'}</FormLabel>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormGroup>
                        <FormLabel className={classes.label} style={{ backgroundColor: themeColors.process_status._7 }}>{'7. Completed w/o ticket'}</FormLabel>
                    </FormGroup>
                </Grid>
            </Grid>

            <Divider />

            <div className={classes.row}>
                <h2>{'Filters'}</h2>
                <IconButton onClick={() => setShowFilters(!showFilters)}>
                    <Icon>{showFilters ? 'expand_less' : 'expand_more'}</Icon>
                </IconButton>
            </div>

            <Collapse in={showFilters}>
                <Grid container>
                    <Grid item xs={6} lg={4}>
                        <FormGroup onClick={handleModal}>
                            <FormLabel>{'Date range'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    readOnly
                                    type='text'
                                    placeholder='Date range'
                                    value={state.filter.dateRange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>

                <div className={classes.buttonsContainer}>
                    <Button
                        variant='contained'
                        className={classes.search}
                        onClick={search}
                    >
                        {'Search'}
                    </Button>
                </div>
            </Collapse>

            <div className={classes.tableContainer}>
                <DataGrid
                    autoHeight
                    pageSize={45}
                    density='compact'
                    columns={columns}
                    rows={state.processes}
                    getRowId={row => row.iddetail_ticket}
                    getRowClassName={getRowClassName}
                />
            </div>

            <DateRangeModal
                open={state.rangeModal.open}
                range={state.rangeModal.range}
                onClose={handleModal}
                onChange={handleDateChange}
                minDate={dayjs('2022-01-01').toDate()}
            />
        </div>
    );
}

export default ProcessPending;