import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    useTheme,
    makeStyles,
    Grid,
    Container,
    Box,
    Button,
    Card,
    CardHeader,
    CardContent,
    FilledInput,
    FormControl,
    InputAdornment,
} from '@material-ui/core';
import { Person, Lock } from '@material-ui/icons';

import Header from '../components/header';

import AuthContext from '../../context';
import themeColors from '../../assets/colors';
import { postLogin } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const styles = theme => ({
    bgDefault: {
        backgroundColor: theme.palette.dark.main,
    }
});

const useStyles = makeStyles(styles);

const Login = () => {

    const { signIn, showToast, showLoader } = useContext(AuthContext);
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const [state, setState] = useState({
        username: '',
        password: '',
    });

    useEffect(() => {
        window.document.title = 'Login';

        document.body.classList.add(classes.bgDefault);

        return () => {
            document.body.classList.remove(classes.bgDefault);
        }
    });

    const handleChange = ({ target }) => setState(prev => ({ ...prev, [target.name]: target.value }));

    const login = async () => {
        showLoader(true);
        const response = await postLogin('/auth/login', state);
        if (response.status === 200) {
            if (Boolean(response.data?.sedes[0])) {
                signIn(response.data, response.token, response.data?.sedes[0]);
                history.replace('/');
            } else {
                showToast('A Sede has not yet been assigned');
            }
        } else {
            console.log('[Login] login error', response);
            helpers.failRequest(response, showToast);
        }
        showLoader(false);
    }

    return (
        <div style={{ backgroundColor: theme.primary }}>
            <Header />
            <Container
                component={Box}
                maxWidth='xl'
                marginTop='-8rem'
                paddingBottom='3rem'
                position='relative'
                zIndex='101'
            >
                <Box component={Grid} display='flex' justifyContent='center'>
                    <Grid item xs={12} lg={5} md={7} >
                        <Card style={{ backgroundColor: themeColors.background.default }}>
                            <CardHeader
                                title={
                                    <Box
                                        fontSize='100%'
                                        fontWeight='800'
                                        component='small'
                                        color={theme.palette.gray[600]}
                                    >
                                        {'CFGProduction'}
                                    </Box>
                                }
                                titleTypographyProps={{
                                    component: Box,
                                    textAlign: 'center',
                                    marginBottom: '1rem!important',
                                    marginTop: '.5rem!important',
                                    fontSize: '1rem!important',
                                }}
                            />
                            <CardContent >
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    marginBottom='1rem!important'
                                >
                                    <FilledInput
                                        autoComplete='off'
                                        type='text'
                                        placeholder='Username'
                                        startAdornment={
                                            <InputAdornment position='start'>
                                                <Person />
                                            </InputAdornment>
                                        }
                                        name='username'
                                        value={state.username}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    marginBottom='1rem!important'
                                >
                                    <FilledInput
                                        autoComplete='off'
                                        type='password'
                                        placeholder='Password'
                                        startAdornment={
                                            <InputAdornment position='start'>
                                                <Lock />
                                            </InputAdornment>
                                        }
                                        name='password'
                                        value={state.password}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                                <Box textAlign='center' marginTop='1.5rem' marginBottom='1.5rem'>
                                    <Button color='primary' variant='contained' onClick={login}>
                                        Sign in
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Box>
            </Container>
        </div>
    );
}

export default Login;
