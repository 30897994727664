import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, FormLabel, FormGroup, Box, FormControl, FilledInput, Tooltip, IconButton, Icon } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation, Link } from 'react-router-dom';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import ViewTitle from '../../common/view-title';
import Trash from '../../common/trash';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%'
    }
}));

const CostCenter3 = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [disbledCategories, setDisbledCategories] = useState(false);
    const [state, setState] = useState({
        loading: true,
        data: [],
        cc: [],
        ec: [],
        es: [],
        cc3: {
            idcost_center_3: 0,
            code: '',
            name: ''
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Cost center 3';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/costCenter3');
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                data: response?.data ?? []
            }));
        } else {
            console.log('[CostCenter3] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const validateForm = (action) => {
        const { idcost_center_3, code, name } = state.cc3;

        switch (action) {
            case 'update':
                const data = state.data.filter(value => value.idcost_center_3 === idcost_center_3);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.cc3);
                    if (before !== after) {
                        if (code.trim() && name.trim()) {
                            return true;
                        } else {
                            showToast('Code and Cost center fields is required', 'error');
                            return false;
                        }
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select some cost center', 'error');
                    return false;
                }

            case 'store':
                if (code.trim() && name.trim()) {
                    return true;
                } else {
                    showToast('Code and Cost center fields is required', 'error');
                    return false;
                }

            case 'delete':
                if (idcost_center_3 === 0) {
                    showToast('Select some cost center', 'error');
                    return false;
                }
                const cond = window.confirm(`Are you sure you want to delete this cost center?`);

                return Boolean(idcost_center_3 && cond);
            default:
                break;
        }
    }

    const clear = () => setState(prev => ({
        ...prev,
        cc3: {
            ...prev.cc3,
            idcost_center_3: 0,
            code: '',
            name: ''
        }
    }));

    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/costCenter3/store', state.cc3);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? prev.data }));
                showToast(response.message);
            } else {
                console.log('[CostCenter3] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false;
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { idcost_center_3 } = state.cc3;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/costCenter3/${idcost_center_3}/update`, state.cc3);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? prev.data }));
                showToast(response.message);
            } else {
                console.log('[CostCenter3] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idcost_center_3 } = state.cc3;
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/costCenter3/${idcost_center_3}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? prev.data }));
                showToast(response.message);
            } else {
                console.log('[CostCenter3] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, cc3: { ...prev.cc3, [target.name]: target.value } }));

    const renderCell = ({ row }) => {
        return (
            <Link to={`/history/cost_center_3/${row.idcost_center_3}`} target='_blank'>
                <Tooltip title='Show history' placement='top'>
                    <IconButton>
                        <Icon>{'history'}</Icon>
                    </IconButton>
                </Tooltip>
            </Link>
        );
    }

    const columns = [
        { field: 'code', headerName: 'Code', flex: 1, minWidth: 100 },
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 100 },
        { field: 'actions', headerName: 'Actions', flex: 0.5, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle title='Cost center 3' />
            <Grid container>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Code'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Code'
                                name='code'
                                value={state.cc3.code}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Name'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Name'
                                name='name'
                                value={state.cc3.name}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>

            </Grid>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            {
                state.permissions.delete_attr &&
                <Trash table='cost_center_3' id='idcost_center_3' onRefresh={makeRequest} />
            }

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.data}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idcost_center_3}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, cc3: row }))}
                />
            </div>
        </div>
    );
}

export default CostCenter3;