import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginTop: 30
    },
    imgContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
    },
    img: {
        width: '50%'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2
    }
}));

const Arrow = require('../assets/images/arrow.png').default;

const SelectPage = () => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.imgContainer}>
                <img className={classes.img} src={Arrow} alt='arrow' />
            </div>
            <div className={classes.content}>
                <h1>{'Select a submodule'}</h1>
                <Typography>{'To get started, select the submodule you want.'}</Typography>
            </div>
        </div>
    );
}

export default SelectPage;