import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Tooltip, IconButton, Icon } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation, Link } from 'react-router-dom';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import Trash from '../../common/trash';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%',
    }
}));

const Locations = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [state, setState] = useState({
        loading: true,
        locations: [],
        location: {
            idlocation: 0,
            code: '',
            name: ''
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Locations';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/locations');
        if (response.status === 200) {
            setState(prev => ({ ...prev, loading: false, locations: response?.data ?? [] }));
        } else {
            console.log('[Locations] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, location: { ...prev.location, [target.name]: target.value } }));

    const validateForm = (type) => {
        const { idlocation, code, name } = state.location;

        switch (type) {
            case 'update':
                const data = state.locations.filter(value => value.idlocation === idlocation);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.location);
                    if (before !== after) {
                        if (code.trim() && name.trim()) {
                            return true;
                        } else {
                            showToast('All fields is required', 'error');
                            return false;
                        }
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select some location', 'error');
                    return false;
                }

            case 'store':

                if (code.trim() && name.trim()) {
                    return true;
                } else {
                    showToast('All fields is required', 'error');
                    return false;
                }

            case 'delete':
                if (idlocation === 0) {
                    showToast('Select some location', 'error');
                    return false;
                }
                const cond = window.confirm('Are you sure you want to delete this location?');

                return Boolean(idlocation && cond);
            default:
                break;
        }
    }

    const clear = () => {
        setState(prev => ({ ...prev, location: { ...prev.location, idlocation: 0, code: '', name: '' } }));
    }

    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/location/store', state.location);
            if (response.status === 200) {
                setState(prev => ({ ...prev, locations: response?.data ?? prev.locations }));
                showToast(response.message);
            } else {
                console.log('[Locations] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { idlocation } = state.location;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/location/${idlocation}/update`, state.location);
            if (response.status === 200) {
                setState(prev => ({ ...prev, locations: response?.data ?? prev.locations }));
                showToast(response.message);
            } else {
                console.log('[Locations] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idlocation } = state.location;
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/location/${idlocation}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, locations: response?.data ?? prev.locations }));
                showToast(response.message);
            } else {
                console.log('[Locations] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const renderCell = ({ row }) => {
        return (
            <Link to={`/history/locations/${row.idlocation}`} target='_blank'>
                <Tooltip title='Show history' placement='top'>
                    <IconButton>
                        <Icon>{'history'}</Icon>
                    </IconButton>
                </Tooltip>
            </Link>
        );
    }

    const columns = [
        { field: 'code', headerName: 'Code', flex: 1 },
        { field: 'name', headerName: 'Name', flex: 2 },
        { field: 'actions', headerName: 'Actions', flex: 0.5, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <h1>{'Locations'}</h1>
            <div>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <FormGroup>
                            <FormLabel>{'Code'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Code'
                                    name='code'
                                    value={state.location.code}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormGroup>
                            <FormLabel>{'Name'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Name'
                                    name='name'
                                    value={state.location.name}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
            </div>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            {
                state.permissions.delete_attr &&
                <Trash table='locations' id='idlocation' onRefresh={makeRequest} />
            }

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.locations}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idlocation}
                    loading={state.loading}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, location: row }))}
                />
            </div>
        </div>
    );
}

export default Locations;