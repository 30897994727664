import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Tooltip, IconButton, Icon, Collapse, Button, TextField } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom';

import AuthContext from '../../context';
import { post, del, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import themeColors from '../../assets/colors';

const useStyles = makeStyles(() => ({
    clickableRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 8,
        paddingLeft: 10,
        userSelect: 'none',
        marginBottom: 10,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(0,0,0,0.04)'
        }
    },
    buttonContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 15
    },
    store: {
        width: '100%',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    update: {
        width: '100%',
        backgroundColor: '#ffc107',
        borderColor: '#ffc107',
        '&:hover': {
            backgroundColor: '#ffc107',
            borderColor: '#ffc107',
            boxShadow: 'none',
        }
    },
    tableContainer: {
        width: '100%',
        '& .list-0': {
            backgroundColor: themeColors.list[0]
        },
        '& .list-1': {
            backgroundColor: themeColors.list[1]
        },
        '& .list-2': {
            backgroundColor: themeColors.list[2]
        },
        '& .list-3': {
            backgroundColor: themeColors.list[3]
        },
        '& .list-4': {
            backgroundColor: themeColors.list[4]
        },
        '& .list-5': {
            backgroundColor: themeColors.list[5]
        },
        '& .list-6': {
            backgroundColor: themeColors.list[6]
        }
    }
}));

const RecipeSubassemblies = ({ recipe, recipes, data, permissions, syncTotals, updateRecipe }) => {

    const classes = useStyles();
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const [showForm, setShowForm] = useState(false);
    const [state, setState] = useState({
        recipe: {},
        recipes: [],
        data: [],
        form: {
            id: 0,
            qty: 1,
            child: { id: 0, name: 'Choose a product sale' },
        },
        permissions: {}
    });

    useEffect(() => {
        const newRecipes = recipes.filter(item => (item.id !== recipe.idproduct_sale));

        setState(prev => ({ ...prev, recipe, recipes: newRecipes, data, permissions }));
        clear();
    }, [recipe, data]);

    const toggleForm = () => setShowForm(prev => !prev);

    const handleChange = ({ target }) => setState(prev => ({ ...prev, form: { ...prev.form, [target.name]: target.value } }));

    const store = async () => {
        showLoader(true);
        const response = await post('/subassembly/store', { ...state.form, id: state.recipe?.idproduct_sale });
        if (response.status === 200) {
            showToast(response.message);
            setState(prev => ({ ...prev, data: response.data }));
            updateRecipe(response.data, response.raws, response.materials, response.labors);
            clear();
        } else {
            console.log('[RecipeSubassemblies] store error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const update = async () => {
        showLoader(true);
        const response = await put(`/subassembly/${state.form.id}/update`, { ...state.form, idproduct_sale: state.recipe?.idproduct_sale });
        if (response.status === 200) {
            showToast(response.message);
            setState(prev => ({ ...prev, data: response.data }));
            updateRecipe(response.data, response.raws, response.materials, response.labors);
            clear();
            setShowForm(false);
        } else {
            console.log('[RecipeSubassemblies] update error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const destroy = async (id) => {
        showLoader(true);
        const response = await del(`/subassembly/${id}`, { idproduct_sale: state.recipe?.idproduct_sale });
        if (response.status === 200) {
            showToast(response.message);
            setState(prev => ({ ...prev, data: response.data }));
            updateRecipe(response.data, response.raws, response.materials, response.labors);
            clear();
        } else {
            console.log('[RecipeSubassemblies] destroy error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const validateForm = (type) => {
        const { qty, child } = state.form;

        if (child.id > 0) {
            if (Number(qty)) {
                if (type === 'store') {
                    store();
                } else if (type === 'update') {
                    update();
                }
            } else {
                showToast('The qty field are required', 'warning');
            }
        } else {
            showToast('You must select a product sale before', 'warning');
        }
    }

    const confirmDelete = (id) => {
        const cond = window.confirm('Are you sure you want to delete this subassembly?\n\nThis action cannot be reversed.');

        if (cond) {
            destroy(id);
        }
    }

    const clear = () => setState(prev => ({
        ...prev,
        form: {
            ...prev.form,
            id: 0,
            qty: 1,
            child: { id: 0, name: 'Choose a product sale' },
        }
    }));

    const productColumn = ({ field, row }) => row[field]?.name ?? '?';

    const renderCell = ({ id, row }) => {
        return (
            <div>
                <Link to={`/bom/recipe/detail?id=${row.idchild}`} target='_blank'>
                    <Tooltip title='Show detail' placement='top'>
                        <IconButton>
                            <Icon>{'visibility'}</Icon>
                        </IconButton>
                    </Tooltip>
                </Link>
                {
                    (id > 0 && state.permissions.delete_attr) &&
                    <Tooltip title='Delete' placement='top'>
                        <IconButton onClick={() => confirmDelete(id)}>
                            <Icon>{'delete'}</Icon>
                        </IconButton>
                    </Tooltip>
                }
            </div>
        );
    }

    const columns = [
        { field: 'child', headerName: 'Product sale', flex: 2, minWidth: 180, valueGetter: productColumn },
        { field: 'qty', headerName: 'Qty', flex: 1, minWidth: 90 },
        { field: 'sub', headerName: 'Actions', flex: 0.5, minWidth: 90, renderCell }
    ];

    return (
        <>
            <div className={classes.clickableRow} onClick={toggleForm}>
                <h2>{'Subassembly'}</h2>
                <IconButton>
                    <Icon>{showForm ? 'expand_less' : 'expand_more'}</Icon>
                </IconButton>
            </div>

            <Collapse in={showForm}>
                <Grid container>
                    <Grid item xs={6} md={3} lg={3}>
                        <FormGroup>
                            <FormLabel>{'Product'}</FormLabel>

                            <Autocomplete
                                options={state.recipes}
                                value={state.form.child}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, child) => setState(prev => ({ ...prev, form: { ...prev.form, child } }))}
                                renderInput={(params) => <TextField {...params} placeholder='Product sale' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                        <FormGroup>
                            <FormLabel>{'Qty'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Qty'
                                    name='qty'
                                    value={state.form.qty}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={2} xl={1} className={classes.buttonContainer}>
                        <Button
                            variant='contained'
                            color='secondary'
                            style={{ width: '100%' }}
                            onClick={clear}
                        >
                            {'Cancel'}
                        </Button>
                    </Grid>
                    {
                        (state.permissions.create_attr && state.form.id === 0) &&
                        <Grid item xs={6} md={2} xl={1} className={classes.buttonContainer}>
                            <Button
                                variant='contained'
                                className={classes.store}
                                onClick={() => validateForm('store')}
                            >
                                {'Store'}
                            </Button>
                        </Grid>
                    }
                    {
                        (state.permissions.update_attr && state.form.id > 0) &&
                        <Grid item xs={6} md={2} xl={1} className={classes.buttonContainer}>
                            <Button
                                variant='contained'
                                className={classes.update}
                                onClick={() => validateForm('update')}
                            >
                                {'Update'}
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Collapse>

            {
                (state.data.length > 0) &&

                <div className={classes.tableContainer}>
                    <DataGrid
                        rows={state.data}
                        columns={columns}
                        density='compact'
                        autoHeight
                        getRowId={row => row.idproduct_sale_subassembly_ref}
                        getRowClassName={({ id, row }) => {
                            const ps = state.recipe.idproduct_sale;
                            if (id !== 0) {
                                const pk = state.data.findIndex(item => item.idproduct_sale_subassembly_ref === id);
                                return (row.idproduct_sale !== ps) ? `list-${pk}` : '';
                            } else {
                                return 'total';
                            }
                        }}
                        onRowClick={({ row }) => {
                            setShowForm(true);
                            setState(prev => ({ ...prev, form: { ...prev.form, id: row.idproduct_sale_subassembly_ref, qty: row.qty, child: row.child } }));
                        }}
                        disableColumnFilter
                        disableColumnMenu
                        hideFooter
                    />
                </div>
            }
        </>
    );
}

export default RecipeSubassemblies;