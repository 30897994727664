import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Box, FormLabel, FormGroup, FormControl, FilledInput, TextField, Button, Icon, IconButton, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import dayjs from "dayjs";

import Loader from "../../common/loader";
import DateRange from "../../common/date-range";

import AuthContext from "../../context";
import helpers from "../../utils/helpers";
import { get } from "../../utils/api-services";

const useStyles = makeStyles(() => ({
	root: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
	},
	search: {
		alignSelf: "flex-end",
		marginBottom: 20,
		backgroundColor: "#0d6efd",
		borderColor: "#0d6efd",
		"&:hover": {
			backgroundColor: "#0d6efd",
			borderColor: "#0d6efd",
			boxShadow: "none",
		},
	},
	tableContainer: {
		width: "100%",
	},
}));

export default function DeliveryTicketsList({ permissions }) {
	const { showToast, signOut, showLoader } = useContext(AuthContext);
	const classes = useStyles();
	const [state, setState] = useState({
		loading: true,
		customers: [],
		invoiceStatus: [],
		paidStatus: [],
		filter: {
			code: "",
			startDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
			endDate: dayjs().format("YYYY-MM-DD"),
			dateRange: `${dayjs().subtract(1, "day").format("MM/DD/YYYY")} - ${dayjs().format("MM/DD/YYYY")}`,
			customer: { id: 0, name: "All customers" },
			invoice: { id: 0, name: "All invoice status" },
			paid: { id: 0, name: "All paid status" },
		},
		tickets: [],
		rangeModal: {
			open: false,
			range: [
				{
					startDate: new Date(dayjs().subtract(1, "day").toDate()),
					endDate: new Date(),
					key: "main",
				},
			],
		},
		permissions: {},
	});

	useEffect(() => {
		setState((prev) => ({ ...prev, permissions }));
	}, [permissions]);

	useEffect(() => {
		window.document.title = "Delivery Tickets List";

		makeRequest();
	}, []);

	const makeRequest = async () => {
		showLoader(true);
		const response = await get("/farmForest/delivery", state.filter);
		if (response.status === 200) {
			setState((prev) => ({
				...prev,
				loading: false,
				tickets: response?.data ?? [],
				customers: response?.customers ?? [],
				invoiceStatus: response?.invoiceStatus ?? [],
				paidStatus: response?.paidStatus ?? [],
			}));
		} else {
			console.log("[DeliveryTicketsList] makeRequest error", response);
			helpers.failRequest(response, showToast, signOut);
		}
		showLoader(false);
	};

	const handleDateChange = (item) => {
		const { startDate, endDate } = item.main;
		const dateRange = `${dayjs(startDate.toISOString()).format("MM/DD/YYYY")} - ${dayjs(endDate.toISOString()).format("MM/DD/YYYY")}`;
		setState((prev) => ({
			...prev,
			filter: {
				...prev.filter,
				dateRange,
				startDate: dayjs(startDate.toISOString()).format("YYYY-MM-DD"),
				endDate: dayjs(endDate.toISOString()).format("YYYY-MM-DD"),
			},
			rangeModal: { ...prev.rangeModal, range: [item.main] },
		}));
	};

	const handleModal = () => setState((prev) => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));

	const handleChange = ({ target: { name, value } }) => setState((prev) => ({ ...prev, filter: { ...prev.filter, [name]: value } }));

	const handleChangeAutocomplete = (key, value) => setState((prev) => ({ ...prev, filter: { ...prev.filter, [key]: value } }));

	const search = async () => {
		showLoader(true);
		const response = await get("/farmForest/delivery/search", state.filter);
		if (response.status === 200) {
			setState((prev) => ({ ...prev, tickets: response?.data ?? [] }));

			if (!response?.data[0]) showToast("There are no delivery tickets with these filters", "warning");
		} else {
			console.log("[DeliveryTicketsSearch] search error", response);
			helpers.failRequest(response, showToast, signOut);
		}
		showLoader(false);
	};

	const renderDate = (params) => dayjs(params.value).format("MM/DD/YYYY");

	const valueGetter = (params) => {
		return params.value === null ? "" : params.value.name;
	};

	const renderCell = ({ row }) => {
		return (
			<Tooltip title="Show detail" placement="right">
				<Link to={`/farmForest/deliveryTickets/${row.idff_delivery}`} target="_blank">
					<IconButton>
						<Icon>{"visibility"}</Icon>
					</IconButton>
				</Link>
			</Tooltip>
		);
	};

	const columns = [
		{ field: "delivery_number", headerName: "No. ticket", flex: 0.5 },
		{ field: "total_invoice", headerName: "Total", flex: 1 },
		{ field: "date_created", headerName: "Date", flex: 1, valueGetter: renderDate },
		{ field: "customer", headerName: "Customer", flex: 1, valueGetter },
		{ field: "invoice_status", headerName: "Invoiced Status", flex: 1 },
		{ field: "payment_status", headerName: "Paid Status", flex: 1 },
		{ field: "detail", headerName: "Detail", flex: 0.5, renderCell },
	];

	if (state.loading) {
		return <Loader />;
	}

	return (
		<div className={classes.root}>
			<Grid container>
				<Grid item xs={6} lg={4}>
					<FormGroup>
						<FormLabel>{"No. ticket"}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput autoComplete="off" type="text" placeholder="No. ticket" name="code" onChange={handleChange} value={state.filter.code} />
						</FormControl>
					</FormGroup>
				</Grid>
				<Grid item xs={6} lg={4}>
					<FormGroup onClick={handleModal}>
						<FormLabel>{"Date range"}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput readOnly autoComplete="off" type="text" placeholder="Date range" name="range" value={state.filter.dateRange} />
						</FormControl>
					</FormGroup>
				</Grid>
				<Grid item xs={6} lg={4}>
					<FormGroup>
						<FormLabel>{"Customer name"}</FormLabel>
						<Autocomplete
							disabled={Boolean(state.filter.code.trim())}
							options={state.customers}
							value={state.filter.customer}
							getOptionLabel={(option) => option?.name ?? ""}
							getOptionSelected={(option, value) => option.id === value.id}
							onChange={(event, value) => handleChangeAutocomplete("customer", value)}
							renderInput={(params) => <TextField {...params} placeholder="Customer" />}
							disableClearable={true}
						/>
					</FormGroup>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={6} lg={4}>
					<FormGroup>
						<FormLabel>{"Invoice Status"}</FormLabel>
						<Autocomplete
							disabled={Boolean(state.filter.code.trim())}
							options={state.invoiceStatus}
							value={state.filter.invoice}
							getOptionLabel={(option) => option?.name ?? ""}
							getOptionSelected={(option, value) => option.id === value.id}
							onChange={(event, value) => handleChangeAutocomplete("invoice", value)}
							renderInput={(params) => <TextField {...params} placeholder="Invoice status" />}
							disableClearable={true}
						/>
					</FormGroup>
				</Grid>
				<Grid item xs={6} lg={4}>
					<FormGroup>
						<FormLabel>{"Paid Status"}</FormLabel>
						<Autocomplete
							disabled={Boolean(state.filter.code.trim())}
							options={state.paidStatus}
							value={state.filter.paid}
							getOptionLabel={(option) => option?.name ?? ""}
							getOptionSelected={(option, value) => option.id === value.id}
							onChange={(event, value) => handleChangeAutocomplete("paid", value)}
							renderInput={(params) => <TextField {...params} placeholder="Paid status" />}
							disableClearable={true}
						/>
					</FormGroup>
				</Grid>
			</Grid>

			<Button variant="contained" className={classes.search} onClick={search}>
				{"Search"}
			</Button>

			<div className={classes.tableContainer}>
				<DataGrid rows={state.tickets} columns={columns} pageSize={45} density="compact" autoHeight getRowId={(row) => row.idff_delivery} />
			</div>

			<DateRange open={state.rangeModal.open} range={state.rangeModal.range} onClose={handleModal} onChange={handleDateChange} />
		</div>
	);
}
