import React from 'react';
import { CircularProgress } from '@material-ui/core';

const Loader = () => {

    return (
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
        </div>
    );
}

export default Loader;