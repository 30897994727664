import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Select, MenuItem, TextField, Tooltip, IconButton, Icon } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLocation, Link } from 'react-router-dom';

import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import Loader from '../../common/loader';
import ViewTitle from '../../common/view-title';
import Trash from '../../common/trash';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%'
    }
}));

const AdminFees = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [state, setState] = useState({
        loading: true,
        lands: [],
        suppliers: [],
        products: [],
        fees: [],
        fee: {
            idadmin_fee: 0,
            percent: false,
            value: 0,
            idland: 0,
            land: {},
            idsupplier: 0,
            supplier: {},
            idproduct: 0,
            product: {}
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Admin fees';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/adminFees');
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                fees: response?.data ?? [],
                lands: response?.lands ?? [],
                suppliers: response?.suppliers ?? [],
                products: response?.products ?? [],
                fee: {
                    ...prev.fee,
                    land: response?.lands[0] ?? {},
                    supplier: response?.suppliers[0] ?? {},
                    product: response?.products[0] ?? {},
                }
            }));
        } else {
            console.log('[AdminFees] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const validateForm = (type) => {
        const { idadmin_fee, value, land, supplier, product } = state.fee;

        switch (type) {
            case 'update':
                const data = state.fees.filter(value => value.idadmin_fee === idadmin_fee);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.fee);
                    if (before !== after) {
                        if (value >= 0) {
                            if (!land?.id && !supplier?.id && !product?.id) {
                                showToast('Combination not allowed', 'error');
                                return false;
                            }

                            return true;
                        } else {
                            showToast('Value is required', 'error');
                            return false;
                        }
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select some admin fee', 'error');
                    return false;
                }

            case 'store':

                if (value >= 0) {
                    if (!land?.id && !supplier?.id && !product?.id) {
                        showToast('Combination not allowed', 'error');
                        return false;
                    }

                    return true;
                } else {
                    showToast('Value is required', 'error');
                    return false;
                }

            case 'delete':
                if (idadmin_fee === 0) {
                    showToast('Select some admin fee', 'error');
                    return false;
                }
                const cond = window.confirm(`Are you sure you want to delete this admin fee?`);

                return Boolean(idadmin_fee && cond);
            default:
                break;
        }
    }

    const clear = () => setState(prev => ({
        ...prev,
        fee: {
            ...prev.fee,
            idadmin_fee: 0,
            percent: false,
            value: 0,
            land: prev.lands[0],
            supplier: prev.suppliers[0],
            product: prev.products[0],
        }
    }));


    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/adminFee/store', state.fee);
            if (response.status === 200) {
                setState(prev => ({ ...prev, fees: response?.data ?? prev.fees }));
                showToast(response.message);
            } else {
                console.log('[AdminFees] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false;
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { idadmin_fee } = state.fee;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/adminFee/${idadmin_fee}/update`, state.fee);
            if (response.status === 200) {
                setState(prev => ({ ...prev, fees: response?.data ?? prev.fees }));
                showToast(response.message);
            } else {
                console.log('[AdminFees] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idadmin_fee } = state.fee;
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/adminFee/${idadmin_fee}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, fees: response?.data ?? prev.fees }));
                showToast(response.message);
            } else {
                console.log('[AdminFees] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, fee: { ...prev.fee, [target.name]: target.value } }));

    const handleAutocomplete = (key, value) => setState(prev => ({ ...prev, fee: { ...prev.fee, [key]: value } }));

    const getField = params => params?.value?.name ?? 'All';

    const getPercentField = params => params.value ? 'Yes' : 'No';

    const getValueField = params => `${params.row.percent ? '%' : '$'} ${params.value}`;

    const renderCell = ({ row }) => {
        return (
            <Link to={`/history/admin_fees/${row.idadmin_fee}`} target='_blank'>
                <Tooltip title='Show history' placement='top'>
                    <IconButton>
                        <Icon>{'history'}</Icon>
                    </IconButton>
                </Tooltip>
            </Link>
        );
    }

    const columns = [
        { field: 'land', headerName: 'Land', flex: 1, minWidth: 180, valueGetter: getField },
        { field: 'supplier', headerName: 'Supplier', flex: 1, minWidth: 180, valueGetter: getField },
        { field: 'product', headerName: 'Product', flex: 1, minWidth: 180, valueGetter: getField },
        { field: 'percent', headerName: 'Is percentage', flex: 1, minWidth: 150, valueGetter: getPercentField },
        { field: 'value', headerName: 'Value', flex: 1, minWidth: 100, valueGetter: getValueField },
        { field: 'history', headerName: 'Actions', flex: 0.5, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle sedeFlag title='Admin fees' />
            <Grid container>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Land'}</FormLabel>
                        <Autocomplete
                            options={state.lands}
                            value={state.fee.land}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleAutocomplete('land', value)}
                            renderInput={params => <TextField {...params} placeholder='Land' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Supplier'}</FormLabel>
                        <Autocomplete
                            options={state.suppliers}
                            value={state.fee.supplier}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleAutocomplete('supplier', value)}
                            renderInput={params => <TextField {...params} placeholder='Supplier' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Product'}</FormLabel>
                        <Autocomplete
                            options={state.products}
                            value={state.fee.product}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleAutocomplete('product', value)}
                            renderInput={params => <TextField {...params} placeholder='Product' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Is percent'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <Select
                                value={state.fee.percent}
                                onChange={({ target }) => setState(prev => ({ ...prev, fee: { ...prev.fee, percent: target.value } }))}
                            >
                                <MenuItem value={false}>{'No'}</MenuItem>
                                <MenuItem value={true}>{'Yes'}</MenuItem>
                            </Select>
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Value'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='number'
                                placeholder='Value'
                                name='value'
                                value={state.fee.value}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            {
                state.permissions.delete_attr &&
                <Trash table='admin_fees' id='idadmin_fee' onRefresh={makeRequest} />
            }

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.fees}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idadmin_fee}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, fee: row }))}
                />
            </div>
        </div>
    );
}

export default AdminFees;