import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, TextField, Divider, IconButton, Icon, Collapse, Button, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLocation } from 'react-router-dom';
import { DataGrid } from '@material-ui/data-grid';

import Loader from '../../common/loader';
import NotFound from '../../common/not-found';
import Crud from '../../common/crud';
import DateRange from '../../common/date-range';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const useStyles = makeStyles(() => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    search: {
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    tableContainer: {
        width: '100%',
        marginTop: 20
    }
}));


const ProductionStartTimeDesktop = () => {

    const sede = JSON.parse(window.localStorage.getItem('@sede'));
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [showFilters, setShowFilters] = useState(false);
    const [state, setState] = useState({
        loading: true,
        products: [],
        employees: [],
        data: [],
        time: {
            idproduction_employee_time_ref: 0,
            product: {},
            employee: {},
            start_time: ''
        },
        filter: {
            startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
            dateRange: `${dayjs().subtract(1, 'day').format('MM/DD/YYYY')} - ${dayjs().format('MM/DD/YYYY')}`,
            productf: { id: 0, name: 'All product sales' },
            employeef: { id: 0, name: 'All employees' }
        },
        rangeModal: {
            open: false,
            range: [
                {
                    startDate: new Date(dayjs().subtract(1, 'day').toDate()),
                    endDate: new Date(),
                    key: 'main'
                }
            ]
        },
        permissions: {}
    });

    useEffect(() => {
        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/productionEmployeeTime', state.filter);
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                data: response.data,
                products: response.products,
                employees: response.employees,
                time: {
                    ...prev.time,
                    product: response.products[0],
                    employee: response.employees[0]
                }
            }));
        } else {
            console.log('[ProductionStartTimeDesktop] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, time: { ...prev.time, [target.name]: target.value } }));

    const handleAutocomplete = (key, value) => setState(prev => ({ ...prev, time: { ...prev.time, [key]: value } }));

    const handleAutocompleteFilter = (key, value) => setState(prev => ({ ...prev, filter: { ...prev.filter, [key]: value } }));

    const handleModal = () => setState(prev => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));

    const handleDateChange = item => {
        const { startDate, endDate } = item.main;
        const dateRange = `${dayjs(startDate.toISOString()).format('MM/DD/YYYY')} - ${dayjs(endDate.toISOString()).format('MM/DD/YYYY')}`;

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                dateRange,
                startDate: dayjs(startDate.toISOString()).format('YYYY-MM-DD'),
                endDate: dayjs(endDate.toISOString()).format('YYYY-MM-DD')
            },
            rangeModal: { ...prev.rangeModal, range: [item.main] }
        }));
    }

    const validateForm = (type) => {
        const { product, employee, start_time } = state.time;

        switch (type) {
            case 'store':
                if (Number(product?.id) && Number(employee?.id)) {
                    return true;
                } else {
                    showToast('All fields is required', 'error');
                }
                break;

            case 'update':
                if (Number(product?.id) && Number(employee?.id) && start_time) {
                    return true;
                } else {
                    showToast('All fields is required', 'error');
                }
                break;

            default:
                break;
        }

        return false;
    }

    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/productionEmployeeTime/store', { ...state.time, ...state.filter });
            if (response.status === 200) {
                clear();
                setState(prev => ({ ...prev, data: response.data }));
                showToast(response.message);
            } else {
                console.log('[ProductionStartTimeDesktop] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false
            }
            showLoader(false);
        }
    }

    const search = async () => {
        showLoader(true);
        const response = await get('/productionEmployeeTime/search', state.filter);
        if (response.status === 200) {
            setState(prev => ({ ...prev, data: response.data }));
            if (!response?.data[0]) showToast('There are no data with these filters', 'warning');
        } else {
            console.log('[ProductionStartTimeDesktop] search error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const update = async () => {
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/productionEmployeeTime/${state.time.idproduction_employee_time_ref}/update`, { ...state.time, ...state.filter });
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response.data }));
                showToast(response.message);
            } else {
                console.log('[ProductionStartTimeDesktop] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async id => {
        const cond = window.confirm('Are you sure you want to delete this start time?\n\n This action cannot be reversed.');

        if (cond) {
            showLoader(true);
            const response = await del(`/productionEmployeeTime/${id}`, state.filter);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response.data }));
            } else {
                console.log('[ProductionStartTimeDesktop] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const clear = () => {
        setState(prev => ({
            ...prev,
            time: {
                ...prev.time,
                idproduction_employee_time_ref: 0,
                product: prev.products[0],
                employee: prev.employees[0],
                start_time: ''
            }
        }));
    }

    const getDate = ({ value }) => dayjs(value).tz(sede.time_zone).format('MM/DD/YYYY HH:mm:ss');

    const getName = params => params.value?.name ?? 'N/A';

    const renderActionsCell = ({ id }) => <>
        {
            (id > 0 && state.permissions.delete_attr) &&
            <Tooltip title='Delete' placement='top'>
                <IconButton onClick={() => destroy(id)}>
                    <Icon>{'delete'}</Icon>
                </IconButton>
            </Tooltip>
        }
    </>

    const columns = [
        { field: 'start_time', headerName: 'Start time', flex: 1, valueGetter: getDate },
        { field: 'product', headerName: 'Product sale', flex: 1, valueGetter: getName },
        { field: 'employee', headerName: 'Employee', flex: 1, valueGetter: getName },
        { field: 'user', headerName: 'Stored by', flex: 1, valueGetter: getName },
        { field: 'actions', headerName: 'Actions', flex: 0.6, minWidth: 90, renderCell: renderActionsCell }
    ];

    if (state.loading) {
        return <Loader />;
    }

    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <>
            <Grid container>
                <Grid item sm={12} md={4}>
                    <FormGroup>
                        <FormLabel>{'Employee'}</FormLabel>
                        <Autocomplete
                            options={state.employees}
                            value={state.time.employee}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleAutocomplete('employee', value)}
                            renderInput={params => <TextField {...params} placeholder='Employee' />}
                        />
                    </FormGroup>
                </Grid>

                <Grid item sm={12} md={4}>
                    <FormGroup>
                        <FormLabel>{'Product sale'}</FormLabel>
                        <Autocomplete
                            options={state.products}
                            value={state.time.product}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleAutocomplete('product', value)}
                            renderInput={params => <TextField {...params} placeholder='Product sale' />}
                        />
                    </FormGroup>
                </Grid>

                {
                    Boolean(state.time.idproduction_employee_time_ref) &&
                    <Grid item sm={12} md={4}>
                        <FormGroup>
                            <FormLabel>{'Start time'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='datetime-local'
                                    placeholder='Start time'
                                    name='start_time'
                                    value={state.time?.start_time ? dayjs(state.time.start_time).format('YYYY-MM-DDTHH:mm') : ''}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                }
            </Grid>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                cancel={clear}
            />

            <Divider />

            <div className={classes.row}>
                <h2>{'Filters'}</h2>
                <IconButton onClick={() => setShowFilters(!showFilters)}>
                    <Icon>{showFilters ? 'expand_less' : 'expand_more'}</Icon>
                </IconButton>
            </div>

            <Collapse in={showFilters}>
                <Grid container>
                    <Grid item xs={6} lg={4}>
                        <FormGroup onClick={handleModal}>
                            <FormLabel>{'Date range'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    readOnly
                                    type='text'
                                    placeholder='Date range'
                                    value={state.filter.dateRange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Product sale'}</FormLabel>
                            <Autocomplete
                                options={state.products}
                                value={state.filter.productf}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(e, value) => handleAutocompleteFilter('productf', value)}
                                renderInput={params => <TextField {...params} placeholder='Product sales' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Employee'}</FormLabel>
                            <Autocomplete
                                options={state.employees}
                                value={state.filter.employeef}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(e, value) => handleAutocompleteFilter('employeef', value)}
                                renderInput={params => <TextField {...params} placeholder='Employee' />}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <div className={classes.buttonsContainer}>
                    <Button
                        variant='contained'
                        className={classes.search}
                        onClick={search}
                    >
                        {'Search'}
                    </Button>
                </div>
            </Collapse>

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.data}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idproduction_employee_time_ref}
                    onRowClick={({ row }) => {
                        const start_time = dayjs(row.start_time).tz(sede.time_zone).format('YYYY-MM-DDTHH:mm');

                        setState(prev => ({ ...prev, time: { ...row, start_time } }));
                    }}
                />
            </div>

            <DateRange
                open={state.rangeModal.open}
                range={state.rangeModal.range}
                onClose={handleModal}
                onChange={handleDateChange}
                minDate={dayjs('2022-01-01').toDate()}
            />
        </>
    );
}

export default ProductionStartTimeDesktop;