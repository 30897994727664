import React from 'react';
import { useLocation } from 'react-router-dom';
import { Typography } from '@material-ui/core';

const NotFound = () => {

    const location = useLocation();

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', paddingTop: 50 }}>
            <div>
                <h1>{'Page not found'}</h1>
                <Typography>{`The requested URL ${location.pathname} was not found on this server`}</Typography>
            </div>
            <img src='https://www.google.com/images/errors/robot.png' alt='robot' />
        </div>
    );
}

export default NotFound;