import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { FormControlLabel, Checkbox, FilledInput, InputAdornment } from '@material-ui/core';


const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 15,
    }
}));

const Type = ({ item, onChangeSelect, onChangePrice }) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <FormControlLabel
                control={<Checkbox color='primary' checked={item.selected} onChange={onChangeSelect} name={`${item.id}`} />}
                label={item.name}
            />
            <FilledInput
                disabled={!item.selected}
                autoComplete='off'
                type='number'
                placeholder='Price'
                startAdornment={<InputAdornment position='start'>{'$'}</InputAdornment>}
                name={`${item.id}`}
                value={item.price}
                onChange={onChangePrice}
            />
        </div>
    );
}

export default Type;