import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import Loader from "../../common/loader";

import AuthContext from "../../context";
import helpers from "../../utils/helpers";
import { get } from "../../utils/api-services";
import CreateTicketBill from "../components/create-ticket-bill";

const Logo = require("../../assets/images/logo-full.png").default;

const useStyles = makeStyles(() => ({
	root: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
	},
	container: {
		display: "flex",
		flex: 1,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-evenly",
	},
	logo: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		"@media (min-width: 480px)": {
			width: "90%",
		},
		"@media (min-width: 720px)": {
			width: "60%",
		},
		"@media (min-width: 1000px)": {
			width: "40%",
		},
	},
	buttonsContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		"@media (min-width: 480px)": {
			width: "90%",
		},
		"@media (min-width: 720px)": {
			width: "60%",
		},
		"@media (min-width: 1000px)": {
			width: "40%",
		},
	},
	button: {
		width: "30%",
		backgroundColor: "#198754",
		borderColor: "#198754",
		"&:hover": {
			backgroundColor: "#198754",
			borderColor: "#198754",
			boxShadow: "none",
		},
	},
}));

export default function BillsCuttingTicketsIndex({ permissions }) {
	const { showToast, signOut, showLoader } = useContext(AuthContext);
	const classes = useStyles();
	const history = useHistory();
	const [showCreate, setShowCreate] = useState(false);
	const [state, setState] = useState({
		loading: true,
		arrays: {},
		permissions: {},
	});

	useEffect(() => {
		setState((prev) => ({ ...prev, permissions }));
	}, [permissions]);

	useEffect(() => {
		window.document.title = "Bills and Cutting Tickets";

		makeRequest();
	}, []);

	const makeRequest = async () => {
		showLoader(true);

		setState((prev) => ({ ...prev, loading: false }));

		showLoader(false);
	};

	if (state.loading) {
		return <Loader />;
	}
	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<img className={classes.logo} src={Logo} alt="logo cfg" />
				<div className={classes.buttonsContainer}>
					{state.permissions?.create_attr && (
						<Button variant="contained" className={classes.button} onClick={() => history.push("/farmForest/billsAndCuttingTickets/create")}>
							{"Create new ticket"}
						</Button>
					)}
					<Button variant="contained" className={classes.button} onClick={() => history.push("/farmForest/billsAndCuttingTickets/list")}>
						{"Show bills and cutting tickets"}
					</Button>
				</div>
				
			</div>
		</div>
	);
}
