import { makeStyles } from '@material-ui/styles';

import themeColors from '../assets/colors';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    sede: {
        fontSize: '1rem',
        color: themeColors.gray[600],
        marginLeft: '.5rem'
    }
}));

const ViewTitle = ({ title, sedeFlag = false }) => {

    const classes = useStyles();
    const sede = JSON.parse(localStorage.getItem('@sede'));

    return (
        <div className={classes.root}>
            <h1>{title}</h1>
            {
                sedeFlag && <span className={classes.sede}>{`(${sede.name})`}</span>
            }
        </div>
    );
}

export default ViewTitle;