import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, FormLabel, FormGroup, TextField, Tooltip, IconButton, Icon } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLocation, Link } from 'react-router-dom';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import Trash from '../../common/trash';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    tableContainer: {
        width: '100%',
    }
}));

const SedeLocations = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [state, setState] = useState({
        loading: true,
        sedes: [],
        locations: [],
        slocations: [],
        slocation: {
            idsede_location: 0,
            sede: {},
            location: {}
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Locations in sede';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/sedeLocations');
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                slocations: response?.data ?? [],
                sedes: response?.sedes ?? [],
                locations: response?.locations ?? [],
                slocation: {
                    sede: response?.sedes[0],
                    location: response?.locations[0]
                }
            }));
        } else {
            console.log('[SedeLocations] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const validateForm = (option) => {
        const { idsede_location, sede, location } = state.slocation;

        switch (option) {
            case 'update':
                const data = state.slocations.filter(value => value.idsede_location === idsede_location);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.slocation);
                    if (before !== after) {
                        if (sede.id && location.id) {
                            return true;
                        } else {
                            showToast('All fields is required', 'error');
                            return false;
                        }
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select some row', 'error');
                    return false;
                }

            case 'store':
                if (sede.id && location.id) {
                    return true;
                } else {
                    showToast('All fields is required', 'error');
                    return false;
                }

            case 'delete':
                if (idsede_location === 0) {
                    showToast('Select some row', 'error');
                    return false;
                }
                const cond = window.confirm(`Are you sure you want to delete this row?`);

                return Boolean(idsede_location && cond);
            default:
                break;
        }
    }

    const clear = () => setState(prev => ({
        ...prev,
        slocation: {
            ...prev.sede,
            idsede_location: 0,
            sede: prev.sedes[0],
            location: prev.locations[0]
        }
    }));

    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/sedeLocation/store', state.slocation);
            if (response.status === 200) {
                setState(prev => ({ ...prev, slocations: response?.data ?? prev.slocations }));
                showToast(response.message);
            } else {
                console.log('[SedeLocations] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { idsede_location } = state.slocation;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/sedeLocation/${idsede_location}/update`, state.slocation);
            if (response.status === 200) {
                setState(prev => ({ ...prev, slocations: response?.data ?? prev.slocations }));
                showToast(response.message);
            } else {
                console.log('[SedeLocations] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idsede_location } = state.slocation;
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/sedeLocation/${idsede_location}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, slocations: response?.data ?? prev.slocations }));
                showToast(response.message);
            } else {
                console.log('[SedeLocations] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const valueGetter = params => params.value.name;

    const renderCell = ({ row }) => {
        return (
            <Link to={`/history/sede_locations/${row.idsede_location}`} target='_blank'>
                <Tooltip title='Show history' placement='top'>
                    <IconButton>
                        <Icon>{'history'}</Icon>
                    </IconButton>
                </Tooltip>
            </Link>
        );
    }

    const columns = [
        { field: 'sede', headerName: 'Sede', flex: 1, valueGetter },
        { field: 'location', headerName: 'Location', flex: 1, valueGetter },
        { field: 'actions', headerName: 'Actions', flex: 0.5, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <h1>{'Sede locations'}</h1>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <FormLabel>{'Sede'}</FormLabel>

                        <Autocomplete
                            options={state.sedes}
                            value={state.slocation.sede}
                            getOptionLabel={sede => sede?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, sede) => {
                                setState(prev => ({ ...prev, slocation: { ...prev.slocation, sede } }));
                            }}
                            renderInput={params => <TextField {...params} placeholder='Sede' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <FormLabel>{'Location'}</FormLabel>

                        <Autocomplete
                            options={state.locations}
                            value={state.slocation.location}
                            getOptionLabel={location => location?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, location) => {
                                setState(prev => ({ ...prev, slocation: { ...prev.slocation, location } }));
                            }}
                            renderInput={params => <TextField {...params} placeholder='Location' />}
                        />
                    </FormGroup>
                </Grid>
            </Grid>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            {
                state.permissions.delete_attr &&
                <Trash table='sede_locations' id='idsede_location' onRefresh={makeRequest} />
            }

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.slocations}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idsede_location}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, slocation: row }))}
                />
            </div>
        </div>
    );
}

export default SedeLocations;