import React, { useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';

import Parent from '../../common/parent';
import SelectPage from '../../common/select-page';
import NotFound from '../../common/not-found';
import Settings from '../../common/module-settings';

import Users from './users';
import Roles from './roles';
import OptionRoles from './option-roles';
import Sedes from './sedes';
import Locations from './locations';
import SedeLocations from './sede-locations';
import UserSedes from './user-sedes';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingTop: 0
    }
}));

const SecurityParent = () => {

    const classes = useStyles();
    const { path } = useRouteMatch();
    const [module, setModule] = useState(null);

    const update = module => {
        if (module) {
            setModule(module);
        }
    }

    return (
        <Parent module={module} updateModule={update}>
            <main className={classes.content}>
                <Toolbar />
                <Switch>
                    <Route exact path={path} render={props => <SelectPage />} />
                    <Route path={`${path}/userSedes`} render={props => <UserSedes {...props} />} />
                    <Route path={`${path}/sedeLocations`} render={props => <SedeLocations {...props} />} />
                    <Route path={`${path}/locations`} render={props => <Locations {...props} />} />
                    <Route path={`${path}/sedes`} render={props => <Sedes {...props} />} />
                    <Route path={`${path}/settings`} render={props => <Settings {...props} module={module} updateModule={update} />} />
                    <Route path={`${path}/users`} render={props => <Users {...props} />} />
                    <Route path={`${path}/roles`} render={props => <Roles {...props} />} />
                    <Route path={`${path}/optionRoles`} render={props => <OptionRoles {...props} />} />
                    <Route path={`${path}/*`} component={NotFound} />
                </Switch>
            </main>
        </Parent>
    );
}

export default SecurityParent;