import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Modal,
    FilledInput,
    Typography,
    Button,
    FormGroup,
    FormLabel,
    FormControl,
    Box,
    Grid,
    InputAdornment,
    Icon,
    IconButton
} from '@material-ui/core';

import themeColors from '../../assets/colors';
import { put } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import AuthContext from '../../context';

const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    edit: {
        backgroundColor: '#ffc107',
        borderColor: '#ffc107',
        '&:hover': {
            backgroundColor: '#ffc107',
            borderColor: '#ffc107',
            boxShadow: 'none'
        }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.background.default,
        padding: 20,
        borderRadius: 10,
        '@media (min-width: 480px)': {
            width: '80%'
        },
        '@media (min-width: 720px)': {
            width: '50%'
        }
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 20
    },
    title: {
        margin: 0
    },
    update: {
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none'
        }
    },
    row: {
        flexDirection: 'row'
    }
}));

const EditPrices = ({ prices, onRefresh }) => {

    const classes = useStyles();
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const [showModal, setShowModal] = useState(false);
    const [state, setState] = useState({
        prices: [],
        value: 0
    });

    useEffect(() => {
        setState(prev => ({ ...prev, prices }));
    }, [prices]);

    const handleChange = ({ target: { name, value } }) => setState(prev => ({ ...prev, [name]: value }));

    const validateForm = () => {
        const { prices, value } = state;

        if (prices.length <= 0) {
            showToast('You must select some prices', 'error');
            return false;
        } else if (value === 0) {
            showToast('Price cannot be $0', 'error');
            return false;
        }
        update();
    }

    const update = async () => {
        showLoader(true);
        const response = await put(`/price/updateMultiple`, state);
        if (response.status === 200) {
            onRefresh();
            showToast(response.message);
        } else {
            console.log('[CreatePrices] update error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    return (
        <div>
            <div>
                <Button
                    variant='contained'
                    className={classes.edit}
                    onClick={() => setShowModal(true)}
                >
                    {'Multiple edit'}
                </Button>
            </div>
            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                className={classes.modal}
            >
                <div className={classes.container}>
                    <div className={classes.header}>
                        <Typography variant='h2' className={classes.title}>{'Edit prices'}</Typography>

                        <IconButton
                            title='Close'
                            onClick={() => setShowModal(false)}
                        >
                            <Icon>{'close'}</Icon>
                        </IconButton>
                    </div>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormLabel>{'Price'}</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    className={classes.row}
                                >
                                    <FilledInput
                                        type='number'
                                        placeholder='Price'
                                        startAdornment={<InputAdornment position='start'>{'$'}</InputAdornment>}
                                        name='value'
                                        value={state.value}
                                        onChange={handleChange}
                                    />
                                    <Button
                                        variant='contained'
                                        className={classes.update}
                                        onClick={validateForm}
                                    >
                                        {'Update'}
                                    </Button>
                                </FormControl>
                            </FormGroup>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </div>
    );
}

export default EditPrices;