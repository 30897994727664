import React, { useState, useEffect, useContext, Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useParams, useHistory, Link } from 'react-router-dom';
import {
    Grid, Box, FormControl, FormLabel,
    FormGroup, FilledInput, InputAdornment,
    IconButton, Icon, Divider, Tooltip,
    Button, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow
} from '@material-ui/core';
import dayjs from 'dayjs';

import Loader from '../../common/loader';
import NotFound from '../../common/not-found';

import AuthContext from '../../context';
import { get, put, del } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import themeColors from '../../assets/colors';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    delete: {
        width: '100%',
        backgroundColor: '#dc3545',
        borderColor: '#dc3545',
        '&:hover': {
            backgroundColor: '#dc3545',
            borderColor: '#dc3545',
            boxShadow: 'none',
        },
    },
    divider: {
        marginBottom: 24
    },
    assignContainer: {
        marginTop: 24
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    rowText: {
        display: 'inherit',
        flexDirection: 'inherit',
        alignItems: 'inherit'
    },
    text: {
        fontSize: '1rem',
        marginLeft: 5,
        marginRight: 10,
        color: themeColors.gray[600]
    },
    detail: {
        backgroundColor: themeColors.white.main
    },
    textContainer: {
        fontSize: 10,
        color: '#000'
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 8,
        backgroundColor: '#F2F2F2'
    },
    total: {
        backgroundColor: '#E0F8E0'
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#283851',
        color: '#fff',
        padding: 8,
        pageBreakAfter: 'always'
    }

}));

const Text = ({ title }) => {
    const classes = useStyles();

    return (
        <TableCell align='center' className={classes.textContainer}>{title}</TableCell>
    );
}

const ChecksDetail = ({ permissions }) => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const { id } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const [state, setState] = useState({
        loading: true,
        check: {},
        tickets: [],
        assignTickets: [],
        permissions: {}
    });

    useEffect(() => {
        setState(prev => ({ ...prev, permissions }));
    }, [permissions]);

    useEffect(() => {
        window.document.title = 'Check detail';

        makeRequest();
    }, []);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get(`/check/${id}`);
        if (response.status === 200) {
            const sede = JSON.parse(localStorage.getItem('@sede'));
            if (response?.data?.status !== 'active' || response?.data?.idsede !== sede.id) {
                showToast('This check is not available', 'error');
                history.replace('/ticket/checks');
            } else {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    check: response?.data ?? {},
                    tickets: response?.data?.tickets ?? []
                }));
            }
        } else {
            console.log('[ChecksDetail] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const update = async () => {
        const { code } = state.check;

        if (code.trim()) {
            showLoader(true);
            const response = await put(`/check/${id}/update`, { code });
            if (response.status === 200) {
                setState(prev => ({
                    ...prev,
                    check: response.data,
                    tickets: response.data?.tickets ?? []
                }));
                showToast(response.message);
            } else {
                console.log('[ChecksDetail] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        } else {
            showToast('Check No. is required', 'error');
        }
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, check: { ...prev.check, [target.name]: target.value } }));

    const getTotal = filter => {
        let total = { qty: 0, subtotal: 0, stumpage: 0, fee: 0, extra: 0, total: 0 };

        filter.tickets.map(ticket => {
            ticket.detail.map(detail => {
                if (detail.item.action === '+') {
                    total.qty = Number(total.qty) + (detail.qty * detail.qty_product);
                    total.subtotal = (Number(total.subtotal) + Number(detail.subtotal)).toFixed(2);
                    total.stumpage = (Number(total.stumpage) + Number(detail.stumpage)).toFixed(2);
                    total.fee = (Number(total.fee) + Number(detail.admin_fee)).toFixed(2);
                    total.extra = (Number(total.extra) + Number(detail.extras)).toFixed(2);
                    total.total = (Number(total.total) + Number(detail.total)).toFixed(2);
                } else if (detail.item.action === '-') {
                    total.qty = Number(total.qty) - (detail.qty * detail.qty_product);
                    total.subtotal = (Number(total.subtotal) - Number(detail.subtotal)).toFixed(2);
                    total.stumpage = (Number(total.stumpage) - Number(detail.stumpage)).toFixed(2);
                    total.fee = (Number(total.fee) - Number(detail.admin_fee)).toFixed(2);
                    total.extra = (Number(total.extra) - Number(detail.extras)).toFixed(2);
                    total.total = (Number(total.total) - Number(detail.total)).toFixed(2);
                }
            });
        });

        const values = [];
        for (const key in total) {
            switch (key) {
                case 'qty':
                    values.push(total[key]);
                    break;
                case 'stumpage':
                    values.push(`- $ ${total[key]}`);
                    break;
                case 'fee':
                    values.push(`- $ ${total[key]}`);
                    break;
                case 'extra':
                    values.push(`+ $ ${total[key]}`);
                    break;
                default:
                    values.push(`$ ${total[key]}`);
                    break;
            }
        }

        return values;
    }

    const getFullTotal = check => {
        let qty = '0';
        let subtotal = '0';
        let stumpage = '0';
        let admin_fee = '0';
        let extras = '0';
        let total = '0';

        check.filters.map(filter => {
            filter.tickets.map(ticket => {
                ticket.detail.map(detail => {
                    if (detail.item.action === '+') {
                        qty = Number(qty) + Number(detail.total_qty);
                        subtotal = (Number(subtotal) + Number(detail.subtotal)).toFixed(2);
                        stumpage = (Number(stumpage) + Number(detail.stumpage)).toFixed(2);
                        admin_fee = (Number(admin_fee) + Number(detail.admin_fee)).toFixed(2);
                        extras = (Number(extras) + Number(detail.extras)).toFixed(2);
                        total = (Number(total) + Number(detail.total)).toFixed(2);
                    } else if (detail.item.action === '-') {
                        qty = Number(qty) - Number(detail.total_qty);
                        subtotal = (Number(subtotal) - Number(detail.subtotal)).toFixed(2);
                        stumpage = (Number(stumpage) - Number(detail.stumpage)).toFixed(2);
                        admin_fee = (Number(admin_fee) - Number(detail.admin_fee)).toFixed(2);
                        extras = (Number(extras) - Number(detail.extras)).toFixed(2);
                        total = (Number(total) - Number(detail.total)).toFixed(2);
                    }
                });
            });
        });

        return [`Qty: ${qty}`, `Subtotal: $ ${subtotal}`, `Stumpage: $ ${stumpage}`, `Admin fee: $ ${admin_fee}`, `Extras: $ ${extras}`, `Total: $ ${total}`];
    }

    const destroy = async () => {
        const { idcheck } = state.check;

        const cond = window.confirm('Are you sure you want to delete this check?');
        if (cond) {
            showLoader(true);
            const response = await del(`/check/${idcheck}`);
            if (response.status === 200) {
                showToast(response.message);
                history.replace('/ticket/checks');
            } else {
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    if (state.loading) {
        return <Loader fullScreen />;
    }
    if (!state.permissions.read_attr || !state.check.idcheck) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <Divider />
            <div className={classes.row}>
                <div className={classes.rowText}>
                    <h3>{'Status:'}</h3>
                    <span className={classes.text}>{state.check.state}</span>
                </div>
            </div>
            <Divider className={classes.divider} />

            <Grid container>
                <Grid item xs={6} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Sede'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                autoComplete='off'
                                type='text'
                                placeholder='Sede'
                                name='sede'
                                value={state.check?.sede?.name}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Check No.'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Check No.'
                                name='code'
                                value={state.check?.code ?? ''}
                                onChange={handleChange}
                                endAdornment={
                                    (state.permissions.update_attr) ?
                                        <InputAdornment position='end'>
                                            <Tooltip title='Edit' placement='top'>
                                                <IconButton size='small' onClick={update}>
                                                    <Icon fontSize='small'>{'edit'}</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment> :
                                        null
                                }
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Week'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                autoComplete='off'
                                type='text'
                                placeholder='Week'
                                name='week'
                                value={state.check.week}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Supplier'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                autoComplete='off'
                                type='text'
                                placeholder='Supplier'
                                name='supplier'
                                value={state.check.supplier.name}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                {
                    state.permissions?.delete_attr &&
                    <Grid item xs={6} lg={2} className={classes.buttonContainer}>
                        <Button
                            variant='contained'
                            className={classes.delete}
                            onClick={destroy}
                        >
                            {'Delete'}
                        </Button>
                    </Grid>
                }
            </Grid>

            <Divider />
            <div className={classes.row}>
                <h2>{'Detail'}</h2>
                <div className={classes.rowText}>
                    <h2>{'Total:'}</h2>
                    <label className={classes.text}>{`$ ${state.check.total}`}</label>
                </div>
            </div>
            <Divider className={classes.divider} />

            <div>
                {
                    state.check.filters.map((filter, i) => {
                        return (
                            <div key={i.toString()} className={classes.tableContainer}>
                                <div className={classes.tableHeader}>
                                    <span><b>{`Date: `}</b>{dayjs(filter.date).format('MM/DD/YYYY')}</span>
                                    <span><b>{`Location: `}</b>{filter.location.name}</span>
                                    <span><b>{`Land: `}</b>{filter.land.name}</span>
                                </div>

                                <TableContainer component={Box}>
                                    <Table size='small' className={classes.detail}>
                                        <TableHead>
                                            <TableRow>
                                                <Text title={'No. Ticket'} />
                                                <Text title={'Item'} />
                                                <Text title={'Product'} />
                                                <Text title={'Type'} />
                                                <Text title={'Price'} />
                                                <Text title={'Qty'} />
                                                <Text title={'Subtotal'} />
                                                <Text title={'Stumpage'} />
                                                <Text title={'Admin Fee'} />
                                                <Text title={'Extras'} />
                                                <Text title={'Total'} />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                filter.tickets.map(ticket => {
                                                    return (
                                                        <Fragment key={ticket.idticket}>
                                                            {
                                                                ticket.detail.map((detail, j) => {
                                                                    return (
                                                                        <TableRow key={detail.iddetail_ticket} component={Link} to={`/ticket/tickets/${detail.idticket}`} target='_blank'>
                                                                            <Text title={(j == 0) ? ticket.code : ''} />
                                                                            <Text title={detail.item.name} />
                                                                            <Text title={detail.product.name} />
                                                                            <Text title={detail.type.name} />
                                                                            <Text title={`$ ${detail.final_price}`} />
                                                                            <Text title={detail.qty * detail.qty_product} />
                                                                            <Text title={`$ ${detail.subtotal}`} />
                                                                            <Text title={`- $ ${detail.stumpage}`} />
                                                                            <Text title={`- $ ${detail.admin_fee}`} />
                                                                            <Text title={`+ $ ${detail.extras}`} />
                                                                            <Text title={`$ ${detail.total}`} />
                                                                        </TableRow>
                                                                    );
                                                                })
                                                            }

                                                        </Fragment>
                                                    );
                                                })
                                            }
                                            <TableRow className={classes.total}>
                                                <TableCell className={classes.text} align='center'>{'TOTAL:'}</TableCell>
                                                <TableCell colSpan={4} />
                                                {
                                                    getTotal(filter).map((item, i) => <Text key={i.toString()} title={`${item}`} />)
                                                }
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        );
                    })
                }
                <div className={classes.footer}>
                    {
                        getFullTotal(state.check).map(
                            (item, i) => <span key={i.toString()}>{item}</span>
                        )
                    }
                </div>
            </div>
        </div >
    );
}

export default ChecksDetail;