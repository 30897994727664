import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, FormGroup, Box } from '@material-ui/core';

import themeColors from '../../assets/colors';

import '../../assets/css/print.css';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0
    },
    page: {
        display: 'block',
        pageBreakAfter: 'always',
    },
    container: {
        display: 'block',
        pageBreakInside: 'avoid'
    },
    header: {
        backgroundColor: themeColors.app.main,
        color: '#FFF',
        fontSize: 16,
        padding: 10,
        marginBottom: 10,
        textAlign: 'center'
    },
    text: {
        fontSize: 10,
        color: '#000'
    },
    textHeader: {
        fontSize: 6,
        color: '#000'
    },
    subtitle: {
        fontSize: 15,
        color: '#525F7F',
        fontWeight: 'bold',
        paddingBottom: 10
    },
    tableHeader: {
        backgroundColor: '#F2F2F2'
    },
    tableTotals: {
        backgroundColor: '#E0F8E0'
    },
    laborHeader: {
        backgroundColor: '#F2F2F2',
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        fontSize: 10
    },
    me: {
        marginLeft: 5,
        padding: 5,
        borderRadius: 5,
        backgroundColor: '#F8ECE0'
    }
}));

const Text = ({ title, align = 'left' }) => {
    const classes = useStyles();

    return <TableCell align={align} className={classes.text}>{title}</TableCell>;
}

const TextHeader = ({ title, align = 'left' }) => {
    const classes = useStyles();

    return <TableCell align={align} className={classes.textHeader}>{title}</TableCell>;
}

const ProductSaleToPrint = ({ data }) => {

    const classes = useStyles();
    const sede = JSON.parse(localStorage.getItem('@sede'));

    return (
        <div className={classes.root}>
            <div>
                {
                    data?.map((item, index) => {
                        const { recipe, subassemblies, raws, materials, labors } = item;
                        return (
                            <div key={index.toString()} className={classes.page}>
                                <div className={classes.header}>
                                    <span>{`Recipe: ${recipe.code} - ${recipe.description}`}</span>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ flex: 1 }}>
                                        <TableContainer component={Box}>
                                            <Table size='small'>
                                                <TableBody>
                                                    <TableRow>
                                                        <Text title={'Department'} />
                                                        <Text title={recipe.department.name} />
                                                    </TableRow>
                                                    <TableRow>
                                                        <Text title={'Pack qty'} />
                                                        <Text title={recipe.pack_qty} />
                                                    </TableRow>
                                                    <TableRow>
                                                        <Text title={'Average weight'} />
                                                        <Text title={recipe.average_weight} />
                                                    </TableRow>
                                                    <TableRow>
                                                        <Text title={'Supplier (shed)'} />
                                                        <Text title={recipe.supplier_shed ? 'Yes' : 'No'} />
                                                    </TableRow>
                                                    <TableRow>
                                                        <Text title={'Sede'} />
                                                        <Text title={sede.name} />
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    <div style={{ flex: 0.5 }}>
                                        {
                                            recipe?.image ?
                                                <img src={`https://cfg-images-bucket.s3.amazonaws.com/recipes/${recipe?.image}`} width='100%' alt='product' />
                                                :
                                                <span className={classes.text}>{'Image N/A'}</span>
                                        }
                                    </div>
                                    <div style={{ flex: 1.5 }}>
                                        <FormGroup>
                                            <TableContainer component={Box}>
                                                <Table size='small'>
                                                    <TableBody>
                                                        <TableRow>
                                                            <Text title={''} />
                                                            <Text title={'Total cost per product'} />
                                                            <Text title={'Total cost per pack'} />
                                                        </TableRow>
                                                        <TableRow>
                                                            <Text title={'Raws'} />
                                                            <Text title={`$ ${recipe.totals?.total_raw_unit ?? 0}`} />
                                                            <Text title={`$ ${recipe.totals?.total_raw_pack ?? 0}`} />
                                                        </TableRow>
                                                        <TableRow>
                                                            <Text title={'Materials'} />
                                                            <Text title={`$ ${recipe.totals?.total_material_unit ?? 0}`} />
                                                            <Text title={`$ ${recipe.totals?.total_material_pack ?? 0}`} />
                                                        </TableRow>
                                                        <TableRow>
                                                            <Text title={'Labors'} />
                                                            <Text title={`$ ${recipe.totals?.total_labor_unit ?? 0}`} />
                                                            <Text title={`$ ${recipe.totals?.total_labor_pack ?? 0}`} />
                                                        </TableRow>
                                                        <TableRow>
                                                            <Text title={<b>{'TOTAL:'}</b>} />
                                                            <Text title={<b>{`$ ${recipe.totals?.final_cost_product ?? 0}`}</b>} />
                                                            <Text title={<b>{`$ ${recipe.totals?.final_cost_pack ?? 0}`}</b>} />
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </FormGroup>
                                    </div>
                                </div>

                                {
                                    subassemblies?.length > 0 &&
                                    <div className={classes.container} style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span className={classes.subtitle}>{'Subassemblies'}</span>

                                        <TableContainer component={Box}>
                                            <Table size='small'>
                                                <TableHead>
                                                    <TableRow className={classes.tableHeader}>
                                                        <TextHeader title={'Product sale'} />
                                                        <TextHeader title={'Qty'} />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        subassemblies.map(subassembly => {
                                                            const index = subassemblies.findIndex(item => item.idproduct_sale_subassembly_ref === subassembly.idproduct_sale_subassembly_ref);

                                                            return (
                                                                <TableRow key={subassembly.idproduct_sale_subassembly_ref} style={{ backgroundColor: themeColors.list[index] }}>
                                                                    <Text title={subassembly.child.name} />
                                                                    <Text title={subassembly.qty} />
                                                                </TableRow>
                                                            );
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                }

                                <div className={classes.container} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span className={classes.subtitle}>{'Raws'}</span>
                                    <TableContainer component={Box}>
                                        <Table size='small'>
                                            <TableHead>
                                                <TableRow className={classes.tableHeader}>
                                                    <TextHeader title={'Product'} />
                                                    <TextHeader title={'Unit qty'} />
                                                    <TextHeader title={'Unit cost'} />
                                                    <TextHeader title={'Shrink unit'} />
                                                    <TextHeader title={'Pack qty'} />
                                                    <TextHeader title={'Pack cost'} />
                                                    <TextHeader title={'Shrink pack'} />
                                                    <TextHeader title={'Total unit cost'} />
                                                    <TextHeader title={'Total pack cost'} />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    raws.map(raw => {
                                                        const index = subassemblies.findIndex(item => item.idchild === raw.idproduct_sale);

                                                        return (
                                                            <TableRow
                                                                key={raw.idproduct_sale_raw_ref}
                                                                className={raw.idproduct_sale_raw_ref === 0 ? classes.tableTotals : ''}
                                                                style={{ backgroundColor: themeColors.list[index] }}
                                                            >
                                                                <Text title={raw.product.name} />
                                                                <Text title={raw.unit_qty} />
                                                                <Text title={raw?.unit_cost ? `$ ${raw.unit_cost}` : ''} />
                                                                <Text title={raw.shrink_unit} />
                                                                <Text title={raw.pack_qty} />
                                                                <Text title={raw?.pack_cost ? `$ ${raw.pack_cost}` : ''} />
                                                                <Text title={raw.shrink_pack} />
                                                                <Text title={`$ ${raw.total_unit_cost}`} />
                                                                <Text title={`$ ${raw.total_pack_cost}`} />
                                                            </TableRow>
                                                        );
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>

                                <div className={classes.container} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span className={classes.subtitle}>{'Materials'}</span>
                                    <TableContainer component={Box}>
                                        <Table size='small'>
                                            <TableHead>
                                                <TableRow className={classes.tableHeader}>
                                                    <TextHeader title={'Product'} />
                                                    <TextHeader title={'Unit used (cost)'} />
                                                    <TextHeader title={'Unit used (material)'} />
                                                    <TextHeader title={'Unit cost'} />
                                                    <TextHeader title={'Shrink unit'} />
                                                    <TextHeader title={'Pack qty'} />
                                                    <TextHeader title={'Pack cost'} />
                                                    <TextHeader title={'Shrink pack'} />
                                                    <TextHeader title={'Total unit cost'} />
                                                    <TextHeader title={'Total pack cost'} />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    materials.map(material => {
                                                        const index = subassemblies.findIndex(item => item.idchild === material.idproduct_sale);

                                                        return (
                                                            <TableRow
                                                                key={material.idproduct_sale_material_ref}
                                                                className={material.idproduct_sale_material_ref === 0 ? classes.tableTotals : ''}
                                                                style={{ backgroundColor: themeColors.list[index] }}
                                                            >
                                                                <Text title={material.product.name} />
                                                                <Text title={material.unit_used_cost} />
                                                                <Text title={material.unit_used_material} />
                                                                <Text title={material?.unit_cost ? `$ ${material.unit_cost}` : ''} />
                                                                <Text title={material.shrink_unit} />
                                                                <Text title={material.pack_qty} />
                                                                <Text title={material?.pack_cost ? `$ ${material.pack_cost}` : ''} />
                                                                <Text title={material.shrink_pack} />
                                                                <Text title={`$ ${material.total_unit_cost}`} />
                                                                <Text title={`$ ${material.total_pack_cost}`} />
                                                            </TableRow>
                                                        );
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>

                                <div className={classes.container} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span className={classes.subtitle}>{'Labors'}</span>
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                        {
                                            labors.map(labor => {
                                                const index = subassemblies.findIndex(item => item.idchild === labor.idproduct_sale);

                                                return (
                                                    <div key={labor.idproduct_sale_labor_ref} style={{ width: '48%', pageBreakInside: 'avoid', marginBottom: 25 }}>
                                                        <div className={classes.laborHeader}>
                                                            <span><b>{'Code: '}</b>{labor.labor.code}</span>
                                                            <span><b>{'Unit qty: '}</b>{labor.unit_qty}</span>
                                                            <span><b>{'Pack qty: '}</b>{labor.pack_qty}</span>
                                                            <span><b>{'Piece rate: '}</b>{`$ ${labor.piece_rate}`}</span>
                                                        </div>

                                                        <TableContainer component={Box} style={{ backgroundColor: themeColors.list[index] }}>
                                                            <Table size='small'>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className={classes.emptyCell}>
                                                                            {
                                                                                labor.edited &&
                                                                                <span className={classes.me}>{'M/E'}</span>
                                                                            }
                                                                        </TableCell>
                                                                        <TextHeader title={'Loaded piece pay'} />
                                                                        <TextHeader title={'Total pack cost'} />
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <Text title={''} />
                                                                        <Text title={`$ ${labor.loaded_piece_rate_unit}`} />
                                                                        <Text title={`$ ${labor.loaded_piece_rate_pack}`} />
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <Text title={'PS - Supervision'} />
                                                                        <Text title={`$ ${labor.supervision_unit}`} />
                                                                        <Text title={`$ ${labor.supervision_pack}`} />
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <Text title={'I - IRS'} />
                                                                        <Text title={`$ ${labor.irs_unit}`} />
                                                                        <Text title={`$ ${labor.irs_pack}`} />
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <Text title={'PW - Support & Waste'} />
                                                                        <Text title={`$ ${labor.support_waste_unit}`} />
                                                                        <Text title={`$ ${labor.support_waste_pack}`} />
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <Text title={'H - H2B Expense'} />
                                                                        <Text title={`$ ${labor.h2b_expense_unit}`} />
                                                                        <Text title={`$ ${labor.h2b_expense_pack}`} />
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <Text title={'TOTAL:'} />
                                                                        <Text title={<b>{`$ ${(Number(labor.loaded_piece_rate_unit) + Number(labor.supervision_unit) + Number(labor.irs_unit) + Number(labor.support_waste_unit) + Number(labor.h2b_expense_unit)).toFixed(4)}`}</b>} />
                                                                        <Text title={<b>{`$ ${(Number(labor.loaded_piece_rate_pack) + Number(labor.supervision_pack) + Number(labor.irs_pack) + Number(labor.support_waste_pack) + Number(labor.h2b_expense_pack)).toFixed(4)}`}</b>} />
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>

                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div >
    );
}

export default ProductSaleToPrint;