import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Tooltip, IconButton, Icon, Divider, InputAdornment, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation, Link } from 'react-router-dom';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import ViewTitle from '../../common/view-title';
import Trash from '../../common/trash';

import AuthContext from '../../context';
import { get, post, put, del } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%'
    },
    divider: {
        marginBottom: 24
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    calculate: {
        marginRight: 15,
        backgroundColor: '#A4A4A4',
        borderColor: '#A4A4A4',
        '&:hover': {
            backgroundColor: '#A4A4A4',
            borderColor: '#A4A4A4',
            boxShadow: 'none'
        }
    },
}));

const firstDept = { id: 0, name: 'Choose a department', sedes: [] };

const Labor = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [state, setState] = useState({
        loading: true,
        data: [],
        departments: [],
        oldLabor: {},
        labor: {
            idproduct_sale_labor: 0,
            code: '',
            size: '',
            units_hour: 0,
            hourly_rate: 0,
            tax_index: 0,
            loaded_hourly_rate: 0,
            piece_rate: 0,
            loaded_piece_rate: 0,
            supervision: 0,
            irs: 0,
            support_waste: 0,
            h2b_expense: 0,
            iddepartment: 0,
            department: firstDept
        },
        param: {
            tax_index: 0,
            supervision: 0,
            irs: 0,
            support_waste: 0,
            h2b_expense: 0,
            idproduct_sale_labor: 0,
            idsede: 0
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Labor';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/labor');
        if (response.status === 200) {
            setState(prev => ({ ...prev, loading: false, data: response?.data ?? [], departments: response?.departments ?? [] }));
        } else {
            console.log('[Labor] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, labor: { ...prev.labor, [target.name]: target.value } }));

    const validateForm = (type) => {
        const { idproduct_sale_labor, code, units_hour, hourly_rate, tax_index, department } = state.labor;
        const labor = JSON.stringify(state.labor);
        const confirmedLabor = JSON.stringify(state.oldLabor);

        switch (type) {
            case 'update':
                const value = state.data.find(value => value.idproduct_sale_labor === idproduct_sale_labor);

                if (Number(idproduct_sale_labor)) {
                    // avoid updating without any changes
                    if (labor !== JSON.stringify(value)) {
                        // avoid not calculating
                        if (labor === confirmedLabor) {
                            // avoid empty values
                            if (code.trim() && department?.id && Number(units_hour) && Number(hourly_rate) && Number(tax_index)) {
                                const cond = window.confirm(`If you update, then all affected data will be updated: \n- Recipe labors \n\nDo you want to continue with this update?`);

                                return cond;
                            } else {
                                showToast('All fields are required', 'error');
                            }
                        } else {
                            showToast('You must calculate before updating', 'error');
                        }
                    } else {
                        showToast('Update some field', 'error');
                    }
                } else {
                    showToast('Select some labor', 'error');
                }

                return false;

            case 'store':
                // avoid not calculating
                if (labor === confirmedLabor) {
                    // avoid empty values
                    if (code.trim() && department?.id && Number(units_hour) && Number(hourly_rate) && Number(tax_index)) {
                        return true;
                    } else {
                        showToast('All fields are required', 'error');
                    }
                } else {
                    showToast('You must calculate before storing', 'error');
                }

                return false;

            case 'delete':
                if (idproduct_sale_labor === 0) {
                    showToast('Select some labor', 'error');
                    return false;
                }
                const cond = window.confirm(`Are you sure you want to delete this labor?`);

                return Boolean(idproduct_sale_labor && cond);
            default:
                break;
        }
    }

    const clear = () => {
        setState(prev => ({
            ...prev,
            labor: {
                ...prev.labor,
                idproduct_sale_labor: 0,
                code: '',
                size: '',
                units_hour: 0,
                hourly_rate: 0,
                tax_index: 0,
                loaded_hourly_rate: 0,
                piece_rate: 0,
                loaded_piece_rate: 0,
                supervision: 0,
                irs: 0,
                support_waste: 0,
                h2b_expense: 0,
                iddepartment: 0,
                department: firstDept
            },
            param: {
                tax_index: 0,
                supervision: 0,
                irs: 0,
                support_waste: 0,
                h2b_expense: 0,
                idproduct_sale_labor: 0,
                idsede: 0
            }
        }));
    }

    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/labor/store', state.labor);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? prev.data }));
                showToast(response.message);
            } else {
                console.log('[Labor] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { idproduct_sale_labor } = state.labor;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/labor/${idproduct_sale_labor}/update`, state.labor);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? prev.data }));
                showToast(response.message);
            } else {
                console.log('[Labor] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idproduct_sale_labor } = state.labor;
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/labor/${idproduct_sale_labor}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? prev.data }));
                showToast(response.message);
            } else {
                console.log('[Labor] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const calculate = async () => {
        const { units_hour, hourly_rate, department } = state.labor;

        showLoader(true);
        const response = await get(`/labor/getParams`, { department });
        if (response.status === 200) {
            const param = response.data;
            const { tax_index } = param;

            let loaded_hourly_rate = Number(hourly_rate) * Number(tax_index);
            const piece_rate = (Number(hourly_rate) / Number(units_hour)).toFixed(4);
            let loaded_piece_rate = Number(loaded_hourly_rate) / Number(units_hour);

            const supervision = (Number(loaded_piece_rate) * (Number(param.supervision) / 100)).toFixed(4);
            const irs = (Number(loaded_piece_rate) * (Number(param.irs) / 100)).toFixed(4);
            const support_waste = (Number(loaded_piece_rate) * (Number(param.support_waste) / 100)).toFixed(4);
            const h2b_expense = (Number(loaded_piece_rate) * (Number(param.h2b_expense) / 100)).toFixed(4);

            loaded_hourly_rate = loaded_hourly_rate.toFixed(4);
            loaded_piece_rate = loaded_piece_rate.toFixed(4);

            setState(prev => ({
                ...prev,
                param,
                labor: {
                    ...prev.labor,
                    hourly_rate,
                    tax_index,
                    loaded_hourly_rate,
                    piece_rate,
                    loaded_piece_rate,
                    supervision,
                    irs,
                    support_waste,
                    h2b_expense
                },
                oldLabor: {
                    ...prev.labor,
                    hourly_rate,
                    tax_index,
                    loaded_hourly_rate,
                    piece_rate,
                    loaded_piece_rate,
                    supervision,
                    irs,
                    support_waste,
                    h2b_expense
                }
            }));

        } else {
            console.log('[Labor] calculate error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const valueGetter = ({ row, field }) => row?.sedes[0]?.[field] ?? 'empty';

    const getDepartment = ({ row }) => row?.department?.name ?? 'N/A'

    const renderCell = ({ row }) => {
        return (
            <>
                <Link to={`/history/product_sale_labor/${row.idproduct_sale_labor}`} target='_blank'>
                    <Tooltip title='Show history: header' placement='top'>
                        <IconButton>
                            <Icon>{'history'}</Icon>
                        </IconButton>
                    </Tooltip>
                </Link>
                {
                    Boolean(row.sedes[0]) &&
                    <Link to={`/history/product_sale_labor_sede_ref/${row.sedes[0].idproduct_sale_labor_sede_ref}`} target='_blank'>
                        <Tooltip title='Show history: data by sede' placement='top'>
                            <IconButton>
                                <Icon>{'history'}</Icon>
                            </IconButton>
                        </Tooltip>
                    </Link>
                }
            </>
        );
    }

    const columns = [
        { field: 'code', headerName: 'Code', flex: 0.7, minWidth: 140 },
        { field: 'department', headerName: 'Department', flex: 1, minWidth: 200, valueGetter: getDepartment },
        { field: 'units_hour', headerName: 'Units / hr', flex: 0.5, minWidth: 100 },
        { field: 'hourly_rate', headerName: 'Hourly rate', flex: 0.5, minWidth: 100, valueGetter },
        { field: 'tax_index', headerName: 'Tax index', flex: 0.5, minWidth: 100, valueGetter },
        { field: 'loaded_hourly_rate', headerName: 'Loaded hourly rate', flex: 0.5, minWidth: 100, valueGetter },
        { field: 'piece_rate', headerName: 'Piece rate', flex: 0.7, minWidth: 140, valueGetter },
        { field: 'actions', headerName: 'Actions', flex: 0.5, minWidth: 100, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle title='Labor' sedeFlag />
            <Grid container>
                <Grid item xs={6} md={4} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Code'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Code'
                                name='code'
                                value={state.labor.code}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={5} lg={5}>
                    <FormGroup>
                        <FormLabel>{'Department'}</FormLabel>
                        <Autocomplete
                            options={state.departments}
                            value={state.labor.department}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => {
                                const hourly_rate = value?.sedes[0]?.hourly_rate ?? 0;
                                const department = (hourly_rate) ? value : firstDept;

                                setState(prev => ({ ...prev, labor: { ...prev.labor, department, hourly_rate } }));
                                if (!hourly_rate) showToast('Department without assigned hourly rate', 'error');
                            }}
                            renderInput={(params) => <TextField {...params} placeholder='Department' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Size'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Size'
                                name='size'
                                value={state.labor.size}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Units / Hr'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='number'
                                placeholder='Units / hr'
                                name='units_hour'
                                value={state.labor.units_hour}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Hourly rate'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                startAdornment={<InputAdornment position='start'>{'$'}</InputAdornment>}
                                placeholder='Hourly rate'
                                value={state.labor.hourly_rate}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Tax index'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                placeholder='Tax index'
                                value={state.param.tax_index}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Loaded hourly rate'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                startAdornment={<InputAdornment position='start'>{'$'}</InputAdornment>}
                                placeholder='Loaded hourly rate'
                                value={state.labor.loaded_hourly_rate}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Piece rate'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                placeholder='Piece rate'
                                value={state.labor.piece_rate}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Loaded piece rate'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                placeholder='Loaded piece rate'
                                value={state.labor.loaded_piece_rate}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'PS - Supervision'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                placeholder='Supervision'
                                value={state.labor.supervision}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'I - IRS'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                placeholder='IRS'
                                value={state.labor.irs}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'PW - Support & Waste'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                placeholder='Support & Waste'
                                value={state.labor.support_waste}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'H - H2B Expense'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                placeholder='H2B Expense'
                                value={state.labor.h2b_expense}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={12} lg={6}>
                    <h3>{'Params:'}</h3>
                    <Divider className={classes.divider} />
                    <Grid container>
                        <Grid item xs={6} md={5} lg={3}>
                            <FormGroup>
                                <FormLabel>{'PS - Supervision'}</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                >
                                    <FilledInput
                                        disabled
                                        type='number'
                                        endAdornment={<InputAdornment position='end'>{'%'}</InputAdornment>}
                                        placeholder='Supervision'
                                        value={state.param.supervision}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={6} md={5} lg={3}>
                            <FormGroup>
                                <FormLabel>{'I - IRS'}</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                >
                                    <FilledInput
                                        disabled
                                        type='number'
                                        endAdornment={<InputAdornment position='end'>{'%'}</InputAdornment>}
                                        placeholder='IRS'
                                        value={state.param.irs}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={6} md={5} lg={3}>
                            <FormGroup>
                                <FormLabel>{'PW - Support & Waste'}</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                >
                                    <FilledInput
                                        disabled
                                        type='number'
                                        endAdornment={<InputAdornment position='end'>{'%'}</InputAdornment>}
                                        placeholder='Support & Waste'
                                        value={state.param.support_waste}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={6} md={5} lg={3}>
                            <FormGroup>
                                <FormLabel>{'H - H2B Expense'}</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                >
                                    <FilledInput
                                        disabled
                                        type='number'
                                        endAdornment={<InputAdornment position='end'>{'%'}</InputAdornment>}
                                        placeholder='H2B Expense'
                                        value={state.param.h2b_expense}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12} lg={6}>
                    <Crud
                        permissions={state.permissions}
                        create={{ create: clear, store }}
                        update={update}
                        destroy={destroy}
                        cancel={clear}
                    />
                    <div className={classes.buttonContainer}>
                        <div>
                            <Button
                                variant='contained'
                                className={classes.calculate}
                                onClick={calculate}
                            >
                                {'Calculate'}
                            </Button>
                        </div>
                        {
                            state.permissions.delete_attr &&
                            <Trash table='product_sale_labor' id='idproduct_sale_labor' onRefresh={makeRequest} />
                        }
                    </div>
                </Grid>
            </Grid>



            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.data}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idproduct_sale_labor}
                    onRowClick={({ row }) => {
                        const loaded_hourly_rate = row?.sedes[0]?.loaded_hourly_rate ?? 0;
                        const piece_rate = row?.sedes[0]?.piece_rate ?? 0;
                        const loaded_piece_rate = row?.sedes[0]?.loaded_piece_rate ?? 0;
                        const supervision = row?.sedes[0]?.supervision ?? 0;
                        const irs = row?.sedes[0]?.irs ?? 0;
                        const support_waste = row?.sedes[0]?.support_waste ?? 0;
                        const h2b_expense = row?.sedes[0]?.h2b_expense ?? 0;

                        const department = row?.department ?? firstDept;
                        const hourly_rate = row?.department?.sedes[0]?.hourly_rate ?? 0;

                        setState(prev => ({ ...prev, labor: { ...row, hourly_rate, loaded_hourly_rate, piece_rate, loaded_piece_rate, supervision, irs, support_waste, h2b_expense, department } }));
                    }}
                />
            </div>
        </div>
    );
}

export default Labor;