import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormLabel, FormGroup, FormControl, FilledInput, TextField, Button, Icon, IconButton, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import dayjs from 'dayjs';

import Loader from '../../common/loader';
import DateRange from '../../common/date-range';

import AuthContext from '../../context';
import helpers from '../../utils/helpers';
import { get } from '../../utils/api-services';

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    search: {
        alignSelf: 'flex-end',
        marginBottom: 20,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    tableContainer: {
        width: '100%'
    }
}));

const TicketsList = ({ permissions }) => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const sede = JSON.parse(localStorage.getItem('@sede'));
    const classes = useStyles();
    const [state, setState] = useState({
        loading: true,
        suppliers: [],
        lands: [],
        locations: [],
        filter: {
            code: '',
            startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
            dateRange: `${dayjs().subtract(1, 'day').format('MM/DD/YYYY')} - ${dayjs().format('MM/DD/YYYY')}`,
            supplier: { id: 0, name: 'All suppliers' },
            land: { id: 0, name: 'All lands' },
            location: { id: 0, name: 'All locations' }
        },
        tickets: [],
        rangeModal: {
            open: false,
            range: [
                {
                    startDate: new Date(dayjs().subtract(1, 'day').toDate()),
                    endDate: new Date(),
                    key: 'main'
                }
            ]
        },
        permissions: {}
    });

    useEffect(() => {
        setState(prev => ({ ...prev, permissions }));
    }, [permissions]);

    useEffect(() => {
        window.document.title = 'Tickets';

        makeRequest();
    }, []);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/tickets', state.filter);
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                tickets: response?.data ?? [],
                suppliers: response?.suppliers ?? [],
                lands: response?.lands ?? [],
                locations: response?.locations ?? []
            }));
        } else {
            console.log('[TicketsList] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const handleDateChange = item => {
        const { startDate, endDate } = item.main;
        const dateRange = `${dayjs(startDate.toISOString()).format('MM/DD/YYYY')} - ${dayjs(endDate.toISOString()).format('MM/DD/YYYY')}`;
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                dateRange,
                startDate: dayjs(startDate.toISOString()).format('YYYY-MM-DD'),
                endDate: dayjs(endDate.toISOString()).format('YYYY-MM-DD')
            },
            rangeModal: { ...prev.rangeModal, range: [item.main] }
        }));
    }

    const handleModal = () => setState(prev => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));

    const handleChange = ({ target: { name, value } }) => setState(prev => ({ ...prev, filter: { ...prev.filter, [name]: value } }));

    const handleChangeAutocomplete = (key, value) => setState(prev => ({ ...prev, filter: { ...prev.filter, [key]: value } }));

    const search = async () => {
        showLoader(true);
        const response = await get('/tickets/search', state.filter);
        if (response.status === 200) {
            setState(prev => ({ ...prev, tickets: response?.data ?? [] }));

            if (!response?.data[0]) showToast('There are no tickets with these filters', 'warning');
        } else {
            console.log('[TicketsList] search error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const renderDate = params => dayjs(params.value).format('MM/DD/YYYY');

    const valueGetter = params => params.value.name;

    const renderCell = ({ row }) => {
        return (
            <div>
                <Tooltip title='Show detail' placement='right'>
                    <Link to={`/ticket/tickets/${row.idticket}`} target='_blank'>
                        <IconButton>
                            <Icon>{'visibility'}</Icon>
                        </IconButton>
                    </Link>
                </Tooltip>
                {
                    (row.idsede !== row.idsede_origin && sede.id === row.idsede) &&
                    <Tooltip title='This ticket was transferred to this sede' placement='top'>
                        <IconButton>
                            <Icon>{'local_shipping'}</Icon>
                        </IconButton>
                    </Tooltip>
                }
                {
                    (row.idsede !== row.idsede_origin && sede.id === row.idsede_origin) &&
                    <Tooltip title='This ticket was transferred to another sede' placement='bottom'>
                        <IconButton>
                            <Icon>{'local_shipping'}</Icon>
                        </IconButton>
                    </Tooltip>
                }

            </div>
        );
    }

    const columns = [
        { field: 'code', headerName: 'No. ticket', flex: 0.5 },
        { field: 'total', headerName: 'Total', flex: 1 },
        { field: 'date', headerName: 'Date', flex: 1, valueGetter: renderDate },
        { field: 'supplier', headerName: 'Supplier', flex: 1, valueGetter },
        { field: 'land', headerName: 'Land', flex: 1, valueGetter },
        { field: 'location', headerName: 'Location', flex: 1, valueGetter },
        { field: 'state', headerName: 'Status', flex: 1 },
        { field: 'detail', headerName: 'Detail', flex: 0.5, renderCell }
    ]

    if (state.loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'No. ticket'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='No. ticket'
                                name='code'
                                onChange={handleChange}
                                value={state.filter.code}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={4}>
                    <FormGroup onClick={handleModal}>
                        <FormLabel>{'Date range'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                readOnly
                                autoComplete='off'
                                type='text'
                                placeholder='Date range'
                                name='range'
                                value={state.filter.dateRange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Supplier'}</FormLabel>
                        <Autocomplete
                            disabled={Boolean(state.filter.code.trim())}
                            options={state.suppliers}
                            value={state.filter.supplier}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleChangeAutocomplete('supplier', value)}
                            renderInput={params => <TextField {...params} placeholder='Supplier' />}
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Land'}</FormLabel>
                        <Autocomplete
                            disabled={Boolean(state.filter.code.trim())}
                            options={state.lands}
                            value={state.filter.land}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleChangeAutocomplete('land', value)}
                            renderInput={params => <TextField {...params} placeholder='Land' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Location'}</FormLabel>
                        <Autocomplete
                            disabled={Boolean(state.filter.code.trim())}
                            options={state.locations}
                            value={state.filter.location}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleChangeAutocomplete('location', value)}
                            renderInput={params => <TextField {...params} placeholder='Location' />}
                        />
                    </FormGroup>
                </Grid>
            </Grid>

            <Button
                variant='contained'
                className={classes.search}
                onClick={search}
            >
                {'Search'}
            </Button>

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.tickets}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idticket}
                />
            </div>

            <DateRange
                open={state.rangeModal.open}
                range={state.rangeModal.range}
                onClose={handleModal}
                onChange={handleDateChange}
            />
        </div>
    );
}

export default TicketsList;