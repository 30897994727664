import React from 'react';
import {
    useTheme,
    Box,
} from '@material-ui/core';

const Header = () => {

    const theme = useTheme();

    return (
        <>
            <Box
                style={{
                    background:
                        'linear-gradient(87deg, #FFFFFF, #D8D8D8)',
                }}
                position='relative'
                paddingTop='8rem'
                paddingBottom='8rem'
            >
                <Box
                    position='absolute'
                    zIndex='100'
                    height='70px'
                    top='auto'
                    bottom='0'
                    pointerEvents='none'
                    left='0'
                    right='0'
                    width='100%'
                    overflow='hidden'
                    transform='translateZ(0)'
                >
                    <Box
                        bottom='0'
                        position='absolute'
                        pointerEvents='none'
                        component='svg'
                        xmlns='http://www.w3.org/2000/svg'
                        preserveAspectRatio='none'
                        version='1.1'
                        viewBox='0 0 2560 100'
                        x='0'
                        y='0'
                    >
                        <Box
                            component='polygon'
                            fill={theme.primary}
                            points='2560 0 2560 100 0 100'
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default Header;