import React, { useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';

import Parent from '../../common/parent';
import SelectPage from '../../common/select-page';
import NotFound from '../../common/not-found';
import Settings from '../../common/module-settings';

import Process from './process';
import Reports from './report';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingTop: 0
    }
}));

const ProcessParent = () => {

    const classes = useStyles();
    const { path } = useRouteMatch();
    const [module, setModule] = useState(null);

    const update = module => {
        if (module) {
            setModule(module);
        }
    }

    return (
        <Parent module={module} updateModule={update}>
            <main className={classes.content}>
                <Toolbar />
                <Switch>
                    <Route exact path={path} render={props => <SelectPage />} />
                    <Route path={`${path}/reportProcess`} render={props => <Reports {...props} />} />
                    <Route path={`${path}/processes`} render={props => <Process {...props} />} />
                    <Route path={`${path}/settings`} render={props => <Settings {...props} module={module} updateModule={update} />} />
                    <Route path={`${path}/*`} component={NotFound} />
                </Switch>
            </main>
        </Parent>
    );
}

export default ProcessParent;