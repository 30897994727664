import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, FormLabel, FormGroup, Box, Tooltip, IconButton, Icon, FormControl, FilledInput } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation, Link } from 'react-router-dom';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import Trash from '../../common/trash';
import ViewTitle from '../../common/view-title';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    tableContainer: {
        width: '100%',
    }
}));

const BreakTime = () => {

    const sede = JSON.parse(window.localStorage.getItem('@sede'));
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [state, setState] = useState({
        loading: true,
        data: [],
        break_time: {
            idbreak_time: 0,
            description: '',
            start_time: dayjs().tz(sede.time_zone).format('HH:mm'),
            end_time: dayjs().tz(sede.time_zone).format('HH:mm'),
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Break time';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/breakTime');
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                data: response?.data ?? []
            }));
        } else {
            console.log('[BreakTime] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const validateForm = (option) => {
        const { idbreak_time, description, start_time, end_time } = state.break_time;

        switch (option) {
            case 'update':
                const data = state.data.filter(value => value.idbreak_time === idbreak_time);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.break_time);
                    if (before !== after) {
                        if (description.trim() && start_time && end_time) {
                            return true;
                        } else {
                            showToast('All fields is required', 'error');
                            return false;
                        }
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select some row', 'error');
                    return false;
                }

            case 'store':
                if (description.trim() && start_time && end_time) {
                    return true;
                } else {
                    showToast('All fields is required', 'error');
                    return false;
                }

            case 'delete':
                if (idbreak_time === 0) {
                    showToast('Select some row', 'error');
                    return false;
                }
                const cond = window.confirm(`Are you sure you want to delete this row?`);

                return Boolean(idbreak_time && cond);
            default:
                break;
        }
    }

    const clear = () => setState(prev => ({
        ...prev,
        break_time: {
            ...prev.break_time,
            idbreak_time: 0,
            description: '',
            start_time: dayjs().tz(sede.time_zone).format('HH:mm'),
            end_time: dayjs().tz(sede.time_zone).format('HH:mm')
        }
    }));

    const handleChange = ({ target }) => setState(prev => ({ ...prev, break_time: { ...prev.break_time, [target.name]: target.value } }));

    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/breakTime/store', state.break_time);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? prev.data }));
                showToast(response.message);
            } else {
                console.log('[BreakTime] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { idbreak_time } = state.break_time;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/breakTime/${idbreak_time}/update`, state.break_time);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? prev.data }));
                showToast(response.message);
            } else {
                console.log('[BreakTime] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idbreak_time } = state.break_time;
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/breakTime/${idbreak_time}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? prev.data }));
                showToast(response.message);
            } else {
                console.log('[BreakTime] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const renderCell = ({ row }) => {
        return (
            <Link to={`/history/break_time/${row.idbreak_time}`} target='_blank'>
                <Tooltip title='Show history' placement='top'>
                    <IconButton>
                        <Icon>{'history'}</Icon>
                    </IconButton>
                </Tooltip>
            </Link>
        );
    }

    const columns = [
        { field: 'description', headerName: 'Description', flex: 1 },
        { field: 'start_time', headerName: 'Start time', flex: 1 },
        { field: 'end_time', headerName: 'End time', flex: 1 },
        { field: 'actions', headerName: 'Actions', flex: 1, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle title='Break time' sedeFlag />
            <Grid container>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{'Description'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Description'
                                name='description'
                                value={state.break_time?.description ?? ''}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{'Start time'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='time'
                                placeholder='Start time'
                                name='start_time'
                                value={state.break_time?.start_time ?? dayjs().tz(sede.time_zone).format('HH:mm')}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{'End time'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='time'
                                placeholder='End time'
                                name='end_time'
                                value={state.break_time?.end_time ?? dayjs().tz(sede.time_zone).format('HH:mm')}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            {
                state.permissions.delete_attr &&
                <Trash table='break_time' id='idbreak_time' onRefresh={makeRequest} />
            }

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.data}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idbreak_time}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, break_time: row }))}
                />
            </div>
        </div>
    );
}

export default BreakTime;