import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal, FilledInput, Typography, Button, FormGroup, FormLabel, FormControl, Box, Select, MenuItem } from '@material-ui/core';

import themeColors from '../../assets/colors';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.background.default,
        padding: 20,
        width: '30%',
        borderRadius: 10,
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    inputContainer: {
        width: '90%',
        marginBottom: 15,
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    button: {
        width: '30%'
    }
}));

const Edit = ({ open, onClose, form, onChange, update }) => {

    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div className={classes.container}>
                <Typography variant='h2'>{'Edit module'}</Typography>
                <form noValidate autoComplete='off' className={classes.formContainer} >
                    <FormGroup className={classes.inputContainer}>
                        <FormLabel>{'Name'}</FormLabel>
                        <FilledInput
                            placeholder='Name'
                            name='name'
                            value={form.name}
                            onChange={onChange}
                        />
                    </FormGroup>
                    <FormGroup className={classes.inputContainer}>
                        <FormLabel>{'Icon'}</FormLabel>
                        <FilledInput
                            placeholder='Icon'
                            name='icon'
                            value={form.icon}
                            onChange={onChange}
                        />
                    </FormGroup>
                    <FormGroup className={classes.inputContainer}>
                        <FormLabel>{'Path'}</FormLabel>
                        <FilledInput
                            placeholder='Path'
                            name='path'

                            value={form.path}
                            onChange={onChange}
                        />
                    </FormGroup>
                    <FormGroup className={classes.inputContainer}>
                        <FormLabel>{'Visible'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                        >
                            <Select
                                name='visible'
                                value={form.visible}
                                onChange={onChange}
                            >
                                <MenuItem value={true}>{'Yes'}</MenuItem>
                                <MenuItem value={false}>{'No'}</MenuItem>
                            </Select>
                        </FormControl>
                    </FormGroup>
                </form>
                <div className={classes.buttonsContainer}>
                    <Button onClick={onClose} variant='contained' color='secondary' className={classes.button}>
                        {'Cancel'}
                    </Button>
                    <Button onClick={update} variant='contained' color='primary' className={classes.button}>
                        {'Update'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default Edit;