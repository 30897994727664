import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        marginBottom: 20,
    },
    button: {
        marginLeft: 15,
    },
    create: {
        marginLeft: 15,
        backgroundColor: '#198754',
        borderColor: '#198754',
        '&:hover': {
            backgroundColor: '#198754',
            borderColor: '#198754',
            boxShadow: 'none',
        },
    },
    store: {
        marginLeft: 15,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        },
    },
    update: {
        marginLeft: 15,
        backgroundColor: '#ffc107',
        borderColor: '#ffc107',
        '&:hover': {
            backgroundColor: '#ffc107',
            borderColor: '#ffc107',
            boxShadow: 'none',
        },
    },
    delete: {
        marginLeft: 15,
        backgroundColor: '#dc3545',
        borderColor: '#dc3545',
        '&:hover': {
            backgroundColor: '#dc3545',
            borderColor: '#dc3545',
            boxShadow: 'none',
        },
    },
}));

const Crud = ({ permissions, create, update, destroy, cancel }) => {

    const classes = useStyles();
    const [creating, setCreating] = useState(false);

    const onCancel = () => {
        cancel && cancel();

        setCreating(false);
    }

    const onCreate = () => {
        create?.create();
        setCreating(true);
    }

    const onStore = async () => {
        const success = await create?.store() ?? true;

        setCreating(!success);
    }

    const onUpdate = () => {
        update && update();

        setCreating(false);
    }

    const onDelete = () => {
        destroy && destroy();

        setCreating(false);
    }

    return (
        <div className={classes.root}>
            {
                !creating ?
                    <>
                        <Button
                            variant='contained'
                            color='secondary'
                            className={classes.button}
                            onClick={onCancel}
                        >
                            {'Cancel'}
                        </Button>
                        {
                            (permissions?.delete_attr && destroy) &&
                            <Button
                                variant='contained'
                                className={classes.delete}
                                onClick={onDelete}
                            >
                                {'Delete'}
                            </Button>
                        }
                        {
                            (permissions?.update_attr && update)&&
                            <Button
                                variant='contained'
                                className={classes.update}
                                onClick={onUpdate}
                            >
                                {'Update'}
                            </Button>
                        }
                        {
                            permissions?.create_attr &&
                            <Button
                                variant='contained'
                                className={classes.create}
                                onClick={onCreate}
                            >
                                {'Create'}
                            </Button>
                        }
                    </>
                    :
                    <>
                        <Button
                            variant='contained'
                            color='secondary'
                            className={classes.button}
                            onClick={onCancel}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            variant='contained'
                            className={classes.store}
                            onClick={onStore}
                        >
                            {'Store'}
                        </Button>
                    </>
            }
        </div>
    );
}

export default Crud;