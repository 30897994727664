import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, Icon, Accordion, AccordionSummary, AccordionDetails, Grid, FormGroup, FormControl, FormLabel, FilledInput, Box, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import NavBar from '../../common/nav-bar';
import ViewTitle from '../../common/view-title';

import AuthContext from '../../context';
import theme from '../../assets/theme';
import { get, post } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(3),
        paddingTop: 0
    },
    gridContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    cardDetail: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 0
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    headerInfo: {
        width: 150,
        textAlign: 'right',
        marginRight: 15,
        color: '#525f7f',
        fontWeight: 'bold'
    },
    update: {
        alignSelf: 'flex-start',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    generate: {
        marginTop: 15,
        alignSelf: 'flex-start',
        backgroundColor: '#198754',
        borderColor: '#198754',
        '&:hover': {
            backgroundColor: '#198754',
            borderColor: '#198754',
            boxShadow: 'none',
        }
    }
}));

const Profile = () => {

    const { signOut, showToast, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const [state, setState] = useState({
        loading: true,
        user: JSON.parse(localStorage.getItem('@auth_user')),
        form: {
            password: '',
            newPassword: '',
            confirm: ''
        },
        token: null
    });

    useEffect(() => {
        window.document.title = 'Profile';

        const makeRequest = async () => {
            const response = await get(`/user/${state.user.iduser}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, loading: false, user: response.data }));
            } else {
                console.log('[Profile] makeRequest error', response);
                helpers.failRequest(response, showToast, signOut);
            }
        }

        makeRequest();
    }, []);

    const handleChange = ({ target: { name, value } }) => setState(prev => ({ ...prev, form: { ...prev.form, [name]: value } }));

    const validateForm = () => {
        const { password, newPassword, confirm } = state.form;

        if (password.trim() && newPassword.trim() && confirm.trim()) {
            if (password.length >= 6 || newPassword >= 6 || confirm >= 6) {
                if (newPassword === confirm) {
                    updatePassword();
                } else {
                    showToast(`The new password don't match`, 'error');
                }
            } else {
                showToast('The new password must be 6 digits or more', 'error');
            }
        } else {
            showToast('All fields is required', 'error');
        }
    }

    const updatePassword = async () => {
        showLoader(true);
        const response = await post('/user/changePassword', state.form);
        if (response.status === 200) {
            showToast(response.message);
        } else {
            console.log('[Profile] updatePassword error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const getToken = async () => {
        showLoader(true);
        const response = await get('/auth/token');
        if (response.status === 200) {
            setState(prev => ({ ...prev, token: response.token }));
        } else {
            console.log('[Profile] getToken error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const copyToken = () => {
        navigator.clipboard.writeText(state.token);
        showToast('Token copy!', 'info', 3000);
    }

    return (
        <div className={classes.root}>
            <NavBar />
            <div className={classes.content}>
                <Grid container spacing={2} className={classes.gridContainer}>
                    <Grid item xl={7} md={8} xs={12}>
                        <ViewTitle title='Profile' />
                    </Grid>

                    <Grid item xl={7} md={8} xs={12}>
                        <Accordion defaultExpanded component={Card}>
                            <AccordionSummary expandIcon={<Icon>{'expand_more'}</Icon>}>
                                <h3>{'Profile detail'}</h3>
                            </AccordionSummary>
                            <AccordionDetails className={classes.cardDetail}>
                                <div className={classes.infoContainer}>
                                    <label className={classes.headerInfo}>{'Username:'}</label>
                                    <span>{state.user.username}</span>
                                </div>
                                <div className={classes.infoContainer}>
                                    <label className={classes.headerInfo}>{'Password:'}</label>
                                    <span>{'******'}</span>
                                </div>
                                <div className={classes.infoContainer}>
                                    <label className={classes.headerInfo}>{'Names:'}</label>
                                    <span>{state.user.names}</span>
                                </div>
                                <div className={classes.infoContainer}>
                                    <label className={classes.headerInfo}>{'Surnames:'}</label>
                                    <span>{state.user.surnames}</span>
                                </div>
                                <div className={classes.infoContainer}>
                                    <label className={classes.headerInfo}>{'Email:'}</label>
                                    <span>{state.user.email}</span>
                                </div>
                                <div className={classes.infoContainer}>
                                    <label className={classes.headerInfo}>{'Role:'}</label>
                                    <span>{state.user.role?.name}</span>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xl={7} md={8} xs={12}>
                        <Accordion component={Card}>
                            <AccordionSummary expandIcon={<Icon>{'expand_more'}</Icon>}>
                                <h3>{'Change password'}</h3>
                            </AccordionSummary>
                            <AccordionDetails className={classes.cardDetail}>
                                <FormGroup>
                                    <FormLabel>{'Current password'}</FormLabel>
                                    <FormControl
                                        variant='filled'
                                        component={Box}
                                        width='40%'
                                    >
                                        <FilledInput
                                            autoComplete='current-password'
                                            type='password'
                                            placeholder='Current password'
                                            name='password'
                                            value={state.form.password}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>{'New password'}</FormLabel>
                                    <FormControl
                                        variant='filled'
                                        component={Box}
                                        width='40%'
                                    >
                                        <FilledInput
                                            autoComplete='new-password'
                                            type='password'
                                            placeholder='New password'
                                            name='newPassword'
                                            value={state.form.newPassword}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>{'Confirm password'}</FormLabel>
                                    <FormControl
                                        variant='filled'
                                        component={Box}
                                        width='40%'
                                    >
                                        <FilledInput
                                            autoComplete='new-password'
                                            type='password'
                                            placeholder='Confirm password'
                                            name='confirm'
                                            value={state.form.confirm}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <Button
                                    variant='contained'
                                    className={classes.update}
                                    onClick={validateForm}
                                    type='submit'
                                >
                                    {'Update'}
                                </Button>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xl={7} md={8} xs={12}>
                        <Accordion component={Card}>
                            <AccordionSummary expandIcon={<Icon>{'expand_more'}</Icon>}>
                                <h3>{'Generate token'}</h3>
                            </AccordionSummary>
                            <AccordionDetails className={classes.cardDetail}>
                                {
                                    state.token &&
                                    <Alert
                                        severity='info'
                                        icon={false}
                                        action={
                                            <Button color='inherit' size='small' onClick={copyToken}>{'Copy'}</Button>
                                        }

                                        style={{ flexWrap: 'wrap', wordBreak: 'break-all' }}
                                    >
                                        {state.token}
                                    </Alert>
                                }
                                <Button
                                    variant='contained'
                                    className={classes.generate}
                                    onClick={getToken}
                                    type='submit'
                                >
                                    {'Generate'}
                                </Button>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default Profile;