import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal, FilledInput, Typography, Button, FormGroup, FormLabel, FormControl, Box, Grid } from '@material-ui/core';

import themeColors from '../../assets/colors';
import { put } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import AuthContext from '../../context';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.background.default,
        padding: 20,
        width: '80%',
        borderRadius: 10
    },
    update: {
        marginLeft: 15,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    bottom: {
        display: 'flex',
        alignSelf: 'flex-end',
    },
    restore: {
        marginLeft: 15,
        backgroundColor: '#A4A4A4',
        borderColor: '#A4A4A4',
        '&:hover': {
            backgroundColor: '#A4A4A4',
            borderColor: '#A4A4A4',
            boxShadow: 'none',
        }
    }
}));

const EditLabor = ({ open, onClose, labor }) => {

    const classes = useStyles();
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const [state, setState] = useState({
        code: '',
        loaded_piece_rate_unit: 0,
        supervision_unit: 0,
        irs_unit: 0,
        support_waste_unit: 0,
        h2b_expense_unit: 0
    });

    useEffect(() => {
        setState(prev => ({ ...prev, ...labor }));
    }, [labor]);

    const handleChange = ({ target }) => setState(prev => ({ ...prev, [target.name]: target.value }));

    const validateForm = () => {
        const { supervision_unit, irs_unit, support_waste_unit, h2b_expense_unit } = state;

        if (supervision_unit && irs_unit && support_waste_unit && h2b_expense_unit) {
            const cond = window.confirm('Confirm if all data is correct');
            if (cond) {
                update();
            }
        } else {
            showToast('All fields is required', 'error');
        }
    }

    const update = async () => {
        showLoader(true);
        const response = await put(`/recipe/${state.idproduct_sale_labor_ref}/updateLabor2`, state);
        if (response.status === 200) {
            showToast(response.message);
            onClose(response.data);
        } else {
            console.log('[EditLabor] update error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const restoreValues = async () => {
        const cond = window.confirm('Are you sure you want to restore to original values?');
        if (cond) {
            showLoader(true);
            const response = await put(`/laborParam/${state.idproduct_sale_labor_ref}/restoreValues`, { from: 'recipe' });
            if (response.status === 200) {
                showToast(response.message);
                onClose(response.data);
            } else {
                console.log('[EditLabor] restoreValues error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    return (
        <Modal
            open={open}
            onClose={() => onClose()}
            className={classes.root}
        >
            <div className={classes.container}>
                <Typography variant='h2'>{'Edit Labor'}</Typography>
                <Grid container>
                    <Grid item xs={12} lg={3}>
                        <FormGroup>
                            <FormLabel>{'Code'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    type='text'
                                    placeholder='Code'
                                    value={state?.labor?.code ?? ''}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Loaded piece rate'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Loaded piece rate'
                                    name='loaded_piece_rate_unit'
                                    value={state.loaded_piece_rate_unit}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'PS - Supervision'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='PS - Supervision'
                                    name='supervision_unit'
                                    value={state.supervision_unit}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'I - IRS'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='I - IRS'
                                    name='irs_unit'
                                    value={state.irs_unit}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'PW - Support & Waste'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='PW - Support & Waste'
                                    name='support_waste_unit'
                                    value={state.support_waste_unit}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'H - H2B Expense'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='H - H2B Expense'
                                    name='h2b_expense_unit'
                                    value={state.h2b_expense_unit}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
                <div className={classes.bottom}>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => onClose()}
                    >
                        {'Cancel'}
                    </Button>
                    {
                        state.edited &&
                        <Button
                            variant='contained'
                            className={classes.restore}
                            onClick={restoreValues}
                        >
                            {'Restore values'}
                        </Button>
                    }
                    <Button
                        variant='contained'
                        className={classes.update}
                        onClick={validateForm}
                    >
                        {'Update'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default EditLabor;