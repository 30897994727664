import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, IconButton, Icon } from '@material-ui/core';

import themeColors from '../../assets/colors';
import helpers from '../../utils/helpers';

const Module = ({ value, onEdit, onDestroy }) => {

    const isVisible = value.visible;
    const isAdmin = helpers.isAdmin();

    return (
        <Grid item lg={6} xs={12}>
            <Card style={{ backgroundColor: isAdmin ? themeColors.visible[isVisible] : '#fff' }}>
                <CardContent>
                    <Typography color='textSecondary'>{value.path}</Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Link to={value.path} style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                            <Icon>{value.icon}</Icon>
                            <Typography style={{ flexGrow: 1, marginLeft: 15, color: '#000' }}>{value.name}</Typography>
                        </Link>
                        {
                            isAdmin &&
                            <>
                                <IconButton onClick={onEdit}>
                                    <Icon>{'edit'}</Icon>
                                </IconButton>
                                <IconButton onClick={onDestroy}>
                                    <Icon>{'delete'}</Icon>
                                </IconButton>
                            </>
                        }
                    </div>
                </CardContent>
            </Card>
        </Grid>
    );
}

export default Module;