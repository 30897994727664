import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Modal,
    FilledInput,
    Typography,
    Button,
    FormGroup,
    FormLabel,
    FormControl,
    Box,
    Grid,
    InputAdornment
} from '@material-ui/core';
import dayjs from 'dayjs';

import themeColors from '../../assets/colors';
import { put } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import AuthContext from '../../context';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.background.default,
        padding: 20,
        width: '80%',
        borderRadius: 10
    },
    update: {
        marginLeft: 15,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    bottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    }
}));

const EditPrice = ({ price, open, onClose }) => {

    const classes = useStyles();
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const [state, setState] = useState({
        idprice: 0,
        date: dayjs().format('YYYY-MM-DD'),
        location: {},
        product: {},
        type: {},
        price: 0
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            idprice: price.idprice,
            date: dayjs(price.date).format('YYYY-MM-DD'),
            location: price.location,
            product: price.product,
            type: price.type,
            price: price.price
        }));
    }, [price]);

    const handleChange = e => {
        const { name, value } = e.target;

        setState(prev => ({ ...prev, [name]: value }));
    }

    const validateForm = () => {
        const { idprice, price } = state;

        (idprice && price) ? update() : showToast('Put a price', 'error');
    }

    const update = async () => {
        showLoader(true);
        const response = await put(`/price/${state.idprice}/update`, state);
        if (response.status === 200) {
            onClose(true);
            showToast(response.message);
        } else {
            console.log('[CreatePrices] update error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div className={classes.container}>
                <Typography variant='h2'>{'Edit price'}</Typography>
                <Grid container>
                    <Grid item xs={12} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Date'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    type='text'
                                    placeholder='Date'
                                    name='date'
                                    value={state.date}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Location'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    type='text'
                                    placeholder='Location'
                                    name='location'
                                    value={state.location?.name}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Product'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    type='text'
                                    placeholder='Product'
                                    name='product'
                                    value={state.product?.name}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Type'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    type='text'
                                    placeholder='Type'
                                    name='type'
                                    value={state.type?.name}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Price'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    type='number'
                                    placeholder='Price'
                                    startAdornment={<InputAdornment position='start'>{'$'}</InputAdornment>}
                                    name='price'
                                    value={state.price}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
                <div className={classes.bottom}>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={onClose}
                    >
                        {'Cancel'}
                    </Button>
                    <Button
                        variant='contained'
                        className={classes.update}
                        onClick={validateForm}
                    >
                        {'Update'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default EditPrice;