import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Icon, IconButton, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { DataGrid } from '@material-ui/data-grid';
import dayjs from 'dayjs';

import Loader from '../../common/loader';

import AuthContext from '../../context';
import helpers from '../../utils/helpers';
import { get } from '../../utils/api-services';

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    button: {
        alignSelf: 'flex-end',
        marginBottom: 20,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    tableContainer: {
        width: '100%',
        '& .highlight': {
            backgroundColor: '#EFF2FB'
        }
    }
}));

const TicketSedeTransferIndex = ({ permissions }) => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const [state, setState] = useState({
        loading: true,
        data: [],
        permissions: {}
    });

    useEffect(() => {
        setState(prev => ({ ...prev, permissions }));
    }, [permissions]);

    useEffect(() => {
        window.document.title = 'Ticket sede transfer';

        makeRequest();
    }, []);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/invTicketSedeTransfer');
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                data: response?.data ?? [],
            }));
        } else {
            console.log('[TicketSedeTransferIndex] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const renderDate = params => dayjs(params.value).format('MM/DD/YYYY');

    const valueGetter = params => params.value.name;

    const renderCell = ({ row }) => {
        return (
            <Tooltip title='Show detail' placement='right'>
                <Link to={`/inventory/invTicketSedeTransfer/${row.idinv_ticket_sede_transfer}`} target='_blank'>
                    <IconButton>
                        <Icon>{'visibility'}</Icon>
                    </IconButton>
                </Link>
            </Tooltip>
        );
    }

    const columns = [
        { field: 'date', headerName: 'Date', flex: 1, minWidth: 160, valueGetter: renderDate },
        { field: 'origin', headerName: 'Origin', flex: 1, minWidth: 160, valueGetter },
        { field: 'destination', headerName: 'Destination', flex: 1, minWidth: 160, valueGetter },
        { field: 'status', headerName: 'Status', flex: 0.7, minWidth: 112 },
        { field: 'total_tickets', headerName: 'Tickets', flex: 1, minWidth: 160 },
        { field: 'notes', headerName: 'Notes', flex: 1, minWidth: 160 },
        { field: 'detail', headerName: 'Detail', flex: 0.7, minWidth: 112, renderCell }
    ]

    if (state.loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <Button
                variant='contained'
                className={classes.button}
                component={Link}
                to={'/inventory/invTicketSedeTransfer/create'}
            >
                {'Start transfer'}
            </Button>

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.data}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idinv_ticket_sede_transfer}
                    getRowClassName={({ row }) => (row?.status === 'In Transit') ? 'highlight' : ''}
                />
            </div>
        </div>
    );
}

export default TicketSedeTransferIndex;