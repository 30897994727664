import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormLabel, FormGroup, FormControl, FilledInput, Button, TextField, Tooltip } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import dayjs from 'dayjs';
import XLSX from 'xlsx';

import Loader from '../../common/loader';
import NotFound from '../../common/not-found';
import ViewTitle from '../../common/view-title';
import Avoided from '../components/avoided';
import DateRange from '../../common/date-range';

import AuthContext from '../../context';
import helpers from '../../utils/helpers';
import { del, get, post } from '../../utils/api-services';

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 20
    },
    buttonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        marginBottom: 20
    },
    import: {
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    delete: {
        marginLeft: 20,
        backgroundColor: '#dc3545',
        borderColor: '#dc3545',
        '&:hover': {
            backgroundColor: '#dc3545',
            borderColor: '#dc3545',
            boxShadow: 'none',
        }
    },
    search: {
        marginLeft: 20,
        backgroundColor: '#198754',
        borderColor: '#198754',
        '&:hover': {
            backgroundColor: '#198754',
            borderColor: '#198754',
            boxShadow: 'none',
        }
    },
    tableContainer: {
        width: '100%'
    }
}));

const EmployeeSchedule = () => {
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        loading: true,
        employees: [],
        data: [],
        filter: {
            startDate: dayjs().subtract(2, 'day').format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
            dateRange: `${dayjs().subtract(2, 'day').format('MM/DD/YYYY')} - ${dayjs().format('MM/DD/YYYY')}`,
            employee: { id: 0, name: 'All employees' }
        },
        rangeModal: {
            open: false,
            range: [
                {
                    startDate: new Date(dayjs().subtract(2, 'day').toDate()),
                    endDate: new Date(),
                    key: 'main'
                }
            ]
        },
        fileData: [],
        avoided: [],
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Employee schedule';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/employeeSchedule', state.filter);
        if (response.status === 200) {
            setState(prev => ({ ...prev, loading: false, data: response.data, employees: response.employees }));
        } else {
            console.log('[EmployeeSchedule] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const handleFile = async ({ target }) => {
        showLoader(true);

        try {
            const file = target.files[0];
            const buffer = await file.arrayBuffer();
            const unit8 = new Uint8Array(buffer);
            const workbook = XLSX.read(unit8, { type: 'array' });

            const firstSheetName = workbook.SheetNames[0]
            /* Get worksheet */
            const worksheet = workbook.Sheets[firstSheetName]
            const info = XLSX.utils.sheet_to_json(worksheet, { raw: true });
            const data = [];
            info.map(item => {
                if (item['Number']) {
                    data.push(item);
                }

                return false;
            });

            console.log(data);
            (data.length < 1) ? showToast('File format not supported', 'error') : setState(prev => ({ ...prev, fileData: data, avoided: [] }));

        } catch (e) {
            console.log(e);
            showToast('Something went wrong', 'error');
        }
        showLoader(false);
    }

    const handleDateChange = item => {
        const { startDate, endDate } = item.main;
        const dateRange = `${dayjs(startDate.toISOString()).format('MM/DD/YYYY')} - ${dayjs(endDate.toISOString()).format('MM/DD/YYYY')}`;
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                dateRange,
                startDate: dayjs(startDate.toISOString()).format('YYYY-MM-DD'),
                endDate: dayjs(endDate.toISOString()).format('YYYY-MM-DD')
            },
            rangeModal: { ...prev.rangeModal, range: [item.main] }
        }));
    }

    const handleModal = () => setState(prev => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));

    const handleAutocomplete = (key, value) => setState(prev => ({ ...prev, filter: { ...prev.filter, [key]: value } }));

    const importData = async () => {
        const { fileData, filter } = state;

        alert('be careful not to import the same file again unless an error has occurred');
        showLoader(true);
        const response = await post('/employeeSchedule/import', { ...filter, fileData });
        if (response.status === 200) {
            setState(prev => ({ ...prev, data: response.data, avoided: response.avoided }));
            if (response.avoided?.length > 0) {
                setOpen(true);
            }
            showToast(response.message)
        } else {
            console.log('[EmployeeSchedule] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const search = async () => {
        const { filter } = state;

        showLoader(true);
        const response = await get('/employeeSchedule/search', filter);
        if (response.status === 200) {
            setState(prev => ({ ...prev, data: response.data }));
            if (!response?.data[0]) showToast('There are no data with these filters', 'warning');
        } else {
            console.log('[EmployeeSchedule] search error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const destroy = async () => {
        const { startDate, endDate, employee } = state.filter;

        const cond = window.confirm(`This will delete data from ${startDate} to ${endDate} for ${employee.name}. \n\nThis action cannot be reversed. Do you want to continue?`);
        if (cond) {
            showLoader(true);
            const response = await del('/employeeSchedule', state.filter);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: [] }));
            } else {
                console.log('[EmployeeSchedule] desroy error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        }
    }

    const getDate = params => dayjs(params.value).format('MM/DD/YYYY');
    const getEmployee = params => params.value?.name;

    const columns = [
        { field: 'date', headerName: 'Date', flex: 1, minWidth: 130, valueGetter: getDate },
        { field: 'employee', headerName: 'Employee', flex: 2, minWidth: 220, valueGetter: getEmployee },
        { field: 'hours', headerName: 'Hours', flex: 1, minWidth: 130 }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle title='Employee schedule' />

            <Grid container>
                <Grid item sm={6} md={6} lg={6}>
                    <FormGroup>
                        <FormLabel>{'Upload file'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='file'
                                onChange={handleFile}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup onClick={handleModal}>
                        <FormLabel>{'Date range'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                readOnly
                                type='text'
                                placeholder='Date range'
                                value={state.filter.dateRange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Employee'}</FormLabel>
                        <Autocomplete
                            options={state.employees}
                            value={state.filter.employee}
                            getOptionLabel={employee => employee?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleAutocomplete('employee', value)}
                            renderInput={params => <TextField {...params} placeholder='Employee' />}
                        />
                    </FormGroup>
                </Grid>
            </Grid>

            <div className={classes.buttonsContainer}>
                {
                    state.permissions.create_attr &&
                    <Button
                        variant='contained'
                        className={classes.import}
                        disabled={state.fileData.length < 1}
                        onClick={importData}
                    >
                        {'Import data'}
                    </Button>
                }
                {
                    state.permissions.delete_attr &&
                    <Tooltip title='This button will delete the data based on the fields above.' placement='top'>
                        <Button
                            variant='contained'
                            className={classes.delete}
                            onClick={destroy}
                        >
                            {'Delete'}
                        </Button>
                    </Tooltip>
                }
                <Button
                    variant='contained'
                    className={classes.search}
                    onClick={search}
                >
                    {'Search'}
                </Button>
            </div>

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.data}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idemployee_schedule}
                />
            </div>

            <Avoided open={open} onClose={() => setOpen(false)} array={state.avoided} />

            <DateRange
                open={state.rangeModal.open}
                range={state.rangeModal.range}
                onClose={handleModal}
                onChange={handleDateChange}
            />
        </div>
    );
}

export default EmployeeSchedule;