import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormLabel, FormGroup, FormControl, FilledInput, TextField, Button, IconButton, Icon, Tooltip } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLocation } from 'react-router-dom';
import ReactExport from 'react-data-export';
import dayjs from 'dayjs';

import Loader from '../../common/loader';
import NotFound from '../../common/not-found';
import DateRange from '../../common/date-range';
import ViewTitle from '../../common/view-title';
import CreatePrice from '../components/create-price';
import EditPrice from '../components/edit-price';
import EditPrices from '../components/edit-prices';

import AuthContext from '../../context';
import { del, get } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 20
    },
    search: {
        marginRight: 15,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none'
        }
    },
    create: {
        marginLeft: 15,
        backgroundColor: '#198754',
        borderColor: '#198754',
        color: '#FFF',
        '&:hover': {
            backgroundColor: '#198754',
            borderColor: '#198754',
            boxShadow: 'none'
        }
    },
    download: {
        backgroundColor: '#6E6E6E',
        borderColor: '#6E6E6E',
        '&:hover': {
            backgroundColor: '#6E6E6E',
            borderColor: '#6E6E6E',
            boxShadow: 'none'
        }
    }
}));

const Prices = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [showCreate, setShowCreate] = useState(false);
    const [editModal, setEditModal] = useState({ open: false, price: {} });
    const [selectedPrices, setSelectedPrices] = useState([]);
    const [state, setState] = useState({
        loading: true,
        locations: [],
        products: [],
        prices: [],
        excelData: [],
        filter: {
            startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
            dateRange: `${dayjs().subtract(1, 'day').format('M/DD/YYYY')} - ${dayjs().format('M/DD/YYYY')}`,
            location: { id: 0, name: 'All' },
            product: { id: 0, name: 'All' }
        },
        rangeModal: {
            open: false,
            range: [
                {
                    startDate: new Date(dayjs().subtract(1, 'day').toDate()),
                    endDate: new Date(),
                    key: 'main'
                }
            ]
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Prices';

        validatePermissions();
    }, []);

    useEffect(() => {
        const excelData = [];

        state.prices.forEach(item => {
            const location = item.location.name;
            const product = item.product.name;
            const type = item.type.name;
            const sede = item.sede.name;

            excelData.push({ ...item, location, product, type, sede });
        });

        setState(prev => ({ ...prev, excelData }));
    }, [state.prices]);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/prices', state.filter);
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                prices: response?.data ?? prev.prices,
                locations: response?.locations ?? prev.locations,
                products: response?.products ?? prev.products,
                filter: {
                    ...prev.filter,
                    location: response?.locations[0],
                    product: response?.products[0]
                }
            }));
        } else {
            console.log('[Prices] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const search = async () => {
        showLoader(true);
        const response = await get('/prices', state.filter);
        if (response.status === 200) {
            setState(prev => ({ ...prev, prices: response?.data ?? prev.prices }));
            if (!response?.data[0]) showToast('There are no prices with these filters', 'warning');
        } else {
            console.log('[Prices] search error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const validateForm = (option, price) => {
        const { idprice } = price;

        switch (option) {
            case 'delete':
                if (idprice === 0) {
                    showToast('Select some price', 'error');
                    return false;
                }
                const cond = window.confirm(`Are you sure you want to delete this price?`);

                return Boolean(idprice && cond);
            default:
                break;
        }
    }

    const edit = price => setEditModal({ price, open: true });

    const destroy = async price => {
        const { idprice } = price;
        const cond = validateForm('delete', price);

        if (cond) {
            showLoader(true);
            const response = await del(`/price/${idprice}`);
            if (response.status === 200) {
                const prices = state.prices.filter((value) => idprice !== value.idprice);

                setState(prev => ({ ...prev, prices }));
                showToast(response.message);
            } else {
                console.log('[Prices] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const handleDateChange = item => {
        const { startDate, endDate } = item.main;
        const dateRange = `${dayjs(startDate.toISOString()).format('MM/DD/YYYY')} - ${dayjs(endDate.toISOString()).format('MM/DD/YYYY')}`;
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                dateRange,
                startDate: dayjs(startDate.toISOString()).format('YYYY-MM-DD'),
                endDate: dayjs(endDate.toISOString()).format('YYYY-MM-DD')
            },
            rangeModal: { ...prev.rangeModal, range: [item.main] }
        }));
    }

    const handleModal = () => {
        setState(prev => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));
    }

    const handleAutocomplete = (key, value) => setState(prev => ({ ...prev, filter: { ...prev.filter, [key]: value } }));

    const valueGetter = params => params.value.name;

    const getDateField = params => dayjs(params.value).format('MM/DD/YYYY');

    const getValueField = params => `$ ${params.value}`;

    const renderCell = params => {
        return (
            <>
                {
                    state.permissions?.update_attr &&
                    <Tooltip title='Edit' placement='left'>
                        <IconButton onClick={() => edit(params.row)}>
                            <Icon>{'edit'}</Icon>
                        </IconButton>
                    </Tooltip>
                }
                {
                    state.permissions?.delete_attr &&
                    <Tooltip title='Delete' placement='right'>
                        <IconButton onClick={() => destroy(params.row)}>
                            <Icon>{'delete'}</Icon>
                        </IconButton>
                    </Tooltip>
                }
            </>
        );
    }

    const columns = [
        { field: 'date', headerName: 'Date', flex: 1, minWidth: 150, valueGetter: getDateField },
        { field: 'location', headerName: 'Location', flex: 1, minWidth: 180, valueGetter },
        { field: 'product', headerName: 'Product', flex: 1, minWidth: 180, valueGetter },
        { field: 'type', headerName: 'Type', flex: 1, minWidth: 180, valueGetter },
        { field: 'price', headerName: 'Price', flex: 1, minWidth: 120, valueGetter: getValueField },
        { field: 'actions', headerName: 'Actions', flex: 1, minWidth: 120, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle sedeFlag title='Prices' />
            <Grid container>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup onClick={handleModal}>
                        <FormLabel>{'Date range'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                readOnly
                                type='text'
                                placeholder='Date range'
                                value={state.filter.dateRange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Location'}</FormLabel>
                        <Autocomplete
                            options={state.locations}
                            value={state.filter.location}
                            getOptionLabel={location => location?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleAutocomplete('location', value)}
                            renderInput={params => <TextField {...params} placeholder='Type' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Product'}</FormLabel>
                        <Autocomplete
                            options={state.products}
                            value={state.filter.product}
                            getOptionLabel={product => product?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleAutocomplete('product', value)}
                            renderInput={params => <TextField {...params} placeholder='Product' />}
                        />
                    </FormGroup>
                </Grid>
            </Grid>

            <div className={classes.buttonContainer}>
                <Button variant='contained' className={classes.search} onClick={search}>{'Search'}</Button>
                {
                    state.permissions?.update_attr &&
                    <EditPrices prices={selectedPrices} onRefresh={search} />
                }
                {
                    state.permissions?.create_attr &&
                    <Button variant='contained' className={classes.create} onClick={() => setShowCreate(true)}>{'Create'}</Button>
                }
            </div>

            <div className={classes.buttonContainer}>
                {
                    state.excelData.length > 0 &&
                    <ExcelFile
                        filename={`${dayjs().format('YYYY-MM-DD')}-prices-report`}
                        element={<Button variant='contained' className={classes.download}>{'Download data'}</Button>}
                    >
                        <ExcelSheet data={state.excelData} name='Prices report'>
                            <ExcelColumn label='Date' value='date' />
                            <ExcelColumn label='Price' value='price' />
                            <ExcelColumn label='Location' value='location' />
                            <ExcelColumn label='Product' value='product' />
                            <ExcelColumn label='Type' value='type' />
                            <ExcelColumn label='Sede' value='sede' />
                        </ExcelSheet>
                    </ExcelFile>
                }
            </div>

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.prices}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idprice}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, price: row }))}
                    checkboxSelection
                    disableSelectionOnClick
                    onSelectionModelChange={prices => setSelectedPrices(prices)}
                />
            </div>

            <DateRange
                open={state.rangeModal.open}
                range={state.rangeModal.range}
                onClose={handleModal}
                onChange={handleDateChange}
            />

            <CreatePrice
                open={showCreate}
                onClose={(srch = false) => {
                    (srch === true) && search();
                    setShowCreate(false);
                }}
                arrays={{ locations: state.locations, products: state.products }}
            />

            <EditPrice
                open={editModal.open}
                onClose={(srch = false) => {
                    (srch === true) && search();
                    console.log(srch);
                    setEditModal(prev => ({ ...prev, open: false }));
                }}
                price={editModal.price}
            />
        </div>
    );
}

export default Prices;