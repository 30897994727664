import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    header: {
        margin: 0,
        padding: 15
    },
    title: {
        margin: 0,
        marginBottom: 0
    },
    closeButton: {
        position: 'absolute',
        right: 15,
        top: 15,
        color: 'grey'
    },
    container: {
        padding: 10
    },
    clear: {
        backgroundColor: '#A4A4A4',
        borderColor: '#A4A4A4',
        '&:hover': {
            backgroundColor: '#A4A4A4',
            borderColor: '#A4A4A4',
            boxShadow: 'none'
        }
    }
}));

const CustomDialog = ({ open, handleClose, content }) => {

    const classes = useStyles();

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle className={classes.header}>
                <h1 className={classes.title}>{'Alert'}</h1>
                {handleClose ? (
                    <IconButton className={classes.closeButton} onClick={handleClose}>
                        <Close />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent dividers className={classes.container}>
                <p>{content}</p>
            </DialogContent>
            <DialogActions className={classes.container}>
                <Button
                    autoFocus
                    variant='contained'
                    className={classes.clear}
                    onClick={handleClose}
                >
                    {'Ok'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CustomDialog;