import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, FormLabel, FormGroup, TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactExport from 'react-data-export';

import Loader from '../../common/loader';

import AuthContext from '../../context';
import helpers from '../../utils/helpers';
import { get } from '../../utils/api-services';
import ViewTitle from '../../common/view-title';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    buttonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        marginBottom: 20
    },
    search: {
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    download: {
        marginRight: 20,
        backgroundColor: '#6E6E6E',
        borderColor: '#6E6E6E',
        '&:hover': {
            backgroundColor: '#6E6E6E',
            borderColor: '#6E6E6E',
            boxShadow: 'none'
        }
    }
}));

const ReportEmployees = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const [state, setState] = useState({
        loading: true,
        CC1: [],
        CC2: [],
        CC3: [],
        filter: {
            cc1: { id: 0, name: 'All CC1' },
            cc2: { id: 0, name: 'All CC2' },
            cc3: { id: 0, name: 'All CC3' }
        },
        data: []
    });

    useEffect(() => {
        makeRequest();
    }, []);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/reports/indexEmployees', state.filter);
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                CC1: response?.cc1 ?? [],
                CC2: response?.cc2 ?? [],
                CC3: response?.cc3 ?? []
            }));
        } else {
            console.log('[ReportEmployees] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const handleChangeAutocomplete = (key, value) => setState(prev => ({ ...prev, filter: { ...prev.filter, [key]: value } }));

    const search = async () => {
        showLoader(true);
        const response = await get('/reports/searchEmployees', state.filter);
        if (response.status === 200) {
            const data = response?.data ?? [];
            setState(prev => ({ ...prev, data }));

            if (!response?.data[0]) showToast('There are no data with these filters', 'warning');
        } else {
            console.log('[ReportEmployees] search error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    if (state.loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle title='Employees' />
            <Grid container>
                <Grid item xs={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Cost center 1'}</FormLabel>
                        <Autocomplete
                            options={state.CC1}
                            value={state.filter.cc1}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleChangeAutocomplete('cc1', value)}
                            renderInput={params => <TextField {...params} placeholder='CC1' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Cost center 2'}</FormLabel>
                        <Autocomplete
                            options={state.CC2}
                            value={state.filter.cc2}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleChangeAutocomplete('cc2', value)}
                            renderInput={params => <TextField {...params} placeholder='CC2' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Cost center 3'}</FormLabel>
                        <Autocomplete
                            options={state.CC3}
                            value={state.filter.cc3}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleChangeAutocomplete('cc3', value)}
                            renderInput={params => <TextField {...params} placeholder='CC3' />}
                        />
                    </FormGroup>
                </Grid>
            </Grid>

            <div className={classes.buttonsContainer}>
                {
                    state.data.length > 0 &&
                    <ExcelFile
                        filename={`${dayjs().format('YYYY-MM-DD')}-employees`}
                        element={<Button variant='contained' className={classes.download}>{'Download data'}</Button>}
                    >
                        <ExcelSheet data={state.data} name='Employee report'>
                            <ExcelColumn label='Code' value='code' />
                            <ExcelColumn label='Name' value='name' />
                            <ExcelColumn label='Base rate' value='base_rate' />
                            <ExcelColumn label='Indicator' value='indicator' />
                            <ExcelColumn label='Sede code' value='sede.code' />
                            <ExcelColumn label='Sede name' value='sede.name' />
                            <ExcelColumn label='Is supplier' value='is_supplier' />
                            <ExcelColumn label='Payment' value='payment' />
                            <ExcelColumn label='Regular overtime' value='regular_overtime' />
                            <ExcelColumn label='Bonus payment' value='bonus_payment' />
                            <ExcelColumn label='CC1' value='cc1.name' />
                            <ExcelColumn label='CC2' value='cc2.name' />
                            <ExcelColumn label='CC3' value='cc3.name' />
                        </ExcelSheet>
                    </ExcelFile>
                }

                <Button
                    variant='contained'
                    className={classes.search}
                    onClick={search}
                >
                    {'Search'}
                </Button>
            </div>
        </div>
    );
}

export default ReportEmployees;