import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, Select, MenuItem, Checkbox, FormControlLabel, Tooltip, IconButton, Icon } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation, Link } from 'react-router-dom';

import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import Loader from '../../common/loader';
import Trash from '../../common/trash';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%',
    }
}));

const OptionRoles = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation()
    const [state, setState] = useState({
        loading: true,
        roles: [],
        optionModules: [],
        options: [],
        option: {
            idoption_role: 0,
            create_attr: false,
            read_attr: false,
            update_attr: false,
            delete_attr: false,
            role: {},
            option: {}
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Option roles';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions?.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/optionRoles');
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                options: response?.data ?? [],
                roles: response?.roles ?? [],
                optionModules: response?.optionModules ?? [],
                option: {
                    ...prev.option,
                    role: response?.roles[0] ?? {},
                    option: response?.optionModules[0] ?? {}
                }
            }));
        } else {
            console.log('[OptionRoles] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, option: { ...prev.option, [target.name]: target.checked } }));

    const validateForm = (type) => {
        const { idoption_role, role, option } = state.option;

        switch (type) {
            case 'update':
                const data = state.options.filter(value => value.idoption_role === idoption_role);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.option);
                    if (before !== after) {
                        if (role.id && option.id) {
                            return true;
                        } else {
                            showToast('All fields is required', 'error');
                            return false;
                        }
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select a data', 'error');
                    return false;
                }

            case 'store':

                if (role.id && option.id) {
                    return true;
                } else {
                    showToast('All fields is required', 'error');
                    return false;
                }

            case 'delete':
                if (idoption_role === 0) {
                    showToast('Select a data', 'error');
                    return false;
                }
                const cond = window.confirm('Are you sure you want to delete this option role?');

                return Boolean(idoption_role && cond);
            default:
                break;
        }
    }

    const clear = () => {
        setState(prev => ({
            ...prev,
            option: {
                ...prev.option,
                idoption_role: 0,
                create_attr: false,
                read_attr: false,
                update_attr: false,
                delete_attr: false,
                role: prev.roles[0],
                option: prev.optionModules[0]
            }
        }));
    }

    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/optionRole/store', { ...state.option });
            if (response.status === 200) {
                setState(prev => ({ ...prev, options: response?.data ?? prev.options }));
                showToast(response.message);
            } else {
                console.log('[OptionRoles] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false;
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { idoption_role } = state.option;

        const cond = validateForm('update');
        if (cond) {
            showLoader(true);
            const response = await put(`/optionRole/${idoption_role}/update`, { ...state.option });
            if (response.status === 200) {
                setState(prev => ({ ...prev, options: response?.data ?? prev.options }));
                showToast(response.message);
            } else {
                console.log('[OptionRoles] update error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idoption_role } = state.option;

        const cond = validateForm('delete');
        if (cond) {
            showLoader(true);
            const response = await del(`/optionRole/${idoption_role}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, options: response?.data ?? prev.options }));
                showToast(response.message);
            } else {
                console.log('[OptionRoles] destroy error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        }
    }

    const valueGetter = ({ row, field }) => row[field] ? '✓' : '✗';

    const valueGetterObj = ({ value }) => value.name;

    const renderCell = ({ row }) => {
        return (
            <Link to={`/history/option_roles/${row.idoption_role}`} target='_blank'>
                <Tooltip title='Show history' placement='top'>
                    <IconButton>
                        <Icon>{'history'}</Icon>
                    </IconButton>
                </Tooltip>
            </Link>
        );
    }

    const columns = [
        { field: 'role', headerName: 'Role', flex: 1, minWidth: 180, valueGetter: valueGetterObj },
        { field: 'option', headerName: 'Module', flex: 1, minWidth: 240, valueGetter: valueGetterObj },
        { field: 'create_attr', headerName: 'Create', minWidth: 120, valueGetter },
        { field: 'read_attr', headerName: 'Read', minWidth: 120, valueGetter },
        { field: 'update_attr', headerName: 'Update', minWidth: 120, valueGetter },
        { field: 'delete_attr', headerName: 'Delete', minWidth: 120, valueGetter },
        { field: 'actions', headerName: 'Actions', flex: 0.5, renderCell }
    ];

    if (state.loading) {
        return <Loader />
    }

    if (!state.permissions?.read_attr) {
        return <NotFound />
    }

    return (
        <div className={classes.root}>
            <h1>{'Option roles'}</h1>
            <div>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <FormGroup aria-label='position' row style={{ justifyContent: 'space-between' }}>
                            <FormControlLabel
                                checked={state.option.create_attr}
                                control={<Checkbox color='primary' />}
                                label='Create'
                                labelPlacement='top'
                                name='create_attr'
                                onChange={handleChange}
                            />
                            <FormControlLabel
                                checked={state.option.read_attr}
                                control={<Checkbox color='primary' />}
                                label='Read'
                                labelPlacement='top'
                                name='read_attr'
                                onChange={handleChange}
                            />
                            <FormControlLabel
                                checked={state.option.update_attr}
                                control={<Checkbox color='primary' />}
                                label='Update'
                                labelPlacement='top'
                                name='update_attr'
                                onChange={handleChange}
                            />
                            <FormControlLabel
                                checked={state.option.delete_attr}
                                control={<Checkbox color='primary' />}
                                label='Delete'
                                labelPlacement='top'
                                name='delete_attr'
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <FormGroup>
                            <FormLabel>{'Role'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <Select
                                    value={state.option.role.id}
                                    onChange={({ target }) => setState(prev => ({ ...prev, option: { ...prev.option, role: { id: target.value } } }))}
                                >
                                    {
                                        state.roles.map(value => (
                                            <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <FormGroup>
                            <FormLabel>{'Module'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <Select
                                    value={state.option.option.id}
                                    onChange={({ target }) => setState(prev => ({ ...prev, option: { ...prev.option, option: { id: target.value } } }))}
                                >
                                    {
                                        state.optionModules.map(value => (
                                            <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
            </div>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            {
                state.permissions.delete_attr &&
                <Trash table='option_roles' id='idoption_role' onRefresh={makeRequest} />
            }

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.options}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idoption_role}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, option: row }))}
                />
            </div>
        </div>
    );
}

export default OptionRoles;