import React, { useState } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Toolbar } from "@material-ui/core";

import Parent from "../../common/parent";
import SelectPage from "../../common/select-page";
import NotFound from "../../common/not-found";
import Settings from "../../common/module-settings";

import BillsAndCuttingTickets from "./bills-cutting-tickets";
import Payments from "./payments";
import DeliveryTickets from "./delivery-tickets";
import Loader from "./loaders";
import Customer from "./customers";
import Trucker from "./truckers";
import Products from "./products";
import Reports from "./reports";
import Items from "./items";

const useStyles = makeStyles((theme) => ({
	content: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		padding: theme.spacing(3),
		paddingTop: 0,
	},
}));

export default function FarmForestParent() {
	const classes = useStyles();
	const { path } = useRouteMatch();
	const [module, setModule] = useState(null);

	const update = (module) => {
		if (module) {
			setModule(module);
		}
	};

	return (
		<Parent module={module} updateModule={update}>
			<main className={classes.content}>
				<Toolbar />
				<Switch>
					<Route exact path={path} render={(props) => <SelectPage />} />
					<Route path={`${path}/billsAndCuttingTickets`} render={(props) => <BillsAndCuttingTickets {...props} />} />
					<Route path={`${path}/deliveryTickets`} render={(props) => <DeliveryTickets {...props} />} />
					<Route path={`${path}/loader`} render={(props) => <Loader {...props} />} />
					<Route path={`${path}/payments`} render={(props) => <Payments {...props} />} />
					<Route path={`${path}/customer`} render={(props) => <Customer {...props} />} />
					<Route path={`${path}/trucking`} render={(props) => <Trucker {...props} />} />
					<Route path={`${path}/ffproducts`} render={(props) => <Products {...props} />} />
					<Route path={`${path}/ffitems`} render={(props) => <Items {...props} />} />
					<Route path={`${path}/ffreports`} render={(props) => <Reports {...props} />} />
					<Route path={`${path}/settings`} render={(props) => <Settings {...props} module={module} updateModule={update} />} />
					<Route path={`${path}/*`} component={NotFound} />
				</Switch>
			</main>
		</Parent>
	);
}
