import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Button, Select, MenuItem, Divider, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import Dialog from '../../common/dialog';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import themeColors from '../../assets/colors';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        width: '100%',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    discarted: {
        width: '100%',
        backgroundColor: '#A901DB',
        borderColor: '#A901DB',
        '&:hover': {
            backgroundColor: '#A901DB',
            borderColor: '#A901DB',
            boxShadow: 'none',
        }
    },
    inStorage: {
        width: '100%',
        backgroundColor: '#0080FF',
        borderColor: '#0080FF',
        '&:hover': {
            backgroundColor: '#0080FF',
            borderColor: '#0080FF',
            boxShadow: 'none',
        }
    },
    divider: {
        marginBottom: 24
    },
    tableContainer: {
        width: '100%'
    },
    special_button: {
        backgroundColor: '#642EFE',
        borderColor: '#642EFE',
        '&:hover': {
            backgroundColor: '#642EFE',
            borderColor: '#642EFE',
            boxShadow: 'none'
        }
    },
    priority_1: {
        backgroundColor: themeColors.process_status._1,
        '&:hover': {
            backgroundColor: helpers.hexToRGBA(themeColors.process_status._1, 0.6),
            boxShadow: 'none'
        }
    },
    priority_2: {
        backgroundColor: themeColors.process_status._2,
        '&:hover': {
            backgroundColor: helpers.hexToRGBA(themeColors.process_status._2, 0.6),
            boxShadow: 'none'
        }
    },
    priority_3: {
        backgroundColor: themeColors.process_status._3,
        '&:hover': {
            backgroundColor: helpers.hexToRGBA(themeColors.process_status._3, 0.6),
            boxShadow: 'none'
        }
    },
    priority_4: {
        backgroundColor: themeColors.process_status._4,
        '&:hover': {
            backgroundColor: helpers.hexToRGBA(themeColors.process_status._4, 0.6),
            boxShadow: 'none'
        }
    },
    priority_5: {
        backgroundColor: themeColors.process_status._5,
        '&:hover': {
            backgroundColor: helpers.hexToRGBA(themeColors.process_status._5, 0.6),
            boxShadow: 'none'
        }
    },
    priority_6: {
        backgroundColor: themeColors.process_status._6,
        '&:hover': {
            backgroundColor: helpers.hexToRGBA(themeColors.process_status._6, 0.6),
            boxShadow: 'none'
        }
    },
    priority_7: {
        backgroundColor: themeColors.process_status._7,
        '&:hover': {
            backgroundColor: helpers.hexToRGBA(themeColors.process_status._7, 0.6),
            boxShadow: 'none'
        }
    }
}));

const ProcessDetail = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const location = useLocation();
    const [state, setState] = useState({
        loading: true,
        code: '',
        ticket: {},
        detail: {},
        details: [],
        employees: [],
        items: [],
        processes: [],
        process: {
            idticket_process: 0,
            date: dayjs().format('YYYY-MM-DD'),
            qty_processed: 0,
            total_qty: 0,
            pending_qty: 0,
            employee: { id: 0, name: 'Choose an employee' },
            idemployee: 0,
            item: { id: 0, name: 'Choose an item' },
            iditem: 0
        },
        dialog: {
            open: false,
            content: ''
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Ticket process';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(location.pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        const code = location.state?.code ?? '';

        const response = await get('/process/detail', { code });
        if (response.status === 200) {
            const newState = { code, loading: false, employees: response.employees, items: response.items };
            if (code) {
                newState.ticket = response.ticket;
                newState.details = response.details;
            }
            setState(prev => ({ ...prev, ...newState }));
        } else {
            console.log('[ProcessDetail] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
    }

    const getTicket = async () => {
        const { code } = state;

        if (code.trim()) {
            showLoader(true);
            const response = await get(`/process/${code}`);
            if (response.status === 200) {
                const ticket = response.ticket;
                const details = response.details;
                setState(prev => ({ ...prev, ticket, details }));
            } else {
                console.log('[ProcessDetail] getTicket error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        }
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, [target.name]: target.value }));

    const handleAutocomplete = (key, value) => {
        const detailItem = state.detail?.item;
        const item = state.items.find(value => value?.id === detailItem?.id);
        let process = { item };

        setState(prev => ({ ...prev, process: { ...prev.process, ...process, [key]: value } }));
    }

    const handleSelect = async ({ target }) => {
        const detail = state.details.find(item => item.id === target.value);

        if (detail !== undefined) {
            showLoader(true);
            clear();
            const response = await get(`/processes/${detail.id}`);
            if (response) {
                const processes = response.data;

                setState(prev => ({ ...prev, detail, processes }));
            } else {
                console.log('[ProcessDetail] store error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        } else {
            setState(prev => ({ ...prev, detail: {}, processes: [] }));
        }
    }

    const handleQty = ({ target }) => {
        const { qty_product } = state.detail;

        const qty_processed = target.value;
        const total_qty = Number(qty_processed) * Number(qty_product ?? 0);

        setState(prev => ({ ...prev, process: { ...prev.process, qty_processed, total_qty } }));
    }

    const validate = type => {
        const { id } = state.detail;
        const { create_attr, update_attr, delete_attr } = state.permissions;
        const { idticket_process, qty_processed } = state.process;

        switch (type) {
            case 'store':
                if (create_attr) {
                    if (id) {
                        if (Number(qty_processed)) {
                            return true;
                        } else {
                            showToast('Qty processed is required', 'error');
                        }
                    } else {
                        showToast('Select some detail', 'error');
                    }
                } else {
                    showToast('You do not have permissions for this action', 'error', 6500);
                }

                return false;

            case 'update':
                if (update_attr) {
                    if (id) {
                        const process = state.processes.find(item => item.idticket_process === idticket_process);
                        if (process) {
                            const before = JSON.stringify(process);
                            const after = JSON.stringify(state.process);
                            if (before !== after) {
                                if (Number(qty_processed)) {
                                    return true;
                                } else {
                                    showToast('Qty processed is required', 'error');
                                }
                            } else {
                                showToast('Update some field', 'error');
                            }
                        } else {
                            showToast('Select some process', 'error');
                        }
                    } else {
                        showToast('Select some detail', 'error');
                    }
                } else {
                    showToast('You do not have permissions for this action', 'error', 6500);
                }

                return false;

            case 'delete':
                if (delete_attr) {
                    if (idticket_process === 0) {
                        showToast('Select some process', 'error');
                        return false;
                    }
                    const cond = window.confirm(`Are you sure you want to delete this process?`);

                    return Boolean(idticket_process && cond);
                } else {
                    showToast('You do not have permissions for this action', 'error', 6500);
                }

                return false;

            case 'discard':
                if (update_attr) {
                    if (id) {
                        const cond = window.confirm(`Are you sure you want to mark this detail as error?`);

                        return Boolean(cond);
                    } else {
                        showToast('Select some detail', 'error');
                    }
                } else {
                    showToast('You do not have permissions for this action', 'error', 6500);
                }

                return false;

            case 'inStorage':
                if (update_attr) {
                    if (id) {
                        const cond = window.confirm(`Are you sure you want to mark this detail as in storage?`);

                        return Boolean(cond);
                    } else {
                        showToast('Select some detail', 'error');
                    }
                } else {
                    showToast('You do not have permissions for this action', 'error', 6500);
                }

                return false;

            case 'complete':
                if (update_attr) {
                    if (id) {
                        const cond = window.confirm(`Are you sure you want to complete without ticket this detail?`);

                        return Boolean(cond);
                    } else {
                        showToast('Select some detail', 'error');
                    }
                } else {
                    showToast('You do not have permissions for this action', 'error', 6500);
                }

                return false;

            default:
                break;
        }
    }

    const store = async () => {
        const form = { ...state.process, iddetail_ticket: state.detail.id, idticket: state.ticket.idticket };

        const cond = validate('store');
        if (cond) {
            showLoader(true);
            const response = await post(`/process/store`, form);
            if (response.status === 200) {
                setState(prev => ({ ...prev, processes: response.data ?? [] }));
                handleOpen(response.alert);
                showToast(response.message);
            } else {
                console.log('[ProcessDetail] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false;
            }
            showLoader(false);
        }
    }

    const update = async () => {
        const { idticket_process } = state.process;

        const cond = validate('update');
        if (cond) {
            showLoader(true);
            const response = await put(`/process/${idticket_process}/update`, state.process);
            if (response.status === 200) {
                setState(prev => ({ ...prev, processes: response?.data ?? prev.processes }));
                showToast(response.message);
            } else {
                console.log('[ProcessDetail] update error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idticket_process } = state.process;

        const cond = validate('delete');
        if (cond) {
            showLoader(true);
            const response = await del(`/process/${idticket_process}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, processes: response?.data ?? prev.processes }));
                showToast(response.message);
            } else {
                console.log('[ProcessDetail] update error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        }
    }

    const setDiscarted = async () => {
        const { id } = state.detail;

        const cond = validate('discard');
        if (cond) {
            showLoader(true);
            const response = await put(`/process/${id}/discard`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, processes: response?.data ?? prev.processes }));
                showToast(response.message);
            } else {
                console.log('[ProcessDetail] setDiscarted error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        }
    }

    const toggleInStorage = async () => {
        const { id } = state.detail;

        const cond = validate('inStorage');
        if (cond) {
            showLoader(true);
            const response = await put(`/process/${id}/setInStorage`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, processes: response?.data ?? prev.processes }));
                showToast(response.message);
            } else {
                console.log('[ProcessDetail] toggleInStorage error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        }
    }

    const setCompletedWithoutTicket = async () => {
        const { id } = state.detail;

        const cond = validate('complete');
        if (cond) {
            showLoader(true);
            const response = await put(`/process/${id}/completeWithoutTicket`, { date: state.process.date });
            if (response.status === 200) {
                setState(prev => ({ ...prev, processes: response?.data ?? prev.processes }));
                showToast(response.message);
            } else {
                console.log('[ProcessDetail] setCompletedWithoutTicket error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        }
    }

    const clear = () => {
        setState(prev => ({
            ...prev,
            process: {
                idticket_process: 0,
                date: dayjs().format('YYYY-MM-DD'),
                employee: { id: 0, name: 'Choose an employee' },
                item: { id: 0, name: 'Choose an item' },
                iditem: 0,
                idemployee: 0,
                qty_processed: 0,
                total_qty: 0,
                pending_qty: 0
            }
        }));
    }

    const handleOpen = content => setState(prev => ({ ...prev, dialog: { content, open: true } }));
    const handleClose = () => setState(prev => ({ ...prev, dialog: { open: false, content: '' } }));

    const getJson = params => params?.value?.name ?? 'Empty';
    const getDate = params => dayjs(params.value).format('MM/DD/YYYY');

    const columns = [
        { field: 'employee', headerName: 'Employee', flex: 1, minWidth: 180, valueGetter: getJson },
        { field: 'item', headerName: 'Item', flex: 1, minWidth: 130, valueGetter: getJson },
        { field: 'date', headerName: 'Date', flex: 1, minWidth: 120, valueGetter: getDate },
        { field: 'qty_processed', headerName: 'Qty processed', flex: 1, minWidth: 150, },
        { field: 'total_qty', headerName: 'Total qty', flex: 1, minWidth: 150 },
        { field: 'pending_qty', headerName: 'Pending qty', flex: 1, minWidth: 150 }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={6} sm={6} md={4} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Ticket No.'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Ticket No.'
                                name='code'
                                value={state.code}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={2} className={classes.buttonContainer}>
                    <Button
                        variant='contained'
                        className={classes.button}
                        onClick={getTicket}
                    >
                        {'Search'}
                    </Button>
                </Grid>
                {
                    state.permissions?.update_attr &&
                    <>
                        <Grid item xs={6} sm={6} md={3} lg={2} className={classes.buttonContainer}>
                            <Button
                                variant='contained'
                                className={classes.discarted}
                                onClick={setDiscarted}
                            >
                                {'Mark as discarted'}
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={2} className={classes.buttonContainer}>
                            <Button
                                variant='contained'
                                className={classes.inStorage}
                                onClick={toggleInStorage}
                            >
                                {'Mark in storage'}
                            </Button>
                        </Grid>
                    </>
                }
            </Grid>
            <Grid container>
                <Grid item xs={6} sm={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Ticket date'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='text'
                                placeholder='Date'
                                value={state.ticket?.date ?? ''}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Supplier'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='text'
                                placeholder='Supplier'
                                value={state.ticket?.supplier?.name ?? ''}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Product'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <Select
                                disabled={!state.details.length}
                                value={state.detail?.id ?? 0}
                                onChange={handleSelect}
                            >
                                <MenuItem value={0}>{'Choose a detail'}</MenuItem>
                                {
                                    state.details.map(value => (
                                        <MenuItem
                                            key={value.id}
                                            value={value.id}
                                            className={classes[`priority_${value.process}`]}
                                        >
                                            {value.product.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Qty product'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                placeholder='Qty'
                                value={state.detail?.qty_product ?? 0}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Qty'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                placeholder='Qty'
                                value={state.detail?.qty ?? 0}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Total Qty'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                placeholder='Qty'
                                value={state.detail?.total_qty ?? 0}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Grid container>
                <Grid item xs={6} sm={6} md={4} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Date'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='date'
                                placeholder='Date'
                                name='date'
                                value={state.process.date}
                                onChange={({ target }) => setState(prev => ({ ...prev, process: { ...prev.process, date: target.value } }))}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Employee'}</FormLabel>
                        <Autocomplete
                            options={state.employees}
                            value={state.process.employee}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleAutocomplete('employee', value)}
                            renderInput={params => <TextField {...params} placeholder='Employee' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Qty processed'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='number'
                                name='qty_processed'
                                value={state.process.qty_processed}
                                onChange={handleQty}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Total qty'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                value={state.process.total_qty}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Pending qty'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                value={state.process.pending_qty}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Items'}</FormLabel>
                        <Autocomplete
                            options={state.items}
                            value={state.process.item}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleAutocomplete('item', value)}
                            renderInput={params => <TextField {...params} placeholder='Item' />}
                        />
                    </FormGroup>
                </Grid>
            </Grid>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            {
                helpers.hasSpecialPermission() &&
                <div style={{ alignSelf: 'flex-end', marginBottom: 20 }}>
                    <Button
                        variant='contained'
                        className={classes.special_button}
                        onClick={setCompletedWithoutTicket}
                    >
                        {'Complete w/o ticket'}
                    </Button>
                </div>
            }

            <div className={classes.tableContainer}>
                <DataGrid
                    autoHeight
                    pageSize={45}
                    density='compact'
                    columns={columns}
                    rows={state.processes}
                    getRowId={row => row.idticket_process}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, process: row }))}
                />
            </div>

            <Dialog open={state.dialog.open} handleClose={handleClose} content={state.dialog.content} />
        </div>
    );
}

export default ProcessDetail;