import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal, Typography, Button, FormGroup, FormLabel, Grid, TextField, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import themeColors from '../../assets/colors';
import { del, get, post } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import AuthContext from '../../context';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.background.default,
        padding: 20,
        width: '70%',
        borderRadius: 10
    },
    store: {
        marginLeft: 15,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    bottom: {
        display: 'flex',
        alignSelf: 'flex-end',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    }
}));

const EmployeeSede = ({ open, onClose, employee, sedes }) => {

    const classes = useStyles();
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const [state, setState] = useState({
        sedes,
        data: [],
        sede: sedes[0]
    });

    useEffect(() => {
        if (employee.idemployee) {
            search(employee.idemployee);
        }
    }, [employee]);

    const search = async (id) => {
        showLoader(true);
        const response = await get(`/employee/${id}/getSedes`);
        if (response.status === 200) {
            setState(prev => ({ ...prev, data: response.data }));
        } else {
            console.log('[EmployeeSede] search error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const store = async () => {
        showLoader(true);
        const response = await post(`/employee/${employee.idemployee}/assignSede`, { sede: state.sede });
        if (response.status === 200) {
            showToast(response.message);
            setState(prev => ({ ...prev, data: response.data }));
        } else {
            console.log('[EmployeeSede] store error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const destroy = async (id) => {
        showLoader(true);
        const response = await del(`/employee/${id}/unnasignSede`, { idemployee: employee.idemployee });
        if (response.status === 200) {
            showToast(response.message);
            setState(prev => ({ ...prev, data: response.data }));
        } else {
            console.log('[EmployeeSede] store error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.root}
        >
            <div className={classes.container}>
                <Typography variant='h2'>{`${employee?.code} - ${employee?.name}`}</Typography>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <FormGroup>
                            <FormLabel>{'Sede'}</FormLabel>

                            <Autocomplete
                                options={state.sedes}
                                value={state.sede}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(e, sede) => setState(prev => ({ ...prev, sede }))}
                                renderInput={params => <TextField {...params} placeholder='Sede' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={2} className={classes.buttonContainer}>
                        <Button
                            variant='contained'
                            className={classes.store}
                            onClick={store}
                        >
                            {'Store'}
                        </Button>
                    </Grid>
                </Grid>

                {
                    state.data.length > 0 ?
                        <div>
                            {
                                state.data.map(item => <Chip label={item.sede.name} onDelete={() => destroy(item.idemployee_sede_ref)} style={{ margin: 5 }} />)
                            }
                        </div>
                        :
                        <span>{'There are no data'}</span>
                }

                <div className={classes.bottom}>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={onClose}
                    >
                        {'Close'}
                    </Button>

                </div>
            </div>
        </Modal>
    );
}

export default EmployeeSede;