import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Box, FormLabel, FormGroup, FormControl, FilledInput, TextField, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ReactExport from "react-data-export";
import dayjs from "dayjs";

import Loader from "../../common/loader";
import DateRange from "../../common/date-range";

import AuthContext from "../../context";
import helpers from "../../utils/helpers";
import { get } from "../../utils/api-services";
import ViewTitle from "../../common/view-title";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(() => ({
	root: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
	},
	buttonsContainer: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "flex-end",
		marginBottom: 20,
	},
	search: {
		backgroundColor: "#0d6efd",
		borderColor: "#0d6efd",
		"&:hover": {
			backgroundColor: "#0d6efd",
			borderColor: "#0d6efd",
			boxShadow: "none",
		},
	},
	download: {
		marginRight: 20,
		backgroundColor: "#6E6E6E",
		borderColor: "#6E6E6E",
		"&:hover": {
			backgroundColor: "#6E6E6E",
			borderColor: "#6E6E6E",
			boxShadow: "none",
		},
	},
}));

const defaultFilter = {
	code: "",
	startDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
	endDate: dayjs().format("YYYY-MM-DD"),
	dateRange: `${dayjs().subtract(1, "day").format("MM/DD/YYYY")} - ${dayjs().format("MM/DD/YYYY")}`,
	customer: { id: 0, name: "All customers" },
	invoice: { id: 0, name: "All invoice status" },
	paid: { id: 0, name: "All paid status" },
	supplier: { id: 0, name: "All suppliers" },
	land: { id: 0, name: "All lands" },
}

const Reports = ({ permissions }) => {
	const { showToast, signOut, showLoader } = useContext(AuthContext);
	const classes = useStyles();
	const [state, setState] = useState({
		loading: true,
		customers: [],
		invoiceStatus: [],
		paidStatus: [],
		suppliers: [],
		lands: [],
		tickets: [
			{id: 0, name: "Delivery Tickets"},
			{id: 1, name: "Bills and Cutting Tickets"}
		],
		filter: {
			...defaultFilter,
			tickets: {id: 0, name: "Delivery Tickets"},
		},
		data: [],
		rangeModal: {
			open: false,
			range: [
				{
					startDate: new Date(dayjs().subtract(1, "day").toDate()),
					endDate: new Date(),
					key: "main",
				},
			],
		},
		permissions: {},
	});

	useEffect(() => {
		setState((prev) => ({ ...prev, permissions }));
	}, [permissions]);

	useEffect(() => {
		window.document.title = "Reports";

		makeRequest();
	}, []);

	const makeRequest = async () => {
		showLoader(true);
		const response = await get("/reports/indexDelivery", state.filter);
		if (response.status === 200) {
			setState((prev) => ({
				...prev,
				loading: false,
				data: response?.data ?? [],
				customers: response?.customers ?? [],
				invoiceStatus: response?.invoiceStatus ?? [],
				paidStatus: response?.paidStatus ?? [],
				suppliers: response?.suppliers ?? [],
				lands: response?.lands ?? [],
			}));
		} else {
			console.log("[Reports] makeRequest error", response);
			helpers.failRequest(response, showToast, signOut);
		}
		showLoader(false);
	};

	const handleDateChange = (item) => {
		const { startDate, endDate } = item.main;
		const dateRange = `${dayjs(startDate.toISOString()).format("MM/DD/YYYY")} - ${dayjs(endDate.toISOString()).format("MM/DD/YYYY")}`;
		setState((prev) => ({
			...prev,
			filter: {
				...prev.filter,
				dateRange,
				startDate: dayjs(startDate.toISOString()).format("YYYY-MM-DD"),
				endDate: dayjs(endDate.toISOString()).format("YYYY-MM-DD"),
			},
			rangeModal: { ...prev.rangeModal, range: [item.main] },
		}));
	};

	const handleModal = () => {
		setState((prev) => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));
	};

	const handleChange = ({ target: { name, value } }) => setState((prev) => ({ ...prev, filter: { ...prev.filter, [name]: value } }));

	const handleChangeAutocomplete = (key, value) => {
		if(key === "tickets"){
			setState((prev) => ({...prev,data: [], 
				rangeModal: {...prev.rangeModal,  range: [
					{
						startDate: new Date(dayjs().subtract(1, "day").toDate()),
						endDate: new Date(),
						key: "main",
					}],
				},
				filter: {...prev.filter, ...defaultFilter}}))
		}
		setState((prev) => ({ ...prev, filter: { ...prev.filter, [key]: value } }))
	};

	const search = async () => {
		showLoader(true);
		var urlSearch = state.filter.tickets.id === 0 ? "/reports/searchDelivery" : "/reports/searchBillsCutting"
		const response = await get(urlSearch, state.filter);
		if (response.status === 200) {
			setState((prev) => ({ ...prev, data: response?.data ?? [] }));

			if (!response?.data[0]) showToast("There are no data with these filters", "warning");
		} else {
			console.log("[Reports] search error", response);
			helpers.failRequest(response, showToast, signOut);
		}
		showLoader(false);
	};

	if (state.loading) {
		return <Loader />;
	}

	return (
		<div className={classes.root}>
			<ViewTitle sedeFlag title="Reports" />
			<Grid container>
				<Grid item xs={6} lg={4}>
					<FormGroup>
						<FormLabel>{"Tickets"}</FormLabel>
						<Autocomplete
							options={state.tickets}
							value={state.filter.tickets}
							getOptionLabel={(option) => option?.name ?? ""}
							getOptionSelected={(option, value) => option.id === value.id}
							onChange={(event, value) => handleChangeAutocomplete("tickets", value)}
							renderInput={(params) => <TextField {...params} placeholder="Tickets" />}
							disableClearable={true}
						/>
					</FormGroup>
				</Grid>
				<Grid item xs={6} lg={4}>
					<FormGroup>
						<FormLabel>{"No. ticket"}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput autoComplete="off" type="number" placeholder="No. ticket" name="code" onChange={handleChange} value={state.filter.code} />
						</FormControl>
					</FormGroup>
				</Grid>
				<Grid item xs={6} lg={4}>
					<FormGroup onClick={handleModal}>
						<FormLabel>{"Date range"}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput readOnly autoComplete="off" type="text" placeholder="Date range" name="range" value={state.filter.dateRange} />
						</FormControl>
					</FormGroup>
				</Grid>
				{state.filter.tickets.id === 0 ? (
					<Grid item xs={6} lg={4}>
						<FormGroup>
							<FormLabel>{"Customer"}</FormLabel>
							<Autocomplete
								disabled={Boolean(state.filter.code.trim())}
								options={state.customers}
								value={state.filter.customer}
								getOptionLabel={(option) => option?.name ?? ""}
								getOptionSelected={(option, value) => option.id === value.id}
								onChange={(event, value) => handleChangeAutocomplete("customer", value)}
								renderInput={(params) => <TextField {...params} placeholder="Customer" />}
								disableClearable={true}
							/>
						</FormGroup>
					</Grid> ): (
					<Grid item xs={6} lg={4}>
						<FormGroup>
							<FormLabel>{"Suppliers"}</FormLabel>
							<Autocomplete
								disabled={Boolean(state.filter.code.trim())}
								options={state.suppliers}
								value={state.filter.supplier}
								getOptionLabel={(option) => option?.name ?? ""}
								getOptionSelected={(option, value) => option.id === value.id}
								onChange={(event, value) => handleChangeAutocomplete("supplier", value)}
								renderInput={(params) => <TextField {...params} placeholder="Suppliers" />}
								disableClearable={true}
							/>
						</FormGroup>
					</Grid>
				)}
				{state.filter.tickets.id === 0 ? (
					<Grid item xs={6} lg={4}>
						<FormGroup>
							<FormLabel>{"Invoice status"}</FormLabel>
							<Autocomplete
								disabled={Boolean(state.filter.code.trim())}
								options={state.invoiceStatus}
								value={state.filter.invoice}
								getOptionLabel={(option) => option?.name ?? ""}
								getOptionSelected={(option, value) => option.id === value.id}
								onChange={(event, value) => handleChangeAutocomplete("invoice", value)}
								renderInput={(params) => <TextField {...params} placeholder="Invoice" />}
								disableClearable={true}
							/>
						</FormGroup>
					</Grid>): (
					<Grid item xs={6} lg={4}>
						<FormGroup>
							<FormLabel>{"Lands"}</FormLabel>
							<Autocomplete
								disabled={Boolean(state.filter.code.trim())}
								options={state.lands}
								value={state.filter.land}
								getOptionLabel={(option) => option?.name ?? ""}
								getOptionSelected={(option, value) => option.id === value.id}
								onChange={(event, value) => handleChangeAutocomplete("land", value)}
								renderInput={(params) => <TextField {...params} placeholder="Lands" />}
								disableClearable={true}
							/>
						</FormGroup>
					</Grid>
					)}
				
				<Grid item xs={6} lg={4}>
					<FormGroup>
						<FormLabel>{"Paid status"}</FormLabel>
						<Autocomplete
							disabled={Boolean(state.filter.code.trim())}
							options={state.paidStatus}
							value={state.filter.paid}
							getOptionLabel={(option) => option?.name ?? ""}
							getOptionSelected={(option, value) => option.id === value.id}
							onChange={(event, value) => handleChangeAutocomplete("paid", value)}
							renderInput={(params) => <TextField {...params} placeholder="Paid" />}
							disableClearable={true}
						/>
					</FormGroup>
				</Grid>
			</Grid>

			<div className={classes.buttonsContainer}>
				{state.data.length > 0 && state.filter.tickets.id === 0 && (
					<ExcelFile
						filename={`${dayjs().format("YYYY-MM-DD")}-${state.filter.tickets.name}-report`}
						element={
							<Button variant="contained" className={classes.download}>
								{"Download data"}
							</Button>
						}
					>
						<ExcelSheet data={state.data.filter(i => i.sectionCategory === "Products")} name="Products">
							<ExcelColumn label="Ticket No." value="deliveryNumber" />
							<ExcelColumn label="Date" value="dateCreated" />
							<ExcelColumn label="Paid status" value="status" />
							<ExcelColumn label="Date payment" value="datePayment" />
							<ExcelColumn label="Invoice status" value="invoiceStatus" />
							<ExcelColumn label="Invoice number" value="invoiceNumber" />
							<ExcelColumn label="Purchase number" value="purchaseOrder" />
							<ExcelColumn label="Loader name" value="loader" />
							<ExcelColumn label="Trucking company" value="trucker" />
							<ExcelColumn label="Trucking number" value="truckNumber" />
							<ExcelColumn label="Truck plate" value="truckPlate" />
							<ExcelColumn label="Trailer number" value="trailerNumber" />
							<ExcelColumn label="CFF full weight" value="fullWeight" />
							<ExcelColumn label="CFF empty weight" value="emptyWeight" />
							<ExcelColumn label="CFF net weight" value="netWeight" />
							<ExcelColumn label="Customer name" value="customer" />
							<ExcelColumn label="Date received" value="dateReceived" />
							<ExcelColumn label="Invoice total" value="invoiceTotal" />
							<ExcelColumn label="Note" value="note" />
							<ExcelColumn label="Section category" value="sectionCategory" />
							<ExcelColumn label="Section name" value="sectionName" />
							<ExcelColumn label="Section weight" value="sectionWeight" />
							<ExcelColumn label="Section rate" value="sectionRate" />
							<ExcelColumn label="Section subtotal" value="sectionSubtotal" />
						</ExcelSheet>
						<ExcelSheet data={state.data.filter(i => i.sectionCategory === "Suppliers")} name="Suppliers">
							<ExcelColumn label="Ticket No." value="deliveryNumber" />
							<ExcelColumn label="Date" value="dateCreated" />
							<ExcelColumn label="Note" value="note" />
							<ExcelColumn label="Section category" value="sectionCategory" />
							<ExcelColumn label="Section name" value="sectionName" />
							<ExcelColumn label="Section land" value="sectionLand" />
							<ExcelColumn label="Section product" value="sectionProduct" />
							<ExcelColumn label="Section weight" value="sectionWeight" />
							<ExcelColumn label="Section rate" value="sectionRate" />
							<ExcelColumn label="Section subtotal" value="sectionSubtotal" />
						</ExcelSheet>
						<ExcelSheet data={state.data.filter(i => i.sectionCategory === "Cutters")} name="Cutters">
							<ExcelColumn label="Ticket No." value="deliveryNumber" />
							<ExcelColumn label="Date" value="dateCreated" />
							<ExcelColumn label="Note" value="note" />
							<ExcelColumn label="Section category" value="sectionCategory" />
							<ExcelColumn label="Section name" value="sectionName" />
							<ExcelColumn label="Section product" value="sectionProduct" />
							<ExcelColumn label="Section weight" value="sectionWeight" />
							<ExcelColumn label="Section rate" value="sectionRate" />
							<ExcelColumn label="Section subtotal" value="sectionSubtotal" />
						</ExcelSheet>
					</ExcelFile>	
				)}

				{state.data.length > 0 && state.filter.tickets.id === 1 && (
					<ExcelFile
						filename={`${dayjs().format("YYYY-MM-DD")}-${state.filter.tickets.name}-report`}
						element={
							<Button variant="contained" className={classes.download}>
								{"Download data"}
							</Button>
						}
					>
						<ExcelSheet data={state.data} name="Bills and Cutting report">
							<ExcelColumn label="Ticket No." value="code" />
							<ExcelColumn label="Date" value="date" />
							<ExcelColumn label="Paid state" value="state" />
							<ExcelColumn label="Payment Number" value="payment_number" />
							<ExcelColumn label="Location" value="sede" />
							<ExcelColumn label="Supplier Code" value="supplier_code" />
							<ExcelColumn label="Supplier" value="supplier" />
							<ExcelColumn label="Land Code" value="land_code" />
							<ExcelColumn label="Land" value="land" />
							<ExcelColumn label="Item" value="item" />
							<ExcelColumn label="Product Code" value="product_code" />
							<ExcelColumn label="Product" value="product" />
							<ExcelColumn label="Price" value="price" />
							<ExcelColumn label="Quantity" value="quantity" />
							<ExcelColumn label="Total" value="total" />
							<ExcelColumn label="Note" value="note" />
						</ExcelSheet>
					</ExcelFile>	
				)}
				<Button variant="contained" className={classes.search} onClick={search}>
					{"Search"}
				</Button>
			</div>

			<DateRange open={state.rangeModal.open} range={state.rangeModal.range} onClose={handleModal} onChange={handleDateChange} minDate={dayjs("2022-01-01").toDate()} />
		</div>
	);
};

export default Reports;
