import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Button } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import Loader from '../../common/loader';

import AuthContext from '../../context';
import { get, post } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    store: {
        width: '100%',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    }
}));

const ProductionStartTimeMobile = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [state, setState] = useState({
        loading: true,
        time: {
            code_product: '',
            product_sale: {},
            code_employee: '',
            employee: {}
        },
        permissions: {}
    });

    useEffect(() => {
        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);

        setState(prev => ({ ...prev, permissions, loading: false }));
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, time: { ...prev.time, [target.name]: target.value } }));

    const validateForm = () => {
        const { product_sale, employee } = state.time;

        if (Number(product_sale?.id) && Number(employee?.id)) {
            return true;
        } else {
            showToast('All fields is required', 'error');
            return false;
        }
    }

    const searchProductSale = async (e) => {
        const { code_product } = state.time;

        if (e.key === 'Enter') {
            showLoader(true);
            const response = await get(`/productionMobile/${code_product}/searchProductSale`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, time: { ...prev.time, product_sale: response?.data ?? {} } }));
            } else {
                console.log('[ProductionStartTimeMobile] searchProductSale error', response);
                helpers.failRequest(response, showToast, signOut);
                setState(prev => ({ ...prev, time: { ...prev.time, product_sale: {} } }))
            }
            showLoader(false);
        }
    }

    const searchEmployee = async (e) => {
        const { code_employee } = state.time;

        if (e.key === 'Enter') {
            showLoader(true);
            const response = await get(`/productionMobile/${code_employee}/searchEmployee`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, time: { ...prev.time, employee: response?.data ?? {} } }));
            } else {
                console.log('[ProductionStartTimeMobile] searchEmployee error', response);
                helpers.failRequest(response, showToast, signOut);
                setState(prev => ({ ...prev, time: { ...prev.time, employee: {} } }))
            }
            showLoader(false);
        }
    }

    const store = async () => {
        const cond = validateForm();

        if (cond) {
            showLoader(true);
            const response = await post('/productionEmployeeTime/storeMobile', state.time);
            if (response.status === 200) {
                clear();
                showToast(response.message);
            } else {
                console.log('[ProductionStartTimeMobile] store error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const clear = () => {
        setState(prev => ({
            ...prev,
            time: {
                ...prev.time,
                code_product: '',
                product_sale: {},
                code_employee: '',
                employee: {}
            }
        }));
    }

    if (state.loading) {
        return <Loader />;
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <FormGroup>
                        <FormLabel>{'Code - Employee'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Code employee'
                                name='code_employee'
                                value={state.time.code_employee}
                                onChange={handleChange}
                                onKeyPress={searchEmployee}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormGroup>
                        <FormLabel>{'Employee'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                disabled={true}
                                type='text'
                                placeholder='Employee'
                                value={state.time.employee?.name ?? ''}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormGroup>
                        <FormLabel>{'Code - Product sale'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Code product'
                                name='code_product'
                                value={state.time.code_product}
                                onChange={handleChange}
                                onKeyPress={searchProductSale}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormGroup>
                        <FormLabel>{'Product sale'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                disabled={true}
                                type='text'
                                placeholder='Product sale'
                                value={state.time.product_sale?.description ?? ''}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>

                <Grid item xs={12} sm={12} md={12} className={classes.buttonContainer}>
                    <Button
                        variant='contained'
                        className={classes.store}
                        onClick={store}
                    >
                        {'Store'}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default ProductionStartTimeMobile;