import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Divider } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import Loader from './loader';
import NavBar from './nav-bar';
import ViewTitle from './view-title';

import AuthContext from '../context';
import { get } from '../utils/api-services';
import helpers from '../utils/helpers';
import theme from '../assets/theme';
import themeColors from '../assets/colors';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(3),
        paddingTop: 0
    },
    gridContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    cardDetail: {
        display: 'flex',
        flexDirection: 'column'
    },
    trash: {
        backgroundColor: '#6E6E6E',
        borderColor: '#6E6E6E',
        '&:hover': {
            backgroundColor: '#6E6E6E',
            borderColor: '#6E6E6E',
            boxShadow: 'none'
        }
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {
        margin: 0
    },
    item: {
        paddingTop: 8,
        paddingBottom: 8,
        backgroundColor: themeColors.white.main
    },
    list: {
        flex: 1,
        overflow: 'auto',
        marginTop: 15
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    }
}));

const History = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const { table, pk } = useParams();
    const classes = useStyles();
    const [state, setState] = useState({
        loading: true,
        data: []
    });

    useEffect(() => {
        window.document.title = 'History';

        makeRequest();
    }, []);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/universal/history', { table, pk });
        if (response.status === 200) {
            setState(prev => ({ ...prev, loading: false, data: response.data }));
        } else {
            console.log('[History] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const getDiff = (before, after) => {
        const b = JSON.parse(before);
        const a = JSON.parse(after);
        const result = [];

        for (const key in b) {
            if (b[key] !== a[key]) {
                result.push({ key, before: b[key], after: a[key] });
            }
        }

        return <div>
            {
                result.map(item =>
                    <div key={item.key} style={{ marginTop: 15 }}>
                        <Typography><b>{`Field: `}</b>{`${item.key}`}</Typography>
                        <Typography><b>{`Before: `}</b>{`${item.before}`}</Typography>
                        <Typography><b>{`After: `}</b>{`${item.after}`}</Typography>
                    </div>
                )
            }
        </div>
    }

    if (state.loading) {
        return <Loader fullScreen />;
    }

    return (
        <div className={classes.root}>
            <NavBar />
            <div className={classes.content}>
                <Grid container spacing={2} className={classes.gridContainer}>
                    <Grid item xl={7} md={8} xs={10}>
                        <ViewTitle title={`History`} />
                    </Grid>

                    {
                        state.data.map(item =>
                            <Grid item key={item.idrecord} xl={7} md={8} xs={10}>
                                <Card>
                                    <CardContent className={classes.cardDetail}>
                                        <div className={classes.header}>
                                            <Typography variant='h4'>{item.action}</Typography>
                                            <Typography>{`${item.user.name} | ${dayjs(item.date).utc().local().format('MM/DD/YYYY HH:mm:ss')}`}</Typography>
                                        </div>
                                        {
                                            Boolean(item.description) &&
                                            <>
                                                <h4>{`Description: ${item.description}`}</h4>
                                                <Divider />
                                            </>
                                        }
                                        {
                                            (item.action === 'updated') && getDiff(item.before, item.after)
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    }
                    {
                        state.data.length < 1 &&
                        <Grid item xl={7} md={8} xs={10}>
                            <Card>
                                <CardContent className={classes.cardDetail}>
                                    <Typography variant='subtitle1'>{'No records found'}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                </Grid>
            </div>
        </div>
    );
}

export default History;