const ENV = process.env.REACT_APP_ENV;
const URL_API = process.env[`REACT_APP_BACKEND_URL_${ENV}`];

const get = async (method, form = {}) => {
    try {
        const { body, token } = await setForm(form, 'GET');

        const response = await fetch(URL_API + method + body, {
            method: 'GET',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token}`
            },
            redirect: 'follow',
            referrer: 'no-referrer',
        });

        const data = await response.json();

        return {
            ...data,
            status: response.status,
        }
    } catch (e) {
        console.log('[ApiServices] get error', e);
        return { status: 400, error: 'Something went wrong' };
    }
}

const post = async (method, form = {}) => {
    try {
        const { body, token } = await setForm(form, 'POST');

        const response = await fetch(URL_API + method, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body),
            redirect: 'follow',
            referrer: 'no-referrer',
        });
        const data = await response.json();

        return {
            ...data,
            status: response.status,
        }

    } catch (e) {
        console.log('[ApiServices] post error', e);
        return { status: 400, error: 'Something went wrong' };
    }
}

const put = async (method, form = {}) => {
    try {
        const { body, token } = await setForm(form, 'PUT');

        const response = await fetch(URL_API + method, {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body),
            redirect: 'follow',
            referrer: 'no-referrer',
        });
        const data = await response.json();

        return {
            ...data,
            status: response.status,
        }

    } catch (e) {
        console.log('[ApiServices] put error', e);
        return { status: 400, error: 'Something went wrong' };
    }
}

const del = async (method, form = {}) => {
    try {
        const { body, token } = await setForm(form, 'DELETE');

        const response = await fetch(URL_API + method, {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body),
            redirect: 'follow',
            referrer: 'no-referrer',
        });
        const data = await response.json();

        return {
            ...data,
            status: response.status,
        }

    } catch (e) {
        console.log('[ApiServices] delete error', e);
        return { status: 400, error: 'Something went wrong' };
    }
}

const postLogin = async (method, form) => {
    try {
        const response = await fetch(URL_API + method, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(form),
            redirect: 'follow',
            referrer: 'no-referrer',
        });
        const data = await response.json();

        return {
            ...data,
            status: response.status,
        }
    }
    catch (e) {
        console.log('[ApiServices] postLogin error', e);
        return { status: 400, error: 'Something went wrong' };
    }
}

const setForm = async (form, method) => {
    const token = localStorage.getItem('@token');
    const sede = JSON.parse(localStorage.getItem('@sede'));

    if (token !== null) {
        if (method === 'GET') {
            let newForm = '?';
            for (const key in form) {
                if (typeof form[key] === 'object') {
                    newForm += `${key}=${form[key]?.id}&`;
                } else {
                    newForm += `${key}=${form[key]}&`;
                }
            }
            newForm += `idsede=${sede.id}`;

            return { token, body: newForm };
        } else {
            for (const key in form) {
                if (typeof form[key] === 'string') {
                    form[key] = form[key]?.trim();
                }
            }
            form.idsede = sede.id;
        }

        return { token, body: form };
    }
}

export { get, post, put, del, postLogin, URL_API };
