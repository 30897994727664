import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from '@material-ui/core';
import dayjs from 'dayjs';

import '../../assets/css/print.css';

const Logo = require('../../assets/images/logo-full.png').default;

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0
    },
    page: {
        display: 'block',
        pageBreakAfter: 'always'
    },
    text: {
        fontSize: 10,
        color: '#000'
    },
    filterContainer: {
        display: 'block',
        pageBreakInside: 'avoid'
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 8,
        backgroundColor: '#F2F2F2'
    },
    total: {
        backgroundColor: '#E0F8E0'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#283851',
        color: '#fff',
        padding: 8,
    },
    description: {
        display: 'flex',
        flexDirection: 'column'
    },
    logo: {
        maxHeight: 80
    }
}));

const Text = ({ title }) => {
    const classes = useStyles();

    return (
        <TableCell align='center' className={classes.text}>{title}</TableCell>
    );
}

const ChecksToPrint = ({ data }) => {

    const classes = useStyles();

    const getTotal = (filter) => {
        let total = { qty: 0, subtotal: 0, stumpage: 0, fee: 0, extra: 0, total: 0 };

        filter.tickets.map(ticket => {
            ticket.detail.map(detail => {
                if (detail.item.action === '+') {
                    total.qty = Number(total.qty) + (detail.qty * detail.qty_product);
                    total.subtotal = (Number(total.subtotal) + Number(detail.subtotal)).toFixed(2);
                    total.stumpage = (Number(total.stumpage) + Number(detail.stumpage)).toFixed(2);
                    total.fee = (Number(total.fee) + Number(detail.admin_fee)).toFixed(2);
                    total.extra = (Number(total.extra) + Number(detail.extras)).toFixed(2);
                    total.total = (Number(total.total) + Number(detail.total)).toFixed(2);
                } else if (detail.item.action === '-') {
                    total.qty = Number(total.qty) - (detail.qty * detail.qty_product);
                    total.subtotal = (Number(total.subtotal) - Number(detail.subtotal)).toFixed(2);
                    total.stumpage = (Number(total.stumpage) - Number(detail.stumpage)).toFixed(2);
                    total.fee = (Number(total.fee) - Number(detail.admin_fee)).toFixed(2);
                    total.extra = (Number(total.extra) - Number(detail.extras)).toFixed(2);
                    total.total = (Number(total.total) - Number(detail.total)).toFixed(2);
                }
            });
        });

        const values = [];
        for (const key in total) {
            switch (key) {
                case 'qty':
                    values.push(total[key]);
                    break;
                case 'stumpage':
                    values.push(`- $ ${total[key]}`);
                    break;
                case 'fee':
                    values.push(`- $ ${total[key]}`);
                    break;
                case 'extra':
                    values.push(`+ $ ${total[key]}`);
                    break;
                default:
                    values.push(`$ ${total[key]}`);
                    break;
            }
        }

        return values;
    }

    const getFullTotal = filters => {
        let qty = '0';
        let subtotal = '0';
        let stumpage = '0';
        let admin_fee = '0';
        let extras = '0';
        let total = '0';

        filters.map(filter => {
            filter.tickets.map(ticket => {
                ticket.detail.map(detail => {
                    if (detail.item.action === '+') {
                        qty = Number(qty) + Number(detail.total_qty);
                        subtotal = (Number(subtotal) + Number(detail.subtotal)).toFixed(2);
                        stumpage = (Number(stumpage) + Number(detail.stumpage)).toFixed(2);
                        admin_fee = (Number(admin_fee) + Number(detail.admin_fee)).toFixed(2);
                        extras = (Number(extras) + Number(detail.extras)).toFixed(2);
                        total = (Number(total) + Number(detail.total)).toFixed(2);
                    } else if (detail.item.action === '-') {
                        qty = Number(qty) - Number(detail.total_qty);
                        subtotal = (Number(subtotal) - Number(detail.subtotal)).toFixed(2);
                        stumpage = (Number(stumpage) - Number(detail.stumpage)).toFixed(2);
                        admin_fee = (Number(admin_fee) - Number(detail.admin_fee)).toFixed(2);
                        extras = (Number(extras) - Number(detail.extras)).toFixed(2);
                        total = (Number(total) - Number(detail.total)).toFixed(2);
                    }
                });
            });
        });

        return [`Qty: ${qty}`, `Subtotal: $ ${subtotal}`, `Stumpage: $ ${stumpage}`, `Admin fee: $ ${admin_fee}`, `Extras: $ ${extras}`, `Total: $ ${total}`];
    }

    return (
        <div className={classes.root}>
            <div>
                {
                    data?.map(check => {
                        return (
                            <div key={check.idcheck} className={classes.page}>
                                <div className={classes.header}>
                                    <div className={classes.description}>
                                        <span><b>{`Date: `}</b>{dayjs().format('MM/DD/YYYY')}</span>
                                        <span><b>{`Supplier: `}</b>{check.supplier.name}</span>
                                        <span><b>{`Year-Week: `}</b>{check.week}</span>
                                        <span><b>{`Total: `}</b>{`$ ${check.total}`}</span>
                                    </div>
                                    <img className={classes.logo} src={Logo} alt='logo cfg' />
                                </div>
                                {
                                    check.filters.map((filter, i) => {
                                        return (
                                            <div key={i.toString()} className={classes.filterContainer}>
                                                <div className={classes.tableHeader}>
                                                    <span><b>{`Date: `}</b>{dayjs(filter.date).format('MM/DD/YYYY')}</span>
                                                    <span><b>{`Location: `}</b>{filter.location.name}</span>
                                                    <span><b>{`Land: `}</b>{filter.land.name}</span>
                                                </div>

                                                <TableContainer component={Box}>
                                                    <Table size='small'>
                                                        <TableHead>
                                                            <TableRow>
                                                                <Text title={'No. Ticket'} />
                                                                <Text title={'Item'} />
                                                                <Text title={'Product'} />
                                                                <Text title={'Type'} />
                                                                <Text title={'Price'} />
                                                                <Text title={'Qty'} />
                                                                <Text title={'Subtotal'} />
                                                                <Text title={'Stumpage'} />
                                                                <Text title={'Admin Fee'} />
                                                                <Text title={'Extras'} />
                                                                <Text title={'Total'} />
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                filter.tickets.map(ticket => {
                                                                    return (
                                                                        <Fragment key={ticket.idticket}>
                                                                            {
                                                                                ticket.detail.map((detail, j) => {
                                                                                    return (
                                                                                        <TableRow key={detail.iddetail_ticket}>
                                                                                            <Text title={(j == 0) ? ticket.code : ''} />
                                                                                            <Text title={detail.item.name} />
                                                                                            <Text title={detail.product.name} />
                                                                                            <Text title={detail.type.name} />
                                                                                            <Text title={`$ ${detail.final_price}`} />
                                                                                            <Text title={detail.qty * detail.qty_product} />
                                                                                            <Text title={`$ ${detail.subtotal}`} />
                                                                                            <Text title={`- $ ${detail.stumpage}`} />
                                                                                            <Text title={`- $ ${detail.admin_fee}`} />
                                                                                            <Text title={`+ $ ${detail.extras}`} />
                                                                                            <Text title={`$ ${detail.total}`} />
                                                                                        </TableRow>
                                                                                    );
                                                                                })
                                                                            }

                                                                        </Fragment>
                                                                    );
                                                                })
                                                            }
                                                            <TableRow className={classes.total}>
                                                                <TableCell className={classes.text} align='center'>{'TOTAL:'}</TableCell>
                                                                <TableCell colSpan={4} />
                                                                {
                                                                    getTotal(filter).map((item, i) => <Text key={i.toString()} title={`${item}`} />)
                                                                }
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        );
                                    })
                                }
                                <div className={classes.footer}>
                                    {
                                        getFullTotal(check.filters).map(
                                            (item, i) => <span key={i.toString()}>{item}</span>
                                        )
                                    }
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default ChecksToPrint;