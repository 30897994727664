import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Modal, FilledInput, Typography, Button, FormGroup, FormLabel, FormControl, Box, Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

import themeColors from "../../assets/colors";
import { post } from "../../utils/api-services";
import helpers from "../../utils/helpers";
import AuthContext from "../../context";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	container: {
		display: "flex",
		flexDirection: "column",
		backgroundColor: themeColors.background.default,
		padding: 20,
		width: "80%",
		borderRadius: 10,
	},
	store: {
		marginLeft: 15,
		backgroundColor: "#0d6efd",
		borderColor: "#0d6efd",
		"&:hover": {
			backgroundColor: "#0d6efd",
			borderColor: "#0d6efd",
			boxShadow: "none",
		},
	},
	bottom: {
		display: "flex",
		alignSelf: "flex-end",
	},
	requiredValue: {
		fontWeight: "600",
		color: "rgba(255,0,0,1)",
	},
}));

export default function CreateTicketBill({ open, onClose, arrays }) {
	const classes = useStyles();
	const { showToast, signOut, showLoader } = useContext(AuthContext);
	const history = useHistory();
	const [state, setState] = useState({
		suppliers: arrays?.suppliers ?? [],
		lands: arrays?.lands ?? [],
		ticket: {
			code: "",
			date: dayjs().format("YYYY-MM-DD"),
			supplier: arrays?.suppliers[0],
			land: arrays?.lands[0],
		},
	});

	const handleAutocomplete = (key, value) => setState((prev) => ({ ...prev, ticket: { ...prev.ticket, [key]: value } }));

	const handleChange = ({ target }) => setState((prev) => ({ ...prev, ticket: { ...prev.ticket, [target.name]: target.value } }));

	const validateForm = () => {
		const { code, date, supplier, land } = state.ticket;

		if (code.trim() && date && supplier.id && land.id) {
			const cond = window.confirm("Confirm if all data is correct");
			if (cond) {
				store();
			}
		} else {
			showToast("All fields is required", "error");
		}
	};

	const store = async () => {
		showLoader(true);
		const response = await post("/farmForest/billsAndCuttingTickets/store", state.ticket);
		console.log(response)
		if (response.status === 200) {
			history.push(`/farmForest/billsAndCuttingTickets/${response.id}`);
			showToast(response.message);
		} else {
			console.log("[CreateTickets] store error", response);
			helpers.failRequest(response, showToast, signOut);
		}
		showLoader(false);
	};

	return (
		<Modal open={open} onClose={onClose} className={classes.root}>
			<div className={classes.container}>
				<Typography variant="h2">{"Create bills/cutting ticket"}</Typography>
				<Grid container>
					<Grid item xs={12} lg={6}>
						<FormGroup>
							<FormLabel>{"No. Ticket"}  <span className={classes.requiredValue}>*</span>{" "}</FormLabel>
							<FormControl variant="filled" component={Box} width="100%">
								<FilledInput autoComplete="off" type="number" placeholder="Code" name="code" value={state.ticket.code} onChange={handleChange} />
							</FormControl>
						</FormGroup>
					</Grid>
					<Grid item xs={12} lg={6}>
						<FormGroup>
							<FormLabel>{"Date"} <span className={classes.requiredValue}>*</span>{" "}</FormLabel>
							<FormControl variant="filled" component={Box} width="100%">
								<FilledInput autoComplete="off" type="date" placeholder="Date" name="date" value={state.ticket.date} onChange={handleChange} />
							</FormControl>
						</FormGroup>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} lg={6}>
						<FormGroup>
							<FormLabel>{"Supplier"} <span className={classes.requiredValue}>*</span>{" "} </FormLabel>
							<Autocomplete
								options={state.suppliers}
								value={state.ticket.supplier}
								getOptionLabel={(option) => option?.name ?? ""}
								getOptionSelected={(option, value) => option.id === value.id}
								onChange={(e, value) => handleAutocomplete("supplier", value)}
								renderInput={(params) => <TextField {...params} placeholder="Supplier" />}
							/>
						</FormGroup>
					</Grid>
					<Grid item xs={12} lg={6}>
						<FormGroup>
							<FormLabel>{"Land"} <span className={classes.requiredValue}>*</span>{" "} </FormLabel>
							<Autocomplete
								options={state.lands}
								value={state.ticket.land}
								getOptionLabel={(option) => option?.name ?? ""}
								getOptionSelected={(option, value) => option.id === value.id}
								onChange={(e, value) => handleAutocomplete("land", value)}
								renderInput={(params) => <TextField {...params} placeholder="Land" />}
							/>
						</FormGroup>
					</Grid>
				</Grid>
				<div className={classes.bottom}>
					<Button variant="contained" color="secondary" onClick={onClose}>
						{"Cancel"}
					</Button>
					<Button variant="contained" className={classes.store} onClick={validateForm}>
						{"Store"}
					</Button>
				</div>
			</div>
		</Modal>
	);
}
