import React, { useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';

import Parent from '../../common/parent';
import SelectPage from '../../common/select-page';
import NotFound from '../../common/not-found';
import Settings from '../../common/module-settings';

import Departments from './departments';
import ProductSales from './product-sales';
import LaborParam from './labor-param';
import Labor from './labor';
import Recipe from './recipe';
import RecipeHistory from './recipe-history';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingTop: 0
    }
}));

const BOMParent = () => {

    const classes = useStyles();
    const { path } = useRouteMatch();
    const [module, setModule] = useState(null);

    const update = module => {
        if (module) {
            setModule(module);
        }
    }

    return (
        <Parent module={module} updateModule={update}>
            <main className={classes.content}>
                <Toolbar />
                <Switch>
                    <Route exact path={path} render={props => <SelectPage />} />
                    <Route path={`${path}/recipe/history/:id`} render={props => <RecipeHistory {...props} />} />
                    <Route path={`${path}/recipe`} render={props => <Recipe {...props} />} />
                    <Route path={`${path}/labor`} render={props => <Labor {...props} />} />
                    <Route path={`${path}/laborParam`} render={props => <LaborParam {...props} />} />
                    <Route path={`${path}/departments`} render={props => <Departments {...props} />} />
                    <Route path={`${path}/product_sales`} render={props => <ProductSales {...props} />} />
                    <Route path={`${path}/settings`} render={props => <Settings {...props} module={module} updateModule={update} />} />
                    <Route path={`${path}/*`} component={NotFound} />
                </Switch>
            </main>
        </Parent>
    );
}

export default BOMParent;