import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Select, MenuItem } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

import Crud from './crud';
import NotFound from './not-found';
import ViewTitle from './view-title';

import AuthContext from '../context';
import { del, post, put } from '../utils/api-services';
import helpers from '../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    tableContainer: {
        width: '100%',
    }
}));

const Settings = ({ module, updateModule }) => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const [state, setState] = useState({
        loading: true,
        module: {},
        options: [],
        option: {
            idoption_module: 0,
            name: '',
            path: '/',
            icon: '',
            type: 'operation',
            visible: false
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Settings';

        const permissions = helpers.getPermissions();
        if (module !== null && JSON.stringify(state.module) !== JSON.stringify(module)) {
            setState(prev => ({ ...prev, permissions, module, options: module.options, loading: false }));
        }
    }, [module]);

    const handleChange = ({ target }) => setState(prev => ({ ...prev, option: { ...prev.option, [target.name]: target.value } }));

    const validateForm = (type) => {
        const { idoption_module, name, path } = state.option;

        switch (type) {
            case 'update':
                const data = state.options.filter(value => value.idoption_module === idoption_module);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.option);
                    if (before !== after) {
                        if (name.trim() && path.trim()) {
                            return true;
                        } else {
                            showToast('All fields is required', 'error');
                            return false;
                        }
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select a data', 'error');
                    return false;
                }

            case 'store':

                if (name.trim() && path.trim()) {
                    return true;
                } else {
                    showToast('All fields is required', 'error');
                    return false;
                }

            case 'delete':
                if (state.option?.idoption_module) {
                    return true;
                } else {
                    showToast('Select some option', 'error');
                    return false;
                }
            default:
                break;
        }
    }

    const clear = () => {
        setState(prev => ({ ...prev, option: { ...prev.option, idoption_module: 0, name: '', path: '/', icon: '' } }));
    }

    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/optionModule/store', { ...state.option, idmodule: state.module.idmodule });
            if (response.status === 200) {
                updateModule(response.data);
                showToast(response.message);
            } else {
                console.log('[Settings] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false;
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { idoption_module } = state.option;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/optionModule/${idoption_module}/update`, { ...state.option, idmodule: state.module.idmodule });
            if (response.status === 200) {
                updateModule(response.data);
                showToast(response.message);
            } else {
                console.log('[Settings] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idoption_module } = state.option;
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/optionModule/${idoption_module}`, { idmodule: state.module.idmodule });
            if (response.status === 200) {
                updateModule(response.data);
                showToast(response.message);
            } else {
                console.log('[Settings] delete error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const getVisibleField = params => {
        const value = params.row.visible ? 'Yes' : 'No';
        return value;
    }

    const columns = [
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'path', headerName: 'Path', flex: 1 },
        { field: 'icon', headerName: 'Icon', flex: 1 },
        { field: 'type', headerName: 'Type', flex: 1 },
        { field: 'visible', headerName: 'Visible', flex: 1, valueGetter: getVisibleField }
    ];

    if (!helpers.isAdmin()) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle title='Settings' />
            <div>
                <Grid container>
                    <Grid item xs={12} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Name'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Name'
                                    name='name'
                                    value={state.option.name}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Path'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Path'
                                    name='path'
                                    value={state.option.path}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Icon'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Icon'
                                    name='icon'
                                    value={state.option.icon}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>{'Type'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <Select
                                    name='type'
                                    value={state.option.type}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={'operation'}>{'Operation'}</MenuItem>
                                    <MenuItem value={'reporting'}>{'Reporting'}</MenuItem>
                                    <MenuItem value={'settings'}>{'Settings'}</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>{'Visible'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <Select
                                    name='visible'
                                    value={state.option.visible}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={true}>{'Yes'}</MenuItem>
                                    <MenuItem value={false}>{'No'}</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
            </div>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.options}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idoption_module}
                    loading={state.loading}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, option: row }))}
                />
            </div>
        </div>
    );
}

export default Settings;