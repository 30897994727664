import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, TextField, Tooltip, IconButton, Icon, InputAdornment } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation, Link } from 'react-router-dom';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import ViewTitle from '../../common/view-title';

import AuthContext from '../../context';
import { get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%',
        '& .ss': {
            backgroundColor: '#EFF2FB'
        }
    }
}));

const defSede = { id: 0, name: 'Choose a sede' };
const defDepartment = { id: 0, name: 'Choose a department' };

const LaborParam = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [state, setState] = useState({
        loading: true,
        sedes: [],
        departments: [],
        labors: [],
        data: [],
        param: {
            idproduct_sale_labor_param: 0,
            tax_index: 0,
            supervision: 0,
            irs: 0,
            support_waste: 0,
            h2b_expense: 0,
            idsede: 0,
            sede: defSede,
            iddepartment: 0,
            department: defDepartment
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Labor params';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/laborParam');
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                data: response?.data ?? [],
                sedes: response?.sedes ?? [],
                departments: response?.departments ?? [],
                labors: response?.labors ?? []
            }));
        } else {
            console.log('[LaborParam] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, param: { ...prev.param, [target.name]: target.value } }));

    const validateForm = (param) => {
        const { idproduct_sale_labor_param, tax_index, supervision, irs, support_waste, h2b_expense, sede, department } = state.param;

        switch (param) {
            case 'update':
                const data = state.data.filter(value => value.idproduct_sale_labor_param === idproduct_sale_labor_param);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.param);
                    if (before !== after) {
                        if (Number(tax_index) && Number(supervision) && Number(irs) && Number(support_waste) && Number(h2b_expense) && Number(sede?.id) && Number(department?.id)) {
                            const cond = window.confirm(`If you update, then all affected data will be updated: \n- Labors \n- Recipe labors \n\nDo you want to continue with this update?`);

                            return cond;
                        } else {
                            showToast('All fields are required', 'error');
                            return false;
                        }
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select some data', 'error');
                    return false;
                }

            case 'store':
                if (Number(tax_index) && Number(supervision) && Number(irs) && Number(support_waste) && Number(h2b_expense) && Number(sede?.id) && Number(department?.id)) {
                    return true;
                } else {
                    showToast('All fields are required', 'error');
                    return false;
                }

            default:
                break;
        }
    }

    const clear = () => {
        setState(prev => ({
            ...prev,
            param: {
                ...prev.param,
                idproduct_sale_labor_param: 0,
                tax_index: 0,
                supervision: 0,
                irs: 0,
                support_waste: 0,
                h2b_expense: 0,
                sede: defSede,
                department: defDepartment
            }
        }));
    }

    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/laborParam/store', state.param);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? prev.data }));
                showToast(response.message);
            } else {
                console.log('[LaborParam] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { idproduct_sale_labor_param } = state.param;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/laborParam/${idproduct_sale_labor_param}/update`, state.param);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? prev.data }));
                showToast(response.message);
            } else {
                console.log('[LaborParam] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const restoreValues = async (id) => {
        const cond = window.confirm('Are you sure you want to restore to original values?');

        if (cond) {
            showLoader(true);
            const response = await put(`/laborParam/${id}/restoreValues`, { from: 'laborParam' });
            if (response.status === 200) {
                setState(prev => ({ ...prev, labors: response?.data ?? prev.data }));
                showToast(response.message);
            } else {
                console.log('[LaborParam] restoreValues error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const valueGetter = ({ row, field }) => row[field]?.name ?? 'N/A';

    const renderCell = ({ row }) => {
        return (
            <Link to={`/history/product_sale_labor_param/${row.idproduct_sale_labor_param}`} target='_blank'>
                <Tooltip title='Show history' placement='top'>
                    <IconButton>
                        <Icon>{'history'}</Icon>
                    </IconButton>
                </Tooltip>
            </Link>
        );
    }

    const renderCell2 = ({ id, row }) => {
        return (
            <>
                <Tooltip title='Restore to original values' placement='top'>
                    <IconButton onClick={() => restoreValues(id)}>
                        <Icon>{'fast_rewind'}</Icon>
                    </IconButton>
                </Tooltip>
                <Link to={`/bom/recipe/detail?id=${row.idproduct_sale}`} target='_blank'>
                    <Tooltip title='Show recipe' placement='top'>
                        <IconButton>
                            <Icon>{'visibility'}</Icon>
                        </IconButton>
                    </Tooltip>
                </Link>
            </>
        );
    }

    const columns = [
        { field: 'sede', headerName: 'Sede', flex: 1, minWidth: 130, valueGetter },
        { field: 'department', headerName: 'Department', flex: 1, minWidth: 130, valueGetter },
        { field: 'tax_index', headerName: 'Tax index', flex: 1, minWidth: 130 },
        { field: 'supervision', headerName: 'PS - Supervision', flex: 1, minWidth: 130 },
        { field: 'irs', headerName: 'I - IRS', flex: 1, minWidth: 130 },
        { field: 'support_waste', headerName: 'PW - Support & Waste', flex: 1, minWidth: 130 },
        { field: 'h2b_expense', headerName: 'H - H2B Expense', flex: 1, minWidth: 130 },
        { field: 'actions', headerName: 'Actions', flex: 0.5, minWidth: 65, renderCell }
    ]

    const laborColumns = [
        { field: 'header', headerName: 'Recipe', flex: 1, minWidth: 100, valueGetter },
        { field: 'code', headerName: 'Code', flex: 1, minWidth: 100, valueGetter: ({ row }) => row?.labor?.code ?? '?' },
        { field: 'piece_rate', headerName: 'Piece rate', flex: 0.7 },
        { field: 'unit_qty', headerName: 'Unit qty', flex: 0.7 },
        { field: 'pack_qty', headerName: 'Pack qty', flex: 0.7 },
        { field: 'actions', headerName: 'Actions', flex: 1, renderCell: renderCell2 }
    ]

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle title='Labor parameters' />
            <div>
                <Grid container>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormGroup>
                            <FormLabel>{'Sede'}</FormLabel>

                            <Autocomplete
                                options={state.sedes}
                                value={state.param.sede}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(e, sede) => setState(prev => ({ ...prev, param: { ...prev.param, sede } }))}
                                renderInput={(params) => <TextField {...params} placeholder='Sede' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormGroup>
                            <FormLabel>{'Department'}</FormLabel>

                            <Autocomplete
                                options={state.departments}
                                value={state.param.department}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(e, department) => setState(prev => ({ ...prev, param: { ...prev.param, department } }))}
                                renderInput={(params) => <TextField {...params} placeholder='Department' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                        <FormGroup>
                            <FormLabel>{'Tax index'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Tax index'
                                    name='tax_index'
                                    value={state.param.tax_index}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                        <FormGroup>
                            <FormLabel>{'PS - Supervision'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Supervision'
                                    name='supervision'
                                    endAdornment={<InputAdornment position='end'>{'%'}</InputAdornment>}
                                    value={state.param.supervision}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                        <FormGroup>
                            <FormLabel>{'I - IRS'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='IRS'
                                    name='irs'
                                    endAdornment={<InputAdornment position='end'>{'%'}</InputAdornment>}
                                    value={state.param.irs}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                        <FormGroup>
                            <FormLabel>{'PW - Support & Waste'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Support & Waste'
                                    name='support_waste'
                                    endAdornment={<InputAdornment position='end'>{'%'}</InputAdornment>}
                                    value={state.param.support_waste}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                        <FormGroup>
                            <FormLabel>{'H - H2B Expense'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='H2B Expense'
                                    name='h2b_expense'
                                    endAdornment={<InputAdornment position='end'>{'%'}</InputAdornment>}
                                    value={state.param.h2b_expense}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
            </div>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                cancel={clear}
            />

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.data}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    hideFooter
                    getRowId={row => row.idproduct_sale_labor_param}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, param: row }))}
                />
            </div>

            <br />
            <br />
            <br />

            <ViewTitle title='Labors edited' sedeFlag />
            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.labors}
                    columns={laborColumns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    hideFooter
                    getRowId={row => row.idproduct_sale_labor_ref}
                    getRowClassName={({ row }) => row?.header?.supplier_shed ? 'ss' : ''}
                />
            </div>
        </div>
    );
}

export default LaborParam;