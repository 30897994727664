import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Tooltip, IconButton, Icon, Collapse, Button, TextField } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

import AuthContext from '../../context';
import { post, del, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import themeColors from '../../assets/colors';

const useStyles = makeStyles(() => ({
    clickableRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 8,
        paddingLeft: 10,
        userSelect: 'none',
        marginBottom: 10,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(0,0,0,0.04)'
        }
    },
    buttonContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 15
    },
    store: {
        width: '100%',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    update: {
        width: '100%',
        backgroundColor: '#ffc107',
        borderColor: '#ffc107',
        '&:hover': {
            backgroundColor: '#ffc107',
            borderColor: '#ffc107',
            boxShadow: 'none',
        }
    },
    tableContainer: {
        width: '100%',
        '& .total': {
            backgroundColor: '#E0F8E0',
            fontWeight: 'bold'
        },
        '& .list-0': {
            backgroundColor: themeColors.list[0]
        },
        '& .list-1': {
            backgroundColor: themeColors.list[1]
        },
        '& .list-2': {
            backgroundColor: themeColors.list[2]
        },
        '& .list-3': {
            backgroundColor: themeColors.list[3]
        },
        '& .list-4': {
            backgroundColor: themeColors.list[4]
        },
        '& .list-5': {
            backgroundColor: themeColors.list[5]
        },
        '& .list-6': {
            backgroundColor: themeColors.list[6]
        },
        '& .unselected': {
            color: '#DF0101'
        }
    }
}));

const RecipeMaterials = ({ recipe, materials, data, permissions, subassemblies, syncTotals }) => {

    const classes = useStyles();
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const [showForm, setShowForm] = useState(false);
    const [state, setState] = useState({
        recipe: {},
        materials: [],
        data: [],
        form: {
            id: 0,
            product: { id: 0, name: 'Choose a product' },
            unit_used_cost: 0,
            unit_used_material: 0,
            pack_qty: 0,
            shrink_unit_percent: 0,
            shrink_pack_percent: 0,
            idproduct_sale: 0
        },
        subassemblies: [],
        permissions: {}
    });

    useEffect(() => {
        setState(prev => ({ ...prev, recipe, materials, data, subassemblies, permissions }));
        clear();
    }, [recipe, data, subassemblies]);

    const toggleForm = () => setShowForm(prev => !prev);

    const handleChange = ({ target }) => setState(prev => ({ ...prev, form: { ...prev.form, [target.name]: target.value } }));

    const store = async () => {
        showLoader(true);
        const response = await post('/recipe/storeMaterial', { ...state.form, id: state.recipe?.idproduct_sale });
        if (response.status === 200) {
            showToast(response.message);
            setState(prev => ({ ...prev, data: response.data }));
            syncTotals();
            clear();
        } else {
            console.log('[RecipeMaterials] store error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const update = async () => {
        showLoader(true);
        const response = await put(`/recipe/${state.form.id}/updateMaterial`, { ...state.form, idproduct_sale: state.recipe?.idproduct_sale });
        if (response.status === 200) {
            showToast(response.message);
            setState(prev => ({ ...prev, data: response.data }));
            syncTotals();
        } else {
            console.log('[RecipeMaterials] update error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const destroy = async (id) => {
        showLoader(true);
        const response = await del(`/recipe/${id}/material`, { idproduct_sale: state.recipe?.idproduct_sale });
        if (response.status === 200) {
            showToast(response.message);
            setState(prev => ({ ...prev, data: response.data }));
            syncTotals();
            clear()
        } else {
            console.log('[RecipeMaterials] destroy error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const toggleSubassembly = async (id) => {
        const cond = window.confirm('Are you sure you want to make this action?');
        if (cond) {
            showLoader(true);
            const response = await post(`/recipe/${id}/toggleSubassemblyMaterial`, { idproduct_sale: state.recipe.idproduct_sale });
            if (response.status === 200) {
                showToast(response.message);
                setState(prev => ({ ...prev, data: response.data }));
                syncTotals();
            } else {
                console.log('[RecipeMaterials] toggleSubassembly error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const validateForm = (type) => {
        const { product, unit_used_cost, unit_used_material, pack_qty } = state.form;

        if (product.id > 0) {
            if (Number(unit_used_cost) && Number(unit_used_material) && Number(pack_qty)) {
                if (type === 'store') {
                    store();
                } else if (type === 'update') {
                    update();
                }
            } else {
                showToast('The Unit used and Pack qty fields are required', 'warning');
            }
        } else {
            showToast('You must select a product before', 'warning');
        }
    }

    const confirmDelete = (id) => {
        const cond = window.confirm('Are you sure you want to delete this product?\n\nThis action cannot be reversed.');

        if (cond) {
            destroy(id);
        }
    }

    const clear = () => setState(prev => ({
        ...prev,
        form: {
            ...prev.form,
            id: 0,
            product: { id: 0, name: 'Choose a product' },
            unit_used_cost: 0,
            unit_used_material: 0,
            pack_qty: recipe?.pack_qty ?? 0,
            shrink_unit_percent: 0,
            shrink_pack_percent: 0
        }
    }));

    const productColumn = ({ field, row }) => row[field]?.name ?? '?';

    const renderCell = ({ value }) => (value !== undefined) ? `$ ${value}` : '';

    const renderCell2 = (params, currensy = true) => {
        const { value, row, field } = params;
        const { idproduct_sale } = state.recipe;
        let result = '?';

        if (value === undefined) return '';
        if (row.idproduct_sale !== idproduct_sale && Boolean(row.idproduct_sale)) {
            result = row.sub[0][field];
        } else if (row.idproduct_sale === idproduct_sale || row.idproduct_sale_material_ref === 0) {
            result = row[field];
        }

        return currensy ? `$ ${result}` : result;
    };

    const renderSP = params => {
        const { value, row, field } = params;
        const { idproduct_sale } = state.recipe;
        let result = '?';

        if (value === undefined) return '';
        if (row.idproduct_sale !== idproduct_sale && Boolean(row.idproduct_sale)) {
            result = row.sub[0].pack_qty;
        } else if (row.idproduct_sale === idproduct_sale || row.idproduct_sale_material_ref === 0) {
            result = row[field];
        }

        return Number(result).toFixed(4);
    }

    const renderActionsCell = ({ id, row }) => {
        const isSubassembly = Boolean(row.sub);

        return (
            <>
                {
                    (id > 0 && state.permissions.delete_attr && row.idproduct_sale === state.recipe.idproduct_sale) &&
                    <Tooltip title='Delete' placement='top'>
                        <IconButton onClick={() => confirmDelete(id)}>
                            <Icon>{'delete'}</Icon>
                        </IconButton>
                    </Tooltip>
                }
                {
                    (isSubassembly) &&
                    <Tooltip title={(row.sub[0].selected) ? 'Exclude from this recipe' : 'Include in this recipe'} placement='bottom'>
                        <IconButton onClick={() => toggleSubassembly(row.sub[0].idsubassembly_material)}>
                            <Icon>{(row.sub[0].selected) ? 'close' : 'add'}</Icon>
                        </IconButton>
                    </Tooltip>
                }
            </>
        );
    }

    const columns = [
        { field: 'product', headerName: 'Product', flex: 2, minWidth: 180, valueGetter: productColumn },
        { field: 'unit_used_cost', headerName: 'Unit used (cost)', flex: 1, minWidth: 90 },
        { field: 'unit_used_material', headerName: 'Unit used (material)', flex: 1, minWidth: 90 },
        { field: 'unit_cost', headerName: 'Unit cost', flex: 1, minWidth: 90, renderCell },
        { field: 'shrink_unit', headerName: 'Shrink unit', flex: 1, minWidth: 90 },
        { field: 'pack_qty', headerName: 'Pack qty', flex: 1, minWidth: 90, renderCell: params => renderCell2(params, false) },
        { field: 'pack_cost', headerName: 'Pack cost', flex: 1, minWidth: 90, renderCell: renderCell2 },
        { field: 'shrink_pack', headerName: 'Shrink pack', flex: 1, minWidth: 90, renderCell: renderSP },
        { field: 'total_unit_cost', headerName: 'Total unit cost', flex: 1, minWidth: 90, renderCell },
        { field: 'total_pack_cost', headerName: 'Total pack cost', flex: 1, minWidth: 90, renderCell: renderCell2 },
        { field: 'material', headerName: 'Actions', flex: 0.5, minWidth: 90, renderCell: renderActionsCell }
    ];

    return (
        <>
            <div className={classes.clickableRow} onClick={toggleForm}>
                <h2>{'Materials'}</h2>
                <IconButton>
                    <Icon>{showForm ? 'expand_less' : 'expand_more'}</Icon>
                </IconButton>
            </div>

            <Collapse in={showForm}>
                <Grid container>
                    <Grid item xs={6} md={5} lg={5}>
                        <FormGroup>
                            <FormLabel>{'Product'}</FormLabel>

                            <Autocomplete
                                options={state.materials}
                                value={state.form.product}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, product) => {
                                    let pack_qty = 1;
                                    let unit_used_cost = 1;
                                    let unit_used_material = 1;
                                    if (product?.product_type?.divisible) {
                                        pack_qty = state.recipe?.pack_qty ?? pack_qty;
                                        unit_used_cost = (Number(1) / Number(pack_qty)).toFixed(2);
                                        unit_used_material = unit_used_cost;
                                    }
                                    setState(prev => ({ ...prev, form: { ...prev.form, product, pack_qty, unit_used_cost, unit_used_material } }));
                                }}
                                renderInput={(params) => <TextField {...params} placeholder='Product' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                        <FormGroup>
                            <FormLabel>{'Unit used (cost)'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Unit used (cost)'
                                    name='unit_used_cost'
                                    value={state.form.unit_used_cost}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                        <FormGroup>
                            <FormLabel>{'Unit used (materials)'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Unit used (materials)'
                                    name='unit_used_material'
                                    value={state.form.unit_used_material}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                        <FormGroup>
                            <FormLabel>{'Pack qty'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Pack qty'
                                    name='pack_qty'
                                    value={state.form.pack_qty}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                        <FormGroup>
                            <FormLabel>{'Shrink unit (%)'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Shrink unit'
                                    name='shrink_unit_percent'
                                    value={state.form.shrink_unit_percent}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                        <FormGroup>
                            <FormLabel>{'Shrink pack (%)'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Shrink pack'
                                    name='shrink_pack_percent'
                                    value={state.form.shrink_pack_percent}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={2} xl={1} className={classes.buttonContainer}>
                        <Button
                            variant='contained'
                            color='secondary'
                            style={{ width: '100%' }}
                            onClick={clear}
                        >
                            {'Cancel'}
                        </Button>
                    </Grid>
                    {
                        (state.form.idproduct_sale === recipe.idproduct_sale || state.form.id === 0) &&
                        <>
                            {
                                (state.permissions.create_attr && state.form.id === 0) &&
                                <Grid item xs={6} md={2} xl={1} className={classes.buttonContainer}>
                                    <Button
                                        variant='contained'
                                        className={classes.store}
                                        onClick={() => validateForm('store')}
                                    >
                                        {'Store'}
                                    </Button>
                                </Grid>
                            }
                            {
                                (state.permissions.update_attr && state.form.id > 0) &&
                                <Grid item xs={6} md={2} xl={1} className={classes.buttonContainer}>
                                    <Button
                                        variant='contained'
                                        className={classes.update}
                                        onClick={() => validateForm('update')}
                                    >
                                        {'Update'}
                                    </Button>
                                </Grid>
                            }
                        </>
                    }
                </Grid>
            </Collapse>

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.data}
                    columns={columns}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idproduct_sale_material_ref}
                    getRowClassName={({ id, row }) => {
                        const ps = state.recipe.idproduct_sale;
                        if (id !== 0) {
                            const pk = state.subassemblies.findIndex(item => item.idchild === row.idproduct_sale);
                            let className = (row.idproduct_sale !== ps) ? `list-${pk}` : '';
                            if (Boolean(row.sub) && row.sub[0].selected === false) className += ' unselected';

                            return className;
                        } else {
                            return 'total';
                        }
                    }}
                    onRowClick={({ row }) => {
                        setShowForm(true);
                        setState(prev => ({
                            ...prev,
                            form: {
                                ...prev.form,
                                id: row.idproduct_sale_material_ref,
                                product: row.product,
                                unit_used_cost: row.unit_used_cost,
                                unit_used_material: row.unit_used_material,
                                pack_qty: row.pack_qty,
                                idproduct_sale: row.idproduct_sale
                            }
                        }));
                    }}
                    disableColumnFilter
                    disableColumnMenu
                    hideFooter
                />
            </div>
        </>
    );
}

export default RecipeMaterials;