import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Tooltip, IconButton, Icon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, Button, TextField } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

import EditLabor from '../components/edit-labor';

import AuthContext from '../../context';
import { post, del, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import themeColors from '../../assets/colors';

const useStyles = makeStyles(() => ({
    clickableRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 8,
        paddingLeft: 10,
        userSelect: 'none',
        marginBottom: 10,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(0,0,0,0.04)'
        }
    },
    buttonContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 15
    },
    store: {
        width: '100%',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    update: {
        width: '100%',
        backgroundColor: '#ffc107',
        borderColor: '#ffc107',
        '&:hover': {
            backgroundColor: '#ffc107',
            borderColor: '#ffc107',
            boxShadow: 'none',
        }
    },
    tableContainer: {
        width: '100%',
        '& .total': {
            backgroundColor: '#E0F8E0',
            fontWeight: 'bold'
        },
        '& .list-0': {
            backgroundColor: themeColors.list[0]
        },
        '& .list-1': {
            backgroundColor: themeColors.list[1]
        },
        '& .list-2': {
            backgroundColor: themeColors.list[2]
        },
        '& .list-3': {
            backgroundColor: themeColors.list[3]
        },
        '& .list-4': {
            backgroundColor: themeColors.list[4]
        },
        '& .list-5': {
            backgroundColor: themeColors.list[5]
        },
        '& .list-6': {
            backgroundColor: themeColors.list[6]
        },
        '& .unselected': {
            color: '#DF0101'
        }
    },
    table: {
        marginBottom: 0
    },
    emptyCell: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    me: {
        marginLeft: 8,
        padding: 8,
        borderRadius: 8,
        backgroundColor: '#F8ECE0'
    }
}));

const RecipeLabors = ({ recipe, labors, data, permissions, subassemblies, syncTotals }) => {

    const classes = useStyles();
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const [showEdit, setShowEdit] = useState({ open: false, item: {} });
    const [showForm, setShowForm] = useState(false);
    const [state, setState] = useState({
        recipe: {},
        labors: [],
        data: [],
        form: {
            id: 0,
            pr: { id: 0, name: 'Choose a labor' },
            unit_qty: 0,
            pack_qty: 0,
            idproduct_sale: 0
        },
        subassemblies: [],
        permissions: {}
    });

    useEffect(() => {
        setState(prev => ({ ...prev, recipe, labors, data, subassemblies, permissions }));
    }, [recipe, data, subassemblies]);

    const toggleForm = () => setShowForm(prev => !prev);

    const handleChange = ({ target }) => setState(prev => ({ ...prev, form: { ...prev.form, [target.name]: target.value } }));

    const store = async () => {
        showLoader(true);
        const response = await post('/recipe/storeLabor', { ...state.form, id: state.recipe?.idproduct_sale });
        if (response.status === 200) {
            showToast(response.message);
            setState(prev => ({ ...prev, data: response.data }));
            syncTotals();
            clear();
        } else {
            console.log('[RecipeLabors] storeLabor error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const update = async () => {
        showLoader(true);
        const response = await put(`/recipe/${state.form.id}/updateLabor`, { ...state.form, idproduct_sale: state.recipe?.idproduct_sale });
        if (response.status === 200) {
            showToast(response.message);
            setState(prev => ({ ...prev, data: response.data }));
            syncTotals();
        } else {
            console.log('[RecipeLabors] updateLabor error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const destroy = async (id) => {
        showLoader(true);
        const response = await del(`/recipe/${id}/labor`, { idproduct_sale: state.recipe?.idproduct_sale });
        if (response.status === 200) {
            showToast(response.message);
            setState(prev => ({ ...prev, data: response.data }));
            syncTotals();
            clear();
        } else {
            console.log('[RecipeLabors] deleteLabor error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const toggleSubassembly = async (id) => {
        const cond = window.confirm('Are you sure you want to make this action?');
        if (cond) {
            showLoader(true);
            const response = await post(`/recipe/${id}/toggleSubassemblyLabor`, { idproduct_sale: state.recipe.idproduct_sale });
            if (response.status === 200) {
                showToast(response.message);
                setState(prev => ({ ...prev, data: response.data }));
                syncTotals();
            } else {
                console.log('[RecipeLabors] toggleSubassembly error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const validateForm = (type) => {
        const { pr, unit_qty, pack_qty } = state.form;

        if (pr.id > 0) {
            if (Number(unit_qty) && Number(pack_qty)) {
                if (type === 'store') {
                    store();
                } else if (type === 'update') {
                    update();
                }
            } else {
                showToast('The Unit qty and Pack qty fields are required', 'warning');
            }
        } else {
            showToast('You must select a labor before', 'warning');
        }
    }

    const confirmDelete = (id) => {
        const cond = window.confirm('Are you sure you want to delete this labor?\n\nThis action cannot be reversed.');

        if (cond) {
            destroy(id);
        }
    }

    const onCloseEditModal = (labors = null) => {
        setShowEdit({ open: false, item: {} });
        if (labors !== null) {
            setState(prev => ({ ...prev, data: labors }));
        }
        syncTotals();
    }

    const clear = () => setState(prev => ({
        ...prev,
        form: {
            ...prev.form,
            id: 0,
            pr: { id: 0, name: 'Choose a labor' },
            unit_qty: 0,
            pack_qty: 0,
        }
    }));

    const renderPQ = ({ row, field }) => {
        const { idproduct_sale } = state.recipe;
        let result = '?';

        if (row.idproduct_sale !== idproduct_sale && Boolean(row.idproduct_sale)) {
            result = row.sub[0][field];
        } else if (row.idproduct_sale === idproduct_sale) {
            result = row[field];
        }

        return result;
    }

    const renderPR = ({ value }) => (value !== undefined) ? `$ ${value}` : '';

    const renderActionsCell = ({ id, row }) => {
        const isSubassembly = Boolean(row.sub);

        return (
            <>
                {
                    (id > 0 && state.permissions.delete_attr && row.idproduct_sale === state.recipe.idproduct_sale) &&
                    <Tooltip title='Delete' placement='top'>
                        <IconButton onClick={() => confirmDelete(id)}>
                            <Icon>{'delete'}</Icon>
                        </IconButton>
                    </Tooltip>
                }
                {
                    (isSubassembly) &&
                    <Tooltip title={(row.sub[0].selected) ? 'Exclude from this recipe' : 'Include in this recipe'} placement='bottom'>
                        <IconButton onClick={() => toggleSubassembly(row.sub[0].idsubassembly_labor)}>
                            <Icon>{(row.sub[0].selected) ? 'close' : 'add'}</Icon>
                        </IconButton>
                    </Tooltip>
                }
            </>
        );
    }

    const columns = [
        { field: 'code', headerName: 'Code', flex: 1, minWidth: 90, valueGetter: ({ field, row }) => row.labor[field] ?? '?' },
        { field: 'type', headerName: 'Type', flex: 1, minWidth: 90, valueGetter: ({ field, row }) => row.labor[field] ?? '?' },
        { field: 'unit_qty', headerName: 'Unit qty', flex: 1, minWidth: 90 },
        { field: 'pack_qty', headerName: 'Pack qty', flex: 1, minWidth: 90, renderCell: renderPQ },
        { field: 'piece_rate', headerName: 'Piece rate', flex: 1, minWidth: 90, renderCell: renderPR },
        { field: 'labor', headerName: 'Actions', flex: 0.5, minWidth: 90, renderCell: renderActionsCell }
    ];

    return (
        <>
            <div className={classes.clickableRow} onClick={toggleForm}>
                <h2>{'Labors'}</h2>
                <IconButton>
                    <Icon>{showForm ? 'expand_less' : 'expand_more'}</Icon>
                </IconButton>
            </div>

            <Collapse in={showForm}>
                <Grid container>
                    <Grid item xs={6} md={3} lg={3}>
                        <FormGroup>
                            <FormLabel>{'Labor'}</FormLabel>

                            <Autocomplete
                                options={state.labors}
                                value={state.form.pr}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, pr) => setState(prev => ({ ...prev, form: { ...prev.form, pr } }))}
                                renderInput={(params) => <TextField {...params} placeholder='Labor' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                        <FormGroup>
                            <FormLabel>{'Unit qty'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Unit qty'
                                    name='unit_qty'
                                    value={state.form.unit_qty}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                        <FormGroup>
                            <FormLabel>{'Pack qty'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Pack qty'
                                    name='pack_qty'
                                    value={state.form.pack_qty}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={2} xl={1} className={classes.buttonContainer}>
                        <Button
                            variant='contained'
                            color='secondary'
                            style={{ width: '100%' }}
                            onClick={clear}
                        >
                            {'Cancel'}
                        </Button>
                    </Grid>
                    {
                        (state.form.idproduct_sale === recipe.idproduct_sale || state.form.id === 0) &&
                        <>
                            {
                                (state.permissions.create_attr && state.form.id === 0) &&
                                <Grid item xs={6} md={2} xl={1} className={classes.buttonContainer}>
                                    <Button
                                        variant='contained'
                                        className={classes.store}
                                        onClick={() => validateForm('store')}
                                    >
                                        {'Store'}
                                    </Button>
                                </Grid>
                            }
                            {
                                (state.permissions.update_attr && state.form.id > 0) &&
                                <Grid item xs={6} md={2} xl={1} className={classes.buttonContainer}>
                                    <Button
                                        variant='contained'
                                        className={classes.update}
                                        onClick={() => validateForm('update')}
                                    >
                                        {'Update'}
                                    </Button>
                                </Grid>
                            }
                        </>
                    }
                </Grid>
            </Collapse>

            <Grid container spacing={3}>
                <Grid item xs={12} xl={6}>
                    <div className={classes.tableContainer}>
                        <DataGrid
                            rows={state.data}
                            columns={columns}
                            density='compact'
                            autoHeight
                            getRowId={row => row.idproduct_sale_labor_ref}
                            getRowClassName={({ row }) => {
                                const ps = state.recipe.idproduct_sale;
                                const pk = state.subassemblies.findIndex(item => item.idchild === row.idproduct_sale);
                                let className = (row.idproduct_sale !== ps) ? `list-${pk}` : '';
                                if (Boolean(row.sub) && row.sub[0].selected === false) className += ' unselected';

                                return className;
                            }}
                            onRowClick={({ row }) => {
                                setShowForm(true);
                                setState(prev => ({ ...prev, form: { ...prev.form, id: row.idproduct_sale_labor_ref, pr: row.labor, unit_qty: row.unit_qty, pack_qty: row.pack_qty, idproduct_sale: row.idproduct_sale } }));
                            }}
                            disableColumnFilter
                            disableColumnMenu
                            hideFooter
                        />
                    </div>
                </Grid>
                <Grid item xs={12} xl={6}>
                    {
                        state.data.map((item, i) => {
                            const index = state.subassemblies.findIndex(value => value.idchild === item.idproduct_sale);
                            const isParent = Boolean(item.idproduct_sale === state.recipe.idproduct_sale);
                            let row = item;
                            if (!isParent) row = item.sub[0];

                            return <FormGroup key={i.toString()}>
                                <TableContainer component={Paper} className='MuiDataGrid-root' style={{ backgroundColor: themeColors.list[index] }} >
                                    <Table size='small' className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.emptyCell}>
                                                    {
                                                        isParent &&
                                                        <Button size='small' variant='contained' onClick={() => setShowEdit({ open: true, item })}>{'Edit'}</Button>
                                                    }
                                                    {
                                                        item.edited &&
                                                        <span className={classes.me}>{'M/E'}</span>
                                                    }
                                                </TableCell>
                                                <TableCell>{'Loaded piece pay'}</TableCell>
                                                <TableCell>{'Total pack cost'}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>{''}</TableCell>
                                                <TableCell>{`$ ${item.loaded_piece_rate_unit}`}</TableCell>
                                                <TableCell>{`$ ${row.loaded_piece_rate_pack}`}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ color: (row.selected === false) ? '#DF0101' : '' }}>{'PS - Supervision'}</TableCell>
                                                <TableCell>{`$ ${item.supervision_unit}`}</TableCell>
                                                <TableCell>{`$ ${row.supervision_pack}`}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ color: (row.selected === false) ? '#DF0101' : '' }}>{'I - IRS'}</TableCell>
                                                <TableCell>{`$ ${item.irs_unit}`}</TableCell>
                                                <TableCell>{`$ ${row.irs_pack}`}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ color: (row.selected === false) ? '#DF0101' : '' }}>{'PW - Support & Waste'}</TableCell>
                                                <TableCell>{`$ ${item.support_waste_unit}`}</TableCell>
                                                <TableCell>{`$ ${row.support_waste_pack}`}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ color: (row.selected === false) ? '#DF0101' : '' }}>{'H - H2B Expense'}</TableCell>
                                                <TableCell>{`$ ${item.h2b_expense_unit}`}</TableCell>
                                                <TableCell>{`$ ${row.h2b_expense_pack}`}</TableCell>
                                            </TableRow>
                                            <TableRow className='total'>
                                                <TableCell style={{ color: (row.selected === false) ? '#DF0101' : '' }}><b>{'TOTAL:'}</b></TableCell>
                                                <TableCell><b>{`$ ${(Number(item.loaded_piece_rate_unit) + Number(item.supervision_unit) + Number(item.irs_unit) + Number(item.support_waste_unit) + Number(item.h2b_expense_unit)).toFixed(4)}`}</b></TableCell>
                                                <TableCell><b>{`$ ${(Number(row.loaded_piece_rate_pack) + Number(row.supervision_pack) + Number(row.irs_pack) + Number(row.support_waste_pack) + Number(row.h2b_expense_pack)).toFixed(4)}`}</b></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </FormGroup>
                        }
                        )
                    }
                </Grid>
            </Grid>

            <EditLabor open={showEdit.open} onClose={onCloseEditModal} labor={showEdit.item} />
        </>
    );
}

export default RecipeLabors;