import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, TextField, Divider, IconButton, Icon, Collapse, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation } from 'react-router-dom';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import DateRange from '../../common/date-range';

import AuthContext from '../../context';
import { get, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import ViewTitle from '../../common/view-title';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    divider: {
        marginBottom: 24
    },
    tableContainer: {
        width: '100%',
        marginTop: 20,
        '& .redRow': {
            backgroundColor: '#FBEFEF'
        }
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    search: {
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    }
}));

const ProductionTimes = () => {

    const sede = JSON.parse(window.localStorage.getItem('@sede'));
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [showFilters, setShowFilters] = useState(false);
    const [state, setState] = useState({
        loading: true,
        departments: [],
        employees: [],
        product_sales: [],
        productions: [],
        filter: {
            startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
            dateRange: `${dayjs().subtract(1, 'day').format('MM/DD/YYYY')} - ${dayjs().format('MM/DD/YYYY')}`,
            departmentf: { id: 0, name: 'All departments' },
            productf: { id: 0, name: 'All product sales' },
            employeef: { id: 0, name: 'All employees' }
        },
        production: {
            idproduction: 0,
            department: {},
            date: dayjs().format('YYYY-MM-DD'),
            product: {},
            employee: {},
            start_time: '',
            end_time: ''
        },
        rangeModal: {
            open: false,
            range: [
                {
                    startDate: new Date(dayjs().subtract(1, 'day').toDate()),
                    endDate: new Date(),
                    key: 'main'
                }
            ]
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Production times';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/productionTimes', state.filter);
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                departments: response?.departments ?? [],
                employees: response?.employees ?? [],
                product_sales: response?.products ?? [],
                productions: response?.data ?? [],
                production: {
                    ...prev.production,
                    department: response?.departments[0] ?? {},
                    employee: response?.employees[0] ?? {},
                    product: response?.products[0] ?? {}
                }
            }));
        } else {
            console.log('[ProductionTimes] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, production: { ...prev.production, [target.name]: target.value } }));

    const handleAutocompleteFilter = (key, value) => setState(prev => ({ ...prev, filter: { ...prev.filter, [key]: value } }));

    const handleModal = () => setState(prev => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));

    const handleDateChange = item => {
        const { startDate, endDate } = item.main;
        const dateRange = `${dayjs(startDate.toISOString()).format('MM/DD/YYYY')} - ${dayjs(endDate.toISOString()).format('MM/DD/YYYY')}`;
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                dateRange,
                startDate: dayjs(startDate.toISOString()).format('YYYY-MM-DD'),
                endDate: dayjs(endDate.toISOString()).format('YYYY-MM-DD')
            },
            rangeModal: { ...prev.rangeModal, range: [item.main] }
        }));
    }

    const validateForm = (option) => {
        const { idproduction, start_time, end_time } = state.production;

        switch (option) {
            case 'update':
                const data = state.productions.filter(value => value.idproduction === idproduction);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.production);
                    if (before !== after) {
                        if (start_time.trim() && end_time.trim()) {
                            return true;
                        } else {
                            showToast('All fields is required', 'error');
                            return false;
                        }
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select some production', 'error');
                    return false;
                }

            default:
                return false;
        }
    }

    const clear = () => {
        setState(prev => ({ ...prev, production: { ...prev.production, position: 0, employee: prev.employees[0], weight: 0, ticket: '', product_qty: 0, qty: 0, total_qty: 0 } }));
    }

    const search = async () => {
        showLoader(true);
        const response = await get('/production/search', state.filter);
        if (response.status === 200) {
            setState(prev => ({ ...prev, productions: response.data }));
            if (!response?.data[0]) showToast('There are no data with these filters', 'warning');
        } else {
            console.log('[ProductionTimes] search error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const update = async () => {
        const { idproduction } = state.production;
        const { filter, production } = state;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/productionTime/${idproduction}/update`, { ...filter, ...production });
            if (response.status === 200) {
                setState(prev => ({ ...prev, productions: response?.data ?? prev.productions }));
                showToast(response.message);
            } else {
                console.log('[ProductionTimes] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const getPosition = params => `${params.row.station}${params.row.section}${params.row.position}`;
    const getName = params => params.value?.name ?? 'N/S';

    const columns = [
        { field: 'date', headerName: 'Date', flex: 0.5 },
        { field: 'position', headerName: 'Position', flex: 0.5, valueGetter: getPosition },
        { field: 'product', headerName: 'Product sale', flex: 1.3, valueGetter: getName },
        { field: 'department', headerName: 'Department', flex: 0.6, valueGetter: getName },
        { field: 'employee', headerName: 'Employee', flex: 0.8, valueGetter: getName },
        { field: 'product_qty', headerName: 'Product qty', flex: 0.4 },
        { field: 'qty', headerName: 'Qty', flex: 0.4 },
        { field: 'total_qty', headerName: 'Total qty', flex: 0.4 },
    ];

    if (state.loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>

            <ViewTitle sedeFlag title='Update production times' />

            <Grid container>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{'Date'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='date'
                                placeholder='Date'
                                value={state.production?.date ?? ''}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{'Position'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='text'
                                placeholder='Position'
                                value={`${state.production?.station ?? ''}${state.production?.section ?? ''}${state.production?.position ?? ''}`}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{'Product'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='text'
                                placeholder='Product'
                                value={state.production?.product?.name ?? ''}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{'Start time'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='datetime-local'
                                placeholder='Start time'
                                name='start_time'
                                value={state.production?.start_time ? dayjs(state.production.start_time).format('YYYY-MM-DDTHH:mm') : ''}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{'End time'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='datetime-local'
                                placeholder='Product'
                                name='end_time'
                                value={state.production?.end_time ? dayjs(state.production.end_time).format('YYYY-MM-DDTHH:mm') : ''}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Crud
                permissions={state.permissions}
                update={update}
                cancel={clear}
            />

            <Divider />

            <div className={classes.row}>
                <h2>{'Filters'}</h2>
                <IconButton onClick={() => setShowFilters(!showFilters)}>
                    <Icon>{showFilters ? 'expand_less' : 'expand_more'}</Icon>
                </IconButton>
            </div>
            <Collapse in={showFilters}>
                <Grid container>
                    <Grid item xs={6} lg={4}>
                        <FormGroup onClick={handleModal}>
                            <FormLabel>{'Date range'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    readOnly
                                    type='text'
                                    placeholder='Date range'
                                    value={state.filter.dateRange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Department'}</FormLabel>
                            <Autocomplete
                                options={state.departments}
                                value={state.filter.departmentf}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(e, value) => handleAutocompleteFilter('departmentf', value)}
                                renderInput={params => <TextField {...params} placeholder='Department' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Product sale'}</FormLabel>
                            <Autocomplete
                                options={state.product_sales}
                                value={state.filter.productf}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(e, value) => handleAutocompleteFilter('productf', value)}
                                renderInput={params => <TextField {...params} placeholder='Product sales' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Employee'}</FormLabel>
                            <Autocomplete
                                options={state.employees}
                                value={state.filter.employeef}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(e, value) => handleAutocompleteFilter('employeef', value)}
                                renderInput={params => <TextField {...params} placeholder='Employee' />}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <div className={classes.buttonsContainer}>
                    <Button
                        variant='contained'
                        className={classes.search}
                        onClick={search}
                    >
                        {'Search'}
                    </Button>
                </div>
            </Collapse>

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.productions}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idproduction}
                    getRowClassName={({ row }) => row.idemployee_pay ? 'redRow' : ''}
                    onRowClick={({ row }) => {
                        const start_time = dayjs(row.start_time).tz(sede.time_zone).format('YYYY-MM-DDTHH:mm');
                        const end_time = dayjs(row.end_time).tz(sede.time_zone).format('YYYY-MM-DDTHH:mm');

                        setState(prev => ({ ...prev, production: { ...row, start_time, end_time } }));
                    }}
                />
            </div>

            <DateRange
                open={state.rangeModal.open}
                range={state.rangeModal.range}
                onClose={handleModal}
                onChange={handleDateChange}
                minDate={dayjs('2022-01-01').toDate()}
            />
        </div>
    );
}

export default ProductionTimes;