import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, IconButton, Icon, Box, Grid, Typography } from '@material-ui/core';

import NavBar from '../../common/nav-bar';
import Module from '../components/module';
import Create from '../components/create';
import Edit from '../components/edit';

import { get, post, put, del } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import AuthContext from '../../context';
import themeColors from '../../assets/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    }
}));

const Home = () => {

    const classes = useStyles();
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const [openCreate, setOpenCreate] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [modules, setModules] = useState([]);
    const [form, setForm] = useState({
        idmodule: 0,
        name: '',
        icon: '',
        path: '/'
    });

    useEffect(() => {
        window.document.title = 'Home';

        makeRequest();
    }, []);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/modules');
        if (response.status === 200) {
            setModules(response?.data ?? []);
        } else {
            console.log('[Home] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const closeModal = () => {
        setOpenCreate(false);
        setOpenEdit(false);
        setForm({ idmodule: 0, name: '', icon: '', path: '/' });
    }

    const handleChange = (e) => {
        setForm(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const store = async () => {
        if (form.name.trim() && form.path.trim()) {
            showLoader(true);
            const response = await post('/module/store', form);
            if (response.status === 200) {
                setModules(response.data);
                showToast(response.message);
                closeModal();
            } else {
                console.log('[Home] store error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        } else {
            showToast('All fields is required', 'error');
        }
    }

    const edit = (item) => {
        setForm(item);
        setOpenEdit(true);
    }

    const update = async () => {
        if (form.name.trim() && form.path.trim()) {
            showLoader(true);
            const response = await put(`/module/${form.idmodule}/update`, form);
            if (response.status === 200) {
                setModules(response.data);
                showToast(response.message);
                closeModal();
            } else {
                console.log('[Home] update error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        } else {
            showToast('All fields is required', 'error');
        }
    }

    const destroy = async (item) => {
        const next = window.confirm('Are you sure you want to delete this module?');
        if (next) {
            showLoader(true);
            const response = await del(`/module/${item.idmodule}`);
            if (response.status === 200) {
                setModules(response.data);
                showToast(response.message);
            } else {
                console.log('[Home] destroy error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        }
    }

    const isAdmin = helpers.isAdmin();

    return (
        <div className={classes.root} >
            <NavBar />
            <Box component={Grid} display='flex' style={{ marginTop: 50, paddingLeft: '20%', paddingRight: '20%' }}>
                <Grid container spacing={3}>
                    {
                        modules.length ?
                            modules.map(value => {
                                return (
                                    <Module
                                        key={value.idmodule}
                                        value={value}
                                        onEdit={() => edit(value)}
                                        onDestroy={() => destroy(value)}
                                    />
                                );
                            }) :
                            <Typography>{'No modules are assigned to you yet'}</Typography>
                    }
                    {
                        isAdmin &&
                        <Grid item lg={3} xs={12} >
                            <Card onClick={() => setOpenCreate(true)}>
                                <CardContent
                                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}
                                >
                                    <Typography className={classes.title} style={{ color: themeColors.link }}>
                                        {'Create module'}
                                    </Typography>
                                    <IconButton >
                                        <Icon style={{ color: themeColors.link }} >add</Icon>
                                    </IconButton>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                </Grid>
            </Box>
            <Create
                open={openCreate}
                onClose={closeModal}
                form={form}
                onChange={handleChange}
                store={store}
            />
            <Edit
                open={openEdit}
                onClose={closeModal}
                form={form}
                onChange={handleChange}
                update={update}
            />
        </div>
    );
}

export default Home;