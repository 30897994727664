import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormLabel, FormGroup, FormControl, FilledInput, TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactExport from 'react-data-export';

import Loader from '../../common/loader';
import DateRange from '../../common/date-range';

import AuthContext from '../../context';
import helpers from '../../utils/helpers';
import { get } from '../../utils/api-services';
import ViewTitle from '../../common/view-title';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    buttonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        marginBottom: 20
    },
    search: {
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    download: {
        marginRight: 20,
        backgroundColor: '#6E6E6E',
        borderColor: '#6E6E6E',
        '&:hover': {
            backgroundColor: '#6E6E6E',
            borderColor: '#6E6E6E',
            boxShadow: 'none'
        }
    }
}));

const ReportsProduction = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const [state, setState] = useState({
        loading: true,
        departments: [],
        products: [],
        employees: [],
        filter: {
            startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
            dateRange: `${dayjs().subtract(1, 'day').format('MM/DD/YYYY')} - ${dayjs().format('MM/DD/YYYY')}`,
            department: { id: 0, name: 'All departments' },
            product: { id: 0, name: 'All product sales' },
            employee: { id: 0, name: 'All employees' }
        },
        data: [],
        rangeModal: {
            open: false,
            range: [
                {
                    startDate: new Date(dayjs().subtract(1, 'day').toDate()),
                    endDate: new Date(),
                    key: 'main'
                }
            ]
        }
    });

    useEffect(() => {
        makeRequest();
    }, []);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/reports/indexProductions', state.filter);
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                departments: response?.departments ?? [],
                products: response?.products ?? [],
                employees: response?.employees ?? []
            }));
        } else {
            console.log('[ReportsProduction] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const handleDateChange = item => {
        const { startDate, endDate } = item.main;
        const dateRange = `${dayjs(startDate.toISOString()).format('MM/DD/YYYY')} - ${dayjs(endDate.toISOString()).format('MM/DD/YYYY')}`;
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                dateRange,
                startDate: dayjs(startDate.toISOString()).format('YYYY-MM-DD'),
                endDate: dayjs(endDate.toISOString()).format('YYYY-MM-DD')
            },
            rangeModal: { ...prev.rangeModal, range: [item.main] }
        }));
    }

    const handleModal = () => setState(prev => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));

    const handleChangeAutocomplete = (key, value) => setState(prev => ({ ...prev, filter: { ...prev.filter, [key]: value } }));

    const search = async () => {
        showLoader(true);
        const response = await get('/reports/searchProductions', state.filter);
        if (response.status === 200) {
            const data = response?.data ?? [];
            setState(prev => ({ ...prev, data }));

            if (!response?.data[0]) showToast('There are no data with these filters', 'warning');
        } else {
            console.log('[ReportsProduction] search error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    if (state.loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle title='Production' />
            <Grid container>
                <Grid item xs={6} lg={4}>
                    <FormGroup onClick={handleModal}>
                        <FormLabel>{'Date range'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                readOnly
                                type='text'
                                placeholder='Date range'
                                value={state.filter.dateRange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Department'}</FormLabel>
                        <Autocomplete
                            options={state.departments}
                            value={state.filter.department}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleChangeAutocomplete('department', value)}
                            renderInput={params => <TextField {...params} placeholder='Department' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Product sale'}</FormLabel>
                        <Autocomplete
                            options={state.products}
                            value={state.filter.product}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleChangeAutocomplete('product', value)}
                            renderInput={params => <TextField {...params} placeholder='Products' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Employee'}</FormLabel>
                        <Autocomplete
                            options={state.employees}
                            value={state.filter.employee}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleChangeAutocomplete('employee', value)}
                            renderInput={params => <TextField {...params} placeholder='Employee' />}
                        />
                    </FormGroup>
                </Grid>
            </Grid>

            <div className={classes.buttonsContainer}>
                {
                    state.data.length > 0 &&
                    <ExcelFile
                        filename={`${dayjs().format('YYYY-MM-DD')}-production-report`}
                        element={<Button variant='contained' className={classes.download}>{'Download data'}</Button>}
                    >
                        <ExcelSheet data={state.data} name='Production report'>
                            <ExcelColumn label='ID' value='idproduction' />
                            <ExcelColumn label='Date' value='date' />
                            <ExcelColumn label='Station' value='station' />
                            <ExcelColumn label='Section' value='section' />
                            <ExcelColumn label='Position' value='position' />
                            <ExcelColumn label='Qty' value='qty' />
                            <ExcelColumn label='Product qty' value='product_qty' />
                            <ExcelColumn label='Total qty' value='total_qty' />
                            <ExcelColumn label='Weight' value='weight' />
                            <ExcelColumn label='Average weight' value='average_weight' />
                            <ExcelColumn label='Department' value='department.name' />
                            <ExcelColumn label='Product code' value='product.code' />
                            <ExcelColumn label='Product description' value='product.description' />
                            <ExcelColumn label='Product average weight' value='product.average_weight' />
                            <ExcelColumn label='Product Supplier (Shed)' value='product.supplier_shed' />
                            <ExcelColumn label='Employee code (header)' value='employee.header.code' />
                            <ExcelColumn label='Employee name (header)' value='employee.header.name' />
                            <ExcelColumn label='Employee code' value='employee.code' />
                            <ExcelColumn label='Employee name' value='employee.name' />
                            <ExcelColumn label='Scan type' value='scan_type' />
                            <ExcelColumn label='Start time' value='start' />
                            <ExcelColumn label='End time' value='end' />
                            <ExcelColumn label='Time diff (min)' value='diff' />
                            <ExcelColumn label='Hours break' value='hours_break' />
                            <ExcelColumn label='Hours break (min)' value='hours_break_min' />
                        </ExcelSheet>
                    </ExcelFile>
                }

                <Button
                    variant='contained'
                    className={classes.search}
                    onClick={search}
                >
                    {'Search'}
                </Button>
            </div>

            <DateRange
                open={state.rangeModal.open}
                range={state.rangeModal.range}
                onClose={handleModal}
                onChange={handleDateChange}
                minDate={dayjs('2022-01-01').toDate()}
            />
        </div>
    );
}

export default ReportsProduction;