import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import ViewTitle from '../../common/view-title';
import ProductionStartTimeMobile from './production-start-time-mobile';
import ProductionStartTimeDesktop from './production-start-time-desktop';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    }
}));

const ProductionStartTime = () => {

    const classes = useStyles();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

    useEffect(() => {
        window.document.title = 'Production start time';

        window.addEventListener('resize', handleWindowSize);

        return () => window.removeEventListener('resize', handleWindowSize);
    }, []);

    const handleWindowSize = () => setIsMobile(window.innerWidth < 500);

    return (
        <div className={classes.root}>
            <ViewTitle title='Production start time' sedeFlag />

            {isMobile ? <ProductionStartTimeMobile /> : <ProductionStartTimeDesktop />}
        </div>
    );
}

export default ProductionStartTime;