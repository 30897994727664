import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Button, Modal, Typography, Tooltip, IconButton, Icon } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation, Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import ViewTitle from '../../common/view-title';
import Trash from '../../common/trash';
import StationsToPrint from '../components/stations-to-print';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import themeColors from '../../assets/colors';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%'
    },
    stationsToPrint: {
        display: 'none'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    print: {
        marginRight: 15,
        marginLeft: 15,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none'
        }
    },
    modalRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.background.default,
        padding: 20,
        width: '70%',
        borderRadius: 10
    }
}));

const Stations = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [selectedStations, setSelectedStations] = useState([]);
    const [state, setState] = useState({
        loading: true,
        stations: [],
        station: {
            idstation: 0,
            description: 0
        },
        modal: {
            sections: '',
            positions: 0
        },
        stationsToPrint: [],
        permissions: {}
    });

    const printRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printRef.current
    });

    useEffect(() => {
        window.document.title = 'Stations';

        validatePermissions();
    }, []);

    useEffect(() => {
        if (state.stationsToPrint.length > 0) {
            handlePrint();
        }
    }, [state.stationsToPrint]);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/stations');
        if (response.status === 200) {
            setState(prev => ({ ...prev, loading: false, stations: response?.data ?? [] }));
        } else {
            console.log('[Stations] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, station: { ...prev.station, [target.name]: target.value } }));

    const handleChangeModal = ({ target, nativeEvent }) => {
        if (nativeEvent.data !== ' ')
            setState(prev => ({ ...prev, modal: { ...prev.modal, [target.name]: target.value } }));
    }

    const validateForm = (station) => {
        const { idstation, description } = state.station;

        switch (station) {
            case 'update':
                const data = state.stations.filter(value => value.idstation === idstation);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.station);
                    if (before !== after) {
                        if (Number(description)) {
                            return true;
                        } else {
                            showToast('All fields is required', 'error');
                            return false;
                        }
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select some station', 'error');
                    return false;
                }

            case 'store':

                if (Number(description)) {
                    return true;
                } else {
                    showToast('All fields is required', 'error');
                    return false;
                }

            case 'delete':
                if (idstation === 0) {
                    showToast('Select some station', 'error');
                    return false;
                }
                const cond = window.confirm('Are you sure you want to delete this station?');

                return Boolean(idstation && cond);
            default:
                break;
        }
    }

    const clear = () => {
        setState(prev => ({ ...prev, station: { ...prev.station, idstation: 0, description: 0 } }));
    }

    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/station/store', state.station);
            if (response.status === 200) {
                setState(prev => ({ ...prev, stations: response?.data ?? prev.stations }));
                showToast(response.message);
            } else {
                console.log('[Stations] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { idstation } = state.station;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/station/${idstation}/update`, state.station);
            if (response.status === 200) {
                setState(prev => ({ ...prev, stations: response?.data ?? prev.stations }));
                showToast(response.message);
            } else {
                console.log('[Stations] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idstation } = state.station;
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/station/${idstation}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, stations: response?.data ?? prev.stations }));
                showToast(response.message);
            } else {
                console.log('[Stations] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const getStationsToPrint = async () => {
        const { sections, positions } = state.modal;
        const ids = selectedStations;

        if (ids.length < 1) {
            showToast('Select some station', 'error');
            return null;
        }
        if (!Boolean(sections.trim()) || !Boolean(Number(positions))) {
            showToast('Both fields are required', 'error');
            return null;
        }

        let form = { idstation: ids.join('-') };

        showLoader(true);
        const response = await get('/stations/print', form);
        if (response.status === 200) {
            setState(prev => ({ ...prev, stationsToPrint: response?.data ?? [] }));

            if (response.data?.length === 0) showToast('No stations to print', 'warning');
        } else {
            console.log('[Stations] getStationsToPrint error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const renderCell = ({ row }) => {
        return (
            <Link to={`/history/stations/${row.idstation}`} target='_blank'>
                <Tooltip title='Show history' placement='top'>
                    <IconButton>
                        <Icon>{'history'}</Icon>
                    </IconButton>
                </Tooltip>
            </Link>
        );
    }

    const columns = [
        { field: 'description', headerName: 'Description', flex: 1 },
        { field: 'actions', headerName: 'Actions', flex: 0.3, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle title='Stations' />
            <div>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <FormGroup>
                            <FormLabel>{'Description'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Description'
                                    name='description'
                                    value={state.station.description}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
            </div>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            <div className={classes.buttonContainer}>
                <div>
                    <Button
                        variant='contained'
                        className={classes.print}
                        onClick={() => setShowModal(true)}
                    >
                        {'Print barcodes'}
                    </Button>
                </div>
                {
                    state.permissions.delete_attr &&
                    <Trash table='stations' id='idstation' onRefresh={makeRequest} />
                }
            </div>

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.stations}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idstation}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, station: row }))}
                    checkboxSelection
                    disableSelectionOnClick
                    onSelectionModelChange={array => setSelectedStations(array)}
                />
            </div>

            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                className={classes.modalRoot}
            >
                <div className={classes.modalContainer}>
                    <Typography variant='h2'>{'Create barcodes'}</Typography>
                    <Grid container>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormGroup>
                                <FormLabel>{'Sections'}</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                >
                                    <FilledInput
                                        autoComplete='off'
                                        type='text'
                                        placeholder='Ej. "WG,A,B..."'
                                        name='sections'
                                        value={state.modal.sections}
                                        onChange={handleChangeModal}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormGroup>
                                <FormLabel>{'Positions per section'}</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                >
                                    <FilledInput
                                        autoComplete='off'
                                        type='number'
                                        name='positions'
                                        value={state.modal.positions}
                                        onChange={handleChangeModal}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <div className={classes.buttonContainer}>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={() => setShowModal(false)}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            variant='contained'
                            className={classes.print}
                            onClick={getStationsToPrint}
                        >
                            {'Print'}
                        </Button>
                    </div>
                </div>
            </Modal>

            <div className={classes.stationsToPrint}>
                <div ref={printRef}>
                    <StationsToPrint data={state.stationsToPrint} other={state.modal} />
                </div>
            </div>
        </div>
    );
}

export default Stations;