import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import Loader from '../../common/loader';
import CreateCheck from '../components/create-check';

const Logo = require('../../assets/images/logo-full.png').default;

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20
    },
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    logo: {
        '@media (min-width: 480px)': {
            width: '90%'
        },
        '@media (min-width: 720px)': {
            width: '60%'
        },
        '@media (min-width: 1000px)': {
            width: '40%'
        }
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '@media (min-width: 480px)': {
            width: '90%'
        },
        '@media (min-width: 720px)': {
            width: '60%'
        },
        '@media (min-width: 1000px)': {
            width: '40%'
        }
    },
    button: {
        display: 'inherit',
        width: '30%',
        backgroundColor: '#198754',
        borderColor: '#198754',
        '&:hover': {
            backgroundColor: '#198754',
            borderColor: '#198754',
            boxShadow: 'none',
        },
    },
}));

const ChecksIndex = ({ permissions }) => {

    const classes = useStyles();
    const [showCreate, setShowCreate] = useState(false);
    const [state, setState] = useState({
        loading: true,
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Checks';

        setState(prev => ({ ...prev, permissions, loading: false }));
    }, [permissions]);

    if (state.loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <img className={classes.logo} src={Logo} alt='logo cfg' />
                <div className={classes.buttonsContainer}>
                    {
                        state.permissions?.create_attr &&
                        <Button
                            variant='contained'
                            className={classes.button}
                            onClick={() => setShowCreate(true)}
                        >
                            {'Create new check'}
                        </Button>
                    }

                    <Button
                        variant='contained'
                        className={classes.button}
                        component={Link}
                        to={'/ticket/checks/list'}
                    >
                        {'Show checks'}
                    </Button>
                </div>
            </div>

            <CreateCheck
                open={showCreate}
                onClose={() => setShowCreate(false)}
            />
        </div>
    );
}

export default ChecksIndex;