import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Icon } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import Loader from '../../common/loader';
import NotFound from '../../common/not-found';

import AuthContext from '../../context';
import { del, get } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import themeColors from '../../assets/colors';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%',
        marginBottom: 24
    },
    controls: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    delete: {
        marginLeft: 20,
        backgroundColor: '#dc3545',
        borderColor: '#dc3545',
        '&:hover': {
            backgroundColor: '#dc3545',
            borderColor: '#dc3545',
            boxShadow: 'none',
        }
    },
    edit: {
        marginLeft: 20,
        backgroundColor: '#ffc107',
        borderColor: '#ffc107',
        '&:hover': {
            backgroundColor: '#ffc107',
            borderColor: '#ffc107',
            boxShadow: 'none',
        }
    },
    inTransit: {
        marginLeft: 20,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none'
        }
    },
    received: {
        marginLeft: 20,
        backgroundColor: '#198754',
        borderColor: '#198754',
        '&:hover': {
            backgroundColor: '#198754',
            borderColor: '#198754',
            boxShadow: 'none',
        }
    },
    status: {
        padding: 10,
        borderStyle: 'solid',
        borderRadius: 10,
        borderWidth: 1,
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
        fontSize: 16,
        fontWeight: 'bold'
    },
    icon: {
        marginRight: 8
    }
}));

const TicketLocationTransferDetail = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const location = useLocation();
    const { id } = useParams();
    const [state, setState] = useState({
        loading: true,
        tt: {},
        tickets: [],
        code: '',
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Ticket location transfer';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(location.pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        const response = await get(`/invTicketLocationTransfer/${id}`);
        if (response.status === 200) {
            setState(prev => ({ ...prev, loading: false, tt: response.tt, tickets: response.tickets }));
        } else {
            console.log('[TicketLocationTransferDetail] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
    }


    const cancel = async () => {
        const cond = window.confirm('Are you sure you want to cancel this transfer?\n\nThis action cannot be reversed.');
        if (cond) {
            showLoader(true);
            const response = await del(`/invTicketLocationTransfer/${id}/cancel`);
            if (response.status === 200) {
                showToast(response.message);
                makeRequest();
            } else {
                console.log('[TicketLocationTransferDetail] cancel error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const getDate = ({ value }) => dayjs(value).format('MM/DD/YYYY');

    const getName = ({ value }) => value?.name ?? '?';

    const columns = [
        { field: 'code', headerName: 'Code', flex: 1, minWidth: 150 },
        { field: 'date', headerName: 'Date', flex: 1, minWidth: 150, valueGetter: getDate },
        { field: 'location_origin', headerName: 'Origin location', flex: 1, minWidth: 150, valueGetter: getName },
        { field: 'product', headerName: 'Product', flex: 1, minWidth: 150, valueGetter: getName },
        { field: 'product_qty', headerName: 'Product qty', flex: 1, minWidth: 150 }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            {
                state.tt.canceled === true &&
                <div className={classes.status} style={{ color: '#dc3545' }}>
                    <Icon className={classes.icon} style={{ color: '#dc3545' }}>{'delete'}</Icon>
                    {'Canceled'}
                </div>
            }

            <Grid container style={{ backgroundColor: themeColors.card.default, margin: 0, borderRadius: 10 }}>
                <Grid item xs={6} sm={6} lg={3}>
                    <h4>{'Origin'}</h4>
                    <p>{state.tt?.origin?.name}</p>
                </Grid>
                <Grid item xs={6} sm={6} lg={3}>
                    <h4>{'Destination'}</h4>
                    <p>{state.tt?.destination?.name}</p>
                </Grid>
                <Grid item xs={6} sm={6} lg={3}>
                    <h4>{'Date'}</h4>
                    <p>{dayjs(state.tt?.date).format('MM/DD/YYYY')}</p>
                </Grid>
                <Grid item xs={6} sm={6} lg={3}>
                    <h4>{'Notes'}</h4>
                    <p>{state.tt?.notes ?? 'N/A'}</p>
                </Grid>
            </Grid>

            <h2>{`Tickets (${state.tickets.length})`}</h2>

            <div className={classes.tableContainer}>
                <DataGrid
                    autoHeight
                    pageSize={45}
                    density='compact'
                    columns={columns}
                    rows={state.tickets}
                    getRowId={row => row.idticket}
                    checkboxSelection
                />
            </div>
            {
                state.tt.canceled === false &&
                <div className={classes.controls}>
                    {
                        (state.permissions?.delete_attr) &&
                        <Button
                            variant='contained'
                            className={classes.delete}
                            onClick={cancel}
                        >
                            {'Cancel transfer'}
                        </Button>
                    }
                </div>
            }
        </div>
    );
}

export default TicketLocationTransferDetail;