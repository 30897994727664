import React, { useEffect, useContext } from 'react';

import Loader from './common/loader';

import AuthContext from './context';
import { get } from './utils/api-services';

const Loading = () => {

    const { signIn, signOut, showToast } = useContext(AuthContext);

    useEffect(() => {
        validateUser();
    });

    const validateUser = async () => {
        const authUser = localStorage.getItem('@auth_user');
        const sede = localStorage.getItem('@sede');
        const token = localStorage.getItem('@token');

        if (authUser !== null && token !== null) {
            if (sede !== 'undefined' && Boolean(sede)) {
                const user = JSON.parse(authUser);
                const response = await get(`/user/${user.iduser}`);
                if (response.status === 200) {
                    signIn(response.data, token, JSON.parse(sede));
                } else {
                    signOut();
                }
            } else {
                signOut();
                showToast('A Sede has not yet been assigned');
            }
        } else {
            signOut();
        }
    }

    return (
        <div>
            <Loader fullScreen />
        </div>
    );
}

export default Loading;