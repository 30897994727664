import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Tooltip, IconButton, Icon, TextField } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useLocation, Link } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";

import Loader from "../../common/loader";
import Crud from "../../common/crud";
import NotFound from "../../common/not-found";
import ViewTitle from "../../common/view-title";
import Trash from "../../common/trash";

import AuthContext from "../../context";
import { del, get, post, put } from "../../utils/api-services";
import helpers from "../../utils/helpers";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		paddingBottom: 20,
	},
	tableContainer: {
		width: "100%",
	},
	requiredValue: {
		fontWeight: "600",
		color: "rgba(255,0,0,1)",
	},
}));

const Products = () => {
	const { showToast, signOut, showLoader } = useContext(AuthContext);
	const classes = useStyles();
	const { pathname } = useLocation();
	const [state, setState] = useState({
		loading: true,
		products: [],
		items: [],
		item: { id: 0, name: "Choose an item" },
		product: {
			idff_product: 0,
			code: "",
			name: "",
			idff_item: 0,
		},
		permissions: {},
	});

	useEffect(() => {
		window.document.title = "Products";

		validatePermissions();
	}, []);

	const validatePermissions = () => {
		const permissions = helpers.getPermissions(pathname);
		setState((prev) => ({ ...prev, permissions }));

		permissions.read_attr ? makeRequest() : setState((prev) => ({ ...prev, loading: false }));
	};

	const makeRequest = async () => {
		showLoader(true);
		const response = await get("/farmForest/product");
		if (response.status === 200) {
			setState((prev) => ({ ...prev, loading: false, products: response?.data, items: response?.items ?? [] }));
		} else {
			console.log("[Products] makeRequest error", response);
			helpers.failRequest(response, showToast, signOut);
		}
		showLoader(false);
	};

	const handleChange = ({ target }) => setState((prev) => ({ ...prev, product: { ...prev.product, [target.name]: target.value } }));

	const handleAutocomplete = (key, value) => {
		console.log(key, value)
		if(key === "ff_item" && value === null){
			setState((prev) => ({ ...prev, item: {id: 0, name: "Choose an item"}, product: {...prev.product, idff_item: 0}}))
		}else{
			setState((prev) => ({ ...prev, item: value, product: {...prev.product, idff_item: value?.id}}))
		}
	};

	const validateForm = (type) => {
		const { idff_product, name, code, idff_item } = state.product;

		switch (type) {
			case "update":
				const data = state.products.filter((value) => value.idff_product === idff_product);

				if (data[0]) {
					const before = JSON.stringify(data[0]);
					const after = JSON.stringify(state.product);
					if (before !== after) {
						if (name.trim() && code.trim && idff_item !== 0) {
							return true;
						} else {
							showToast("All fields are required", "error");
							return false;
						}
					} else {
						showToast("Update some field", "error");
						return false;
					}
				} else {
					showToast("Select some product", "error");
					return false;
				}

			case "store":
				if (name.trim() && code.trim && idff_item !== 0 && state.item !== null) {
					return true;
				} else {
					showToast("All fields are required", "error");
					return false;
				}

			case "delete":
				if (idff_product === 0) {
					showToast("Select some product", "error");
					return false;
				}
				const cond = window.confirm("Are you sure you want to delete this product?");

				return Boolean(idff_product && cond);
			default:
				break;
		}
	};

	const clear = () => {
		setState((prev) => ({ ...prev, product: { ...prev.product, idff_product: 0, code: "", name: "", idff_item: 0, }, item: { id: 0, name: "Choose an item" } }));
	};

	const store = async () => {
		const cond = validateForm("store");

		if (cond) {
			showLoader(true);
			const response = await post("/farmForest/product/store", state.product);
			if (response.status === 200) {
				setState((prev) => ({ ...prev, products: response?.data ?? prev.products, product: { idff_product: 0, code: "", name: "", idff_item: 0 }, item: { id: 0, name: "Choose an item" }, }));
				showToast(response.message);
			} else {
				console.log("[Products] store error", response);
				helpers.failRequest(response, showToast, signOut);
				showLoader(false);

				return false;
			}
			showLoader(false);
		}

		return cond;
	};

	const update = async () => {
		const { idff_product } = state.product;
		const cond = validateForm("update");

		if (cond) {
			showLoader(true);
			const response = await put(`/farmForest/product/${idff_product}`, state.product);
			if (response.status === 200) {
				setState((prev) => ({ ...prev, products: response?.data ?? prev.products, product: { idff_product: 0, code: "", name: "", idff_item: 0, }, item: { id: 0, name: "Choose an item" }, }));
				showToast(response.message);
			} else {
				console.log("[Products] update error", response);
				helpers.failRequest(response, showToast, signOut);
			}
			showLoader(false);
		}
	};

	const destroy = async () => {
		const { idff_product } = state.product;
		const cond = validateForm("delete");

		if (cond) {
			showLoader(true);
			const response = await del(`/farmForest/product/${idff_product}`);
			if (response.status === 200) {
				setState((prev) => ({ ...prev, products: response?.data ?? prev.products, product: { idff_product: 0, code: "", name: "", idff_item: 0, }, item: { id: 0, name: "Choose an item" }, }));
				showToast(response.message);
			} else {
				console.log("[Loaders] destroy error", response);
				helpers.failRequest(response, showToast, signOut);
			}
			showLoader(false);
		}
	};

	const extractNumber = (product) => {
    let match = product.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
}

	const renderCell = ({ row }) => {
		return (
			<Link to={`/history/ff_product/${row.idff_product}`} target="_blank">
				<Tooltip title="Show history" placement="top">
					<IconButton>
						<Icon>{"history"}</Icon>
					</IconButton>
				</Tooltip>
			</Link>
		);
	};

	const columns = [
		{ field: "code", headerName: "Code", flex: 1, sortComparator: (a, b) => a.localeCompare(b, undefined, {numeric: true,sensitivity: 'base'}) },
		{ field: "name", headerName: "Name", flex: 1 },
		{ field: "ff_item", headerName: "Item", flex: 1, valueGetter: (params) => params.row?.ff_item?.name },
		{ field: "history", headerName: "Actions", flex: 0.3, renderCell },
	];

	if (state.loading) return <Loader />;

	if (!state.permissions.read_attr) return <NotFound />;

	return (
		<div className={classes.root}>
			<ViewTitle sedeFlag title="Products" />
			<div>
				<Grid container>
					<Grid item xs={12} md={4}>
						<FormGroup>
							<FormLabel>{"Code"}</FormLabel>
							<FormControl variant="filled" component={Box} width="100%">
								<FilledInput autoComplete="off" type="text" placeholder="Code" name="code" value={state.product.code} onChange={handleChange} />
							</FormControl>
						</FormGroup>
					</Grid>
					<Grid item xs={12} md={4}>
						<FormGroup>
							<FormLabel>{"Name"}</FormLabel>
							<FormControl variant="filled" component={Box} width="100%">
								<FilledInput autoComplete="off" type="text" placeholder="Name" name="name" value={state.product.name} onChange={handleChange} />
							</FormControl>
						</FormGroup>
					</Grid>
					<Grid item xs={12} md={4}>
						<FormGroup>
							<FormLabel>{"Item"} <span className={classes.requiredValue}>*</span>{" "} </FormLabel>
							<Autocomplete
								options={state.items}
								value={state.item}
								getOptionLabel={(option) => option?.name ?? ""}
								getOptionSelected={(option, value) => option.id === value.id}
								onChange={(event, value) => handleAutocomplete("ff_item", value)}
								renderInput={(params) => <TextField {...params} placeholder="Item" />}
							/>
						</FormGroup>
					</Grid>
				</Grid>
			</div>

			<Crud permissions={state.permissions} create={{ create: clear, store }} update={update} destroy={destroy} cancel={clear} />

			{state.permissions.delete_attr && <Trash table="ff_product" id="idff_product" onRefresh={makeRequest} />}

			<div className={classes.tableContainer}>
				<DataGrid
					rows={state.products}
					columns={columns}
					pageSize={45}
					density="compact"
					autoHeight
					getRowId={(row) => row.idff_product}
					loading={state.loading}
					onRowClick={({ row }) => setState((prev) => ({ ...prev, product: row, item: row.ff_item }))}
				/>
			</div>
		</div>
	);
};

export default Products;
