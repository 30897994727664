import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Switch, Route, useLocation, useRouteMatch } from "react-router-dom";

import BillsCuttingTicketsIndex from "./bills-cutting-tickets-index";
import BillsCuttingTicketsCreateUpdate from "./bills-cutting-tickets-create-update";
import BillsCuttingTicketsList from "./bills-cutting-tickets-list";

import ViewTitle from "../../common/view-title";
import Loader from "../../common/loader";
import NotFound from "../../common/not-found";

import helpers from "../../utils/helpers";

const useStyles = makeStyles(() => ({
	root: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
	},
}));

export default function BillsAndCuttigTickets() {
	const classes = useStyles();
	const { pathname } = useLocation();
	const { path } = useRouteMatch();
	const [state, setState] = useState({
		loading: true,
		permissions: {},
	});

	useEffect(() => {
		const validatePermissions = () => {
			const permissions = helpers.getPermissions(pathname);
			setState((prev) => ({ ...prev, permissions, loading: false }));
		};

		validatePermissions();
	}, []);

	if (state.loading) {
		return <Loader />;
	}

	if (!state.permissions?.read_attr) {
		return <NotFound />;
	}

	return (
		<div className={classes.root}>
			<ViewTitle sedeFlag title="Bills and Cutting Tickets" />

			<Switch>
				<Route exact path={path}>
					<BillsCuttingTicketsIndex permissions={state.permissions} />
				</Route>
				<Route exact strict path={`${path}/list`}>
					<BillsCuttingTicketsList permissions={state.permissions} />
				</Route>
				<Route exact strict path={`${path}/create`}>
					<BillsCuttingTicketsCreateUpdate permissions={state.permissions} />
				</Route>
				<Route exact strict path={`${path}/:id`}>
					<BillsCuttingTicketsCreateUpdate permissions={state.permissions} />
				</Route>
				<Route path={`${path}/*`}>
					<NotFound />
				</Route>
			</Switch>
		</div>
	);
}
