import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal, Typography, Button } from '@material-ui/core';
import { DateRange } from 'react-date-range';
import dayjs from 'dayjs';

import themeColors from '../assets/colors';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.background.default,
        padding: 20,
        borderRadius: 10,
        '@media (min-width: 480px)': {
            width: '80%'
        },
        '@media (min-width: 720px)': {
            width: '50%'
        },
        '@media (min-width: 1000px)': {
            width: '40%'
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 15
    },
    button: {
        width: '30%'
    }
}));

const DateRangeModal = ({ open, onClose, range, onChange, minDate, maxDate }) => {

    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div className={classes.container}>
                <Typography variant='h2'>{'Select the date range'}</Typography>
                <DateRange
                    editableDateInputs={true}
                    onChange={onChange}
                    moveRangeOnFirstSelection={false}
                    ranges={range}
                    maxDate={maxDate ? maxDate : undefined}
                    minDate={minDate ? minDate : dayjs().subtract(15, 'years').toDate()}
                />
                <div className={classes.buttonContainer}>
                    <Button
                        variant='contained'
                        color='secondary'
                        className={classes.button}
                        onClick={onClose}
                    >
                        {'Close'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default DateRangeModal;