import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Tooltip, IconButton, Icon } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useLocation, Link } from "react-router-dom";

import Loader from "../../common/loader";
import Crud from "../../common/crud";
import NotFound from "../../common/not-found";
import ViewTitle from "../../common/view-title";
import Trash from "../../common/trash";

import AuthContext from "../../context";
import { del, get, post, put } from "../../utils/api-services";
import helpers from "../../utils/helpers";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		paddingBottom: 20,
	},
	tableContainer: {
		width: "100%",
	},
}));

const Truckers = () => {
	const { showToast, signOut, showLoader } = useContext(AuthContext);
	const classes = useStyles();
	const { pathname } = useLocation();
	const [state, setState] = useState({
		loading: true,
		truckers: [],
		trucker: {
			idff_trucking: 0,
			name: "",
		},
		permissions: {},
	});

	useEffect(() => {
		window.document.title = "Trucking companies";

		validatePermissions();
	}, []);

	const validatePermissions = () => {
		const permissions = helpers.getPermissions(pathname);
		setState((prev) => ({ ...prev, permissions }));

		permissions.read_attr ? makeRequest() : setState((prev) => ({ ...prev, loading: false }));
	};

	const makeRequest = async () => {
		showLoader(true);
		const response = await get("/farmForest/trucking");
		if (response.status === 200) {
			setState((prev) => ({ ...prev, loading: false, truckers: response?.data ?? [] }));
		} else {
			console.log("[Trucking] makeRequest error", response);
			helpers.failRequest(response, showToast, signOut);
		}
		showLoader(false);
	};

	const handleChange = ({ target }) => setState((prev) => ({ ...prev, trucker: { ...prev.trucker, [target.name]: target.value } }));

	const validateForm = (type) => {
		const { idff_trucking, name } = state.trucker;

		switch (type) {
			case "update":
				const data = state.truckers.filter((value) => value.idff_trucking === idff_trucking);

				if (data[0]) {
					const before = JSON.stringify(data[0]);
					const after = JSON.stringify(state.trucker);
					if (before !== after) {
						if (name.trim()) {
							return true;
						} else {
							showToast("All fields are required", "error");
							return false;
						}
					} else {
						showToast("Update some field", "error");
						return false;
					}
				} else {
					showToast("Select some trucking company", "error");
					return false;
				}

			case "store":
				if (name.trim()) {
					return true;
				} else {
					showToast("All fields are required", "error");
					return false;
				}

			case "delete":
				if (idff_trucking === 0) {
					showToast("Select some trucking company", "error");
					return false;
				}
				const cond = window.confirm("Are you sure you want to delete this trucking companyr?");

				return Boolean(idff_trucking && cond);
			default:
				break;
		}
	};

	const clear = () => {
		setState((prev) => ({ ...prev, trucker: { ...prev.trucker, idff_trucking: 0, name: "" } }));
	};

	const store = async () => {
		const cond = validateForm("store");

		if (cond) {
			showLoader(true);
			const response = await post("/farmForest/trucking/store", state.trucker);
			if (response.status === 200) {
				setState((prev) => ({ ...prev, truckers: response?.data ?? prev.truckers, trucker: { idff_trucking: 0, name: "" } }));
				showToast(response.message);
			} else {
				console.log("[Trucking] store error", response);
				helpers.failRequest(response, showToast, signOut);
				showLoader(false);

				return false;
			}
			showLoader(false);
		}

		return cond;
	};

	const update = async () => {
		const { idff_trucking } = state.trucker;
		const cond = validateForm("update");

		if (cond) {
			showLoader(true);
			const response = await put(`/farmForest/trucking/${idff_trucking}`, state.trucker);
			if (response.status === 200) {
				setState((prev) => ({ ...prev, truckers: response?.data ?? prev.truckers, trucker: { idff_trucking: 0, name: "" } }));
				showToast(response.message);
			} else {
				console.log("[Trucking] update error", response);
				helpers.failRequest(response, showToast, signOut);
			}
			showLoader(false);
		}
	};

	const destroy = async () => {
		const { idff_trucking } = state.trucker;
		const cond = validateForm("delete");

		if (cond) {
			showLoader(true);
			const response = await del(`/farmForest/trucking/${idff_trucking}`);
			if (response.status === 200) {
				setState((prev) => ({ ...prev, truckers: response?.data ?? prev.truckers, trucker: { idff_trucking: 0, name: "" } }));
				showToast(response.message);
			} else {
				console.log("[Trucking] destroy error", response);
				helpers.failRequest(response, showToast, signOut);
			}
			showLoader(false);
		}
	};

	const renderCell = ({ row }) => {
		return (
			<Link to={`/history/ff_trucking/${row.idff_trucking}`} target="_blank">
				<Tooltip title="Show history" placement="top">
					<IconButton>
						<Icon>{"history"}</Icon>
					</IconButton>
				</Tooltip>
			</Link>
		);
	};

	const columns = [
		{ field: "name", headerName: "Name", flex: 1 },
		{ field: "history", headerName: "Actions", flex: 0.3, renderCell },
	];

	if (state.loading) {
		return <Loader />;
	}
	if (!state.permissions.read_attr) {
		return <NotFound />;
	}

	return (
		<div className={classes.root}>
			<ViewTitle sedeFlag title="Trucking companies" />
			<div>
				<Grid container>
					<Grid item xs={12} md={6}>
						<FormGroup>
							<FormLabel>{"Name"}</FormLabel>
							<FormControl variant="filled" component={Box} width="100%">
								<FilledInput autoComplete="off" type="text" placeholder="Name" name="name" value={state.trucker.name} onChange={handleChange} />
							</FormControl>
						</FormGroup>
					</Grid>
				</Grid>
			</div>

			<Crud permissions={state.permissions} create={{ create: clear, store }} update={update} destroy={destroy} cancel={clear} />

			{state.permissions.delete_attr && <Trash table="ff_trucking" id="idff_trucking" onRefresh={makeRequest} />}

			<div className={classes.tableContainer}>
				<DataGrid
					rows={state.truckers}
					columns={columns}
					pageSize={45}
					density="compact"
					autoHeight
					getRowId={(row) => row.idff_trucking}
					loading={state.loading}
					onRowClick={({ row }) => setState((prev) => ({ ...prev, trucker: row }))}
				/>
			</div>
		</div>
	);
};

export default Truckers;
