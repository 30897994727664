import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, TextField, Tooltip, IconButton, Icon } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLocation, Link } from 'react-router-dom';
import dayjs from 'dayjs';

import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import Loader from '../../common/loader';
import ViewTitle from '../../common/view-title';
import Trash from '../../common/trash';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%'
    }
}));

const defTypes = [{ id: 0, name: 'Choose a type' }];

const Extras = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [state, setState] = useState({
        loading: true,
        products: [],
        types: [],
        locations: [],
        suppliers: [],
        extras: [],
        extra: {
            idextra: 0,
            date: dayjs().format('YYYY-MM-DD'),
            value: 0,
            idproduct: 0,
            product: {},
            idtype: 0,
            type: {},
            idlocation: 0,
            location: {},
            idsupplier: 0,
            supplier: {}
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Extras';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/extras');
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                extras: response?.data ?? [],
                products: response?.products ?? [],
                types: defTypes,
                locations: response?.locations ?? [],
                suppliers: response?.suppliers ?? [],
                extra: {
                    ...prev.extra,
                    product: response?.products[0] ?? {},
                    type: defTypes[0],
                    location: response?.locations[0] ?? {},
                    supplier: response?.suppliers[0] ?? {}
                }
            }));
        } else {
            console.log('[Extras] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const validateForm = (option) => {
        const { idextra, value, product, type, location, supplier } = state.extra;

        switch (option) {
            case 'update':
                const data = state.extras.filter(value => value.idextra === idextra);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.extra);
                    if (before !== after) {
                        if (value >= 0 && product.id && type.id && location.id && supplier.id) {
                            return true;
                        } else {
                            showToast('All fields is required', 'error');
                            return false;
                        }
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select some extra', 'error');
                    return false;
                }

            case 'store':

                if (value >= 0 && product.id && type.id && location.id && supplier.id) {
                    return true;
                } else {
                    showToast('All fields is required', 'error');
                    return false;
                }

            case 'delete':
                if (idextra === 0) {
                    showToast('Select some extra', 'error');
                    return false;
                }
                const cond = window.confirm(`Are you sure you want to delete this extra?`);

                return Boolean(idextra && cond);
            default:
                break;
        }
    }

    const clear = () => setState(prev => ({
        ...prev,
        types: defTypes,
        extra: {
            ...prev.extra,
            idextra: 0,
            date: dayjs().format('YYYY-MM-DD'),
            value: 0,
            product: prev.products[0],
            type: defTypes[0],
            location: prev.locations[0],
            supplier: prev.suppliers[0]
        }
    }));


    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/extra/store', state.extra);
            if (response.status === 200) {
                setState(prev => ({ ...prev, extras: response?.data ?? prev.extras }));
                showToast(response.message);
            } else {
                console.log('[Extras] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false;
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { idextra } = state.extra;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/extra/${idextra}/update`, state.extra);
            if (response.status === 200) {
                setState(prev => ({ ...prev, extras: response?.data ?? prev.extras }));
                showToast(response.message);
            } else {
                console.log('[Extras] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idextra } = state.extra;
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/extra/${idextra}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, extras: response?.data ?? prev.extras }));
                showToast(response.message);
            } else {
                console.log('[Extras] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const getTypes = async product => {
        const response = await get(`/product/${product.id}`);
        if (response.status === 200) {
            const { data } = response;
            setState(prev => ({ ...prev, types: [...defTypes, ...data.types] }));
        } else {
            setState(prev => ({ ...prev, types: defTypes, extra: { ...prev.extra, type: defTypes[0] } }));
            console.log('[Extras] getTypes error', response);
            helpers.failRequest(response, showToast, signOut);
        }
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, extra: { ...prev.extra, [target.name]: target.value } }));

    const handleAutocomplete = (key, value) => setState(prev => ({ ...prev, extra: { ...prev.extra, [key]: value } }));

    const getField = params => params.value.name;

    const getDateField = params => dayjs(params.value).format('MM/DD/YYYY');

    const getValueField = params => `$ ${params.value}`;

    const renderCell = ({ row }) => {
        return (
            <Link to={`/history/extras/${row.idextra}`} target='_blank'>
                <Tooltip title='Show history' placement='top'>
                    <IconButton>
                        <Icon>{'history'}</Icon>
                    </IconButton>
                </Tooltip>
            </Link>
        );
    }

    const columns = [
        { field: 'date', headerName: 'Date', flex: 1, minWidth: 110, valueGetter: getDateField },
        { field: 'product', headerName: 'Product', flex: 1, minWidth: 180, valueGetter: getField },
        { field: 'type', headerName: 'Type', flex: 1, minWidth: 180, valueGetter: getField },
        { field: 'location', headerName: 'Location', flex: 1, minWidth: 180, valueGetter: getField },
        { field: 'supplier', headerName: 'Supplier', flex: 1, minWidth: 180, valueGetter: getField },
        { field: 'value', headerName: 'Value', flex: 1, minWidth: 100, valueGetter: getValueField },
        { field: 'history', headerName: 'Actions', flex: 0.5, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle sedeFlag title='Extras' />
            <Grid container>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Product'}</FormLabel>
                        <Autocomplete
                            options={state.products}
                            value={state.extra.product}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => {
                                handleAutocomplete('product', value);
                                (value.id != 0)
                                    ? getTypes(value)
                                    : setState(prev => ({ ...prev, types: defTypes, extra: { ...prev.extra, type: defTypes[0] } }));
                            }}
                            renderInput={params => <TextField {...params} placeholder='Product' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Type'}</FormLabel>
                        <Autocomplete
                            options={state.types}
                            value={state.extra.type}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleAutocomplete('type', value)}
                            renderInput={params => <TextField {...params} placeholder='Type' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Location'}</FormLabel>
                        <Autocomplete
                            options={state.locations}
                            value={state.extra.location}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleAutocomplete('location', value)}
                            renderInput={params => <TextField {...params} placeholder='Location' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Supplier'}</FormLabel>
                        <Autocomplete
                            options={state.suppliers}
                            value={state.extra.supplier}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleAutocomplete('supplier', value)}
                            renderInput={params => <TextField {...params} placeholder='Supplier' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Date'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='date'
                                placeholder='Date'
                                name='date'
                                value={state.extra.date}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Value'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='number'
                                placeholder='Value'
                                name='value'
                                value={state.extra.value}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            {
                state.permissions.delete_attr &&
                <Trash table='extras' id='idextra' onRefresh={makeRequest} />
            }

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.extras}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idextra}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, extra: row }))}
                />
            </div>
        </div>
    );
}

export default Extras;